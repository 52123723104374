import React, { useState } from 'react'
import imgShowBlu from '../../../../styles/images/show_blu.png'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
// import { ReactComponent as ISignature } from '../../../../styles/images/svg/file-signature.svg'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { ReactComponent as IExport } from '../../../../styles/images/svgs/regular/file-export.svg'

import { BwmInput } from '../BwmInputB5'
import * as Constants from '../../../../config/Constants'
import {
  postSendFileToVerifySidCertificate,
  postSendFileToVerifySidCertificateById,
  exportCertificateFiles
} from '../../../../actions/index'
import { PopupError } from '../../PopupError'
import ModalPasswordBits from './ModalPasswordBits'
import { handleChangeCerts, saveSid } from '../../SidCertsHandler'
import * as Utility from '../../Utility'
import { PopupSuccess } from '../../PopupSuccess'
import { PageSpinner } from '../../spinner/PageSpinner'
import { LicenseExpiredPopup } from '../../auth/license/LicenseExpiredPopup'
import { Col, Modal } from 'react-bootstrap'
import { StarTooltip } from '../../tooltips/Tooltip'

function SidForm(props) {
  const [openModalPasswordBits, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState('')
  const [certificateDto, setCertificateDto] = useState()
  const [signatureCertificateDto, setSignatureCertificateDto] = useState()

  const nameApplication = props.nameApplication

  /**
   * Questa funzione serve per capire se attivare il pulsante VERIFICA.
   */
  //condizioni per abilitare il pulsante:
  //- i certificati devono essere stati caricati
  //- il codice fiscale, numero sid e password devono essere valorizzati
  //- la licenza deve essere valida
  const shouldEnableVerifyButton =
    Utility.isNotEmpty(props.coraSubjectSid?.sidNumber) &&
    Utility.isNotEmpty(props.coraSubjectSid?.password) &&
    Utility.isNotEmpty(props.fiscalCode) &&
    !props.disabled &&
    props.coraSubjectSid?.certificate1 &&
    props.coraSubjectSid?.certificate1.includes('UTEC.P12') &&
    props.coraSubjectSid?.certificate2 &&
    props.coraSubjectSid?.certificate2.includes('UTEF.P12')

  const shouldEnableLoadButton =
    Utility.isNotEmpty(props.coraSubjectSid?.sidNumber) &&
    Utility.isNotEmpty(props.coraSubjectSid?.password) &&
    Utility.isNotEmpty(props.fiscalCode)

  const onClickIconPsw = () => {
    let x = document.getElementById(`password${props.id || 0}`)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    }
  }

  /**
   * verifica, i certificati caricati contro la password fornita nel form
   * se il SID non è ancora stato salvato, viene passato anche il file  UTEC.P12 per la verifica
   * se il SID è già stato salvato, lo aggiorna con i nuovi dati(bits, date di scadenza/emissione)
   */
  const verifySidCertificate = () => {
    const id = props.coraSubjectSid?.id
    const password = props.coraSubjectSid?.password
    const files = props.certificateFiles.files
    if (Utility.isNotEmpty(id)) {
      postSendFileToVerifySidCertificateById(nameApplication, id, password, props.fiscalCode).then(
        (answer) => onCorrectRequest(answer),
        (err) => errRequest(err)
      )
    } else if (files.length > 0 && files.length < 4) {
      postSendFileToVerifySidCertificate(nameApplication, files, password, props.fiscalCode).then(
        (answer) => {
          onCorrectRequest(answer)
        },
        (err) => errRequest(err)
      )
    }
  }

  /**
   * al caricamento, viene effettuata automaticamente una verifica.
   * Il savataggio dei file sarà subordinata alla verifica con esito positivo
   * @param {*} e evento
   */
  const onChangeCertificateFiles = (e) => {
    const password = props.coraSubjectSid?.password
    const files = props.certificateFiles.files
    postSendFileToVerifySidCertificate(nameApplication, files, password, props.fiscalCode).then(
      (answer) => {
        onCorrectRequest(answer)
        handleChangeCerts(
          e,
          props.certificateFiles,
          (value) => props.setErrorCertificates(value),
          (value) => props.setCoraSubjectSid(value),
          props.coraSubjectSid,
          props.subject?.coraSubjectSid,
          props.defaultEmail
        )
      },
      (err) => errRequest(err)
    )
  }

  const onClickExportCertificateFiles = () => {
    const id = props.coraSubjectSid?.id
    if (!id) {
      return
    }
    exportCertificateFiles(id)
      .then(
        (res) => {
          if (res) {
            Utility.downloadFile(res.data, 'certificati.zip')
            PopupSuccess({ text: 'Il file è stato scaricato correttamente' })
          }
        },
        (err) => {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => setIsLoading(false))
  }

  /**
   * Questa funzione aggiorna il sid con i dati appena ottenuti dalla verifica dei certificati
   * @param {array} answer la risposta data dalla chiamata rest
   */
  const onCorrectRequest = (answer) => {
    answer.forEach((element) => {
      if (element.certificateType === 'CERTIFICATO') {
        setCertificateDto(element)
        props.handleChangeSidValues({
          encryptionBits: element.econdingBits || null,
          endDate: element.endDate ? new Date(element.endDate) : null,
          startDate: element.startDate ? new Date(element.startDate) : null
        })
      } else if (element.certificateType === 'FIRMA') {
        setSignatureCertificateDto(element)
      }
    })
    setOpen(true)
  }

  /**
   * Questa funzione deve ritornare un popup e nel farlo deve capire che errore dare
   * Essendo che non c'è la certezza che title abbia un valore si fa questo controllo e
   * e si mette un valore piu generico casomai non c'è niente di piu descrittivo dal
   * backend
   * @param {*} err L'errore dato nel fare la chiamata REST
   */
  const errRequest = (err) => {
    if (err?.title) {
      if (err.errorKey === 'InvalidLicense') {
        LicenseExpiredPopup()
      } else {
        PopupError({ text: err.title })
      }
    } else {
      PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
    }
  }

  const datesText =
    props.coraSubjectSid?.startDate &&
    props.coraSubjectSid?.expirationDate &&
    `${new Date(props.coraSubjectSid?.startDate).toLocaleDateString()} - ${new Date(props.coraSubjectSid?.expirationDate).toLocaleDateString()}`

  const hideModal = () => {
    if (props.coraSubjectSid.id) {
      saveSid(
        undefined,
        props.coraSubjectSid,
        props.coraSubjectSid.fiscalCode,
        props.coraSubjectSid.application,
        undefined,
        () => PopupError(),
        () => setOpen(false)
      )
    } else {
      setOpen(false)
    }
  }

  return (
    <div className="">
      {isLoading && <PageSpinner />}
      <div className="row mb-2">
        <div className="col d-flex justify-content-end align-self-center">
          <span className="image-upload">
            <label htmlFor={`certificateFiles${props.id || 0}`}>
              <StarTooltip
                text={
                  !shouldEnableLoadButton &&
                  'Prima di caricare i certificati, inserire Codice fiscale/P.IVA, numero SID e password certificati'
                }>
                <div
                  className={`btn btn-outline-primary btn-empty px-3 btn-sm btn-cell text-small ${!shouldEnableLoadButton ? 'disabled' : ''}`}>
                  <IUpload width="12" height="18" fill="#128186" className="me-2" />
                  CARICA
                </div>
              </StarTooltip>
            </label>
            <input
              id={`certificateFiles${props.id}`}
              type="file"
              name={`certificateFiles${props.id}`}
              multiple
              accept=".p12,.ks"
              ref={(ref) =>
                props.handleCertificateFilesCustom && props.handleCertificateFilesCustom(ref)
              }
              disabled={Utility.isEmpty(props.coraSubjectSid?.password)}
              onChange={(e) => onChangeCertificateFiles(e)}
            />
          </span>

          <StarTooltip
            text={
              !shouldEnableVerifyButton &&
              'Inserire Codice fiscale/P.IVA, numero SID, password certificati e caricare file'
            }>
            <button
              disabled={!shouldEnableVerifyButton || props.disabled}
              className="btn btn-outline-primary btn-empty px-3 btn-sm btn-cell me-2 ms-2 text-small"
              onClick={() => verifySidCertificate()}
              title="Verifica certificati">
              <ICheck width="18" height="17" fill="#128186" className="me-2" />
              VERIFICA
            </button>
          </StarTooltip>
          <button
            className="btn btn-outline-primary btn-empty px-3 btn-sm btn-cell text-small"
            onClick={() => onClickExportCertificateFiles()}
            title="Scarica certificati"
            disabled={!(props.coraSubjectSid?.id && shouldEnableVerifyButton) || props.disabled}>
            <IExport width="16" height="18" fill="#128186" className="me-2" />
            ESPORTA
          </button>
        </div>
      </div>
      {props.errors?.errorCertificates && !props.errors.errorCertificates.isValid && (
        <div className="row align-items-center justify-content-end text-danger me-2">
          <strong>{props.errors.errorCertificates.msg}</strong>
        </div>
      )}
      <div className="row mt-2 align-items-center">
        <div className="w-26 d-flex pe-2">
          <BwmInput
            disabled={props.disabled}
            className={'form-control'}
            label={'Numero accreditamento SID'}
            name="sidNumber"
            value={props.coraSubjectSid?.sidNumber}
            error={props.errors?.errorSidNumber?.msg}
            touched={props.errors?.errorSidNumber?.isValid === false}
            onChange={(e) => props.handleChangeSid(e, 'errorSidNumber', 'Campo obbligatorio')}
          />
        </div>
        <div className="col pe-2">
          <div className="d-flex">
            <BwmInput
              disabled={props.disabled}
              id={`password${props.id || 0}`}
              type={'password'}
              className={'form-control'}
              label={'Password di protezione (Max 20 caratteri)'}
              maxLength={20}
              name="password"
              value={props.coraSubjectSid?.password}
              error={props.errors?.errorPassword?.msg}
              touched={props.errors?.errorPassword?.isValid === false}
              onChange={(e) => props.handleChangeSid(e, 'errorPassword', 'Campo obbligatorio')}
              autoComplete={'new-password'}
            />
            <div className="align-self-center mt-2">
              <img
                alt={'eye-blu'}
                className="img-info ms-2 cursor-pointer"
                src={imgShowBlu}
                onClick={onClickIconPsw}
              />
            </div>
          </div>
        </div>
        <div className="w-23 w-lg-31">
          <BwmInput
            disabled
            label={'Validità certificati'}
            className={'form-control'}
            name="expirationDate"
            value={datesText}
            dataTip={datesText}
          />
        </div>
        {openModalPasswordBits && (
          <Modal
            show={openModalPasswordBits}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={hideModal}
            size="lg">
            <div className="bar"></div>
            <Modal.Header closeButton>
              <Modal.Title>ESITO VERIFICA CERTIFICATI</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: '27px', paddingTop: '70px' }}>
              <div className="d-flex">
                <Col>
                  <ModalPasswordBits
                    certificateDto={certificateDto}
                    fiscalCode={props.fiscalCode}
                  />
                </Col>

                <Col>
                  <ModalPasswordBits
                    certificateDto={signatureCertificateDto}
                    fiscalCode={props.fiscalCode}
                  />
                </Col>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
              <button className="btn btn-primary btn-new-rel " onClick={hideModal}>
                CHIUDI E SALVA
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default SidForm
