import { useState } from 'react'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { ReactComponent as ITextFile } from '../../../../styles/images/svgs/regular/file-alt.svg'
import { CommunicationModal } from './CommunicationModal'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import { isEmpty } from '../../../shared/Utility'

export function ElaborateCommunicationModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [communicationFile, setCommunicationFile] = useState('')
  const [errorCommunication, setErrorCommunication] = useState({ isValid: true, msg: '' })
  const [communication, setCommunication] = useState(undefined)
  const [sentCommunications, setSentCommunications] = useState([])

  const closeModal = () => {
    setLoading(false)
    setCommunication(undefined)
    setCommunicationFile('')
    setShowModal(false)
    setSentCommunications([])
  }

  const openModal = () => {
    setShowModal(true)
  }

  const handleUploadCommunication = (val) => {
    if (isEmpty(val)) {
      setErrorCommunication({ isValid: false, msg: "E' necessario inserire almeno un file" })
    } else {
      const file = val.target.files[0]
      setCommunicationFile(file)
      if (val.target.files.length !== 1) {
        setErrorCommunication({ isValid: false, msg: 'La comunicazione deve essere singola' })
      } else if (!checkFileName(file.name)) {
        setErrorCommunication({ isValid: false, msg: 'Estensione file errato' })
      } else if (file.size > 15 * 1000 * 1000) {
        setErrorCommunication({
          isValid: false,
          msg: "File troppo grande per l'elaborazione (max 15MB). Si prega di compattarlo in formato ZIP o RAR e riprovare"
        })
      } else {
        setErrorCommunication({ isValid: true, msg: '' })
      }
    }
  }

  const checkFileName = (name) => {
    const ext = name.split('.').pop().toUpperCase()
    return ['TXT', 'RAR', 'ZIP', '7Z'].includes(ext)
  }

  const handleSendCommunication = () => {
    setShowModal(false)
    setLoading(true)
    const formData = new FormData()
    formData.append('dataFile', communicationFile)

    actions.elaborateCommunicationTxt(formData, props.subjectId).then(
      (communication) => {
        if (communication && communication[0]?.id) {
          setLoading(false)
          setCommunication(communication[0])
          setCommunicationFile('')
          setShowModal(true)
          const _sentCommunications = cloneDeep(sentCommunications)
          _sentCommunications.push(communication[0])
          setSentCommunications(_sentCommunications)
        } else {
          setLoading(false)
          PopupError({
            text: "Si è verificato un errore nell'elaborazione della ricevuta. Errore non gestito. "
          })
        }
      },
      (errors) => {
        setLoading(false)
        setCommunication(undefined)
        setCommunicationFile('')
        setShowModal(false)
        PopupError({ text: errors || APPLICATION_GENERIC_ERROR })
      }
    )
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        className={`btn btn-outline-primary  btn-empty px-4 btn-sm`}
        data-tip={'Carica comunicazione TXT'}
        onClick={openModal}>
        <ITextFile className={'padding-right-4px svg'} width="16" fill={`#128186`} />
        &nbsp; Rielabora comunicazione TXT
      </button>
      {communication ? (
        <CommunicationModal
          showModal={showModal}
          closeModal={closeModal}
          communications={[communication]}
          coraSubject={communication.subject}
          downloadTxt={props.downloadTxt}
          downloadPecTz={props.downloadPecTz}
          downloadDgn={props.downloadDgn}
          downloadPdf={props.downloadPdf}
          downloadErrorReport={props.downloadErrorReport}
        />
      ) : (
        <Modal
          backdrop="static"
          show={showModal}
          onHide={closeModal}
          dialogClassName="modal-40w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>ELABORA COMUNICAZIONE TXT</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-between">
              <div className="image-upload text-start">
                <label htmlFor={`communicationFile`}>
                  <div className="certificateFiles">
                    CARICA FILE COMUNICAZIONE <IUpload width="25" fill="#000" />
                  </div>
                </label>
                <input
                  id={`communicationFile`}
                  type="file"
                  name={`communicationFile`}
                  multiple
                  onChange={(e) => handleUploadCommunication(e)}
                  accept={'.txt,.zip,.rar.7z'}
                />
                <div className="certificatiLoaded text-start">
                  {!errorCommunication.isValid ? (
                    <div className="certificatiLoaded-error">{errorCommunication.msg}</div>
                  ) : (
                    <div>
                      {communicationFile ? (
                        <div>
                          <ICheck width="12" fill="#8e8e8e" />
                          {communicationFile.name}
                        </div>
                      ) : (
                        <div>---</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
              <button
                disabled={!errorCommunication.isValid || !communicationFile}
                onClick={handleSendCommunication}
                type="button"
                className="btn btn-primary btn-new-rel px-5">
                CONFERMA
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
