import { useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import { StarTooltip } from '../../shared/tooltips/Tooltip'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('it', it)

export function ProvinceField(props) {
  const [error, setError] = useState()

  useEffect(() => {
    if (props.error !== undefined && props.error !== error) {
      setError(props.error)
    } else {
      setError(undefined)
    }
  }, [props.error])

  const inputWrapperClass = `input-wrapper ${props.touched && error !== undefined ? 'has-error' : ''}`

  return (
    <StarTooltip text={props.placeholder}>
      <div className={inputWrapperClass}>
        <FloatingLabel id="floatingLabel" label={props.label}>
          <Form.Control
            type={'text'}
            name={props.name}
            value={props.province || ''}
            disabled={props.disabled}
            placeholder={props.placeholder}
            isInvalid={props.touched ? error : undefined}
            onFocus={props.onFocus}
            onChange={props.onChange}
          />
        </FloatingLabel>
      </div>
    </StarTooltip>
  )
}
