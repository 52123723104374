import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import axiosService from '../../../../services/axios-service'
import { cloneDeep } from 'lodash'
import { PaymentRow } from './NewPaymentRow'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { isNotEmpty } from '../../../shared/Utility'

const THIRD_TAB = 'SALDO E PAGAMENTI'

export const PaymentList = forwardRef((props, ref) => {
  const [paymentRows, setPaymentRows] = useState([])
  const [relationshipPaymentTypes, setRelationshipPaymentTypes] = useState([])
  const paymentRefs = useRef([])

  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)

  useImperativeHandle(ref, () => ({
    addCrsPayment(id) {
      return _addCrsPayment(id)
    },
    getCrsPaymentList(id) {
      return _getCrsPaymentList(id)
    }
  }))

  useEffect(() => {
    if (props.activeTab === THIRD_TAB) {
      getRelationshipPaymentTypes()
      _getCrsPaymentList()
    }
    resetCheckBox()
  }, [props.activeTab])

  const _getCrsPaymentList = (id) => {
    let relId = props.relationship?.id || id
    const axiosInstance = axiosService.getInstance()
    if (relId && props.subject?.id) {
      axiosInstance
        .get(`/api/crs/custom/crs-payments/${props.subject?.id}/${relId}`)
        .then((res) => {
          setPaymentRows(res.data)
          setTimeout(() => {
            const element = document.getElementById('payment-list')
            element.scrollTop += 200
          }, 400)
        })
    }
  }

  const getRelationshipPaymentTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-payment-types').then((res) => {
      setRelationshipPaymentTypes(res.data)
    })
  }

  const renderRows = () => {
    return [
      paymentRows.map((payment, index) => {
        return (
          <PaymentRow
            key={'payment' + payment.id}
            ref={(el) => (paymentRefs.current[index + 1] = el)}
            payment={payment}
            currencies={props.currencies}
            relationship={props.relationship}
            relationshipPaymentTypes={relationshipPaymentTypes}
            checkbox={checkedItems[payment.progressive]}
            onClickCheckBox={(e) => handleCheckChange(e, payment.progressive)}
          />
        )
      }),
      <PaymentRow
        key={'payment_0'}
        ref={(el) => (paymentRefs.current[0] = el)}
        currencies={props.currencies}
        relationship={props.relationship}
        relationshipPaymentTypes={relationshipPaymentTypes}
      />
    ]
  }

  const _addCrsPayment = (id) => {
    const relId = props.relationship?.id || id

    const newPaymentRows = cloneDeep(paymentRefs.current)
      .filter((ref) => !!ref)
      .map((ref) => ref.getData())

    newPaymentRows.forEach(async (data, index) => {
      if (isNotEmpty(data?.amount) && isNotEmpty(data?.paymentType?.id)) {
        try {
          await actions.addCrsPayment(data, relId)
          _getCrsPaymentList()
        } catch (errors) {
          PopupError({ ...props, text: errors })
        }
      }
    })

    // Resetta i campi del primo pagamento
    paymentRefs.current[0]?.resetFields()
    _getCrsPaymentList()
  }

  const deleteElement = () => {
    let relId = props.relationship?.id

    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      data: Object.keys(checkedItems),
      url: `/api/crs/custom/crs-payments/${relId}`,
      method: 'DELETE'
    }).then(
      () => {
        getRelationshipPaymentTypes()
        _getCrsPaymentList()
        resetCheckBox()
      },
      (errors) => {
        PopupError({ text: errors })
      }
    )
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newPaymentRows = cloneDeep(paymentRows)
      newPaymentRows.forEach((payment) => (items[payment.progressive] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, progressive) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[progressive] = true
      else delete newCheckedItems[progressive]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === paymentRows?.length)
      for (const checkedItem of Object.keys(checkedItems)) {
        if (checkedItem) {
          return
        }
      }
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  return (
    <>
      <div>
        <div id="payment-list" className="star-table payment-list">
          <div className="text-start header-table">
            <div className="w-5 text-center div-td-sm justify-content-center">
              <input
                disabled={!paymentRows?.length > 0}
                type="checkbox"
                checked={allCheck}
                onChange={(e) => handleAllCheckChange(e)}
              />
            </div>
            <div className="w-13 div-td-sm">PROG.</div>
            <div className="w-41 div-td-sm">TIPO PAGAMENTO</div>
            <div className="w-28 div-td-sm">IMPORTO</div>
            <div className="w-13 div-td-sm">DIVISA</div>
          </div>
          {renderRows()}
        </div>
        <div className={'row-payment mt-3'}>
          <button
            className="btn btn-outline-primary btn-empty px-4 me-2 btn-sm btn-cell"
            disabled={Object.keys(checkedItems)?.length === 0 && !allCheck}
            onClick={() => deleteElement()}>
            <IDelete
              className={'padding-bottom-4px'}
              width="12"
              fill={` ${Object.keys(checkedItems)?.length === 0 && !allCheck ? '#FFFFFF' : '#128186'}`}
            />
            &nbsp; Elimina
          </button>
          <button
            className="btn btn-primary btn-new-rel px-5 btn-sm btn-cell"
            onClick={() => _addCrsPayment()}>
            <IPlus
              className={'padding-right-4px'}
              width="14"
              fill={` ${props.licenseExpired ? '#128186' : '#FFFFFF'}`}
            />
            &nbsp; AGGIUNGI
          </button>
        </div>
      </div>
    </>
  )
})
