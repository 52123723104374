import { useState } from 'react'
import { ReactComponent as IExport } from '../../../../styles/images/svgs/regular/file-export.svg'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import * as actions from '../../../../actions'
import { downloadRelationshipsCsvTemplate } from '../relationships/utils/CoraTemplateUtils'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'
import { PopupError } from '../../../shared/PopupError'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { Modal } from 'react-bootstrap'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import { InfoBox } from '../../../shared/InfoBox'

export default function CoraImportRelationsModal({ subject, show, onClose, onSuccess }) {
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })
  const [loadingSpinner, setLoadingSpinner] = useState(false)
  const [includedInNextCommunication, setIncludedInNextCommunication] = useState(false)
  const [charSeparator, setCharSeparator] = useState(';')
  const onChangeIncludedInNextCommunication = () => {
    setIncludedInNextCommunication(!includedInNextCommunication)
  }

  const checkFileName = (name) => {
    return name.split('.').pop().toLowerCase() === 'csv'
  }

  const handleUploadFile = (val) => {
    if (!val) {
      setErrorDataFile({ isValid: false, msg: 'Il file è obbligatorio' })
    }

    let files = []
    for (let file of val.target.files) {
      if (!checkFileName(file.name)) {
        setErrorDataFile({
          isValid: false,
          msg: 'Estensione o nome file errati'
        })
      } else {
        setErrorDataFile({ isValid: true, msg: '' })
        files.push(file)
      }
    }
    if (files.length > 0) {
      setDataFile(files)
    }
  }

  const handleSendDataFile = () => {
    let formData = new FormData()
    formData.append('subjectId', subject?.id)
    formData.append('includedInNextCommunication', includedInNextCommunication)
    formData.append('separator', charSeparator)
    if (dataFile?.length === 1) {
      formData.append('file', dataFile[0])
    } else {
      setErrorDataFile({
        isValid: false,
        msg: 'È consentito un solo file CSV'
      })
      return
    }
    setLoadingSpinner(true)
    actions.uploadRelationshipCsvFile(formData).then(
      (res) => {
        if (!res.error) {
          const messageSplit = res.trim().split(/\r?\n/)
          if (messageSplit.length > 1) {
            let blob = new Blob([res], { type: 'text/plain' })
            let textFile = window.URL.createObjectURL(blob)
            let fragment = (
              <>
                <a
                  download={`IMPORTAZIONE RAPPORTI DEL ${new Date().toLocaleDateString()}`}
                  id="downloadErrorFile"
                  href={textFile}>
                  Scarica qui il file per vedere le righe in errore
                </a>
              </>
            )
            PopupSuccess({
              dataList: [messageSplit[0], fragment]
            })
          } else {
            const relations = messageSplit[0].split(' ')[3]
            PopupSuccess({
              text: `Lette correttamente ${relations} righe: importazione rapporti terminata`
            })
          }
          onSuccess()
          onClose()
          setLoadingSpinner(false)
        } else {
          setLoadingSpinner(false)
          setDataFile([])
        }
      },
      (err) => {
        setLoadingSpinner(false)
        PopupError({
          text: err?.title || APPLICATION_GENERIC_ERROR
        })
      }
    )
  }

  return (
    <>
      {loadingSpinner && <PageSpinner />}
      <Modal
        backdrop="static"
        show={show}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Importa Rapporti</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mt-5  ">
            <div className="row justify-content-between align-items-center mb-5">
              <div className="col-lg-4 col-sm-12">
                <div className="image-upload text-center">
                  <UploadFileLabel label={'CARICA FILE CSV'} forLabel={'dataFile'} />
                  <input
                    id={`dataFile`}
                    type="file"
                    name={`dataFile`}
                    onChange={(e) => handleUploadFile(e)}
                    accept=".csv"
                  />
                </div>
              </div>
              <div className="col-lg-8 col-sm-12 text-center">
                <div className="certificatiLoaded ps-0 ms-0 text-center">
                  {!errorDataFile.isValid ? (
                    <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
                  ) : (
                    <div>
                      {dataFile.length > 0 &&
                        dataFile.map((file, index) => {
                          return (
                            <div
                              key={'blue-import' + { index }}
                              className="green-label text-truncate">
                              <ICheck width="14" fill="#128186" />
                              {file.name}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 col-lg-6">
                <div className="pt-1 pb-1">
                  <BwmCheckbox
                    name="includedInNextComm"
                    label="Selezionare se si desidera includere i rapporti nella prossima comunicazione"
                    className="form-control"
                    value={includedInNextCommunication}
                    onClick={onChangeIncludedInNextCommunication}
                  />
                </div>
                <div className=" pt-1 pb-1">
                  <label className="form-group label me-1">Separatore di dati:</label>
                  <input
                    style={{ maxWidth: '20px', textAlign: 'center' }}
                    maxLength="1"
                    type="text"
                    value={charSeparator}
                    onChange={(e) => setCharSeparator(e.target.value)}></input>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <InfoBox
                  simplePointsArr={[
                    {
                      title: 'Istruzioni',
                      text: 'Se si desidera inserire rapporti senza codice univoco, compilare il campo "Posizione" con valore 1 in corrispondenza del primo titolare.'
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-5">
            <div className="mt-5 btngroup d-flex col-lg-8 justify-content-start">
              <button onClick={() => onClose()} className="btn btn-empty px-4 btn-sm">
                <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
                &nbsp; ANNULLA
              </button>
              <button
                onClick={() => downloadRelationshipsCsvTemplate()}
                className="btn btn-empty ms-2 px-4 btn-sm">
                <IExport className={'padding-right-4px svg'} width="16" fill={'#128186'} />
                &nbsp; Scarica template csv
              </button>
            </div>
            <div className="mt-5 btngroup d-flex col-lg-4 justify-content-end">
              <button
                onClick={() => handleSendDataFile()}
                disabled={!errorDataFile.isValid || !dataFile || dataFile.length === 0}
                className="btn btn-primary w-45 btn-new-rel px-3">
                <ICheck className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
                CONFERMA
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
