import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BtnFill } from './BtnFill'

export function PopupAlert(props) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="close btn btn-outline-primary btn-sm"
                onClick={onClose}>
                <span aria-hidden="true" style={{ fontSize: '14px' }}>
                  X
                </span>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <Row className={'show-grid popup-title-alert text-center'}>
              <Col md={12} lg={12} className="pb-3">
                <h5>{props?.title || 'Attenzione'}</h5>
              </Col>
            </Row>
            <Row className={'show-grid text-center'}>
              <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: props?.innerHtml }}></Col>
              <Col md={12} lg={12}>
                {props.text}
              </Col>
            </Row>
            <div className="pt-5 d-flex justify-content-between align-items-center">
              <button
                type="button"
                className="btn btn-outline-primary btn-empty px-4 btn-sm"
                onClick={() => {
                  if (props.onClickCancel) props.onClickCancel()
                  onClose()
                }}>
                {props.cancel || 'ANNULLA'}
              </button>
              <BtnFill
                text={props.confirm || 'CONTINUA'}
                handlerOnClick={() => {
                  if (props.handleClickConfirm) props.handleClickConfirm()
                  onClose()
                }}
              />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
