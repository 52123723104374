import { useEffect, useState } from 'react'
import * as actions from '../../../../actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { ReactComponent as IListFile } from '../../../../styles/images/svg/list.svg'
import { ReactComponent as IZipFile } from '../../../../styles/images/svg/file-archive.svg'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PopupError } from '../../../shared/PopupError'
import { ReactComponent as IEnvelope } from '../../../../styles/images/svgs/solid/envelope.svg'
import EmailForm from './EmailForm'
import Modal from 'react-bootstrap/Modal'
import { CreateCommunicationModal } from './NewCreateCommunicationModal'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { base64ToArrayBuffer, downloadFile } from '../../../shared/Utility'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { PopupDelete } from '../../../shared/PopupDelete'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

export const columnSizes = {
  row: {
    checkBox: 'w-5',
    creationDate: 'w-12',
    referenceYEar: 'w-5',
    subject: 'w-50',
    fiscalCode: 'w-5',
    status: 'w-50',
    download: 'w-10'
  }
}

export const CommunicationsHistoryList = (props) => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [emailFormOpen, setEmailFormOpen] = useState(false)
  const [communicationToSend, setCommunicationToSend] = useState(null)
  const [selectedCommunications, setSelectedCommunications] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [subject, setSubject] = useState()
  const communicationsHistory = useSelector((state) => state.communicationsHistory.data)
  const lastErrorCommunication = useSelector((state) => state.lastErrorCommunication.data)
  const currencies = useSelector((state) => state.currencies.data)

  useEffect(() => {
    getHistory(props.subjectId)
    getSubject(props.subjectId)
    getAllCurrency()
  }, [])

  const getHistory = () => {
    dispatch(actions.getCrsCommunicationsHistoryBySubjectId(props.subjectId))
  }

  const getSubject = (subjectId) => {
    actions.getCrsSubject(subjectId).then((res) => {
      setSubject(res)
    })
  }

  const getAllCurrency = () => {
    if (!currencies?.length) {
      dispatch(actions.getAllCurrency())
    }
  }

  const downloadReceiptTxt = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadReceiptTxt/${props.subjectId}/${communication.id}`
    callDownloadService(url, 'ATPECRQ.' + communication.sidFilename + '.run.txt')
  }

  const downloadDgn = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadDgn/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.xmlFilename.replace('.xml', '.dgn'))
  }

  const downloadXml = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadXml/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.xmlFilename)
  }

  const downloadPecTz = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadPecTz/${props.subjectId}/${communication.id}`
    callDownloadService(url, 'PECAT.' + communication.sidFilename + '.zip')
  }

  const downloadPdf = (communication) => {
    downloadFile(
      new Blob([base64ToArrayBuffer(communication.pdf)], { type: 'application/pdf' }),
      communication.xmlFilename.replace('.xml', '.pdf')
    )
    PopupSuccess({ text: 'File scaricato' })
  }

  const callDownloadService = (url, fileName) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        if (response?.data?.size > 0) {
          downloadFile(new Blob([response.data]), fileName)
          PopupSuccess({ text: 'File scaricato' })
        } else {
          PopupError({ text: 'File non trovato' })
        }
      }
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: `${selectedCommunications.length} comunicazione/i?`,
      handleClickConfirm: onConfirmDeleteSelectedCommunications
    })
  }

  const onConfirmDeleteSelectedCommunications = () => {
    setLoading(true)
    let idArray = selectedCommunications.map((el) => el.id)
    actions
      .deleteCrsCommunications(props.subjectId, idArray)
      .then(
        (res) => {
          PopupSuccess({
            text: 'Operazione completata con successo'
          })
        },
        (err) => {
          let error = Constants.APPLICATION_GENERIC_ERROR
          if (err?.data?.title) {
            error = err.data.title
          }
          PopupError({
            text: error
          })
        }
      )
      .then(() => {
        getHistory(props.subjectId)
        setLoading(false)
        setIsAllSelected(false)
        setSelectedCommunications([])
      })
  }

  const showDetail = () => {
    setShow(!show)
  }

  const closeEmailModal = () => {
    setEmailFormOpen(false)
    setCommunicationToSend(null)
  }

  const openEmailModal = (communication) => {
    setEmailFormOpen(true)
    setCommunicationToSend(communication)
  }

  const renderRowHeader = () => {
    const row = columnSizes.row
    return (
      <>
        <div className={`col ${row.checkBox} div-td text-center`}>
          <input type="checkbox" onChange={() => onSelectAllCommunications()} />
        </div>
        <div className={`col ${row.creationDate} div-td`}>Data creazione</div>
        <div className={`col ${row.referenceYEar} div-td`}>Periodo</div>
        <div className={`col ${row.subject} div-td`}>Soggetto</div>
        <div className={`col ${row.fiscalCode} div-td`}>Tipo</div>
        <div className={`col ${row.status} div-td`}>Stato</div>
        <div className={`col ${row.download} div-td`}>Scarica</div>
      </>
    )
  }

  const renderErrorRowHeader = () => {
    return (
      <>
        <div className="col w-10 div-td">Data creazione</div>
        <div className="col w-8 div-td">Periodo</div>
        <div className="col w-15 div-td">Soggetto</div>
        <div className="col w-10 div-td">Tipologia di invio</div>
        <div className="col w-8 div-td">Comunicazione</div>
        <div className="col w-15 div-td">Stato</div>
        <div className="col w-50 div-td">Errore</div>
        <div className="col w-10 div-td">Scarica</div>
      </>
    )
  }

  const downloadPdfComponent = (communication) => {
    const iconPdf =
      communication?.pdf !== null && communication?.pdf !== '' && communication !== undefined
    if (!iconPdf) {
      return null
    }
    return (
      <div className="pdf">
        <span onClick={() => downloadPdf(communication)}>
          <IPdfFile width="20" fill="#128186" />
        </span>
      </div>
    )
  }

  const onSelectCommunication = (communication) => {
    let selected = selectedCommunications
    if (selected.find((element) => element.id === communication.id)) {
      setSelectedCommunications(selected.filter((el) => el.id !== communication.id))
    } else {
      selected.push(communication)
      setSelectedCommunications(selected)
    }
  }

  const onSelectAllCommunications = () => {
    if (isAllSelected) {
      setSelectedCommunications([])
      setIsAllSelected(false)
    } else {
      let selected = communicationsHistory.filter(
        (el) => el.communicationStatus !== CommunicationStatus.RECEIPT_ACCEPTED
      )
      setSelectedCommunications(selected)
      setIsAllSelected(true)
    }
  }

  const renderRows = (communications) => {
    const row = columnSizes.row
    return communications.map((communication) => {
      /**
       * Se la comunicazione è creata e in attesa: puoi scaricare tutti i file. 
          Se è creata con errori, puoi scaricare l'xml,il pdf ed i log. i Pecat in questo caso non ci sono.
          Se carichi una ricevuta di una comunicazione su crs, puoi scaricare tutti i file 
          Se carichi una ricevuta di una comunicazione che non esiste, puoi scaricare solo l'xml e la ricevuta in chiaro. 
       */
      let status = ''
      let receipt = false // indica se la ricevuta esiste o abbiamo solo la comunicazione
      let communication_exist = false //indica se la comunicazione esiste, o se è stata elaborata solo la ricevuta
      let error = false
      let created =
        communication.communicationStatus === CommunicationStatus.CREATED ||
        communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
      if (communication.communicationStatus === CommunicationStatus.CREATED) {
        status =
          'Creata - In attesa di ricevuta (file ATPECRQ.' + communication.sidFilename + '.p7m)'
        communication_exist = true
      } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
        communication_exist = true
        error = true
        status = 'Creata con errori'
      } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
        communication_exist = true
        status = 'Comunicazione ACCOLTA TOTALMENTE'
        receipt = true
      } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
        communication_exist = true
        status = 'Comunicazione SCARTATA TOTALMENTE'
        receipt = true
      } else if (
        communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR
      ) {
        status = 'Comunicazione ACCOLTA PARZIALMENTE (al netto degli errori indicati)'
        communication_exist = true
        receipt = true
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_WITH_ERROR_AND_COMMUNICATION_NOT_FIND
      ) {
        status = 'Ricevuta ACCOLTA CON ERRORI FORMALI, comunicazione non trovata su CRS WEB'
        receipt = true
        communication_exist = false
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
      ) {
        status = 'Ricevuta ACCOLTA TOTALMENTE, comunicazione non trovata su CRS WEB'
        receipt = true
        communication_exist = false
      } else if (
        communication.communicationStatus ===
        CommunicationStatus.RECEIPT_NOT_ACCEPTED_AND_COMMUNICATION_NOT_FIND
      ) {
        status = 'Ricevuta SCARTATA TOTALMENTE, comunicazione non trovata su CRS WEB'
        receipt = true
        communication_exist = false
      }
      return (
        <div key={'communicationHistory-' + communication.id}>
          <div className={`text-start row-table`}>
            <div className={`col ${row.checkBox} text-center div-td`}>
              <input
                type="checkbox"
                checked={selectedCommunications.find((el) => el.id === communication.id)}
                disabled={
                  communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED
                }
                onChange={() => onSelectCommunication(communication)}
              />
            </div>
            <div className={`col ${row.creationDate} div-td text-truncate`}>
              {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
            </div>
            <div className={`col ${row.referenceYEar} div-td text-truncate`}>
              {communication.referenceYear}
            </div>
            <div className={`col ${row.subject} div-td text-truncate`}>
              {communication.subject.companyName}
            </div>
            <div className={`col ${row.fiscalCode} div-td text-truncate`}>
              {communication.relationships ? 'Con Rapporti' : 'Vuota'}
            </div>
            <div className={`col ${row.status} div-td text-truncate`} title={status}>
              {status}
            </div>
            <div
              className={`col ${row.download} div-td text-truncate d-flex justify-content-around pe-4 ps-4 containerPopOver`}>
              {(created || receipt) && (
                <span
                  data-tip={'Invia COMUNICAZIONE'}
                  onClick={() => openEmailModal(communication)}>
                  <IEnvelope width="20" fill="#128186" />
                </span>
              )}
              {receipt && (
                <div className="recepit">
                  <span onClick={() => downloadReceiptTxt(communication)}>
                    <ITextFile width="20" />
                  </span>
                </div>
              )}
              {communication_exist && (
                <>
                  <div className="xml">
                    <span onClick={() => downloadXml(communication)}>
                      <ITextFile width="20" fill="#128186" />
                    </span>
                  </div>
                  {!error && (
                    <div className="zip">
                      <span onClick={() => downloadPecTz(communication)}>
                        <IZipFile width="20" fill="#128186" />
                      </span>
                    </div>
                  )}

                  {downloadPdfComponent(communication)}

                  <div className="dgn">
                    <span onClick={() => downloadDgn(communication)}>
                      <IListFile width="20" fill="#128186" />
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderErrorRows = (communication) => {
    let status = ''
    if (communication.communicationStatus === CommunicationStatus.CREATED) {
      status = 'Creata - In attesa di ricevuta'
    } else if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR) {
      status = 'Creata con errori'
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_ACCEPTED) {
      status = 'Comunicazione accolta'
    } else if (communication.communicationStatus === CommunicationStatus.RECEIPT_NOT_ACCEPTED) {
      status = 'Comunicazione NON accolta'
    }
    return (
      <>
        <div
          className="text-start row-table"
          onClick={(e) => {
            if (communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR)
              showDetail()
          }}>
          <div className="col w-10 div-td text-truncate">
            {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
          </div>
          <div className="col w-8 div-td text-truncate">{communication.subject.referenceYear}</div>
          <div className="col w-15 div-td text-truncate">{subject.companyName}</div>
          <div className="col w-10 div-td text-truncate">CRS</div>
          <div className="col w-8 div-td text-truncate">XML</div>
          <div className="col w-15 div-td text-truncate">{status}</div>
          <div className="col w-50 div-td text-truncate">
            {communication.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
              ? communication.communicationError
                ? communication.communicationError
                : 'Errori presenti nei rapporti inclusi nella comunicazione'
              : ''}
          </div>
          <div className="col w-10 div-td text-truncate d-flex justify-content-around pe-4 ps-4">
            <button alt="Scarica xml " onClick={() => downloadXml(communication)}>
              <ITextFile width="20" fill="#128186" />
            </button>
          </div>
        </div>
      </>
    )
  }

  const renderLastCommunicationError = () => {
    return (
      <>
        {lastErrorCommunication.creationDate ? (
          <>
            <div className="row header-btn align-items-left">ULTIMA COMUNICAZIONE IN ERRORE</div>
            <div className="text-start header-table error">{renderErrorRowHeader()}</div>
            {renderErrorRows(lastErrorCommunication)}
          </>
        ) : (
          ''
        )}
      </>
    )
  }

  return (
    <>
      {renderLastCommunicationError()}
      {loading && <PageSpinner />}
      <div className="d-flex justify-content-between align-items-center">
        <div className="row header-btn-v2 align-items-left">STORICO COMUNICAZIONI</div>
        <div className="d-flex pb-1">
          <button
            type="button"
            className={`btn btn-outline-primary btn-empty px-4 btn-sm me-2`}
            onClick={() => openPopupDelete()}
            disabled={selectedCommunications.length === 0}>
            <IDelete className={'padding-right-4px'} width="12" />
            &nbsp; Elimina selezionati
          </button>
          <div>
            <CreateCommunicationModal
              upload={true}
              subjectId={subject?.id}
              subject={subject}
              emailAddress={subject?.coraSubjectSid?.email}
              getHistory={getHistory}
            />
          </div>
        </div>
      </div>
      {communicationsHistory?.length > 0 ? (
        <>
          <div className="text-start header-table">{renderRowHeader()}</div>
        </>
      ) : (
        ''
      )}
      {renderRows(communicationsHistory)}
      <Modal
        backdrop="static"
        show={emailFormOpen}
        onHide={closeEmailModal}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>INVIA COMUNICAZIONE SELEZIONATA</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailForm
            closeModal={closeEmailModal}
            emailAddress={subject?.coraSubjectSid?.email}
            flagPec={subject?.coraSubjectSid?.flagPec}
            sentCommunications={[communicationToSend]}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
