import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import axiosService from '../../../../services/axios-service'
import { registerLocale } from 'react-datepicker'
import { it } from 'date-fns/locale'
import * as actions from '../../../../actions'
import * as Utility from '../../../shared/Utility'
import { PopupError } from '../../../shared/PopupError'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

registerLocale('it', it)

const SECOND_TAB = 'PERSONE CONTROLLANTI'

export const ControllingPersonForm = (props) => {
  const dispatch = useDispatch()

  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [touched, setTouched] = useState(false)

  const countries = useSelector((state) =>
    state.countries?.data.map((country) => {
      return { name: country.code, key: country.description }
    })
  )

  const [controllingPersonTypes, setControllingPersonTypes] = useState([])

  useEffect(() => {
    getCountries()
    getControllingPersonTypes()
    setNewInitialValues()
  }, [])

  useEffect(() => {
    setNewInitialValues()
  }, [props.controllingPerson])

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.controllingPerson?.id,
      fiscalIdentifier: props.controllingPerson?.fiscalIdentifier || '',
      controllingPersonType: props.controllingPerson?.controllingPersonType?.id || '',
      controllingPersonTypeCode: props.controllingPerson?.controllingPersonType?.code || '',
      firstName: props.controllingPerson?.firstName || '',
      lastName: props.controllingPerson?.lastName || '',
      address: props.controllingPerson?.address || '',
      birthDate: props.controllingPerson?.birthDate
        ? new Date(props.controllingPerson?.birthDate)
        : new Date(),
      birthLocation: props.controllingPerson?.birthLocation || '',
      countryCode: props.controllingPerson?.countryCode || 'IT',
      country: props.controllingPerson?.country || 'ITALIA (REPUBBLICA ITALIANA)'
    })
  }

  const getControllingPersonTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-controlling-person-types').then((res) => {
      setControllingPersonTypes(res.data)
    })
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const validationSchema = Yup.object().shape({
    controllingPersonType: Yup.string().required('Campo obbligatorio'),
    lastName: Yup.string().required('Campo obbligatorio'),
    firstName: Yup.string().required('Campo obbligatorio'),
    address: Yup.string().required('Campo obbligatorio'),
    birthDate: Yup.date('Inserire una data valida')
      .required('Campo obbligatorio')
      .test(
        'is-valid-date',
        'Inserire una data valida',
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    birthLocation: Yup.string().required('Campo obbligatorio'),
    countryCode: Yup.string().required('Campo obbligatorio')
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalIdentifier',
      errorLabel: 'TIN (Id fiscale)'
    },
    {
      errorKey: 'controllingPersonType',
      errorLabel: 'Tipo'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'birthLocation',
      errorLabel: 'Località di nascita'
    },
    {
      errorKey: 'address',
      errorLabel: 'Indirizzo (via numero, località)'
    },
    {
      errorKey: 'country',
      errorLabel: 'Paese'
    }
  ]

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <div className="mt-3">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <div className={props.formOnBottom ? 'p-2 form-on-bottom' : 'form-on-bottom'}>
            {showErrorAlert && props.activeTab === SECOND_TAB && touched && (
              <div className={props.formOnBottom ? 'crs-relationship-form-on-bottom-error' : ''}>
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              touched={touched}
              countries={countries}
              controllingPersonTypes={controllingPersonTypes}
              setShowErrorAlert={setShowErrorAlert}
              setTouched={setTouched}
            />
          </div>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const handleChangeTIN = (e) => {
    const form = Object.assign({}, values)
    form.fiscalIdentifier = e.target.value?.toUpperCase() ? e.target.value.toUpperCase() : ''
    actions
      .getBaseRegistryByFiscalCode(form.fiscalIdentifier)
      .then((baseRegistry) => {
        if (Utility.isNotEmpty(baseRegistry)) {
          form.lastName = baseRegistry?.lastName || ''
          form.firstName = baseRegistry?.firstName || ''
          form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
          form.birthLocation =
            baseRegistry?.location?.location + '  (' + baseRegistry?.location?.province + ')' || ''
          setValues(form)
        }
      })
      .catch(() => {})
  }

  const handlerChangeControllingPersonType = (e) => {
    let code = e.target[e.target.selectedIndex].dataset.cod
    const form = Object.assign({}, values)
    form.controllingPersonType = e.target.value
    form.controllingPersonTypeCode = code
    setValues(form)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleInputAutocompileCountry = (e) => {
    let newCountry = e.target.value || ''
    const form = Object.assign({}, values)
    form.countryCode = newCountry.toUpperCase()
    let countriesFilter = props.countries.filter((country) => country.name === newCountry)
    if (countriesFilter.length === 1) {
      form.countryCode = countriesFilter[0].name
      form.country = countriesFilter[0].key
    }
    if (countriesFilter.length === 0) {
      form.countryCode = newCountry.toUpperCase()
      form.country = ''
    }
    setValues(form)
  }

  const getData = () => {
    return {
      id: props.controllingPerson?.id || null,
      fiscalIdentifier: values.fiscalIdentifier,
      controllingPersonType: { id: values.controllingPersonType },
      lastName: values.lastName,
      firstName: values.firstName,
      address: values.address,
      birthDate: new Date(values.birthDate),
      birthLocation: values.birthLocation,
      countryCode: values.countryCode,
      country: values.country,
      crsRelationship: { id: props.relationship?.id || null }
    }
  }

  const addCrsControllingPerson = (values) => {
    let relationshipId = ''
    if (props.relationship?.id) {
      relationshipId = props.relationship?.id
    } else {
      relationshipId = props.controllingPerson?.crsRelationship?.id || ''
    }
    let newControllingPerson = getData()
    newControllingPerson.crsRelationship = { id: relationshipId }

    if (props.controllingPerson?.id) {
      newControllingPerson.controllingPersonType = {
        id: parseFloat(values.controllingPersonType)
      }
      actions.updateCrsControllingPeople(newControllingPerson, relationshipId).then(
        (response) => props.handlerShowPersonForm(),
        (errors) => PopupError({ text: errors })
      )
    } else {
      actions.addCrsControllingPeople(newControllingPerson, relationshipId).then(
        (response) => props.handlerShowPersonForm(),
        (errors) => PopupError({ text: errors })
      )
    }
  }

  const handleFormSubmit = async () => {
    const errors = await validateForm(values) // Validazione dei valori
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      addCrsControllingPerson(values) // Esegui l'aggiornamento se non ci sono errori
      props.setShowErrorAlert(false) // Nascondi l'alert
    }
  }

  const close = () => {
    props.setTouched(false)
    if (props.handlerShowPersonForm) props.handlerShowPersonForm()
  }

  useEffect(() => {
    if (props.reset) {
      props.setReset(false)
      close()
    }
  }, [props.reset])

  return (
    <div className="controlPersonForm w-all">
      <div className="row">
        <div className="col-7 d-flex ms-2">
          <h5>
            Titolare: <strong>{props.crsRelationshipHolder?.companyName || ''}</strong>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-2 me-2 ms-2 mb-2">
          <BwmInput
            name="fiscalIdentifier"
            label="TIN (Id fiscale)"
            className="form-control"
            value={values.fiscalIdentifier}
            error={errors.fiscalIdentifier}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'fiscalIdentifier', true)}
            onBlur={(e) => handleChangeTIN(e)}
          />
        </div>
        <div className="col-3">
          <BwmSelect
            options={props.controllingPersonTypes}
            name="controllingPersonType"
            label="Tipo"
            className="form-control"
            value={values.controllingPersonType}
            error={errors.controllingPersonType}
            touched={props.touched}
            onChange={(e) => handlerChangeControllingPersonType(e)}
          />
        </div>
        <div className="col-3 me-2 ms-2">
          <BwmInput
            name="lastName"
            label="Cognome"
            className="form-control font-weight-bold font-size-big"
            value={values.lastName}
            error={errors.lastName}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'lastName', true)}
          />
        </div>
        <div className="col-3 me-2">
          <BwmInput
            name="firstName"
            label="Nome"
            className="form-control font-weight-bold font-size-big"
            value={values.firstName}
            error={errors.firstName}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'firstName', true)}
          />
        </div>
        <div className="row">
          <div className="col-1 ms-2 mb-2 me-2">
            <DateField
              label="Data di nascita"
              className="form-control text-start p-2"
              name="birthDate"
              date={values.birthDate}
              error={errors.birthDate}
              touched={props.touched}
              onChange={(date) => handleChangeValue(date, 'birthDate')}
            />
          </div>
          <div className="col-3 me-2">
            <BwmInput
              name="birthLocation"
              label="Località di nascita"
              className="form-control"
              value={values.birthLocation}
              error={errors.birthLocation}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'birthLocation', true)}
            />
          </div>
          <div className="col-3 me-2">
            <BwmInput
              name="address"
              label="Indirizzo (via numero, località)"
              className="form-control"
              value={values.address}
              error={errors.address}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'address', true)}
            />
          </div>
          <div className="col-4">
            <BwmSelect
              options={props.countries}
              name="country"
              id="country"
              label="Paese"
              className="form-control"
              showCode={true}
              nameKey={true}
              value={values.countryCode}
              error={errors.countryCode}
              touched={props.touched}
              onChange={(e) => handleInputAutocompileCountry(e)}
            />
          </div>
        </div>
      </div>
      <div className={'col-12 pt-2 pb-2 d-flex justify-content-end'}>
        <button
          className="btn btn-outline-primary btn-empty px-4 btn-sm btn-cell me-4"
          onClick={close}>
          &nbsp; ANNULLA
        </button>
        <button
          id={'controlling-person-save-button'}
          type="submit"
          className="btn btn-primary btn-new-rel btn-cell px-5 ml-2"
          onClick={() => handleFormSubmit()}>
          &nbsp; SALVA
        </button>
      </div>
    </div>
  )
}
