import { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import * as actions from '../../../../actions'
import '../../../../styles/comurei-form.css'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PopupError, closePopupError } from '../../../shared/PopupError'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'
import { PopupAlert } from '../../../shared/PopupAlert'
import { BtnFill } from '../../../shared/BtnFill'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import { ParametriPecModal } from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/ParametriPecModal'

const EmailForm = (props) => {
  const [formData, updateFormData] = useState()
  const coraSubjectSid = props.subject?.coraSubjectSid
  const subjectId = props.subject?.id
  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })
  const [flagPec, setFlagPec] = useState(props.flagPec || false)
  const [isPecModalOpen, setPecModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    updateFormData({
      ...formData,
      id: subjectId,
      emailAddress: emailAddress,
      flagPec: flagPec
    })
  }, [])

  const confirmSendCommunicationsEmail = (subjectId, sentCommunications, emailAddress, flagPec) => {
    sentCommunications.map((communication, index) => {
      actions.sendCrsCommunicationMail(subjectId, communication.id, emailAddress, flagPec).then(
        (res) => {
          if (res) {
            PopupSuccess({ text: 'Email inviata' })
          } else {
            PopupError({ text: "Errore nell'invio della email" })
          }
        },
        (errors) => {
          PopupError({ text: "Errore nell'invio della email" })
        }
      )
    })
    props.closeModal()
  }

  const handlerChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      emailAddress: e.target.value.toUpperCase()
    })
    setFlagPec(false)
  }

  const handlerChangeFlagPec = (e) => {
    let checked = e.target.checked
    setFlagPec(checked)
    updateFormData({
      ...formData,
      flagPec: checked
    })
  }
  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (!emailAddress) {
      setEmailAddress({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    } else if (emailAddress.toUpperCase().indexOf('AGENZIAENTRATE') !== -1) {
      setErrorEmailAddress({
        isValid: false,
        msg: "Inserire un destinatario diverso dall'agenzia delle entrate"
      })
      isValid = false
    }

    return isValid
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (handleValidation()) {
      confirmSendCommunicationsEmail(subjectId, props.sentCommunications, emailAddress, flagPec)
    }
  }

  const openPecConfirmPopup = () => {
    if (coraSubjectSid?.couldSendPec) {
      PopupAlert({
        handleClickConfirm: () => sendFromOwnPec(),
        title: `Invia Mail PEC`,
        confirm: 'OK, INVIA',
        innerHtml: `<p>Mittente: ${coraSubjectSid?.pecEmail}</p><p> Destinatario: ${coraSubjectSid?.recipientEmail}</p>`
      })
    } else {
      PopupError({
        text: 'PEC non configurata correttamente',
        buttonClose: () => (
          <BtnEmpty
            classCustom="pe-5"
            text={'Configurazione parametri PEC'}
            handlerOnClick={() => {
              closePopupError()
              setPecModalOpen(true)
            }}></BtnEmpty>
        )
      })
    }
  }

  const sendFromOwnPec = () => {
    let communicationIds = []
    props.sentCommunications.forEach((element) => {
      communicationIds.push(element.id)
    })
    setLoading(true)
    actions
      .sendCrsCommunicationFromOwnPec(subjectId, communicationIds)
      .then(
        (res) => {
          PopupSuccess({ text: 'Invio email avviato.' })
        },
        (err) => {
          PopupError({
            text: err?.response?.data || 'Si è verificato un errore inatteso',
            buttonClose: () => (
              <BtnFill
                classCustom="pe-5"
                text={'Configurazione parametri PEC'}
                handlerOnClick={() => {
                  closePopupError()
                  setPecModalOpen(true)
                }}></BtnFill>
            )
          })
        }
      )
      .then(() => setLoading(false))
  }

  const closePecModal = () => {
    setPecModalOpen(false)
  }

  const renderPecModal = () => {
    return (
      <ParametriPecModal
        coraSubjectSid={{ coraSubjectSid }}
        show={isPecModalOpen}
        onClose={closePecModal}
      />
    )
  }

  return (
    <div>
      {loading && <PageSpinner />}
      {renderPecModal()}
      <div className="row-detail comurei mt-4 d-flex radius">
        <div className="tab-content col-md-12 col-lg-12">
          <div className="form-row">
            <div className="col-md-12 col-lg-12 subDetailComurei">
              <div className="row">
                <div className="col-md-11 col-lg-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control form-control-normal"
                    label="Indirizzo email a cui spedire le comunicazioni completate"
                    name="emailAddress"
                    value={emailAddress}
                    error={errorEmailAddress?.msg}
                    touched={errorEmailAddress?.isValid === false}
                    maxLength="100"
                    onChange={handlerChangeEmailAddress}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12 col-lg-12 pt-1 pb-1">
                  <BwmCheckbox
                    name="flagPec"
                    label="Selezionare in caso di email PEC"
                    className="form-control"
                    value={flagPec}
                    onClick={handlerChangeFlagPec}></BwmCheckbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-4 justify-content-between align-item-center">
        <button
          className="btn btn-outline-primary btn-empty px-4 btn-sm"
          onClick={props.closeModal}>
          <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
          &nbsp; ANNULLA
        </button>
        <button onClick={() => openPecConfirmPopup()} className="btn btn-empty px-5">
          Invia direttamente da PEC
        </button>
        <button
          onClick={(e) => handleFormSubmit(e, false)}
          className="btn btn-primary btn-new-rel px-5">
          <ISave className="padding-right-4px svg" width="20" fill="#FFFFFF" />
          CONFERMA
        </button>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'EmailForm'
})(EmailForm)
