import * as actions from '../../../../actions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import list from '../../../../styles/images/elenco.png'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { PopupSuccess } from '../../../shared/PopupSuccess'

export function CommunicationsHistoryModal(props) {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const communicationsHistory = useSelector((state) => state.comureiCommunications.data)

  const getHistory = (subjectId) => {
    dispatch(actions.getComureiCommunicationsHistoryBySubjectId(subjectId))
  }

  const openModal = () => {
    getHistory(props.subjectId)
    setShowModal(true)
  }

  const closeModal = () => {
    if (props.closeModal) props.closeModal()
    setShowModal(false)
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file)
        document.body.appendChild(link)
        link.click()
        PopupSuccess({ text: 'File scaricato' })
      }
    })
  }

  const downloadTxt = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadTxt/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename)
  }

  const downloadPdf = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadPdf/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename.replace('.txt', '.pdf'))
  }

  const renderTableHeader = () => {
    return (
      <>
        <div className="text-start header-table">
          <div className="col w-20 div-td">DATA CREAZIONE</div>
          <div className="col w-20 div-td">UTENTE</div>
          <div className="col w-40 div-td">TIPO COMUNICAZIONE</div>
          <div className="col w-40 div-td">SEZIONE</div>
          <div className="col w-10 div-td">DOWNLOAD</div>
        </div>
      </>
    )
  }

  const renderRows = (communications) => {
    return communications.map((communication, index) => {
      let section = ''
      if (communication.registryReason) {
        section = section + 'Indagini Bancarie/'
      }

      if (communication.monitoringReason) {
        section = section + 'Monitoraggio Fiscale/'
      }

      if (communication.factaReason) {
        section = section + 'FATCA/CRS'
      }

      if (section.endsWith('/')) {
        section = section.substring(0, section.length - 1)
      }
      return (
        <div key={index}>
          <div className={'text-start row-table'}>
            <div className="col w-20 div-td text-truncate">
              {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
            </div>
            <div className="col w-20 div-td text-truncate">{communication.creationUser}</div>
            <div className="col w-40 div-td text-truncate">
              {communication.communicationType.code} - {communication.communicationType.description}
            </div>
            <div className="col w-40 div-td text-truncate">{section}</div>
            <div className="col w-10 div-td text-truncate text-center containerPopOver">
              <div className="txt">
                <ITextFile width="16" fill="#128186" onClick={() => downloadTxt(communication)} />
              </div>
              <div className="pdf">
                <IPdfFile width="16" fill="#128186" onClick={() => downloadPdf(communication)} />
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <button className="btn btn-outline-primary btn-table" onClick={openModal}>
        <img src={list} alt={'Mostra dettagli'} />
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              STORICO R.E.I. - <strong>{props.subjectName}</strong>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="star-table">
            {renderTableHeader()}
            {renderRows(communicationsHistory)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BtnEmpty text="ANNULLA" handlerOnClick={closeModal} />
        </Modal.Footer>
      </Modal>
    </>
  )
}
