import { useEffect, useState } from 'react'
import { PageSelector } from '../searchComponents/PageSelector'
import { HeaderFilterDropdown } from './HeaderFilterDropdown'
import { TableUtils } from './TableUtils'
import { generateUniqueKey } from '../Utility'
import { NumberOfResultsSelector } from '../searchComponents/NumberOfResultsSelector'

export function StarTableTop({
  usePagination = true,
  actionsComponent = null,
  loading,
  columns,
  hiddenColumns,
  searchObject = {},
  totalPages = 0,
  showPagination = true,
  tableConfiguration,
  tableConfigurationKey,
  withCheckBoxes,
  setColumns,
  setHiddenColumns,
  setHeaderSizes,
  perPageOptions,
  setTableKey,
  onChangePage
}) {
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useState(searchObject.pageSize)

  const nextPage = () => {
    const next = (searchObject.page + 1) * searchObject.pageSize
    return next < searchObject.totalElements ? next : searchObject.totalElements
  }

  useEffect(() => {
    onChangePage({ ...searchObject, page: 0, pageSize: selectedResultsPerPage })
  }, [selectedResultsPerPage])

  return (
    <div className="star-table-top d-flex justify-content-between w-100">
      <div>{actionsComponent}</div>
      <div
        className={
          'd-flex align-items-center justify-content-end' + (usePagination ? '' : ' me-3')
        }>
        {showPagination && (
          <HeaderFilterDropdown
            headers={columns}
            hiddenColumnsProps={hiddenColumns}
            resultsPerPage={searchObject.pageSize}
            onCleanCache={() => {
              tableConfiguration.cleanSearchObject()
              window.location.reload(false)
            }}
            onHide={(hiddenColumns, orderedColumns) => {
              if (tableConfiguration) {
                tableConfiguration.setHiddenColumns(hiddenColumns)
                tableConfiguration.setColumnOrder(orderedColumns)
              }
              setColumns(orderedColumns)
              setHiddenColumns(hiddenColumns)
              setHeaderSizes(
                TableUtils.prepareHeaderSizes(
                  orderedColumns,
                  hiddenColumns,
                  withCheckBoxes,
                  tableConfiguration
                )
              )
              setTableKey(generateUniqueKey(tableConfigurationKey))
            }}
          />
        )}
        {usePagination && showPagination && (
          <>
            <strong className="ms-4 me-4">
              {searchObject.totalElements === 0
                ? searchObject.totalElements
                : searchObject.page * searchObject.pageSize + 1}{' '}
              - {nextPage()} di {searchObject.totalElements}
            </strong>

            <div className="d-flex align-items-center">
              <span style={{ minWidth: '70px' }}>Per pagina</span>
              <NumberOfResultsSelector
                loading={loading}
                options={perPageOptions}
                selected={selectedResultsPerPage}
                onChange={(val) => setSelectedResultsPerPage(Number(val))}
              />
            </div>

            <div>
              <PageSelector
                loading={loading}
                pageNumber={searchObject.page}
                resultsPerPage={searchObject.pageSize}
                totalPages={totalPages}
                onUpdateNumberOfResults={(val) => onChangePage({ ...searchObject, pageSize: val })}
                onClickBack={() => onChangePage({ ...searchObject, page: searchObject.page - 1 })}
                onClickForward={() =>
                  onChangePage({ ...searchObject, page: searchObject.page + 1 })
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
