import { useEffect, useState } from 'react'
import * as actions from '../../../../actions'
import '../../../../styles/comurei-form.css'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupError } from '../../../shared/PopupError'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'

export const ComureiEmailForm = (props) => {
  const [formData, updateFormData] = useState()
  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })

  useEffect(() => {
    updateFormData({
      ...formData,
      id: props.subjectId,
      emailAddress: emailAddress
    })
  }, [])

  const confirmComureiCommunicationsEmail = (sentCommunications, emailAddress) => {
    sentCommunications.map((communication) => {
      actions
        .comureiSendCommunicationMail(communication?.subjectId, communication.id, emailAddress)
        .then(
          (res) => {
            if (res) {
              PopupConfirm({
                titleColor: titleColors.SUCCESS,
                title: "LA COMUNICAZIONE AL REI E' STATA CREATA",
                component: () => SuccessMessage(),
                showCancelButton: false,
                confirm: 'HO CAPITO'
              })
            } else {
              PopupError({ ...this.props, text: "Errore nell'invio della email" })
            }
          },
          (errors) => {
            PopupError({ ...this.props, text: "Errore nell'invio della email " + errors })
          }
        )
    })
    props.closeModal()
  }

  const SuccessMessage = () => {
    return (
      <>
        <div className="text-center">
          <p>
            Il file TXT generato va controllato e spedito all'Agenzia delle Entrate tramite
            Entratel/Fisconline
          </p>
          <p>
            Per aggiornare il Registro Indirizzi l'Agenzia impiega{' '}
            <strong>mediamente 24 ore</strong>: attendere quindi <strong>almeno un giorno</strong>{' '}
            prima di usare questo indirizzo pec per comunicazioni all'Anagrafe Rapporti (programma
            CORA Web)
          </p>
        </div>
      </>
    )
  }

  const handlerChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      emailAddress: e.target.value.toUpperCase()
    })
  }

  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (!emailAddress) {
      setErrorEmailAddress({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }

    return isValid
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (handleValidation()) {
      confirmComureiCommunicationsEmail(props.sentCommunications, emailAddress)
    }
  }

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <div className="row-detail comurei bg-blue p-2 mt-4 d-flex radius">
        <div className="tab-content col-md-12 col-lg-12">
          <div className="form-row">
            <div className="col-md-8 col-lg-8 subDetailComurei">
              <div className="row">
                <div className="col-md-11 col-lg-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control form-control-normal"
                    label="Indirizzo email a cui spedire le comunicazioni"
                    maxLength="100"
                    value={emailAddress}
                    error={errorEmailAddress?.message}
                    touched={errorEmailAddress && !errorEmailAddress.isValid}
                    onChange={handlerChangeEmailAddress}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-4 form-relationship-button justify-content-between align-item-center p-0">
        <button
          className="btn btn-outline-primary btn-empty px-4 btn-sm"
          onClick={props.closeModal}>
          ANNULLA
        </button>
        <button type="submit" className="btn btn-primary btn-new-rel px-5">
          CONFERMA
        </button>
      </div>
    </form>
  )
}
