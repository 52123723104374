import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BtnEmpty } from './BtnEmpty'
import { ButtonAssistance } from './form/ButtonAssistance'

export function PopupError(props) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      const ButtonClose =
        props?.buttonClose ||
        (() => (
          <>
            <BtnEmpty text={props?.close || 'CHIUDI'} classCustom="m-0" handlerOnClick={onClose} />
          </>
        ))
      return (
        <>
          <div className="bar"></div>
          <div className="popup modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="close btn btn-outline-primary btn-sm"
                onClick={onClose}>
                <span aria-hidden="true" style={{ fontSize: '14px' }}>
                  X
                </span>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <Row className={'show-grid popup-title-delete text-center'}>
              <Col md={12} lg={12}>
                <h5>{props?.title || 'Attenzione'}</h5>
              </Col>
            </Row>
            <Row className={'show-grid text-center'}>
              <Col
                md={12}
                lg={12}
                className="d-flex align-items-center flex-column"
                dangerouslySetInnerHTML={{ __html: props?.text }}></Col>
            </Row>
            {props?.second_title !== undefined && (
              <Row className={'show-grid text-center'}>
                <Col
                  className="d-flex align-items-center flex-column"
                  md={12}
                  lg={12}
                  dangerouslySetInnerHTML={{ __html: props?.second_title }}></Col>
              </Row>
            )}

            <div className="d-flex pt-5 justify-content-between">
              <ButtonClose />
              <ButtonAssistance className="mb-0" buttonTitle={props?.request} />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}

export function closePopupError() {
  const closeButton = document.querySelector('.react-confirm-alert .popup .close')
  if (closeButton) closeButton.click()
}
