import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export function CustomerBox(props) {
  const auth = useSelector((state) => state.auth)
  const history = useHistory()

  const handleLogout = () => {
    props.logout()
    history.push('/')
  }

  const handleDashboard = () => {
    history.push('/')
  }

  return (
    <div className={'customerBox  '} onMouseLeave={() => props.mouseLeave()}>
      <div className="customer-info p-3">
        <div className="customer-user">
          {auth.user.firstName && (
            <span className="user">{`${auth.user.lastName || ''} ${auth.user.firstName}`}</span>
          )}
          <span className="company">{auth.user.customer.companyName}</span>
          <span className="mail">{auth.user.email}</span>
        </div>
      </div>
      {window.location.pathname !== '/dashboard' && auth.isAuth && (
        <div className="dashboard-button clickable" onClick={handleDashboard}>
          <i className="thx-home q-icon"></i>
          <a className="nav-item nav-link">Dashboard</a>
        </div>
      )}
      {auth.isAuth && (
        <div className="logout clickable" onClick={handleLogout}>
          <i className="thx-log-out q-icon"></i>
          <a className="nav-item nav-link">Esci</a>
        </div>
      )}
    </div>
  )
}
