import { useEffect, useState } from 'react'
import { ReactComponent as IPdfFile } from '../../../styles/images/svg/file-pdf.svg'
import { ReactComponent as IZipFile } from '../../../styles/images/svg/file-archive.svg'
import { useSelector } from 'react-redux'
import moment from 'moment'
import getText from './labels'
import * as actions from '../../../actions'
import { PopupError } from '../../shared/PopupError'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../shared/PopupSuccess'
import { base64ToArrayBuffer, downloadFile } from '../../shared/Utility'
import { blueDownloadAntiTerrorism } from '../../../actions'

export default function BlueHistoryModal(props) {
  const [searchEvents, setSearchEvents] = useState([])
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)
  const [loading, setLoading] = useState(false)
  const [rowLoading, setRowLoading] = useState(false)

  useEffect(() => {
    setRowLoading(true)
    actions.getBlueSearchEvents(props.subjectId).then((res) => {
      setSearchEvents(res)
      setRowLoading(false)
    })
  }, [props.subjectId])

  const downloadDocument = (searchEvent) => {
    setLoading(true)
    blueDownloadAntiTerrorism(searchEvent.blueSubject.id, searchEvent.id, null, lang)
      .then(
        (response) => {
          if (response) {
            let ext = searchEvent.fileContentType.includes('pdf') ? '.pdf' : '.zip'
            downloadFile(
              base64ToArrayBuffer(response),
              `${searchEvent.blueSubject.denomination}-${moment(searchEvent.searchDate).format('YYYY-MM-DD')}${ext}`
            )
            PopupSuccess({
              title: labels.SUCCESSTITLE,
              text: labels.DOWLOADOK,
              ok: labels.SUCCESSOK
            })
          } else {
            PopupError({
              text: labels.FILEERROR,
              title: labels.WARNING,
              request: labels.CONTACTSUPPORT,
              close: labels.CLOSE
            })
          }
        },
        (err) => {
          PopupError({
            text: labels.FILEERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => {
        setLoading(false)
      })
  }

  const renderRows = () => {
    return searchEvents?.map((el) => {
      let terms = renderSearchTerms(el.searchTerms)
      return (
        <div key={'blueHistory_' + el.id}>
          <div className={'text-start row row-table'}>
            <div className="col-lg-3 div-td text-start text-truncate ">
              {moment(el.searchDate).format('YYYY-MM-DD')}
            </div>
            <div className="col-lg-7 div-td text-start ">{terms} </div>
            <div
              className="col-lg-2 div-td text-center align-self-center pt-1"
              onClick={() => {
                downloadDocument(el)
              }}>
              {el.fileContentType?.includes('pdf') ? (
                <IPdfFile width="16" fill="#128186" />
              ) : (
                <IZipFile width="16" fill="#128186" />
              )}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderSearchTerms = (terms) => {
    if (terms) {
      let object = JSON.parse(terms)

      if (!object.subject) {
        return `${labels.MASSIVE}`
      } else if (object.entities && object.entities.length > 0) {
        let termsStr = ``
        let entity = object.entities[0]
        if (entity.generic) {
          return `${labels.GENERICSEARCH}: "${entity.generic}"`
        } else {
          if (entity.wholeName) {
            termsStr += `${labels.WHOLENAME}: "${entity.wholeName}"; `
          }
          if (entity.firstName) {
            termsStr += `${labels.FIRSTNAME}: "${entity.firstName}"; `
          }
          if (entity.lastName) {
            termsStr += `${labels.LASTNAME}: "${entity.lastName}"; `
          }
          if (entity.birthDate) {
            termsStr += `${labels.BIRTHDATE}: "${entity.birthDate}"; `
          }
          if (entity.birthPlace) {
            termsStr += `${labels.BIRTHPLACE}: "${entity.birthPlace}"; `
          }
          if (entity.passportNumber) {
            termsStr += `${labels.PASSPORTNUMBER}: "${entity.passportNumber}"; `
          }
          if (entity.cityzenCountry) {
            termsStr += `${labels.CITIZENCOUNTRY}: "${entity.passportNumber}"; `
          }
          if (object.precision) {
            termsStr += `${labels.PRECISION}: "${getPrecisionLabel(object.precision)}"; `
          }
        }
        return termsStr.slice(0, termsStr.length - 2)
      }
    }
  }

  const getPrecisionLabel = (precision) => {
    switch (precision) {
      case '0':
        return labels.LOWEST
      case '1':
        return labels.LOW
      case '2':
        return labels.MEDIUM
      case '3':
        return labels.HIGH
      default:
        return ''
    }
  }

  return (
    <>
      {loading && <PageSpinner text={labels.SPINNERTITLE} />}
      <div className="container me-0 ms-0 ps-0 pe-0 overflow-auto blue-result star-table border">
        <div className="row me-0 ms-0">
          <div className="col-lg-12 ps-0 pe-0">
            <div className="text-start header-table">
              <div className="col-lg-3 div-td">{labels.SEARCHDATE}</div>
              <div className="col-lg-7 div-td">{labels.SEARCHTERM}</div>
              <div className="col-lg-2 div-td">Download</div>
            </div>
          </div>
        </div>

        {!rowLoading && !searchEvents?.length > 0 && (
          <div className="col-lg-12 ps-0 pe-0 empty-state-history-modal">
            <td>
              <i
                className="thx-warning-triangle thx-warning-triangle-grey q-icon"
                style={{ width: '56px', height: '56px' }}
              />
              <h4>Non ci sono risultati da mostrare</h4>
            </td>
          </div>
        )}

        {!rowLoading && searchEvents?.length > 0 && (
          <div className="col-lg-12 ps-0 pe-0">{renderRows()}</div>
        )}
      </div>
    </>
  )
}
