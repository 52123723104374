import { useState, forwardRef, useImperativeHandle } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import * as Constants from '../../../../config/Constants'
import '../../../../styles/alertComm.css'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { ImportModal } from '../../../shared/popups/ImportModal'
import { CrsCommunicationModal } from './CrsCommunicationModal'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'

export const CreateCommunicationModal = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sentCommunications, setSentCommunications] = useState([])
  const [showImportModal, setShowImportModal] = useState(false)

  const closeModal = () => {
    if (props.reloadRelationships) props.reloadRelationships(props.subjectId)
    if (props.closeSubjectModal) props.closeSubjectModal()
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  useImperativeHandle(ref, () => ({
    clickButtonSend() {
      confirmCommunications()
    }
  }))

  const confirmCommunications = () => {
    if (props.subject?.coraSubjectSid) {
      if (props.generateEmpty) {
        setLoading(true)
        actions
          .createCrsCommunicationEmpty(props.subjectId)
          .then(
            (res) => {
              if (res?.data?.length > 0) {
                setSentCommunications(res.data)
                openModal()
              } else {
                handleCreateCommunicationErrors()
              }
            },
            (errors) => {
              handleCreateCommunicationErrors(errors)
            }
          )
          .then(() => setLoading(false))
      } else {
        if (props.selectedRelationship?.length > 0) {
          PopupConfirm({
            titleColor: titleColors.ALERT,
            title: 'Creazione comunicazione',
            text: `Si desidera creare una comunicazione con SOLO I RAPPORTI SELEZIONATI? (${props.selectedRelationship?.length})`,
            handleClickConfirm: () =>
              createCrsCommunicationWithRelationships(props.subjectId, props.selectedRelationship)
          })
        } else {
          createCrsCommunicationWithRelationships(props.subjectId)
        }
      }
    } else {
      // SID non inserito
      PopupError({
        text: 'Verificare la correttezza dei certificati e dati SID inseriti nel soggetto'
      })
    }
  }

  const handleCreateCommunicationErrors = (errors) => {
    PopupError({ text: handleCreateCommunicationErrorsText(errors) })
  }

  const createCrsCommunicationWithRelationships = (subjectId, relationshipIds = []) => {
    setLoading(true)
    actions
      .createCrsCommunication(subjectId, relationshipIds)
      .then(
        (res) => {
          if (res?.data?.length > 0) {
            setSentCommunications(res.data)
            openModal()
          } else {
            handleCreateCommunicationErrors()
          }
        },
        (errors) => {
          handleCreateCommunicationErrors(errors)
        }
      )
      .then(() => setLoading(false))
  }

  /**
   * valore booleano per capire se visualizzare il popup
   * @param {boolean} value
   */
  const openImportModal = (value) => {
    setShowImportModal(value)
  }

  /**
   *  Invia i file caricati al backend per essere rielaborati
   * @param {*} dataFile i file caricati poi saranno inviati
   */
  const uploadXMLFile = (dataFile, subjectId) => {
    const json = JSON.stringify(subjectId)
    const blob = new Blob([json], {
      type: 'application/json'
    })
    let formData = new FormData()
    if (dataFile?.length > 0) {
      setLoading(true)

      formData.append('file', dataFile[0])
      formData.append('subjectId', blob)
      actions
        .importCrsDocumentsXmlToElaborateTheFile(formData)
        .then(
          (response) => {
            setSentCommunications(response)
            if (props.getHistory) {
              props.getHistory(subjectId)
            }

            openModal()
          },
          (err) => {
            handleCreateCommunicationErrors(err)
          }
        )
        .then(() => setLoading(false))
    }
    openImportModal(false)
  }

  return (
    <>
      {loading && <PageSpinner />}
      {!props.upload && (
        <button
          id={'btn-fill'}
          onClick={(e) => confirmCommunications()}
          className={
            props.classCustom ? props.classCustom : ' btn btn-primary btn-new-rel px-5 btn-sm'
          }
          disabled={props.disabled}>
          <ITextFile className={'padding-right-4px svg'} width="16" fill={`#FFFFFF`} />
          &nbsp; {props.label ? props.label : 'CREA COMUNICAZIONE'}
        </button>
      )}
      {props.upload && (
        <button
          type="button"
          className={`btn btn-outline-primary btn-empty px-4 btn-sm`}
          data-tip={'Elabora comunicazione XML'}
          aria-label={'Elabora comunicazione XML'}
          onClick={() => openImportModal(true)}>
          <ITextFile className={'padding-right-4px svg'} width="16" fill={`#FFFFFF`} />
          &nbsp; Elabora comunicazione XML
        </button>
      )}
      {showModal && (
        <CrsCommunicationModal
          sentCommunications={sentCommunications}
          showModal={showModal}
          closeModal={closeModal}
          subject={props.subject}
          emailAddress={props.emailAddress}
        />
      )}

      {showImportModal && (
        <ImportModal
          key={'import-xms-' + props.subjectId}
          show={true}
          allowedFileExtensions={['xml']}
          title={'SELEZIONA FILE XML DA ELABORARE'}
          onHide={() => {
            openImportModal(false)
          }}
          onSend={(dataFile) => uploadXMLFile(dataFile, props.subjectId)}
        />
      )}
    </>
  )
})

export function handleCreateCommunicationErrorsText(errors) {
  let text = errors?.title
  const buyLink = Constants.APPLICATIONS.find((app) => app.id === 3).buyLink
  if (errors?.errorKey === 'invalid-license-demo') {
    text = `</br>Per creare comunicazioni CRS è necessario l'acquisto di una licenza.
</br><button className="btn btn-primary btn-new-rel mt-5" onclick="location.href='${buyLink}'" type="button">Acquista licenza</button>`
  } else if (errors?.errorKey === 'invalid-license') {
    text = `</br>Licenza CRS scaduta, acquistare o rinnovare la licenza.
</br><button className="btn btn-primary btn-new-rel mt-5" onclick="location.href='${buyLink}'" type="button">Acquista licenza</button>`
  }
  return text || Constants.APPLICATION_GENERIC_ERROR
}
