import { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import * as actions from '../../../../actions'
import { ModalCreateBalance } from './NewModalCreateBalance'
import { BtnDelete } from '../../../shared/BtnDelete'
import { PopupError } from '../../../shared/PopupError'
import { PopupDelete } from '../../../shared/PopupDelete'
import * as styles from '../../../../config/styles'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'
import RowSpinner from '../../../shared/spinner/Spinner'
import { getBalanceRulesByRelationshipType } from '../BalanceRules'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { IPlus } from '../../../../styles/icons'

registerLocale('it', it)

export const BalanceForm = (props) => {
  const { relationship, currencies, subjectId } = props

  const noBalancesAllowedMessage = 'Saldi non ammessi per questa tipologia di rapporto'
  const relationshipNotSavedMessage =
    'Completa la compilazione del rapporto per registrare un saldo'
  const [balances, setBalances] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [balanceCheckedItems, setBalanceCheckedItems] = useState(new Map())
  const [allCheckBalance, setAllCheckBalance] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [balance, setBalance] = useState(null)
  const [newBalance, setNewBalance] = useState(false)
  const [disableAddBalanceButton, setDisableAddBalanceButton] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const handlerRefreshBalances = () => {
    if (relationship?.id) {
      setIsLoading(true)
      actions
        .getBalancesByRelationshipId(subjectId, relationship?.id)
        .then(
          (res) => {
            setBalances(res)
          },
          (errors) => {
            console.log(errors)
          }
        )
        .then(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    handlerRefreshBalances()
  }, [])

  useEffect(() => {
    setDisableAddBalanceButton(
      isEmpty(getBalanceRulesByRelationshipType(relationship?.relationshipType?.code))
    )
  }, [relationship])

  const handleBalanceAllCheckChange = (event) => {
    setAllCheckBalance(event.target.checked)
    if (event.target.checked && balances.length > 0) {
      if (balanceCheckedItems.size > 0) {
        balanceCheckedItems.clear()
      } else {
        balances.map((balance, index) => balanceCheckedItems.set(balance.id, true))
      }
      setBalanceCheckedItems(new Map(balanceCheckedItems))
      setDisabled(false)
    } else {
      balanceCheckedItems.clear()
      setDisabled(true)
    }
  }

  const handleBalanceCheckChange = (event) => {
    balanceCheckedItems.set(event.target.value, event.target.checked)
    setBalanceCheckedItems(new Map(balanceCheckedItems))
    let count = 0
    for (const checkedItem of balanceCheckedItems) {
      if (checkedItem[1]) {
        setDisabled(false)
        return
      }
      if (!checkedItem[1]) {
        count++
      }
    }
    if (count === balanceCheckedItems.size) {
      setDisabled(true)
    }
  }

  const handlerSubmitBalance = (formDataBalance) => {
    setIsLoading(true)
    if (formDataBalance?.id) {
      actions
        .updateCoraBalance(formDataBalance)
        .then(
          (coraBalance) => {
            handlerRefreshBalances()
            props.onSave()
            closeModal()
          },
          (errors) => {
            closeModal()
            PopupError({ text: "Errore nell'aggiornamento dei saldi" })
          }
        )
        .then(() => setIsLoading(false))
    } else {
      actions
        .createCoraBalance(formDataBalance)
        .then(
          (coraBalance) => {
            handlerRefreshBalances()
            props.onSave()
            closeModal()
          },
          (errors) => {
            closeModal()
            PopupError({ text: "Errore nell'inserimento dei saldi" })
          }
        )
        .then(() => setIsLoading(false))
    }
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Saldi Annuali',
      handleClickConfirm: handlerDeleteBalance
    })
  }

  const handlerDeleteBalance = () => {
    for (const checkedItem of balanceCheckedItems) {
      //FIXME: delete in batch
      if (checkedItem[1]) {
        actions.deleteCoraBalance(checkedItem[0]).then(
          () => {
            handlerRefreshBalances()
          },
          (errors) => PopupError({ text: APPLICATION_GENERIC_ERROR })
        )
      }
    }
    props.onSave()
  }

  const formatCurrency = (amount, currencyCode) => {
    let body = {
      style: 'currency',
      currency: currencyCode || 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
    return amount.toLocaleString('it-IT', body)
  }

  const handlerShowBalanceForm = (balance) => {
    setNewBalance(false)
    if (isNotEmpty(balance)) {
      setBalance(balance)
    }
    setModalShow(true)
  }

  const addNewBalanceForm = () => {
    setBalance(null)
    setNewBalance(true)
    setModalShow(true)
  }

  const closeModal = () => {
    setModalShow(false)
  }

  const openGuideBalances = () => {
    window.open('https://www.starsoluzioni.it/media/TABELLA-RAPPORTI.pdf')
  }

  const displayDisabledTip = () => {
    if (!relationship.id) {
      return relationshipNotSavedMessage
    } else if (disableAddBalanceButton) {
      return noBalancesAllowedMessage
    }
    return null
  }

  const renderBalanceRows = () => {
    if (isLoading) {
      return <RowSpinner />
    } else if (!balances || balances?.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center border pt-2">
          <i
            className="thx-warning-triangle thx-warning-triangle-grey q-icon"
            style={{ width: '30px', height: '30px' }}
          />
          <h6>Non ci sono risultati da mostrare</h6>
        </div>
      )
    }
    return (
      balances.length > 0 &&
      balances.map((balance, index) => (
        <div
          className="text-start row-table"
          key={'row-balance-' + index}
          id={'row-balance-' + index}>
          <div className="col w-5 text-center div-td">
            <input
              type="checkbox"
              value={balance.id}
              id={'balance_' + index}
              name={'balanceCheck'}
              checked={balanceCheckedItems.get(balance.id)}
              onChange={handleBalanceCheckChange}
            />
          </div>
          <div
            className={`${smallcolclass} text-truncate`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {balance.year}
          </div>
          <div
            className={`${smallcolclass} text-truncate text-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount1 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${smallcolclass} text-truncate text-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount2 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${smallcolclass} text-truncate text-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount3 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${smallcolclass} text-truncate text-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount4 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${smallcolclass} text-truncate text-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.amount5 || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${smallcolclass} text-truncate text-end`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {formatCurrency(parseInt(balance.stock || 0), balance.currency?.isoCode)}
          </div>
          <div
            className={`${smallcolclass} text-truncate`}
            onClick={() => handlerShowBalanceForm(balance)}>
            {balance.currency !== undefined ? balance.currency?.isoCode : ''}
          </div>
          <div
            className="col w-30 div-td text-truncate"
            onClick={() => handlerShowBalanceForm(balance)}>
            {isNotEmpty(balance.currencyType?.id) ? balance.currencyType.description : ''}
          </div>
          <div
            className={`${smallcolclass} text-truncate`}
            data-tip={balance.idResult}
            onClick={() => handlerShowBalanceForm(balance)}>
            {balance.idResult}
          </div>
        </div>
      ))
    )
  }

  const smallcolclass = 'col w-8 div-td'
  return (
    <div>
      {disableAddBalanceButton ? (
        <div className="py-3">
          <h5 className={'text-center'}>
            <b>Questo rapporto E' ESCLUSO dalla comunicazione INTEGRATIVA SALDI ANNUALI</b>
          </h5>
        </div>
      ) : (
        <div className="row p-2 pt-2">
          <div className="col-12 d-flex mb-1 align-items-center">
            <h6 className="m-0 pe-2">Elenco saldi del rapporto</h6>
            <BtnDelete
              className="btn-cell"
              handlerOnClick={() => openPopupDelete()}
              disabled={disabled}
            />
            <button
              className="btn btn-empty px-4 btn-sm btn-cell margin-left-5px "
              onClick={() => openGuideBalances()}>
              ISTRUZIONI COMPILAZIONE SALDI ANNUALI
            </button>
            <div className="ms-auto text-end me-2 balancePopOver" data-tip={displayDisabledTip()}>
              <button
                className={`${styles.btnFillPillSm} btn-cell`}
                onClick={() => addNewBalanceForm()}
                disabled={!relationship.id || disableAddBalanceButton || props.disabled}>
                <IPlus
                  className={'padding-right-4px'}
                  width="16"
                  fill={` ${disabled ? '#FFFFFF' : '#128186'}`}
                  alt={'Aggiungi'}
                />{' '}
                Aggiungi
              </button>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 table-balance">
            <div className="col-md-12 col-lg-12 header-table p-0">
              <div className="col w-5 text-center div-td">
                <input
                  type="checkbox"
                  value={allCheckBalance}
                  onChange={handleBalanceAllCheckChange}
                />
              </div>
              <div className={smallcolclass}>Anno</div>
              <div className={smallcolclass}>Importo 1</div>
              <div className={smallcolclass}>Importo 2</div>
              <div className={smallcolclass}>Importo 3</div>
              <div className={smallcolclass}>Importo 4</div>
              <div className={smallcolclass}>Altre Info/Importo 5</div>
              <div className={smallcolclass}>Giaz. Media</div>
              <div className={smallcolclass}>Valuta</div>
              <div className="col w-30 div-td">Natura</div>
              <div className={smallcolclass}>Esito</div>
            </div>

            {renderBalanceRows()}
          </div>
          {(balance || newBalance) && (
            <ModalCreateBalance
              disabled={props.disabled}
              key={balance?.id || new Date().toString()}
              show={modalShow}
              onHide={() => closeModal()}
              handlerSubmitBalance={handlerSubmitBalance}
              relationship={relationship}
              balance={balance}
              currencies={currencies}
              balances={balances}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default BalanceForm
