import { useState, useEffect } from 'react'
import BlueSearchForm from './NewBlueSearchForm'
import BlueResultForm from './NewBlueResultForm'
import arrow_l from '../../../styles/images/arrow_l.png'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../actions'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupError } from '../../shared/PopupError'
import Modal from 'react-bootstrap/Modal'
import BlueSearchResultPopup from './BlueSearchResultsPopup'
import getText from './labels'
import BlueLanguageSelector from './BlueLanguageSelector'
import { CheckLicenseAndRedirect } from '../../shared/auth/license/LicenseChecker'
import { APPLICATION_BLUE } from '../../../config/Constants'

export default function BlueSearchPage(props) {
  const dispatch = useDispatch()
  const subject = useSelector((state) => state.blueSubject.data)
  const auth = useSelector((state) => state.auth)
  const [isLoading, setLoading] = useState(false)
  const [searchResult, setSearchResults] = useState('')
  const [showResultsModal, setShowResultsModal] = useState(false)
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)

  useEffect(() => {
    dispatch(actions.getBlueSubject(props.match.params.id))
  }, [])

  useEffect(() => {
    if (auth) {
      CheckLicenseAndRedirect(
        props.history,
        '/app/blue/dashboard',
        auth?.user?.customer?.customerApplications,
        APPLICATION_BLUE
      )
    }
  }, [auth])

  const executeSearch = (data) => {
    setLoading(true)
    actions
      .checkBlueAntiTerrorism(props.match.params.id, data)
      .then(
        (res) => {
          setSearchResults(res)
          if (res?.result) {
            setShowResultsModal(true)
          }
        },
        (err) => {
          PopupError({
            text: labels.SEARCHERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => setLoading(false))
  }

  const closeResultsModal = () => {
    setShowResultsModal(false)
  }

  const isLoadingFunction = (value) => {
    setLoading(value)
  }
  return (
    <>
      {isLoading && <PageSpinner text={labels.SPINNERTITLE} />}
      <div className="table-custom mt-1">
        <div className="row title-page align-items-center justify-content-between">
          <div className="d-flex mb-3">
            <Link to="/app/blue/dashboard" className="d-flex align-items-center">
              <img
                src={arrow_l}
                className="ms-2 me-3"
                width="10"
                height="19"
                alt="Torna a elenco soggetti"
              />
              {subject?.denomination}
            </Link>
            <BlueLanguageSelector className="w-5" />
          </div>
        </div>
        <div className="tabs single">
          <div className="row">
            <div id="blue-search-form" className="col-lg-5 text-center pe-3">
              <div className="pe-4">
                <BlueSearchForm subject={subject} executeSearch={executeSearch}></BlueSearchForm>
              </div>
            </div>
            <div className="col-lg-7 text-center">
              <BlueResultForm
                isLoading={isLoadingFunction}
                subject={subject}
                results={searchResult}
                className="d-flex flax-direction-column"></BlueResultForm>
            </div>
          </div>
        </div>
      </div>

      <Modal
        backdrop="static"
        show={showResultsModal}
        onHide={closeResultsModal}
        dialogClassName="blue-results-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.SEARCHRESULTTITLE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlueSearchResultPopup
            key="resultsModal"
            data={searchResult}
            subject={subject}
            onClose={closeResultsModal}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
