import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'
import { searchCoraSubjects } from '../../../../actions'
import axiosService from '../../../../services/axios-service'
import { CoraSubjectForm } from './NewCoraSubjectForm'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'
import { isNotEmpty } from '../../../shared/Utility'
import { useHistory } from 'react-router-dom'
import { PopupError } from '../../../shared/PopupError'
import * as Constants from '../../../../config/Constants'

export function CoraSubjectList(props) {
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState('')
  const [id, setId] = useState()
  const history = useHistory()

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => {
      return { name: location.location, key: index }
    })
  )

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    fiscalCode: null,
    denomination: null,
    location: null,
    province: null,
    sidNumber: null,
    sidExpiration: null,
    sidEmail: null,
    version: 1
  })

  useEffect(() => {
    getCurrentUser()
  }, [])

  const getCurrentUser = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get(`/api/common/users/current`).then((res) => {
      if (isNotEmpty(res)) {
        setDefaultEmail(res.data.email)
      }
    })
  }

  const tableUtils = TableUtils
  const tableName = 'cora-subject'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      maxWidth: '190px',
      displayedName: 'Codice Fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      orderingName: 'completeName',
      maxWidth: '460px',
      displayedName: 'Ragione Sociale / Nominativo'
    }),
    tableUtils.composeHeader({
      fieldName: 'location',
      minWidth: '180px',
      displayedName: 'Sede Legale'
    }),
    tableUtils.composeHeader({
      fieldName: 'province',
      displayedName: 'Prov.',
      maxWidth: '100px'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidNumber',
      maxWidth: '130px',
      displayedName: 'Num. SID'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidExpiration',
      type: fieldTypes.DATE,
      maxWidth: '170px',
      displayedName: 'Scad. Certificati'
    }),
    tableUtils.composeHeader({
      fieldName: 'sidEmail',
      maxWidth: '350px',
      displayedName: 'Email da StarSoluzioni'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: c.completeName
          }),
          location: tableUtils.composeCell({
            fieldName: 'location',
            fieldValue: c.location
          }),
          province: tableUtils.composeCell({
            fieldName: 'province',
            fieldValue: c.province,
            minWidth: '200px'
          }),
          sidNumber: tableUtils.composeCell({
            fieldName: 'sidNumber',
            fieldValue: c.sidNumber
          }),
          sidExpiration: tableUtils.composeCell({
            fieldName: 'sidExpiration',
            fieldValue: c.sidExpiration
          }),
          sidEmail: tableUtils.composeCell({
            fieldName: 'sidEmail',
            fieldValue: c.sidEmail
          })
        }
      })
    })
  }

  const openDetail = (subject) => {
    const couldSendPec = subject?.coraSubjectSid?.couldSendPec
    const isDelayedCommunicationDateSet = isNotEmpty(props.delayedCommunicationDate)
    const cannotSendPec = couldSendPec !== true && isDelayedCommunicationDateSet === true
    const disableLink = cannotSendPec || props.disabled
    if (cannotSendPec) {
      const name =
        subject.personType === Constants.PNF
          ? subject.companyName
          : subject.lastName + ' ' + subject.firstName
      PopupError({
        text: `Parametri pec non configurati per il soggetto</br>${name}</br> Completare la configurazione o eliminare data di invio differito per continuare.`
      })
      return
    }
    if (props.disabled) {
      LicenseExpiredPopup({})
      return
    }
    const path = disableLink
      ? '#'
      : {
          pathname: `/app/cora/relationship/${subject.id}`,
          state: { licenseExpired: props.disabled }
        }
    history.push(path)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects))
    setId(id ? id : props.subjects?.[0]?.id)
  }, [props.subjects])

  useEffect(() => {
    setId(props.id)
  }, [props.id])

  useEffect(() => {
    setId()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={id}
        headerColums={header}
        rows={rows}
        offset="480"
        isLoading={show}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => searchCoraSubjects(searchObject)}
        onExecutedSearch={(content) => {
          setId()
          return props.setSubjects(content)
        }}
        usePagination={true}
        setId={setId}
        withCheckBoxes={true}
        onDoubleClickRow={(row) => openDetail(row)}
        onClickCheck={props.checkSubject}
        actionsComponent={props.actionsComponent}
        formComponent={CoraSubjectForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          subjectId: id,
          defaultEmail,
          formOnBottom: true,
          locations,
          setLoading: setLoading,
          addOrUpdateCoraSubject: props.addOrUpdateCoraSubject,
          close: () => setId(id ? id : undefined)
        }}
      />
    )
  }

  return <>{renderTable()}</>
}
