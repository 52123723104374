import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import * as actions from '../../../../actions'
import { PopupError } from '../../PopupError'
import { PopupSuccess } from '../../PopupSuccess'
import { PageSpinner } from '../../spinner/PageSpinner'
import { BwmCheckbox } from '../BwmCheckboxB5'
import { BwmInput } from '../BwmInputB5'
import imgShowBlu from '../../../../styles/images/show_blu.png'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IToggleOn } from '../../../../styles/images/svgs/regular/toggle-on.svg'
import { ReactComponent as IToggleOff } from '../../../../styles/images/svgs/regular/toggle-off.svg'
import { InfoBox } from '../../InfoBox'
import { PopupAlert } from '../../PopupAlert'
import { isEmpty, extractErrors } from '../../Utility'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { BwmSelect } from '../BwmSelectB5'
import { cloneDeep } from 'lodash'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

const onlyNumbers = new RegExp('^d+$')

export function ParametriPecForm(props) {
  const coraSubjectSid = props.coraSubjectSid
  const subjectFiscalCode = props.subjectFiscalCode
  const user = useSelector((state) => state.auth.customer.user)

  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [initialValues, setInitialValues] = useState({ needsToAccept: false })

  const [mailPresets, setMailPresets] = useState([])
  const [comureiSubjectPec, setComureiSubjectPec] = useState(null)
  const [loading, setLoading] = useState(false)
  const [needsTest, setNeedsTest] = useState(true)

  useEffect(() => {
    const form = cloneDeep(initialValues)
    form.emailAddress = coraSubjectSid?.pecEmail || ''
    form.password = coraSubjectSid?.pecEmail || ''
    form.inFolder = coraSubjectSid?.incomingMailFolder || 'INBOX'
    form.contactEmail = coraSubjectSid?.contactEmail || user.email || ''
    form.recipientEmail = coraSubjectSid?.recipientEmail || 'SID1@PCERT.AGENZIAENTRATE.IT'
    form.outgoingMailHost = coraSubjectSid?.outgoingMailHost || ''
    form.outgoingMailPort = coraSubjectSid?.outgoingMailPort || ''
    form.incomingMailHost = coraSubjectSid?.incomingMailHost || ''
    form.incomingMailPort = coraSubjectSid?.incomingMailPort || ''
    form.automaticElaborationEnabled =
      coraSubjectSid?.automaticElaborationEnabled === false ? false : true
    setInitialValues(form)
  }, [coraSubjectSid])

  useEffect(() => {
    actions.getMailPresets().then((res) => setMailPresets(res))
    if (subjectFiscalCode) {
      actions.getPecByFiscalCode(subjectFiscalCode).then((res) => setComureiSubjectPec(res))
    }
  }, [])

  useEffect(() => {
    if (
      mailPresets.length > 0 &&
      comureiSubjectPec &&
      comureiSubjectPec.pecManager &&
      isEmpty(coraSubjectSid?.pecEmail)
    ) {
      const form = cloneDeep(initialValues)
      form.mailPresets = comureiSubjectPec.pecEmail ? comureiSubjectPec.pecEmail.trim() : ''
      setInitialValues(form)
    }
  }, [mailPresets, comureiSubjectPec])

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().required('Campo obbligatorio').email('Formato email non valido'),
    recipientEmail: Yup.string().required('Campo obbligatorio').email('Formato email non valido'),
    password: Yup.string().required('Campo obbligatorio'),
    incomingMailHost: Yup.string().required('Campo obbligatorio'),
    incomingMailPort: Yup.number().required('Campo obbligatorio'),
    outgoingMailHost: Yup.string().required('Campo obbligatorio'),
    outgoingMailPort: Yup.number().required('Campo obbligatorio'),
    inFolder: Yup.string().required('Campo obbligatorio'),
    needsToAccept: Yup.boolean().oneOf([true], 'Devi accettare le condizioni')
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'emailAddress',
      errorLabel: 'Indirizzo Pec MITTENTE'
    },
    {
      errorKey: 'password',
      errorLabel: 'Password'
    },
    {
      errorKey: 'inFolder',
      errorLabel: 'Casella di posta in entrata'
    },
    {
      errorKey: 'contactEmail',
      errorLabel: 'Indirizzo di posta ordinario per contatti'
    },
    {
      errorKey: 'recipientEmail',
      errorLabel: 'Indirizzo Pec DESTINATARIO'
    },
    {
      errorKey: 'outgoingMailHost',
      errorLabel: 'Server posta in uscita'
    },
    {
      errorKey: 'outgoingMailPort',
      errorLabel: 'Porta'
    },
    {
      errorKey: 'incomingMailHost',
      errorLabel: 'Server posta in entrata'
    },
    {
      errorKey: 'incomingMailPort',
      errorLabel: 'Porta'
    },
    {
      errorKey: 'automaticElaborationEnabled',
      errorLabel: 'Elabora automaticamente le ricevute'
    },
    {
      errorKey: 'needsToAccept',
      errorLabel: 'Termini e condizioni'
    },
    {
      errorKey: 'connection',
      errorLabel: 'Verifica connessione'
    }
  ]

  const getErrors = (errors) => {
    if (!errors) errors = {}
    if (needsTest && Object.keys(errors)?.length === 0) {
      errors.connection = 'Devi verificare la connessione prima di salvare'
    }
    return extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <div key={'pec-' + coraSubjectSid?.id}>
      {loading && <PageSpinner />}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form>
            {showErrorAlert && (
              <div>
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              needsTest={needsTest}
              mailPresets={mailPresets}
              coraSubjectSid={coraSubjectSid}
              setNeedsTest={setNeedsTest}
              setLoading={setLoading}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()
  const [touched, setTouched] = useState(false)

  const [passwordFieldType, setPasswordFieldType] = useState('password')
  const [operatorSelect, setOperatorSelect] = useState()

  const handleFormVerify = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      testMailParameters(values)
    }
  }

  const handleChangeMailPreset = (selected) => {
    setOperatorSelect(selected)
    const preset = props.mailPresets.find((e) => e.id == selected)
    if (preset) {
      const form = cloneDeep(values)
      form.outgoingMailHost = preset.outgoingMailHost
        ? preset.outgoingMailHost.trim()
        : preset.outgoingMailHost
      form.outgoingMailPort = preset.outgoingMailPort
        ? preset.outgoingMailPort.trim()
        : preset.outgoingMailPort
      form.incomingMailHost = preset.incomingMailHost
        ? preset.incomingMailHost.trim()
        : preset.incomingMailHost
      form.incomingMailPort = preset.incomingMailPort
        ? preset.incomingMailPort.trim()
        : preset.incomingMailPort
      props.setNeedsTest(true)
      setValues(form)
    }
  }

  const handleAutoElaborationCheckBox = (value) => {
    const form = cloneDeep(values)
    form.automaticElaborationEnabled = value
    props.setNeedsTest(true)
    setValues(form)
  }

  const handleCheckbox = (value) => {
    const form = Object.assign({}, values)
    form.needsToAccept = value
    props.setNeedsTest(true)
    setValues(form)
  }

  const handleChangeTextField = (value, field, trim = true) => {
    let val = value
    if (trim) val = val.trim()
    const form = Object.assign({}, values)
    form[field] = val
    props.setNeedsTest(true)
    setValues(form)
  }

  const handleChangeNumericField = (value, field) => {
    const form = Object.assign({}, values)
    const val = value.replace(onlyNumbers)
    form[field] = val
    props.setNeedsTest(true)
    setValues(form)
  }

  const onClickPwdEye = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text')
    } else if (passwordFieldType === 'text') {
      setPasswordFieldType('password')
    }
  }

  const onClean = () => {
    PopupAlert({
      text: 'Si desidera eliminare la configurazione PEC?',
      handleClickConfirm: () => {
        let toSave = props.coraSubjectSid

        toSave = {
          ...toSave,
          pecEmail: undefined,
          outgoingMailHost: undefined,
          outgoingMailPort: undefined,
          incomingMailHost: undefined,
          incomingMailPort: undefined,
          mailPassword: undefined,
          contactEmail: undefined,
          recipientEmail: undefined,
          incomingMailFolder: undefined,
          automaticElaborationEnabled: false
        }

        props.onSave(toSave)
      }
    })
  }

  const testMailParameters = () => {
    props.setLoading(true)
    actions
      .testMailParameters(
        values.emailAddress,
        values.password,
        values.incomingMailHost,
        values.incomingMailPort,
        values.inFolder,
        values.outgoingMailHost,
        values.outgoingMailPort
      )
      .then(
        (res) => {
          props.setNeedsTest(false)
          PopupSuccess({ title: 'Parametri verificati con successo' })
        },
        (err) => {
          PopupError({ text: err })
        }
      )
      .then(() => props.setLoading(false))
  }

  const onClickSave = () => {
    let toSave = props.coraSubjectSid

    toSave = {
      ...toSave,
      pecEmail: values.emailAddress,
      outgoingMailHost: values.outgoingMailHost,
      outgoingMailPort: values.outgoingMailPort,
      incomingMailHost: values.incomingMailHost,
      incomingMailPort: values.incomingMailPort,
      mailPassword: values.password,
      contactEmail: values.contactEmail,
      recipientEmail: values.recipientEmail,
      incomingMailFolder: values.inFolder,
      automaticElaborationEnabled: values.automaticElaborationEnabled
    }

    props.onSave(toSave)
  }

  const handleFormSubmit = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0 || props.needsTest) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      onClickSave(values)
    }
  }

  const renderTipSection = () => {
    const tips = [
      {
        title: 'Operatore',
        text: 'Selezionare il gestore della vostra PEC'
      },
      {
        title: 'Indirizzo PEC Mittente',
        text: 'La pec indicata nella richiesta di accreditamento SID'
      },
      {
        title: 'Indirizzo PEC Destinatario',
        text: "L'indirizzo dell'Agenzia delle Entrate (SID1, SID2-5)"
      },
      {
        title: 'Server posta in entrata/uscita',
        text: 'I parametri di configurazione del vostro gestore della PEC'
      },
      {
        title: 'Casella di posta in entrata',
        text: 'Il nome della casella configurata dal vostro gestore per la ricezione dei messaggi'
      },
      {
        title: 'Indirizzo di posta ordinario',
        text: 'La mail NON PEC a cui spedire le conferme, gli esiti e gli eventuali errori'
      },
      {
        title: 'Elabora automaticamente le ricevute',
        text: 'Lasciare il visto per attivare la gestione automatica delle ricevute'
      }
    ]

    return <InfoBox additionalClasses={'flex-grow-1 p-3 py-2'} simplePointsArr={tips} />
  }

  const renderMailSection = () => {
    return (
      <div
        className="configurazioneSID d-flex flex-column justify-content-center"
        key={'mailsection-' + props.coraSubjectSid?.id}>
        <Row>
          <Col lg={10} className="mb-2 ps-3">
            <BwmInput
              className={'form-control'}
              label={'Indirizzo Pec MITTENTE'}
              value={values.emailAddress}
              error={errors.emailAddress}
              touched={touched}
              onChange={(e) => handleChangeTextField(e.target.value.toUpperCase(), 'emailAddress')}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={10} className="mb-2 ps-3">
            <div className="d-flex">
              <div style={{ flexGrow: 1 }}>
                <BwmInput
                  className={'form-control'}
                  label={'Password'}
                  type={passwordFieldType}
                  name={'passwordPec'}
                  value={values.password}
                  error={errors.password}
                  touched={touched}
                  onChange={(e) => handleChangeTextField(e.target.value, 'password')}
                  autoComplete={'new-password'}
                />
              </div>
              <div className="align-self-center mt-2">
                <img
                  alt={'mostra password'}
                  className="img-info ms-2"
                  src={imgShowBlu}
                  onClick={() => onClickPwdEye()}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={10} className="mb-2 ps-3">
            <BwmInput
              className={'form-control'}
              label={'Indirizzo Pec DESTINATARIO'}
              value={values.recipientEmail}
              error={errors.recipientEmail}
              touched={touched}
              onChange={(e) => {
                handleChangeTextField(e.target.value.toUpperCase(), 'recipientEmail')
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }

  const renderMailParamsSection = () => {
    return (
      <div key={'mailParamssection-' + props.coraSubjectSid?.id}>
        <Row className="justify-content-center mb-2">
          <Col lg={6} className="pe-2">
            <div className="configurazioneSID p-3">
              {' '}
              {/*posta in entrata*/}
              <div>
                <BwmInput
                  className={'form-control'}
                  label={'Server posta in entrata'}
                  value={values.incomingMailHost}
                  error={errors.incomingMailHost}
                  touched={touched}
                  onChange={(e) => handleChangeTextField(e.target.value, 'incomingMailHost')}
                />
              </div>
              <Row>
                <Col lg={6} className="pe-2 mt-2">
                  <BwmInput
                    className={'form-control'}
                    label={'Porta'}
                    value={values.incomingMailPort}
                    error={errors.incomingMailPort}
                    touched={touched}
                    onChange={(e) => handleChangeNumericField(e.target.value, 'incomingMailPort')}
                  />
                </Col>
                <Col lg={6} className="mt-2">
                  <div className="d-flex">
                    <div style={{ flexGrow: 1 }}>
                      <BwmInput
                        className={'form-control'}
                        label={'Casella di posta in entrata'}
                        value={values.inFolder}
                        error={errors.inFolder}
                        touched={touched}
                        onChange={(e) => handleChangeTextField(e.target.value, 'inFolder')}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="configurazioneSID p-3">
              {' '}
              {/*posta in uscita*/}
              <div className="mb-2">
                <BwmInput
                  className={'form-control'}
                  label={'Server posta in uscita'}
                  value={values.outgoingMailHost}
                  error={errors.outgoingMailHost}
                  touched={touched}
                  onChange={(e) => handleChangeTextField(e.target.value, 'outgoingMailHost')}
                />
              </div>
              <Row>
                <Col lg={6}>
                  <BwmInput
                    className={'form-control'}
                    label={'Porta'}
                    value={values.outgoingMailPort}
                    error={errors.outgoingMailPort}
                    touched={touched}
                    onChange={(e) => handleChangeNumericField(e.target.value, 'outgoingMailPort')}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderServiceSection = () => {
    return (
      <Row>
        <div
          className="configurazioneSID p-3 mb-2"
          key={'servicesection-' + props.coraSubjectSid?.id}>
          <Row>
            <Col lg={8} className="pe-2">
              <div className="d-flex">
                <div style={{ flexGrow: 1 }}>
                  <BwmInput
                    className={'form-control'}
                    label={'Indirizzo di posta ordinario per contatti'}
                    value={values.contactEmail}
                    error={errors.contactEmail}
                    touched={touched}
                    onChange={(e) => handleChangeTextField(e.target.value, 'contactEmail')}
                  />
                </div>
              </div>
            </Col>
            <Col className="d-flex">
              <div className={'align-self-center'}>
                <BwmCheckbox
                  name={'automaticElaborationEnabled'}
                  label={'Elabora automaticamente le ricevute'}
                  checked={values.automaticElaborationEnabled}
                  error={errors.automaticElaborationEnabled}
                  touched={touched}
                  onChange={(e) => handleAutoElaborationCheckBox(e.target.checked)}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    )
  }

  const renderEULASection = () => {
    return (
      <Row>
        <div
          className="configurazioneSID mt-1 pt-1 pb-1 "
          key={'servicesection-' + props.coraSubjectSid?.id}>
          <Row>
            <Col lg={10} className="text-small p-3">
              <small>
                Inserendo i dati nel presente form di configurazione il Cliente è consapevole di
                trasmettere a STAR INFOSTUDIO SRL i dati personali necessari all'erogazione del
                servizio richiesto. STAR INFOSTUDIO SRL tratterrà detti dati con diligenza e nel
                rispetto della normativa sul trattamento dei dati personali.
                <br />
                Il Cliente è consapevole che la gestione della sicurezza e la segretezza di tali
                dati non può essere limitata alla sola azienda, il Cliente pertanto manleva STAR
                INFOSTUDIO SRL da ogni responsabilità derivante da un'errata gestione da parte del
                Cliente o di terzi.
              </small>
            </Col>
            <Col lg={2} className="d-flex align-items-center justify-content-center">
              <button
                onClick={() => handleCheckbox(!values.needsToAccept)}
                className={
                  !values.needsToAccept
                    ? 'btn btn-outline-primary btn-empty px-4 btn-sm'
                    : 'btn btn-outline-primary  btn-new-rel px-4 btn-sm'
                }>
                Accetta&nbsp;
                {!values.needsToAccept ? (
                  <IToggleOff className={'padding-right-4px svg'} width="16" fill={'#128186'} />
                ) : (
                  <IToggleOn className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
                )}
              </button>
            </Col>
          </Row>
        </div>
      </Row>
    )
  }

  return (
    <>
      <div>
        <Row>
          <Col lg={6} className={'d-flex flex-column'}>
            <Row>
              <Col className="mb-2 pe-2">
                <BwmSelect
                  options={
                    props.mailPresets
                      ? props.mailPresets.map((p) => {
                          return { id: p.id, description: p.name }
                        })
                      : []
                  }
                  name="select"
                  label="Seleziona un operatore"
                  className="form-control"
                  value={operatorSelect}
                  onChange={(e) => handleChangeMailPreset(e.target.value)}
                />
              </Col>
            </Row>
            <Row className={'flex-grow-1 mb-2 pe-2'}>{renderMailSection()}</Row>
          </Col>
          <Col lg={6} className={'mb-2 d-flex flex-column'}>
            {renderTipSection()}
          </Col>
        </Row>

        {renderMailParamsSection()}
        {renderServiceSection()}
        {renderEULASection()}
      </div>
      <div className="row pt-3 justify-content-between align-items-center">
        <Col lg={2}>
          <button
            className="btn btn-outline-primary btn-empty px-4 btn-sm"
            onClick={() => props.onCancel()}>
            <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
            &nbsp; ANNULLA
          </button>
        </Col>
        <Col lg={4}>
          <button
            className="btn btn-outline-primary ms-2  btn-empty btn-sm"
            onClick={() => onClean()}>
            ELIMINA CONFIGURAZIONE
          </button>
        </Col>
        <Col lg={3} className="d-flex btn-outline-primary justify-content-center">
          <button
            className="btn btn-outline-primary btn-empty px-4 btn-sm"
            onClick={() => handleFormVerify()}>
            VERIFICA CONNESSIONE
          </button>
        </Col>
        <Col lg={3} className="d-flex justify-content-end">
          <button
            className="btn btn-primary btn-new-rel px-5 ml-2"
            onClick={() => handleFormSubmit()}>
            <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
            &nbsp; CONFERMA
          </button>
        </Col>
      </div>
    </>
  )
}
