import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { BwmInput } from '../../shared/form/BwmInputB5'
import { invioMailManuale } from '../../../actions'
import { PopupSuccess } from '../../shared/PopupSuccess'
import { PopupError } from '../../shared/PopupError'
import ErrorListAlert from '../../shared/form/ErrorListAlert'
import { BtnFill } from '../../shared/BtnFill'
import * as Utility from '../../shared/Utility'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

export function FacciamoNoiAdmin() {
  const auth = useSelector((state) => state.auth)
  const location = useLocation()

  const [initialValues, setInitialValues] = useState({})

  const [showErrorAlert, setShowErrorAlert] = useState(false)

  useEffect(() => {
    setInitialValues({ email: location?.state?.loginParam || '' })
  }, [])

  const validationSchema = Yup.object().shape({
    emailCliente: Yup.string().required('Campo obbligatorio')
  })

  const formErrorsMapping = [
    {
      errorKey: 'emailCliente',
      errorLabel: 'Email cliente'
    }
  ]

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  return (
    <div className="ms-3 mt-3">
      {auth?.isAdmin && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ errors }) => (
            <Form>
              {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
              {showErrorAlert &&
                errors &&
                typeof errors === 'object' &&
                Object.keys(errors).length > 0 && (
                  <div className="w-50">
                    <ErrorListAlert
                      errors={getErrors(errors)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
              <FormBody setShowErrorAlert={setShowErrorAlert} />
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [touched, setTouched] = useState(false)

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleFormSubmit = async () => {
    const errors = await validateForm(values) // Validazione dei valori
    setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      onSubmit()
    }
  }

  const onSubmit = () => {
    invioMailManuale(values).then(
      (rest) => PopupSuccess(),
      (err) => PopupError()
    )
  }

  return (
    <>
      <div className="row mt-4">
        <Col lg={4}>
          <BwmInput
            label={'Email cliente'}
            className={'form-control'}
            value={values.emailCliente}
            error={errors.emailCliente}
            touched={touched}
            onChange={(e) => handleChangeValue(e.target.value, 'emailCliente')}
          />
        </Col>
      </div>
      <div className="row mt-3">
        <Col lg={4}>
          <BwmInput
            label={'Partita IVA'}
            className={'form-control'}
            value={values.codiceFiscaleCliente}
            onChange={(e) => handleChangeValue(e.target.value, 'codiceFiscaleCliente')}
          />
        </Col>
      </div>
      <div className="row mt-3">
        <Col lg={4}>
          <BwmInput
            label={'Denominazione'}
            className={'form-control'}
            value={values.nomeCliente}
            onChange={(e) => handleChangeValue(e.target.value, 'nomeCliente')}
          />
        </Col>
      </div>
      <div className="row mt-4">
        <Col lg={4}>
          <BtnFill handlerOnClick={handleFormSubmit} text={'INVIA'} />
        </Col>
      </div>
    </>
  )
}
