import { IDelete } from '../../styles/icons'

export function BtnDelete(props) {
  const { handlerOnClick, disabled } = props
  return (
    <button
      type="button"
      className={`btn btn-empty px-4  btn-sm ${props.className}`}
      onClick={handlerOnClick}
      disabled={disabled}>
      <IDelete
        className={'padding-right-4px'}
        width="16"
        fill={` ${disabled ? '#FFFFFF' : '#128186'}`}
        alt={'Elimina'}
      />{' '}
      Elimina
    </button>
  )
}
