import { useEffect, useState } from 'react'
import { ReactComponent as ISearch } from '../../../../styles/images/svg/search-solid.svg'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { Col, Row } from 'react-bootstrap'
import { endOfDay } from 'date-fns'

export function SearchBar({
  customClass,
  dynamicSearch,
  searchPlaceholder,
  searchTerm,
  searchTermError,
  doSearch,
  searchOptions,
  handlerChangeSearch,
  searchType,
  setSearchType, // Passato come props
  setSearchTerm // Passato come props
}) {
  const [from, setFrom] = useState(
    endOfDay(new Date(new Date().getFullYear(), new Date().getMonth() - 1))
  )
  const [to, setTo] = useState(new Date())
  const [boxType, setBoxType] = useState('text')

  useEffect(() => {
    setBoxType(getBoxType(searchOptions, searchType))
  }, [searchOptions, searchType])

  const getBoxType = (searchOptions, searchType) => {
    let type = 'text'
    if (searchOptions && searchType) {
      searchOptions.forEach((element) => {
        if (element.id === searchType && element.type) {
          type = element.type
        }
      })
    }
    return type
  }

  const clearSearch = () => {
    setSearchType('') // Resetta il tipo di ricerca
    setSearchTerm('') // Resetta i termini di ricerca
    setTo(null) // Resetta la data "to" se presente
    setFrom(null) // Resetta la data "from" se presente
    doSearch() // Avvia la ricerca con campi vuoti
  }

  useEffect(() => {
    if (to && from && boxType === 'date') {
      let data = { to, from }
      dynamicSearch(data)
    } else {
      dynamicSearch('')
    }
  }, [to, from, boxType])

  return (
    <>
      <div id={'searchBar'} className={`${customClass || 'arcoOperationSearchBar'}`}>
        <div className="row align-items-center">
          <div className="col-2 me-2 mb-2">
            <BwmSelect
              options={searchOptions}
              name="searchType"
              label="Ricerca nel campo:"
              className="form-control"
              onChange={handlerChangeSearch}
              value={searchType}
            />
          </div>
          <div className="col-2 me-2 mb-2">
            {boxType === 'date' ? (
              <DateSearch to={to} from={from} setTo={setTo} setFrom={setFrom} />
            ) : (
              <BwmInput
                name="searchTerms"
                label="Termini di ricerca"
                className="form-control"
                onChange={(e) => dynamicSearch(e.target.value)}
                placeholder={searchPlaceholder}
                value={searchTerm}
                error={searchTermError}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    doSearch()
                  }
                }}
              />
            )}
          </div>

          <div className="col-2 text-end">
            <button className="btn btn-primary btn-new-rel px-3 w-75" onClick={() => doSearch()}>
              <ISearch className="padding-right-4px svg me-1" width="16" fill="#FFFFFF" />
              CERCA
            </button>
          </div>

          {/* <div className="col-2 text-end">
            <button className="btn btn-secondary btn-new-rel px-3 w-75" onClick={clearSearch}>
              <ISearch className="padding-right-4px svg me-1" width="16" fill="#FFFFFF" />
              PULISCI RICERCA
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

function DateSearch({ to, from, setTo, setFrom }) {
  return (
    <Row className="">
      <Col className="d-flex align-items-center justify-content-between">
        <DateField
          label="da"
          date={from}
          onChange={(date) => {
            setFrom(date)
          }}
        />
        <DateField
          label="a"
          date={to}
          onChange={(date) => {
            setTo(date)
          }}
        />
      </Col>
    </Row>
  )
}
