import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../../actions'
import '../../../../styles/monthlyList.css'
import { cloneDeep } from 'lodash'
import { CreateCommunicationModal } from './NewCreateCommunicationModal'
import { PopupError } from '../../../shared/PopupError'
import RowSpinner from '../../../shared/spinner/Spinner'
import { ReactComponent as IExclude } from '../../../../styles/images/svg/do-not-enter.svg'
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility'

export function MonthlyList(props) {
  const [allCheck, setAllCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})

  const subject = useSelector((state) => state.subject.data)
  const [communicationsToSend, setCommunicationsToSend] = useState([])
  const communicationTypes = useSelector((state) => state.communicationTypes.data)
  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    getMonthlyCommunications(props.subjectId)
  }, [])

  const getMonthlyCommunications = (subjectId) => {
    setLoading(true)
    actions.getCommunicationsToSendBySubjectId(subjectId, delayedCommunicationDate).then((res) => {
      setLoading(false)
      setCommunicationsToSend(res)
    })
  }

  const getCount = (communicationsToSend) => {
    let count = 0
    Object.keys(communicationsToSend).map((key) => {
      communicationsToSend[key].map((relationship, index) => {
        count += 1
      })
    })
    return count
  }

  const handleAllCheckChangeCommunications = (event, communicationsToSend) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = new Map()
      Object.keys(communicationsToSend).map((key) => {
        communicationsToSend[key].map((relationship, index) => {
          items[relationship.id] = true
        })
      })
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChangeCommunications = (event, communicationsToSend) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[event.target.value] = true
      else delete newCheckedItems[event.target.value]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === getCount(communicationsToSend))
      for (const checkedItem of Object.keys(newCheckedItems)) {
        if (checkedItem) {
          return
        }
      }
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerDontSendCommunication = () => {
    for (const checkedItem of Object.keys(checkedItems)) {
      if (checkedItem) {
        actions.removeNextCommunication([parseInt(checkedItem)]).then(
          () => {
            getMonthlyCommunications(props.subjectId)
          },
          (errors) => PopupError({ text: errors })
        )
      }
    }
    resetCheckBox()
  }

  /**
   *
   * @param {string} year
   * @param {string} month
   * @returns {boolean} true se precedente a mese anno corrente, se no false
   */
  const isPeriodValid = (year, month) => {
    const now = isNotEmpty(delayedCommunicationDate)
      ? new Date(delayedCommunicationDate)
      : new Date()
    const currYear = now.getFullYear()
    const currMonth = now.getMonth() + 1
    const intYear = parseInt(year)
    const intMonth = parseInt(month)
    if (intYear === currYear) {
      if (intMonth >= currMonth) {
        return false
      }
    } else if (intYear > currYear) {
      return false
    }
    return true
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
    document.getElementsByName('relationshipCheck').forEach((el) => {
      if (el.checked) {
        el.checked = false
      }
    })
  }

  const renderRowHeader = (communicationsToSend) => {
    return (
      <>
        <div className="col w-3 text-center div-td">
          <input
            type="checkbox"
            checked={!!allCheck}
            onChange={(e) => handleAllCheckChangeCommunications(e, communicationsToSend)}
          />
        </div>
        <div className="col w-8 div-td">PERIODO</div>
        <div className="col w-10 div-td">ANAGRAFICA</div>
        <div className="col w-10 div-td">CODICE FISCALE</div>
        <div className="col w-15 div-td">RUOLO</div>
        <div className="col w-8 div-td">DATA INIZIO</div>
        <div className="col w-8 div-td">DATA FINE</div>
        <div className="col w-35 div-td">RAPPORTO</div>
        <div className="col w-40 div-td">CODICE UNIVOCO</div>
      </>
    )
  }

  const renderRows = (key, relationships = []) => {
    return relationships.map((relationship, index) => {
      return (
        <div key={'rapporto-' + index}>
          <div
            className={`text-start row-table row-table-without-hover ${relationships.length - 1 === index ? 'border-radius-bottom' : ''}`}
            id={`row-${index}`}>
            <div className="col w-3 text-center div-td">
              <input
                type="checkbox"
                aria-label="Checkbox for following text input"
                id={`row-${index}`}
                name="relationshipCheck"
                value={relationship.id}
                checked={checkedItems[relationship.id] || false}
                onChange={(e) => handleCheckChangeCommunications(e, communicationsToSend)}
              />
            </div>
            <div className="col w-8 div-td text-truncate">{key}</div>
            <div className="col w-10 div-td text-truncate">{relationship.completeName}</div>
            <div className="col w-10 div-td text-truncate">{relationship.fiscalCode}</div>
            <div className="col w-15 div-td text-truncate">
              {relationship.coraRole
                ? relationship.coraRole.code + ' - ' + relationship.coraRole.description
                : ''}
            </div>
            <div className="col w-8 div-td text-truncate">
              {formatDateForDisplay(relationship.startDate)}
            </div>
            <div className="col w-8 div-td text-truncate">
              {relationship.endDate ? formatDateForDisplay(relationship.endDate) : ''}
            </div>
            <div className="col w-35 div-td text-truncate">
              {relationship.relationshipType.code} - {relationship.relationshipType.description}
            </div>
            <div className="col w-40 div-td text-truncate">{relationship.identifier}</div>
          </div>
        </div>
      )
    })
  }

  const renderGroups = (groupedRelationships, communicationTypes) => {
    if (loading) return <RowSpinner />

    if (!Object.keys(groupedRelationships)?.length > 0) {
      return (
        <div className={'empty-state'} style={{ height: 'unset', width: '99%' }}>
          <i
            className="thx-warning-triangle thx-warning-triangle-grey q-icon"
            style={{ width: '56px', height: '56px' }}
          />
          <h3 className="mt-3">Nessun Rapporto Da Spedire trovato</h3>
        </div>
      )
    }
    return Object.keys(groupedRelationships).map((key, index) => {
      let splitted = key.split('-')

      const year = splitted[0]
      const month = splitted[1]
      let yearAndMonth = year + '-' + month
      let communicationTypeDesc = ''
      let communicationTypeCode = ''
      for (var i = 0; i < communicationTypes.length; i++) {
        if (communicationTypes[i].code === splitted[3]) {
          communicationTypeDesc = communicationTypes[i].description
          communicationTypeCode = communicationTypes[i].code
          break
        }
      }

      let dispatchType = splitted[2]
      let dispatchTypeDesc = ''
      if (dispatchType === '1') {
        dispatchTypeDesc = 'Ordinario'
      } else {
        dispatchTypeDesc = 'Straordinario'
      }

      return (
        <div key={'gruppo-' + index}>
          <div className="monthly bg-blue">
            <div className="me-5">
              <strong className="me-1">Periodo di riferimento:</strong>
              <span>{yearAndMonth}</span>
            </div>
            <div className="me-5">
              <strong className="me-1">Tipo Comunicazione:</strong>
              <span>
                {communicationTypeCode === '2'
                  ? communicationTypeCode + ' - AGGIORNAMENTO/CHIUSURA RAPPORTO TARDIVA'
                  : communicationTypeCode + ' - ' + communicationTypeDesc}
              </span>
            </div>
            <div className="me-5">
              <strong className="me-1">Tipologia di Invio:</strong>
              <span>{dispatchTypeDesc}</span>
            </div>

            {!isPeriodValid(year, month) && (
              <div className="me-5">
                <strong className="period-error">
                  {`ATTENZIONE: il periodo ${yearAndMonth} può essere comunicato dal primo giorno del mese successivo`}{' '}
                </strong>
              </div>
            )}
          </div>
          <div className="monthly-row">{renderRows(key, groupedRelationships[key])}</div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between header-btn-relationship mb-2">
        <div>
          <div className="btn-group" role="group" aria-label="action buttons">
            <button
              type="button"
              className={`btn  btn-empty px-4 btn-sm w-100 ${Object.keys(checkedItems)?.length === 0 && 'disabled'}`}
              disabled={Object.keys(checkedItems)?.length === 0}
              onClick={() => handlerDontSendCommunication()}>
              <IExclude
                className={'padding-bottom-4px'}
                fill={` ${Object.keys(checkedItems)?.length === 0 ? '#FFFFFF' : '#128186'}`}
                width="15"
              />
              &nbsp; Escludi dalla comunicazione
            </button>
          </div>
        </div>

        <div>
          <CreateCommunicationModal
            type="MONTHLY"
            subjectId={props.subjectId}
            subject={subject}
            reloadList={getMonthlyCommunications}
            disabled={Object.keys(communicationsToSend).length <= 0}
            delayedCommunicationDate={delayedCommunicationDate}
          />
        </div>
      </div>
      <div className="star-table">
        <div className="text-start header-table">{renderRowHeader(communicationsToSend)}</div>
        {renderGroups(communicationsToSend, communicationTypes)}
      </div>
    </>
  )
}
