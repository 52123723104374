import React, { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'
import { useSelector } from 'react-redux'
import { DateField } from '../../../shared/form/DateFieldB5'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import NumberFormat from 'react-number-format'

registerLocale('it', it)

export function ArcoRegistrationContractForm(props) {
  const isEmpty = (val) => {
    return val === '' || val === null || val === undefined
  }
  //const arcoCausalOperationsFromStore = useSelector(state => state.arcoCausalOperations.data);
  const arcoSubject = useSelector((state) => state.arcoSubject.data)
  const customer = useSelector((state) => state.auth.customer)
  const currencies = useSelector((state) => state.currencies.data)
  const [arcoCausalOperations, setArcoCausalOperations] = useState(props.arcoCausalOperations)
  const setDefaultCurrency = () => {
    let eur
    if (props.selectedContract) {
      return props.selectedContract.currency.id
    } else {
      currencies.map((currentCurrency, index) => {
        if (currentCurrency.isoCode === 'EUR') {
          eur = currentCurrency.id
        }
      })
      return eur
    }
  }
  const [currency, setCurrency] = useState(setDefaultCurrency())
  const [causal, setCausal] = useState(
    props.selectedContract?.arcoCausalOperationType?.id
      ? props.selectedContract?.arcoCausalOperationType?.id.toString()
      : props.arcoCausalOperations[0].id.toString()
  )
  const [causalError, setCausalError] = useState({ isValid: true, msg: '' })
  const [amount, setAmount] = useState(props.selectedContract?.amount || 0)
  const [amountError, setAmountError] = useState({ isValid: true, msg: '' })
  const [paymentType, setPaymentType] = useState(props.selectedContract?.paymentType || null)
  const [paymentTypeError, setPaymentTypeError] = useState({ isValid: true, msg: '' })
  const [contractDate, setContractDate] = useState(
    props.selectedContract
      ? new Date(props.selectedContract?.contractDate)
      : () => {
          let date = new Date(props.selectedOperation.profilingDate)
          date.setHours(12)
          return date
        }
  )
  const [contractDateError, setContractDateError] = useState({ isValid: true, msg: '' })
  const [registrationDate, setRegistrationDate] = useState(
    props.selectedContract ? new Date(props.selectedContract?.registrationDate) : new Date()
  )
  const [registrationDateError, setRegistrationDateError] = useState({ isValid: true, msg: '' })
  const [cash, setCash] = useState(props.selectedContract?.cash || 0)
  const [cashError, setCashError] = useState({ isValid: true, msg: '' })
  const setDefaultCurrencyIso = () => {
    let eur
    if (props.selectedContract) {
      return props.selectedContract.currency.isoCode
    } else {
      currencies.map((currentCurrency, index) => {
        if (currentCurrency.isoCode === 'EUR') {
          eur = currentCurrency.isoCode
        }
      })
      return eur
    }
  }
  const [currencyDescription, setCurrencyDescritpion] = useState(setDefaultCurrencyIso())
  const [currencyError, setCurrencyError] = useState({ isValid: true, msg: '' })
  const [notes, setNotes] = useState(props.selectedContract?.notes || null)
  const [notesError, setNotesError] = useState({ isValid: true, msg: '' })
  const [index, setIndex] = useState(
    parseInt(props.selectedContract?.index) !== 'NaN' ? props.selectedContract?.index : null
  )
  const [formData, updateFormData] = useState({
    arcoCausalOperationType: props.selectedContract
      ? props.selectedContract.arcoCausalOperationType
      : props.arcoCausalOperations[0],
    currency: {
      id: currency,
      isoCode: currencyDescription
    },
    amount: amount,
    paymentType: paymentType,
    contractDate: contractDate,
    registrationDate: registrationDate,
    cash: cash,
    notes: notes,
    arcoSubject: arcoSubject,
    customer: customer,
    index: index,
    id: props.selectedContract?.id || null
  })

  useEffect(() => {
    if (props.arcoCausalOperations && arcoCausalOperations.length == 0) {
      setArcoCausalOperations(props.arcoCausalOperations)
      updateFormData({
        ...formData,
        arcoCausalOperationType: props.selectedContract
          ? props.selectedContract.arcoCausalOperationType
          : props.arcoCausalOperations[0]
      })
    }
  })

  const handlerChangeCausal = (e) => {
    const causalId = e.target[e.target.selectedIndex].value
    const causalDescription = e.target[e.target.selectedIndex].text
    setCausal(causalId)
    setCausalError({ isValid: true, msg: '' })
    updateFormData({
      ...formData,
      arcoCausalOperationType: {
        id: causalId,
        description: causalDescription
      }
    })
  }

  const handlerChangeAmount = (val) => {
    if (isEmpty(val)) {
      val.floatValue = 0
    }
    setAmount(val.floatValue)
    setAmountError({ isValid: true, msg: '' })
    updateFormData({
      ...formData,
      amount: val.floatValue
    })
  }

  const handlerChangePaymentType = (e) => {
    let value = e.target.value?.toUpperCase()
    setPaymentType(value)
    setPaymentTypeError({ isValid: true, msg: '' })
    updateFormData({
      ...formData,
      paymentType: value
    })
  }

  //metto la data alle 12PM per evitare problemi di conversione in fase di salvataggio
  const handlerChangeContractDate = (date) => {
    if (date) {
      date.setHours(12)
      setContractDate(date)
      setContractDateError({ isValid: true, msg: '' })
      updateFormData({
        ...formData,
        contractDate: date
      })
    }
  }

  const handlerChangeRegistrationDate = (date) => {
    if (date) {
      date.setHours(12)
      setRegistrationDate(date)
      setRegistrationDateError({ isValid: true, msg: '' })
      updateFormData({
        ...formData,
        registrationDate: date
      })
    }
  }

  const handlerChangeCash = (val) => {
    if (isEmpty(val)) {
      val.floatValue = 0
    }
    setCash(val.floatValue)
    updateFormData({
      ...formData,
      cash: val.floatValue
    })
    setCashError({ isValid: true, msg: '' })
  }

  const handlerChangeCurrency = (e) => {
    const currencyId = e.target[e.target.selectedIndex].value
    const currencyisoCode = e.target[e.target.selectedIndex].attributes[1].value
    setCurrency(currencyId)
    setCurrencyDescritpion(currencyisoCode)
    setCurrencyError({ isValid: true, msg: '' })
    updateFormData({
      ...formData,
      currency: {
        id: currencyId,
        isoCode: currencyisoCode
      }
    })
  }

  const handlerChangeNotes = (e) => {
    let value = e.target.value?.toUpperCase()
    setNotes(value)
    setNotesError({ isValid: true, msg: '' })
    updateFormData({
      ...formData,
      notes: value
    })
  }

  const handleContractValidation = () => {
    let isValid = true

    if (!causal) {
      setCausalError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (amount === null || amount === undefined || amount === '') {
      setAmountError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }

    if (!paymentType) {
      setPaymentTypeError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!contractDate) {
      setContractDateError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!registrationDate) {
      setRegistrationDateError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (cash === null || cash === undefined || cash === '') {
      setCashError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (!currency) {
      setCurrencyError({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    }
    if (amount < cash) {
      setCashError({ isValid: false, msg: 'Contanti maggiore di importo' })
      isValid = false
    }
    return isValid
  }

  const saveAll = () => {
    if (!handleContractValidation()) return
    props.addArcoContract(formData)
    props.onHide()
  }

  return (
    <div className="row-detail bg-gray d-flex row-form big">
      <div>
        <div className="soggetto-crs">
          <form id={`anagrafica-`}>
            <div className="row">
              <div className="col-lg-2 me-2">
                <DateField
                  label="Data Registrazione"
                  onChange={(date) => handlerChangeRegistrationDate(date)}
                  error={registrationDateError}
                  date={registrationDate}
                />
              </div>
              <div className="col-lg-2 me-2">
                <DateField
                  label="Data Contratto"
                  onChange={(date) => handlerChangeContractDate(date)}
                  error={contractDateError}
                  date={contractDate}
                />
              </div>

              <div className="col-md-2 me-2">
                <div className="form-group">
                  {/* <label>Importo</label> */}
                  <NumberFormat
                    label="Importo"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className="form-control text-start p-2"
                    name="importo"
                    inputMode="numeric"
                    value={amount || 0}
                    defaultValue={0}
                    onValueChange={(val) => handlerChangeAmount(val)}
                  />
                  {amountError && <div className="text-danger">{amountError.msg}</div>}
                </div>
              </div>
              <div className="col-lg-4">
                <BwmInput
                  name="tipologia"
                  label="Tipologia mezzi di pagamento"
                  className="form-control"
                  value={paymentType}
                  onChange={(e) => handlerChangePaymentType(e)}
                  error={paymentTypeError}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="row mt-2 me-2 mb-2">
              <div className="col-lg-4">
                <BwmSelect
                  options={arcoCausalOperations}
                  name="causale"
                  label="Causale"
                  className="form-control"
                  onChange={(e) => handlerChangeCausal(e)}
                  value={causal}
                  error={causalError}
                  showCode={true}
                />
              </div>

              <div className="col-md-1 ms-2 me-2">
                <div className="form-group">
                  <NumberFormat
                    thousandSeparator={'.'}
                    label="Contanti"
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className="form-control text-start p-2"
                    name="contanti"
                    inputMode="numeric"
                    value={cash || 0}
                    defaultValue={0}
                    onValueChange={(val) => handlerChangeCash(val)}
                  />
                  {cashError && <div className="text-danger">{cashError.msg}</div>}
                </div>
              </div>
              <div className="col-lg-4">
                <BwmSelect
                  options={currencies}
                  name="divisa"
                  label="Divisa"
                  className="form-control"
                  onChange={(e) => handlerChangeCurrency(e)}
                  value={currency}
                  error={currencyError}
                  showCode={true}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className="col-md-10">
                <BwmInput
                  name="note"
                  label="Note (non ai fini AUI)"
                  className="form-control"
                  value={notes}
                  onChange={(e) => handlerChangeNotes(e)}
                  error={notesError}
                  maxLength={255}
                />
              </div>
            </div>
          </form>
        </div>

        <div className="form-row mt-3 ms-2  justify-content-between align-item-center">
          <button className="btn btn-outline-primary btn-empty px-4 btn-sm" onClick={props.onHide}>
            <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
            &nbsp; ANNULLA
          </button>

          <button onClick={saveAll} className="btn btn-primary btn-new-rel px-5">
            <ISave className={'padding-right-4px svg'} width="16" fill={'#FFFFFF'} />
            &nbsp; CONFERMA
          </button>
        </div>
      </div>
    </div>
  )
}
