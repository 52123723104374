import { useEffect, useState } from 'react'
import { BtnEmpty } from '../../shared/BtnEmpty'
import { BtnFill } from '../../shared/BtnFill'
import Modal from 'react-bootstrap/Modal'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import * as actions from '../../../actions'
import { PRIORITIES } from './PRIORITY'
import { formatDateForDisplay, formatTimeFromDate, joinDateAndTime } from '../../shared/Utility'
import { DateField } from '../../shared/form/DateField'
import { BwmSelect } from '../../shared/form/BwmSelectB5'
import { BwmInput } from '../../shared/form/BwmInputB5'
import { ICopy, IDelete, IPhoneAlt } from '../../../styles/icons'
import { PopupError } from '../../shared/PopupError'
import { APPLICATION_GENERIC_ERROR } from '../../../config/Constants'
import { SimpleSpinner } from '../../shared/spinner/Spinner'
import { PopupConfirm, titleColors } from '../../shared/PopupConfirm'
import { ContactHistoryForm } from './ContactHistoryForm'
import * as Utility from '../../shared/Utility'
import ErrorListAlert from '../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import './contactInfoModal.css'
export const baseContact = { id: null, denomination: '', phone: '' }

export const ContactInfoModal = (props) => {
  const [initialValues, setInitialValues] = useState({
    id: null,
    date: new Date(),
    caller: props.defaultCaller,
    notes: '',
    contact: props.selectedContactNumber?.denomination,
    phone: props.selectedContactNumber?.phone
  })

  const [contactHistories, setContactHistories] = useState([])

  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.contactInfo?.id) {
      fetchContactInfo(props.contactInfo)
    } else {
      setInitialValues({ ...props.contactInfo, contactNumbers: [baseContact] })
    }
  }, [props.contactInfo])

  const fetchContactInfo = (contactInfo) => {
    setLoading(true)
    actions
      .getContactInfo(contactInfo?.id)
      .then((res) => {
        let contacts = res.contactNumbers
        if (!res.contactNumbers?.length) {
          contacts.unshift(baseContact)
        }
        setInitialValues({ ...res, contactNumbers: contacts })
        setContactHistories([...res.contactHistories])
      })
      .then(() => setLoading(false))
  }

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  const formErrorsMapping = [
    {
      errorKey: 'comureiSubjectType.id',
      errorLabel: 'Tipo soggetto'
    }
  ]

  const validationSchema = Yup.object().shape({})

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      size={'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      className="big-modal mt-0 mb-1"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{initialValues?.denomination || initialValues?.login || 'Nuovo contatto'}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3">
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}>
            {({ errors }) => (
              <Form>
                {showErrorAlert && (
                  <div>
                    <ErrorListAlert
                      errors={getErrors(errors)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
                <FormBody
                  {...props}
                  loading={loading}
                  contactHistories={contactHistories}
                  setContactHistories={setContactHistories}
                  setShowErrorAlert={setShowErrorAlert}
                  setLoading={setLoading}
                />
              </Form>
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  )
}

function HistoryList({ elements, onDelete, loading = false, onChangeRow }) {
  const [localElements, setLocalElements] = useState([])
  useEffect(() => {
    if (elements) {
      setLocalElements(elements)
    }
  }, [elements])

  const onChange = (index, field, value) => {
    onChangeRow(index, field, value)
  }

  return (
    <div>
      {localElements.map((ch, index) => {
        return (
          <div className="row align-items-center mb-2" key={'history' + ch.id}>
            <div className="col-1 pe-2">
              <DateField
                className="form-control form-control-cell"
                date={ch.date}
                onChange={(date) => onChange(index, 'date', date)}
              />
            </div>

            <div className="col-1 pe-2">
              <BwmInput
                type={'time'}
                className="form-control form-control-cell"
                value={formatTimeFromDate(ch.date)}
                onChange={(e) => onChange(index, 'date', joinDateAndTime(ch.date, e.target.value))}
              />
            </div>

            <div className="col-2 pe-2">
              <BwmInput
                className="form-control form-control-cell"
                value={ch.contact}
                onChange={(e) => onChange(index, 'contact', e.target.value)}
              />
            </div>
            <div className="col-2 pe-2">
              {
                <BwmInput
                  className="form-control form-control-cell"
                  value={ch.phone}
                  onChange={(e) => onChange(index, 'phone', e.target.value)}
                  Icon={() => <CallIcon phone={ch.phone} />}
                />
              }
            </div>
            <div className="col-1 pe-2">
              <BwmInput
                className="form-control form-control-cell"
                value={ch.caller}
                onChange={(e) => onChange(index, 'caller', e.target.value)}
              />
            </div>
            <div className="col">
              <BwmInput
                className="form-control form-control-cell"
                value={ch.notes}
                onChange={(e) => onChange(index, 'notes', e.target.value)}
                Icon={() => (
                  <button onClick={() => onDelete(ch)} disabled={loading}>
                    <IDelete width="17" className="text-red" />
                  </button>
                )}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export function CallIcon({ phone }) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://starinfostudio.my3cx.it/webclient/#/people?phone=${phone}`}>
      <IPhoneAlt fill={'#128186'} className={'padding-right-4px svg'} width="22" height="20" />
    </a>
  )
}

function ContactList({ contactInfo, onChangeRow, onDelete, onAdd, onSelect, loading = false }) {
  const newContact = baseContact
  const onChange = (index, field, value) => onChangeRow(index, field, value)

  return (
    <>
      <div className="d-flex align-items-center">
        <label className="fw-bold">Contatti: </label>

        <button
          className="ms-2 mb-1 btn btn-new-rel btn-sm btn-cell"
          onClick={() => onAdd(newContact)}>
          AGGIUNGI
        </button>
      </div>

      {loading && <SimpleSpinner size={15} />}

      <div className="p-2 contact-info-table">
        <div className="row d-flex align-items-center">
          <div className="col-7">
            <label>Nominativo</label>
          </div>
          <div className="col-5">
            <label>Numero</label>
          </div>
        </div>
        {contactInfo?.contactNumbers &&
          contactInfo?.contactNumbers.map((item, index) => {
            return (
              <div key={index} className="row d-flex align-items-center mb-2">
                <div className="col-7 pe-2">
                  <BwmInput
                    className="form-control form-control-cell"
                    value={item?.denomination}
                    placeholder={'Inserisci nominativo'}
                    onChange={(e) => onChange(index, 'denomination', e.target.value)}
                  />
                </div>
                <div className="col-5">
                  <BwmInput
                    className="form-control form-control-cell"
                    value={item?.phone}
                    onChange={(e) => onChange(index, 'phone', e.target.value)}
                    placeholder={'Inserisci numero'}
                    Icon={() => (
                      <span>
                        {' '}
                        <button className="py-0" onClick={() => onSelect(item)}>
                          <CallIcon phone={item.phone} />
                        </button>
                        <button
                          className="py-0"
                          onClick={() => onDelete(index, item)}
                          disabled={loading}>
                          <IDelete width="16" className="text-red" />
                        </button>
                      </span>
                    )}
                  />
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues } = useFormikContext()

  const [touched, setTouched] = useState(false)

  const baseHistoryObject = {
    id: null,
    date: new Date(),
    contact: '',
    phone: '',
    caller: props.defaultCaller,
    notes: ''
  }

  const [newHistory, setNewHistory] = useState(baseHistoryObject)
  const [showNewHistoryForm, setShowNewHistoryForm] = useState(false)

  useEffect(() => {
    if (props.selectedContactNumber) {
      setShowNewHistoryForm(true)
      const newHistory = {
        ...baseHistoryObject,
        contact: props.selectedContactNumber?.denomination,
        phone: props.selectedContactNumber?.phone
      }
      setNewHistory(newHistory)
    }

    return () => {}
  }, [])

  const handleChangeContactHistory = (index, field, value) => {
    let list = props.contactHistories
    list[index] = { ...list[index], [field]: value }
    props.setContactHistories([...list])
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (val && val !== '' && trim) val = val.trim()
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleDelete = () => {
    if (values?.id) {
      PopupConfirm({
        titleColor: titleColors.ALERT,
        title: 'Eliminazione contatto',
        text: "Si vuole eliminare il contatto? L'operazione è irreversibile",
        handleClickConfirm: () => {
          actions.deleteContactInfo(values.id).then(
            (res) => props.onSave(),
            (err) => PopupError({ text: APPLICATION_GENERIC_ERROR })
          )
        }
      })
    }
  }

  const handleChangeContactNumber = (index, field, value) => {
    let list = values.contactNumbers
    list[index] = { ...list[index], [field]: value }

    setValues({ ...values, contactNumbers: list })
  }

  const handleSave = (afterSave = (result) => {}) => {
    actions.saveContactInfo(values).then(
      (res) => {
        //salvo tutte le modifiche allo storico e contatti
        const numbersToSave = values.contactNumbers.map((c) => {
          c.contactInfo = res
          return c
        })
        let historiesToSave = props.contactHistories.map((c) => {
          c.contactInfo = res
          return c
        })
        if (newHistory.notes) {
          historiesToSave.push({ ...newHistory, contactInfo: res })
        }
        actions
          .saveContactNumbers(numbersToSave)
          .then(
            (res) => console.log('numeri salvati'),
            (err) => console.log('numeri NON salvati')
          )
          .then(() => {
            actions
              .saveContactHistories(historiesToSave)
              .then(
                (res) => console.log('storici salvati'),
                (err) => console.log('storici NON salvati')
              )
              .then(() => {
                afterSave(res)
              })
          })
      },
      (err) => {
        PopupError({ text: APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const handleAddContactNumber = (contact) => {
    let contacts = values.contactNumbers
    contacts.unshift(contact)
    setValues({ ...values, contactNumbers: contacts })
  }

  const handleDeleteContactNumber = (index, contact) => {
    if (contact?.id) {
      PopupConfirm({
        titleColor: titleColors.ALERT,
        text: 'Si desidera eliminare il contatto selezionato?',
        handleClickConfirm: () => {
          props.setLoading(true)
          actions.deleteContactNumber(contact.id).then((res) => {
            let contacts = values.contactNumbers.filter((c) => c.id !== contact.id) //elimino i contatti in memoria invece di ricaricarli per preservare eventuali contatti non salvati
            setValues({ ...values, contactNumbers: contacts })
            props.setLoading(false)
          })
        }
      })
    } else {
      let contacts = values.contactNumbers
      contacts.splice(index, 1)
      setValues({ ...values, contactNumbers: contacts })
    }
  }

  const handleDeleteContactHistory = (contactHistory) => {
    PopupConfirm({
      titleColor: titleColors.ALERT,
      text: 'Si desidera eliminare la telefonata?',
      handleClickConfirm: () => {
        props.setLoading(true)
        actions.deleteContactHistory(contactHistory.id).then((res) => {
          let histories = values.contactHistories.filter((c) => c.id !== contactHistory.id)
          setValues({ ...values, contactHistories: histories })
          props.setContactHistories(histories)
          props.setLoading(false)
        })
      }
    })
  }

  const onSelect = (contact) => {
    setShowNewHistoryForm(true)
    setNewHistory({
      ...baseHistoryObject,
      contact: contact.denomination,
      phone: contact.phone
    })
  }

  return (
    <div>
      <div className="row">
        <div className="col pe-2">
          <BwmInput
            name="denomination"
            label="Nominativo"
            className="form-control"
            value={values?.denomination}
            error={errors?.denomination}
            touched={touched}
            onChange={(e) => handleChangeValue(e.target.value, 'denomination')}
          />
        </div>
        <div className="col pe-2">
          <BwmInput
            name="login"
            label="Login"
            className="form-control"
            value={values?.login}
            error={errors?.login}
            touched={touched}
            onChange={(e) => handleChangeValue(e.target.value, 'login')}
          />
        </div>
        <div className="col pe-2">
          <BwmInput
            name="email"
            label="Email"
            className="form-control"
            value={values?.email}
            Icon={() => (
              <ICopy fill={'#128186'} className={'padding-bottom-4px svg'} width="22" height="22" />
            )}
            onIconClick={() => navigator.clipboard.writeText(values?.email)}
            onChange={(e) => handleChangeValue(e.target.value, 'email')}
          />
        </div>
        <div className="col pe-2">
          <BwmInput
            name="pwd"
            label="Password"
            type={'password'}
            className="form-control"
            value={values?.pwd}
            Icon={() => (
              <ICopy fill={'#128186'} className={'padding-bottom-4px svg'} width="22" height="22" />
            )}
            onIconClick={() => navigator.clipboard.writeText(values?.pwd)}
            onChange={(e) => handleChangeValue(e.target.value, 'pwd')}
          />
        </div>
        <div className="col">
          <BwmSelect
            options={PRIORITIES || []}
            name="callPriority"
            label="Priorità"
            className="form-control"
            value={values.callPriority}
            error={errors.callPriority}
            touched={touched}
            onChange={(e) => handleChangeValue(e.target.value, 'callPriority')}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-3 pe-3">
          <FloatingLabel className="w-100 h-100" label={'Note'}>
            <textarea
              name="comment"
              className={'form-control custom-text-area'}
              rows={3}
              cols={12}
              value={values.notes}
              maxLength={255}
              onChange={(e) => handleChangeValue(e.target.value, 'notes')}
            />
          </FloatingLabel>
        </div>
        <div className="form-group col pe-3">
          <ContactList
            loading={props.loading}
            contactInfo={values}
            onAdd={(newContact) => handleAddContactNumber(newContact)}
            onDelete={(index, contact) => handleDeleteContactNumber(index, contact)}
            onChangeRow={(index, field, value) => handleChangeContactNumber(index, field, value)}
            onSelect={(contact) => onSelect(contact)}
          />
        </div>
        <div className="form-group col">
          <label className="mb-2 fw-bold">Licenze: </label>
          <div className="p-2 contact-info-table">
            <div className="row">
              <div className="col">
                <label>App e soggetti</label>
              </div>
              <div className="col">
                <label>Tipo Licenza</label>
              </div>
              <div className="col">
                <label>Scadenza</label>
              </div>
            </div>
            {values?.contactApplications &&
              values?.contactApplications.map((ca) => {
                return (
                  <div className="row" key={'applications-' + ca.id}>
                    <div className="col">{`${ca.application?.name} x${ca.maxSubjects}`}</div>
                    <div className="col">{ca.licenseType}</div>
                    <div className="col">{formatDateForDisplay(ca.expiration)}</div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group">
          <div className="mt-3 contact-history-call">
            <div className="row">
              <div className="d-flex align-items-center">
                <h6 className="text-primary fw-bold">Storico Telefonate </h6>

                {props.loading && <SimpleSpinner size={16} />}
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <label>Data</label>
              </div>

              <div className="col-1">
                <label>Ora</label>
              </div>

              <div className="col-2">
                <label>Contatto</label>
              </div>
              <div className="col-2">
                <label>Numero</label>
              </div>
              <div className="col-1">
                <label>Chiamato Da</label>
              </div>
              <div className="col">
                <label>Note</label>
              </div>
            </div>
            {showNewHistoryForm && (
              <ContactHistoryForm
                key={'history-form-new'}
                historyObject={newHistory}
                onChange={(changed) => setNewHistory(changed)}
              />
            )}
            {HistoryList({
              elements: props.contactHistories,
              onChangeRow: (index, field, value) => handleChangeContactHistory(index, field, value),
              onDelete: (contactHistory) => handleDeleteContactHistory(contactHistory),
              loading: props.loading
            })}
          </div>
        </div>
      </div>
      <div className={'col-12 mt-4 d-flex justify-content-between'}>
        <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={props.onClose} />
        {values?.id && (
          <button
            className="btn btn-outline-primary btn-empty px-5 btn-sm ms-3"
            onClick={() => handleDelete()}>
            <IDelete width="15" />
            &nbsp; ELIMINA CLIENTE
          </button>
        )}

        <BtnFill
          text="SALVA E CHIUDI"
          classCustom="float-end text-end"
          handlerOnClick={() => handleSave((result) => props.onSave(result))}
        />
      </div>
    </div>
  )
}
