import { useEffect, useRef, useState } from 'react'
import { StarTooltip } from '../tooltips/Tooltip'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'

export const BwmInput = ({
  label,
  type,
  Icon,
  onIconClick = () => undefined,
  clickableIcon = true,
  iconTip,
  className,
  onChange,
  name,
  value,
  onKeyPress,
  onFocus,
  maxLength,
  error,
  touched,
  pattern,
  step,
  onBlur = () => {},
  readOnly,
  id,
  onInput,
  disabled,
  autoComplete,
  dataTip,
  autofocus = false
}) => {
  //in certi casi, il cursore può saltare alla fine. questo stato riporta il cursore al posto giusto
  const [cursor, setCursor] = useState(null)
  const ref = useRef(null)

  //Focus su primo render, se richiesto
  useEffect(() => {
    if (autofocus) {
      ref.current.focus()
    }
  }, [])

  useEffect(() => {
    const input = ref.current
    if (input && ['text', 'search', 'URL', 'tel', 'password'].includes(input.type))
      input.setSelectionRange(cursor || 0, cursor || 0)
  }, [ref, cursor, value])

  const handleChange = (e) => {
    setCursor(e.target.selectionStart)
    onChange && onChange(e)
  }

  const inputWrapperClass = `input-wrapper d-flex w-100 align-items-center ${touched && error !== undefined ? 'has-error' : ''}`

  return (
    <div className={inputWrapperClass}>
      <FloatingLabel id="floatingLabel" className="w-100" label={label}>
        <Form.Control
          key={name}
          ref={ref}
          id={id}
          type={type}
          className={className}
          name={name}
          value={value || ''}
          placeholder=" "
          maxLength={maxLength}
          pattern={pattern}
          step={step}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          onKeyDown={onKeyPress}
          onBlur={onBlur}
          onInput={onInput}
          onFocus={onFocus}
          onChange={handleChange}
        />
      </FloatingLabel>

      {Icon && Icon() && (
        <div className="input-group-append">
          <StarTooltip text={iconTip}>
            <div
              className="input-group-text py-0"
              onClick={onIconClick}
              style={{
                cursor: clickableIcon && 'pointer',
                border: 0,
                background: 'inherit',
                color: 'inherit'
              }}>
              <Icon />
            </div>
          </StarTooltip>
        </div>
      )}
    </div>
  )
}
