import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { BwmSelect } from '../../../shared/form/BwmSelect'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustom'
import NumberFormat from 'react-number-format'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'

export const PaymentRow = forwardRef((props, ref) => {
  const [id, setId] = useState(props.payment?.id || null)
  const [progressive, setProgressive] = useState(props.payment?.progressive || '')
  const [paymentType, setPaymentType] = useState(props.payment?.paymentType?.id || '')
  const [amount, setAmount] = useState(
    isNotEmpty(props.payment?.amount) ? props.payment?.amount : ''
  )
  const [currency, setCurrency] = useState(
    props.payment?.currency || {
      id: '58',
      isoCode: 'EUR',
      description: ''
    }
  )
  const [errorPaymentType, setErrorPaymentType] = useState('')
  const [errorCurrency, setErrorCurrency] = useState('')

  useImperativeHandle(ref, () => ({
    resetFields() {
      setId(null)
      setProgressive('')
      setPaymentType('')
      setAmount(0)
      setCurrency({
        id: '58',
        isoCode: 'EUR',
        description: ''
      })
    },
    getData() {
      let payment = {
        id: id || null,
        progressive: progressive,
        paymentType: { id: paymentType },
        amount: amount || 0,
        currency: currency
      }
      return payment
    }
  }))

  const handlerChangePaymentType = (e) => {
    setPaymentType(e.target.value)
    setErrorPaymentType('')
  }

  const handlerAmount = (val) => {
    if (isEmpty(val)) val = 0
    setAmount(val.floatValue)
    setErrorCurrency('')
  }

  const handleInputAutocompileCurrency = (newCurrency, id, suggestionIndex) => {
    setCurrency({
      id: '',
      isoCode: newCurrency,
      description: ''
    })

    let currencyFilter = props.currencies.filter((currency) => {
      return currency.name === newCurrency
    })
    if (currencyFilter.length === 1) {
      setCurrency({
        id: currencyFilter[0].id,
        isoCode: currencyFilter[0].name.split(' - ')[0],
        description: currencyFilter[0].name.split(' - ')[1]
      })
    }
    if (currencyFilter.length === 0) {
      setCurrency({
        id: '',
        isoCode: newCurrency,
        description: ''
      })
    }
  }

  return (
    <div className="text-start crs d-flex row-table">
      <div className="w-5 div-td-sm text-center">
        {progressive !== '' && (
          <input
            type="checkbox"
            className="mt-1"
            id={`checkbox_${id || ''}`}
            checked={props.checkbox || false}
            onChange={(e) => props.onClickCheckBox(e)}
          />
        )}
      </div>
      <div className="w-13 div-td-sm text-truncate">
        <input
          name={`progressive_${id || ''}`}
          className="form-control form-control-cell text-end"
          value={progressive}
          maxLength="16"
          disabled="disabled"
        />
      </div>
      <div className="w-41 div-td-sm text-truncate">
        <BwmSelect
          options={props.relationshipPaymentTypes}
          name={`holderType_${id || ''}`}
          className="form-control form-control-cell"
          onChange={handlerChangePaymentType}
          value={paymentType}
          showCode={true}
          error={errorPaymentType}
        />
      </div>
      <div className="w-28 div-td-sm text-truncate text-start">
        <NumberFormat
          name={`amount_${id || ''}`}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={0}
          fixedDecimalScale={true}
          className="form-control form-control-cell p-2 text-start"
          inputMode="numeric"
          value={amount || 0}
          onValueChange={(val) => handlerAmount(val)}
        />
      </div>
      <div className="w-13 div-td-sm currency-cell">
        <AutoCompileCustom
          name={`currency_${id || ''}`}
          filter={props.currencies}
          value={currency.isoCode || ''}
          error={errorCurrency}
          handleInputAutocompile={handleInputAutocompileCurrency} // Passaggio diretto della funzione
        />
      </div>
    </div>
  )
})
