import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as actions from '../../../../actions'
import elenco from '../../../../styles/images/elenco.png'
import spedire from '../../../../styles/images/spedire.png'
import arrow_l from '../../../../styles/images/arrow_l.png'
import { Tabs } from '../../application-cora/relationships/Tabs'
import { CrsRelationshipList } from './NewRelationshipList'
import { CommunicationsHistoryList } from '../communications/NewCommunicationsHistoryList'
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker'
import { APPLICATION_CRS } from '../../../../config/Constants'

export const CrsRelationships = (props) => {
  const [subject, setSubject] = useState(undefined)

  const history = useHistory()

  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    getSubject(props.match.params.id)
  }, [props.match.params.id])

  useEffect(() => {
    if (auth?.user?.customer) {
      CheckLicenseAndRedirect(
        history,
        '/app/crs/dashboard',
        auth?.user?.customer?.customerApplications,
        APPLICATION_CRS
      )
    }
  }, [auth, history])

  const getSubject = () => {
    actions.getCrsSubject(props.match.params.id).then((res) => {
      setSubject(res)
    })
  }

  const truncateTitle = () => {
    let newTitle = subject?.companyName || ''
    const truncate = 60
    if (newTitle.length > truncate) {
      return newTitle.slice(0, truncate) + '...'
    }
    return newTitle
  }

  const title = () => {
    return (
      <div className="d-flex title-page justify-content-between m-0 p-0 me-4">
        <Link to="/app/crs/dashboard">
          <img
            src={arrow_l}
            className="ms-2 me-3 mb-2 mt-1"
            width="10"
            height="19"
            alt="Torna a elenco soggetti"
          />
          {truncateTitle()}
        </Link>
      </div>
    )
  }

  return (
    <>
      <div className="table-custom">
        <Tabs className="mt-1" title={title()}>
          <div label="ELENCO RAPPORTI" icon={elenco}>
            {props.match.params.id && (
              <CrsRelationshipList
                auth={auth}
                subjectId={props.match.params.id}
                subject={subject}
              />
            )}
          </div>
          <div label="STORICO COMUNICAZIONI" icon={spedire}>
            <CommunicationsHistoryList subjectId={props?.match?.params?.id} auth={auth} />
          </div>
        </Tabs>
      </div>
    </>
  )
}
