import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CoraSubjectList } from './NewCoraSubjectList'
// import { CoraSubjectModal } from './NewCoraSubjectModal'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import { PopupDelete } from '../../../shared/PopupDelete'
import '../../../../styles/subject.css'
import { UploadReceiptModal } from '../receipts/NewUploadReceiptModal'
import { ImportDataModal } from '../import/NewImportDataModal'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { CoraSubjectsExportModal } from './NewCoraSubjectsExportModal'
import * as Constants from '../../../../config/Constants'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'
import { ReactComponent as ICalendar } from '../../../../styles/images/svg/calendar-alt-regular.svg'
import { ReactComponent as IQuestion } from '../../../../styles/images/svgs/regular/question-circle.svg'
import { ReactComponent as IImport } from '../../../../styles/images/svgs/regular/file-import.svg'

import { DelayedCommunicationModal } from '../communications/DelayedCommunicationModal'
import { DELAYED_COMMUNICATION_DATE } from '../../../../actions/types'
import { formatDateForDisplay } from '../../../shared/Utility'
import { Modal, Row } from 'react-bootstrap'
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'

export function CoraSubject() {
  const dispatch = useDispatch()

  // WITH MODAL
  const [id, setId] = useState(null)
  const [subjects, setSubjects] = useState([])
  const [checkedSubjects, setCheckedSubjects] = useState([])
  const [customerApplication, setCustomerApplication] = useState('')
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [expired, setExpired] = useState(false)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const [showDelayedCommunicationModal, setShowDelayedCommunicationModal] = useState(false)
  const [showDelayedCommunicationHint, setShowDelayedCommunicationHint] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [licenseExpiration, setLicenseExpiration] = useState()
  const [showSubjectImportModal, setShowSubjectImportModal] = useState(false)
  const [resetForm, setResetForm] = useState(false)

  const auth = useSelector((state) => state.auth)
  const _subjects = useSelector((state) => state.subjects.data)

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_CORA)
    }
  }

  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    getAllLocations()
    getSubjectSummary()
  }, [])

  useEffect(() => {
    setSubjects(_subjects)
  }, _subjects)

  useEffect(() => {
    if (expired) LicenseExpiredPopup({})
  }, [expired])

  useEffect(() => {
    setExpired(isExpired())
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_CORA
    )
    const customerApplication = auth?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application?.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  const getSubjectsByPersonType = () => {
    actions.searchCoraSubjects().then((res) => {
      if (subjectSummary?.available < 0) {
        PopupSubjectLicense({
          total: subjectSummary?.total,
          used: subjectSummary.used,
          name: Constants.APPLICATION_CORA
        })
      }
      setSubjects(res?.content || res?.data)
    })
  }

  const checkLicense = () => {
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_CORA
    )
    const newCustomerApplication = auth?.user?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application.id
    )
    setCustomerApplication(newCustomerApplication)
    setLicenseExpiration(newCustomerApplication?.expiration)
  }

  const getSubjectSummary = (shouldDisplayError) => {
    CheckRemainingSubjects(Constants.APPLICATION_CORA, shouldDisplayError, (subjectSummary) => {
      setDisableNewSubjectButtons(subjectSummary?.available < 1)
      setSubjectSummary(subjectSummary)
    })
  }

  const getAllLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const addOrUpdateCoraSubject = (newSubject, previousFiscalCode) => {
    newSubject = trimFields(newSubject)
    const promise = newSubject.id
      ? actions.updateCoraSubject(newSubject)
      : actions.addCoraSubject(newSubject)
    promise.then(
      (response) => {
        if (previousFiscalCode !== newSubject.fiscalCode) {
          verifyCoraSubjectOnComurei(newSubject.fiscalCode)
        } else {
          PopupSuccess({ text: 'Soggetto salvato correttamente' })
        }

        setId(response.id)

        getSubjectsByPersonType()
        getSubjectSummary(true)
      },
      (errors) => {
        if (errors.status === 511) {
          PopupSubjectLicense({
            total: subjectSummary.total,
            used: subjectSummary.used,
            available: subjectSummary?.available,
            name: Constants.APPLICATION_CORA
          })
        } else {
          PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
        }
      }
    )
  }

  const verifyCoraSubjectOnComurei = (fiscalCode, completeName) => {
    actions.verifyCoraSubjectOnComurei(fiscalCode).then(
      (res) => {
        if (res === false) {
          ComureiSubjectNotPresentAlert({ fiscalCode: fiscalCode, denomination: completeName })
        } else {
          PopupSuccess({ text: 'Soggetto salvato correttamente' })
        }
      },
      (errors) => {
        console.log(errors)
        PopupSuccess({ text: 'Soggetto salvato correttamente' })
      }
    )
  }

  const updateCoraSubject = (subject, previousFiscalCode = '') => {
    subject = trimFields(subject)
    actions
      .updateCoraSubject(subject)
      .then(
        () => {
          if (previousFiscalCode !== subject.fiscalCode) {
            verifyCoraSubjectOnComurei(subject.fiscalCode)
          } else {
            PopupSuccess({ text: 'Soggetto salvato correttamente' })
          }
        },
        (errors) => {
          PopupError({ text: errors?.title || Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => {
        getSubjectsByPersonType()
      })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Soggetti Selezionati',
      handleClickConfirm: deleteCoraSubjects,
      text: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    })
  }

  const deleteCoraSubjects = () => {
    setIsLoading(true)
    let promises = []
    checkedSubjects.map((subject) => {
      promises.push(actions.deleteCoraSubject(subject))
    })
    Promise.all(promises)
      .then(
        () => {
          PopupSuccess()
        },
        (errors) => {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => {
        setIsLoading(false)
        getSubjectsByPersonType()
        getSubjectSummary(false)
        checkSubject([])
      })
  }

  const checkSubject = (list = []) => {
    setCheckedSubjects(list)
  }

  const trimFields = (subject) => {
    if (subject.companyName) {
      //TODO: trimmare lato BE
      subject.companyName = subject.companyName.trim()
    } else if (subject.firstName && subject.lastName) {
      subject.firstName = subject.firstName.trim()
      subject.lastName = subject.lastName.trim()
    }
    return subject
  }

  const onChangeScheduledDate = (date) => {
    dispatch({ type: DELAYED_COMMUNICATION_DATE, delayedCommunicationDate: date })
    setShowDelayedCommunicationModal(false)
  }

  const actionsComponent = () => {
    return (
      <div className="col-12 ps-0">
        <div className="btn-group" role="group" aria-label="action buttons">
          <button
            type="button"
            disabled={Object.keys(checkedSubjects).length === 0}
            className={`btn btn-empty btn-outline-primary btn-sm ${Object.keys(checkedSubjects).length === 0 ? 'disabled' : ''}`}
            onClick={() => openPopupDelete()}>
            <IDelete
              width="16"
              fill={` ${Object.keys(checkedSubjects).length === 0 ? '#FFFFFF' : '#128186'}`}
            />
            &nbsp; Elimina
          </button>
          <CoraSubjectsExportModal
            disabled={Object.keys(checkedSubjects).length === 0}
            checkedSubjects={checkedSubjects}
          />
          <button
            type="button"
            data-tip={'Importa soggetti alla tua lista'}
            className={`btn btn-empty btn-sm`}
            disabled={disableNewSubjectButtons || expired}
            onClick={() => setShowSubjectImportModal(true)}>
            <IImport
              width="18"
              height="20"
              fill={` ${disableNewSubjectButtons || expired ? '#FFFFFF' : '#128186'}`}
            />
            &nbsp; Importa
          </button>
          {showSubjectImportModal && (
            <ImportDataModal
              show={showSubjectImportModal}
              onSuccess={() => {
                checkLicense()
                getSubjectsByPersonType()
                setShowSubjectImportModal(false)
              }}
              onClose={() => setShowSubjectImportModal(false)}
            />
          )}
          <UploadReceiptModal disabled={expired} refreshSubjects={getSubjectsByPersonType} />
          {delayedCommunicationDate ? (
            <>
              <button
                type="button"
                className={`btn btn-danger btn-sm`}
                onClick={() => onChangeScheduledDate(null)}>
                <IDelete fill={`#FFFFFF`} width="16" />
                &nbsp;INVIO DIFFERITO IMPOSTATO AL {formatDateForDisplay(delayedCommunicationDate)}
              </button>
              <button
                type="button"
                className={`btn  btn-empty btn-sm`}
                onClick={() => setShowDelayedCommunicationHint(true)}>
                <IQuestion fill={`#128186`} width="16" />
              </button>
            </>
          ) : (
            <button
              type="button"
              className={`btn  btn-empty btn-sm`}
              onClick={() => setShowDelayedCommunicationModal(true)}>
              <ICalendar fill={`#128186`} width="20" height="17" />
              &nbsp; INVIO DIFFERITO
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && <PageSpinner />}
      <div className="table-custom">
        <div className="tabs single">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col-8 ps-0 d-flex align-items-center">
                <SubjectTotalAndCallToAction
                  total={subjectSummary?.total}
                  remaining={subjectSummary?.available}
                  used={subjectSummary?.used}
                  licenseType={customerApplication?.licenseType}
                  customerApplication={customerApplication}
                />
              </div>

              <div className="d-flex col-4 text pe-0 align-items-center justify-content-end">
                <div className="col d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-new-rel px-3 my-2 btn-sm"
                    disabled={expired}
                    onClick={() => setResetForm(!resetForm)}>
                    <IPlus
                      className={'padding-right-4px'}
                      width="14"
                      fill={` ${expired ? '#128186' : '#FFFFFF'}`}
                    />
                    &nbsp; Nuovo soggetto
                  </button>
                </div>
              </div>
            </div>

            <CoraSubjectList
              disabled={expired}
              id={id}
              resetForm={resetForm}
              subjects={subjects}
              updateCoraSubject={updateCoraSubject}
              checkSubject={checkSubject}
              reloadSubjects={getSubjectsByPersonType}
              setSubjects={setSubjects}
              addOrUpdateCoraSubject={addOrUpdateCoraSubject}
              actionsComponent={actionsComponent()}
            />
          </div>
        </div>
      </div>
      {/* {id && (
        <CoraSubjectModal
          id={id}
          showModal={!!id}
          hideButton={true}
          subjects={subjects}
          licenseExpired={!isLicenseValid}
          subjectSummary={subjectSummary}
          addOrUpdateCoraSubject={addOrUpdateCoraSubject}
          locations={locations}
          closeDialog={() => setId(null)}
        />
      )} */}
      {showDelayedCommunicationModal && (
        <DelayedCommunicationModal
          show={showDelayedCommunicationModal}
          onHide={() => setShowDelayedCommunicationModal(false)}
          onConfirm={(date) => onChangeScheduledDate(date)}
          date={delayedCommunicationDate}
          licenseExpiration={licenseExpiration}
        />
      )}
      {showDelayedCommunicationHint && (
        <Modal
          centered
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          show={showDelayedCommunicationHint}
          onHide={() => setShowDelayedCommunicationHint(false)}>
          <div className="bar"></div>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container">
              <p>
                INVIO DIFFERITO impostato per la data:{' '}
                <strong>{formatDateForDisplay(delayedCommunicationDate)}</strong>
              </p>
              <p>Alla chiusura della sessione (o premendo F5) l'impostazione viene eliminata</p>
              <p>Le eventuali comunicazioni create con l'impostazione attiva rimangono valide</p>
              <p>Per annullare questi invii va eliminata la riga dallo STORICO comunicazioni</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Row className={'justify-content-center'}>
              <button
                type="button"
                className={`btn  btn-empty px-4 btn-sm`}
                onClick={() => setShowDelayedCommunicationHint(false)}>
                OK, CHIUDI
              </button>
            </Row>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
