import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { BtnDelete } from '../../../shared/BtnDelete'
import * as styles from '../../../../config/styles'
import { IPlus } from '../../../../styles/icons'
import { PopupDelete } from '../../../shared/PopupDelete'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PopupError } from '../../../shared/PopupError'
import RowSpinner from '../../../shared/spinner/Spinner'
import { APPLICATION_GENERIC_ERROR } from '../../../../config/Constants'

const SECOND_TAB = 'PERSONE CONTROLLANTI'

export const ControllingPersonList = forwardRef((props, ref) => {
  const [allCheck, setAllCheck] = useState(false)
  const [checkedItems, setCheckedItems] = useState({})
  const [loading, setLoading] = useState(false)
  const [controllingPersonList, setControllingPersonList] = useState([])
  const [errorControllingPerson, _setErrorControllingPerson] = useState('')

  useImperativeHandle(ref, () => ({
    countControllingPerson() {
      if (controllingPersonList) return controllingPersonList.length
      else return 0
    },
    setErrorControllingPerson() {
      _setErrorControllingPerson('')
    },
    getAllCrsControllingPeople() {
      return _getAllCrsControllingPeople
    }
  }))

  useEffect(() => {
    if (props.activeTab === SECOND_TAB) _getAllCrsControllingPeople()
    resetCheckBox()
  }, [props.activeTab])

  const _getAllCrsControllingPeople = () => {
    let relId = props.relationship?.id
    if (relId) {
      setLoading(true)
      actions
        .getAllCrsControllingPeople(props.subject.id, relId)
        .then((resp) => {
          setLoading(false)
          setControllingPersonList(resp)
          if (resp.length > 0) {
            _setErrorControllingPerson('')
          } else {
            _setErrorControllingPerson(
              'È obbligatorio inserire almeno una persona controllante per il tipo titolare selezionato '
            )
          }
          return resp.length
        })
        .catch(() => setLoading(false))
    }
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: `${Object.keys(checkedItems).length} persone controllanti selezionate?`,
      handleClickConfirm: () => handlerDeleteControllingPerson(Object.keys(checkedItems))
    })
  }

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newControllingPersonRows = cloneDeep(controllingPersonList)
      newControllingPersonRows.forEach((cp) => (items[cp.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === controllingPersonList?.length)
      for (const checkedItem of Object.keys(checkedItems)) {
        if (checkedItem) {
          return
        }
      }
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handlerDeleteControllingPerson = (ids = []) => {
    let relId = props.relationship?.id
    actions.deleteControllingPeople(props.subject.id, relId, ids).then(
      (res) => {
        _getAllCrsControllingPeople()
        resetCheckBox()
        PopupSuccess()
      },
      (err) => PopupError({ text: APPLICATION_GENERIC_ERROR })
    )
  }

  const renderRows = () => {
    if (loading) return <RowSpinner />

    return controllingPersonList.map((cp, index) => {
      const idCheckbox = `cpDetail${cp.id}`
      const isChecked = !!checkedItems[cp.id]
      return (
        <div key={index} className="text-start row-table" id={index}>
          <div className="w-5 text-center div-td-sm-checkbox">
            <input
              type="checkbox"
              name="rowCheckBox"
              id={idCheckbox}
              checked={isChecked}
              onChange={(e) => handleCheckChange(e, cp?.id)}
            />
          </div>
          <div className="w-20 div-td-sm" onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.fiscalIdentifier}
          </div>
          <div className="w-25 div-td-sm" onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.lastName + ' ' + cp.firstName}
          </div>
          <div className="w-30 div-td-sm" onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.controllingPersonType?.code + ':' + cp.controllingPersonType?.description}
          </div>
          <div className="w-40 div-td-sm" onClick={() => props.handlerShowPersonForm(cp)}>
            {cp.countryCode + ' - ' + cp.country}
          </div>
        </div>
      )
    })
  }

  let relId = props.relationship?.id

  useEffect(() => {
    if (props.activeTab !== SECOND_TAB) _setErrorControllingPerson('')
  }, [props.activeTab])

  return (
    <div className="row mb-2 ms-2">
      <div className="col-12 d-flex align-items-center justify-content-between mt-3">
        <h5>
          Elenco persone fisiche <strong>controllanti</strong> il rapporto
        </h5>
        <div className="ms-auto d-flex mb-2">
          <BtnDelete
            handlerOnClick={() => openPopupDelete()}
            className="btn-sm btn-cell me-2"
            disabled={Object.keys(checkedItems)?.length === 0 && !allCheck}
            classCustom="me-2"
          />
          <button
            className={`${styles.btnFillPillSm} btn-cell`}
            onClick={() => props.handlerShowPersonForm()}>
            <IPlus
              className={'padding-right-4px'}
              width="16"
              fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`}
              alt={'Aggiungi'}
            />{' '}
            Aggiungi
          </button>
        </div>
      </div>
      <div className="col-12 mb-3 w-all star-table controlling-person-list">
        <div className="col-12 header-table p-0">
          <div className="w-5 text-center div-td-sm-checkbox">
            <input
              name="tableCheckBox"
              type="checkbox"
              onChange={(e) => handleAllCheckChange(e)}
              checked={allCheck}
            />
          </div>
          <div className="w-20 div-td-sm">TIN</div>
          <div className="w-25 div-td-sm">Anagrafica</div>
          <div className="w-30 div-td-sm">Tipo persona</div>
          <div className="w-40 div-td-sm">Paese residenza fiscale</div>
        </div>

        {relId ? renderRows() : ''}
      </div>
      <div className="form-group col-12">
        <div className="text-danger pb-2">{errorControllingPerson}</div>
      </div>
    </div>
  )
})
