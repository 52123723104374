import { useEffect, useState } from 'react'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'
import { searchCrsSubjects } from '../../../../actions'
// import { CrsSubjectModal } from './NewCrsSubjectModal'
import { APPLICATION_CRS } from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import { CrsForm } from './NewCrsForm'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

export function CrsSubjectList(props) {
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  const [newSubjectCode, setNewSubjectCode] = useState(0)
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    subjectCode: null,
    fiscalCode: null,
    companyName: null,
    idFiscalOicrTrust: null,
    referenceYear: null,
    id: null,
    version: '1'
  })

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => ({
      name: location.location,
      key: index
    }))
  )

  useEffect(() => {
    const subjectSummary = props.subjectSummary
    if (subjectSummary && subjectSummary?.available > 0) {
      const axiosInstance = axiosService.getInstance()
      axiosInstance.get('/api/crs/custom/crs-subjects/lastProgressive').then((res) => {
        let newCode = parseInt(res.data) + 1
        setNewSubjectCode(newCode)
        setSubject(subject)
      })
    } else if (subjectSummary?.available <= 0) {
      PopupSubjectLicense({
        total: subjectSummary?.total,
        used: subjectSummary?.used,
        available: subjectSummary?.available,
        name: APPLICATION_CRS
      })
    }
  }, [props.subjectSummary])

  const countries = useSelector((state) =>
    state.countries?.data.map((country) => ({
      name: country.code,
      key: country.description
    }))
  )

  const [subject, setSubject] = useState(null)
  const tableUtils = TableUtils
  const tableName = 'crs-subject'
  const history = useHistory()

  const header = [
    tableUtils.composeHeader({
      fieldName: 'subjectCode',
      displayedName: 'Codice'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: 'Codice Fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'companyName',
      displayedName: 'Soggetto obbligato'
    }),
    tableUtils.composeHeader({
      fieldName: 'idFiscalOicrTrust',
      displayedName: 'OICR'
    }),
    tableUtils.composeHeader({
      fieldName: 'referenceYear',
      displayedName: 'Anno Riferimento'
    }),
    tableUtils.composeHeader({
      fieldName: 'expiration',
      type: fieldTypes.DATE,
      displayedName: 'Scad. Certificati'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          subjectCode: tableUtils.composeCell({
            fieldName: 'subjectCode',
            fieldValue: c.subjectCode
          }),
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          companyName: tableUtils.composeCell({
            fieldName: 'companyName',
            fieldValue: c.companyName
          }),
          idFiscalOicrTrust: tableUtils.composeCell({
            fieldName: 'idFiscalOicrTrust',
            fieldValue: c.idFiscalOicrTrust
          }),
          referenceYear: tableUtils.composeCell({
            fieldName: 'referenceYear',
            fieldValue: c.referenceYear
          }),
          expiration: tableUtils.composeCell({
            fieldName: 'expiration',
            fieldValue: c.expiration
          })
        }
      })
    })
  }

  const onClickRow = (subject) => {
    setSubject(subject)
  }

  const onDoubleClickRow = (subject) => {
    setSubject(subject)
    const path = props.licenseExpired ? '#' : `/app/crs/relationship/${subject.id}`
    history.push(path)
  }

  const setNewSubjects = (subjects) => {
    props.setData(subjects, searchObject)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects))
    setSubject(subject ? subject : props.subjects?.[0])
  }, [props.subjects])

  useEffect(() => {
    setSubject()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={subject?.id}
        headerColums={header}
        checkedItems={props.checkedItems}
        rows={rows}
        isLoading={show}
        offset={window.innerWidth < 1500 ? 430 : 535}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setSubject()
          return searchCrsSubjects(searchObject)
        }}
        onExecutedSearch={(content) => setNewSubjects(content)}
        usePagination={true}
        withCheckBoxes={true}
        onClickRow={onClickRow}
        onDoubleClickRow={onDoubleClickRow}
        onClickCheck={props.onClickCheck}
        actionsComponent={props.actionsComponent}
        formComponent={CrsForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          formOnBottom: true,
          subjectId: subject?.id,
          locations: locations,
          countries: countries,
          newSubjectCode: newSubjectCode,
          setLoading: setLoading,
          close: (newSubject) =>
            setSubject(newSubject ? newSubject : subject ? subject : undefined),
          getSubjects: props.getSubjects
        }}
        // dialogComponent={
        //   subject?.id !== null &&
        //   subject?.id !== undefined && (
        //     <CrsSubjectModal
        //       id={subject?.id}
        //       hideButton={true}
        //       subject={subject}
        //       showModal={!!subject.id}
        //       licenseExpired={props.disabled}
        //       subjectSummary={props.subjectSummary}
        //       locations={locations || []}
        //       countries={countries || []}
        //       getSubjects={props.getSubjects}
        //       closeModal={() => setSubject(null)}
        //     />
        //   )
        // }
      />
    )
  }

  return <>{renderTable()}</>
}

export default CrsSubjectList
