import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { ArcoSubjectForm as ArcoForm } from './ArcoSubjectForm'
import { IPlus } from '../../../../styles/icons'
// import { getAllArcoRegistriesBySubjectIdNoCache } from '../../../../actions'
import Loader from '../../../Loader'

export function ArcoSubjectModal(props) {
  const { showModal, subject } = props

  const arcoDocumentTypes = useSelector((state) => state.arcoRelationshipDocuments.data)

  // useEffect(() => {
  //   console.log(subject)
  // }, [])

  return (
    <>
      <Modal
        {...props}
        dialogClassName=""
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="m-0">
              {props.currentOperation
                ? subject?.id
                  ? `Modifica Soggetto ${subject.companyName}`
                  : 'Nuovo soggetto'
                : 'Nuovo soggetto'}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 pt-0">
          {((props.id && subject) || !props.id) && (
            <ArcoForm
              {...props}
              disableInsert={props.disabled}
              subjectTypes={props.arcoSubjectTypes}
            />
          )}
          {props.id && !subject && <Loader />}
        </Modal.Body>
      </Modal>
    </>
  )
}
