import { useEffect, useState } from 'react'
import * as actions from '../../../../actions'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { useSelector, useDispatch } from 'react-redux'
import { CommunicationModal } from './CommunicationModal'
import { Modal, Row } from 'react-bootstrap'
import EmailForm from './EmailForm'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownButton } from 'react-bootstrap'
import { CommunicationHistoryList } from './NewCommunicationHistoryList'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { PopupError } from '../../../shared/PopupError'
import * as Constants from '../../../../config/Constants'
import { PopupDelete } from '../../../shared/PopupDelete'
import { CommunicationErrorList } from './CommunicationErrorList'
import * as Utils from '../CommunicationDownloadUtils'
import { isEmpty } from '../../../shared/Utility'
import { BwmInput } from '../../../shared/form/BwmInput'
import RowSpinner from '../../../shared/spinner/Spinner'

export function CommunicationsTab(props) {
  const { subjectId } = props
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [loadedInitialHistory, setLoadedInitialHistory] = useState(false)
  const [initialHistoryRows, setInitialHistoryRows] = useState([])
  const [communicationHistory, setCommunicationHistory] = useState([])
  const communicationErrors = useSelector((state) => state.lastErrorCommunication.data)
  const subject = useSelector((state) => state.subject.data)
  const [showCommunicationModal, setShowCommunicationModal] = useState(false)
  const [reelaboratedCommunication, setReelaboratedCommunication] = useState(undefined)
  const resetCommunicationModal = () => {
    setShowCommunicationModal(false)
    setReelaboratedCommunication(undefined)
  }
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [sentCommunication, setSentCommunication] = useState(undefined)

  const resetEmailModal = () => {
    setShowEmailModal(false)
    setSentCommunication(undefined)
  }

  const getHistory = () => {
    setLoading(true)
    actions.getCommunicationsHistoryBySubjectId(subjectId).then((res) => {
      setCommunicationHistory(res)
      setLoading(false)
    })
  }

  const getErrors = () => {
    dispatch(actions.getLastCommunicationsErrorBySubjectId(subjectId))
  }

  const getSubject = () => {
    dispatch(actions.getCoraSubject(subjectId))
  }

  const reloadAll = () => {
    getHistory()
    getErrors()
    getSubject()
  }

  useEffect(() => {
    reloadAll()
  }, [])

  //Conservo il risultato del caricamento iniziale delle righe: servirà a determinare se mostrare le tabelle o no
  useEffect(() => {
    if (!loadedInitialHistory && communicationHistory.length !== 0) {
      setInitialHistoryRows(communicationHistory)
      setLoadedInitialHistory(true)
    }
  }, [communicationHistory])

  const reelaborateCommunicationTxt = (communication) => {
    PopupConfirm({
      title: 'Richiesta conferma',
      innerHtml: `Rielaborazione comunicazione txt: <p class = "text-truncate">${communication.txtFilename}</p>`,
      titleColor: titleColors.SUCCESS,
      handleClickConfirm: () => {
        setLoading(true)
        actions
          .reElaborateCommunicationTxt(subjectId, communication.id)
          .then(
            (res) => {
              if (res?.length) {
                setReelaboratedCommunication(res[0])
                setShowCommunicationModal(true)
                reloadAll()
              }
            },
            (err) => {
              PopupError({ text: err || Constants.APPLICATION_GENERIC_ERROR })
            }
          )
          .then(() => setLoading(false))
      }
    })
  }

  const renderCommunicationModal = () => {
    return (
      reelaboratedCommunication && (
        <CommunicationModal
          showModal={showCommunicationModal}
          closeModal={() => resetCommunicationModal()}
          communications={[reelaboratedCommunication]}
          coraSubject={reelaboratedCommunication.subject}
          downloadTxt={Utils.downloadTxt}
          downloadPecTz={Utils.downloadPecTz}
          downloadDgn={Utils.downloadDgn}
          downloadPdf={Utils.downloadPdf}
          downloadErrorReport={Utils.downloadErrorReport}
        />
      )
    )
  }

  const renderEmailModal = () => {
    return (
      <Modal
        backdrop="static"
        show={showEmailModal}
        onHide={() => resetEmailModal()}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>INVIA COMUNICAZIONE SELEZIONATA</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailForm
            {...props}
            closeModal={() => resetEmailModal()}
            emailAddress={subject?.coraSubjectSid?.email}
            flagPec={subject?.coraSubjectSid?.flagPec}
            sentCommunications={[sentCommunication]}
            subject={subject}
            subjectId={subject?.id}
          />
        </Modal.Body>
      </Modal>
    )
  }

  const renderDeleteButton = (disabled = false, communications = []) => {
    return (
      <button
        type="button"
        className={`btn btn-empty px-4 btn-sm ${disabled && 'disabled'}`}
        onClick={() => openPopupDelete(communications)}
        disabled={disabled}>
        <IDelete
          className={'padding-right-4px'}
          width="18"
          fill={` ${disabled ? '#FFFFFF' : '#128186'}`}
        />
        &nbsp; Elimina
      </button>
    )
  }

  const confirmSearchHistory = (selectedSearchOption, query) => {
    if (selectedSearchOption?.id === 'BY_IDENTIFIER') {
      dispatch(actions.getCommunicationsHistoryBySubjectIdAndIdentifier(subjectId, query))
    } else if (selectedSearchOption?.id === 'BY_NAME') {
      dispatch(actions.getCommunicationsHistoryBySubjectIdAndDenomination(subjectId, query))
    } else if (selectedSearchOption?.id === 'BY_FILE') {
      dispatch(actions.getCommunicationsHistoryBySubjectIdAndFileName(subjectId, query))
    }
  }

  const openPopupDelete = (communications = []) => {
    let idsToDelete = []
    communications.forEach((comm) => idsToDelete.push(comm.id))
    PopupDelete({
      operazione: 'Comunicazioni',
      text: `Sei proprio sicuro di voler eliminare ${idsToDelete.length} comunicazione/i dallo storico?`,
      handleClickConfirm: () => handlerDeleteCommunications(idsToDelete)
    })
  }

  const handlerDeleteCommunications = (idsToDelete = []) => {
    setLoading(true)
    let promises = []
    for (const id of idsToDelete) {
      promises.push(actions.deleteCommunication(id))
    }
    Promise.all(promises)
      .then(
        () => {
          PopupSuccess()
        },
        (errors) => {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => {
        reloadAll(subjectId)
        setLoading(false)
      })
  }

  if (loading) {
    return <RowSpinner />
  }

  return (
    <>
      {renderCommunicationModal()}
      {renderEmailModal()}
      <CommunicationErrorList
        communicationsInError={communicationErrors}
        renderDeleteButton={(disabled, elementsToDelete) =>
          renderDeleteButton(disabled, elementsToDelete)
        }
        downloadDgn={(communication) => Utils.downloadDgn(communication)}
        downloadTxt={(communication) => Utils.downloadTxt(communication)}
        reelaborateCommunicationTxt={(communication) => reelaborateCommunicationTxt(communication)}
      />
      <CommunicationHistoryList
        hasRowsToShow={initialHistoryRows?.length > 0}
        communications={communicationHistory}
        subjectId={subjectId}
        renderDeleteButton={(disabled, elementsToDelete) =>
          renderDeleteButton(disabled, elementsToDelete)
        }
        openEmailModal={(communication) => {
          setShowEmailModal(true)
          setSentCommunication(communication)
        }}
        downloadReceiptTxt={(communication) => Utils.downloadReceiptTxt(communication)}
        downloadPecTz={(communication) => Utils.downloadPecTz(communication)}
        downloadPdf={(communication) => Utils.downloadPdf(communication)}
        downloadTxt={(communication) => Utils.downloadTxt(communication)}
        downloadDgn={(communication) => Utils.downloadDgn(communication)}
        downloadErrorReport={(communication) => Utils.downloadErrorReport(communication)}
        reelaborateCommunicationTxt={(communication) => reelaborateCommunicationTxt(communication)}
        SearchBar={() => (
          <CoraCommunicationSearchBar
            onClickSearch={(searchOption, query) => confirmSearchHistory(searchOption, query)}
            onAbortSearch={getHistory}
          />
        )}
      />
    </>
  )
}

function CoraCommunicationSearchBar({ onClickSearch, onAbortSearch }) {
  const searchOptions = [
    {
      id: 'BY_IDENTIFIER',
      description: 'Codice Univoco',
      placeholder: 'Inserire codice univoco, anche parziale (min. 5 caratteri)'
    },
    {
      id: 'BY_NAME',
      description: 'Denominazione',
      placeholder: 'Inserire denominazione titolare rapporto, anche parziale (min. 5 caratteri)'
    },
    {
      id: 'BY_FILE',
      description: 'Nome comunicazione',
      placeholder: 'Inserire nome del file della comunicazione, anche parziale (min. 5 caratteri)'
    }
  ]
  const [selectedSearchOption, setSelectedSearchOption] = useState(searchOptions[0])
  const [searchQuery, setSearchQuery] = useState('')
  const [isQueryActive, setIsQueryActive] = useState(false)
  return (
    <>
      <Row className="align-items-center">
        <div className="inputSelect col">
          <Dropdown
            onSelect={(value) =>
              setSelectedSearchOption(searchOptions.find((o) => o.id === value))
            }>
            <DropdownButton
              title={`Ricerca nel campo: ${selectedSearchOption?.description}`}
              bsPrefix={'dropdown-menu-green btn-fill m-0 me-1 pe-2'}>
              {searchOptions &&
                searchOptions.map((el, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      eventKey={el.id}
                      active={el.id === selectedSearchOption?.id}>
                      {el.description}
                    </Dropdown.Item>
                  )
                })}
            </DropdownButton>
          </Dropdown>
        </div>
        <div className="col-5 pe-2">
          <BwmInput
            name="searchTerms"
            className="form-control form-control-button"
            maxLength={50}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={selectedSearchOption?.placeholder}
            value={searchQuery}
          />
        </div>
        <div className="col-1 ms-2">
          <button
            type="button"
            disabled={isEmpty(searchQuery) || searchQuery.length < 5}
            className="btn btn-new-rel"
            onClick={() => {
              setIsQueryActive(true)
              onClickSearch(selectedSearchOption, searchQuery)
            }}>
            CERCA
          </button>
        </div>
        <div className="col">
          {isQueryActive && (
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                setSearchQuery('')
                setIsQueryActive(false)
                onAbortSearch()
              }}>
              PULISCI RICERCA
            </button>
          )}
        </div>
      </Row>
    </>
  )
}
