import React from 'react'
import * as actions from '../../../../actions'
import { ReactComponent as ITextFile } from '../../../../styles/images/svgs/regular/file-alt.svg'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { PopupError } from '../../../shared/PopupError'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { ImportModal } from '../../../shared/popups/ImportModal'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import '../../../../styles/dac7NewStyles.css'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

export class ArcoSubjectForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subject: {
        errors: null, // Stato per memorizzare gli errori
        showError: false, // Stato per controllare la visualizzazione dell'errore
        // ... altre variabili di stato
        id: this.props.subject?.id || null,
        fiscalCode: this.props.subject?.fiscalCode || '',
        companyName: this.props.subject?.companyName || '',
        address: this.props.subject?.address || '',
        location: this.props.subject?.location || '',
        province: this.props.subject?.province || '',
        postalCode: this.props.subject?.postalCode || '',
        vatNumber: this.props.subject?.vatNumber || '',
        operationDescription: this.props.subject?.operationDescription || '',
        operationNature: this.props.subject?.operationNature || '',
        operationPurpose: this.props.subject?.operationPurpose || '',
        arcoSubjectType:
          this.props.subject?.arcoSubjectType ||
          (this.props?.subjectTypes ? this.props?.subjectTypes[0] : {})
      },
      showImportDocumentsModal: false,
      isLoading: false
    }
  }

  extractErrors = (errorObj, fields) => {
    const result = []
    // Helper function to access nested fields using dot notation
    const getNestedField = (obj, fieldPath) => {
      return fieldPath
        .split('.')
        .reduce((o, key) => (o && o[key] !== undefined ? o[key] : null), obj)
    }
    // Iterate over each field and extract errors
    fields.forEach(({ errorKey, errorLabel }) => {
      const error = getNestedField(errorObj, errorKey)
      if (error) {
        result.push({ errorKey, errorLabel, errorMessage: error })
      }
    })
    return result
  }

  handleSubmit = (e) => {
    e.preventDefault() // Ferma l'invio del form
    const { validateForm, values } = this.props // Assumi che validateForm e values siano passati come props

    // Validazione dei dati
    const errors = validateForm(values) // Supponi che validateForm restituisca un oggetto di errori
    if (Object.keys(errors).length > 0) {
      this.setState({ errors, showError: true }) // Mostra l'errore se ci sono errori
    } else {
      // Altrimenti, procedi con la logica di submit
      this.props.handleSubmit(values) // Assumi che handleSubmit sia una prop che gestisce l'invio
    }
  }

  formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'vatNumber',
      errorLabel: 'Partita IVA'
    },
    {
      errorKey: 'arcoSubjectType',
      errorLabel: 'Tipo Soggeto'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Ragione Sociale'
    },
    {
      errorKey: 'address',
      errorLabel: 'Indirizzo'
    },
    {
      errorKey: 'location',
      errorLabel: 'Località'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'postalCode',
      errorLabel: 'CAP'
    },
    {
      errorKey: 'operationDescription',
      errorLabel: 'Descrizione Operazione'
    },
    {
      errorKey: 'operationNature',
      errorLabel: 'Natura Operazione'
    },
    {
      errorKey: 'operationPurpose',
      errorLabel: 'Scopo'
    }
  ]

  handlerChangeSubjectType = (e, setValues, values) => {
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setValues({
      ...values,
      arcoSubjectType: { id: parseInt(e.target.value), code: cod }
    })
  }

  handleFiscalCode = (val, setValues, values) => {
    actions.getBaseRegistryByFiscalCode(val).then(
      (baseRegistry) => {
        if (baseRegistry) {
          setValues({
            ...values,
            companyName: baseRegistry?.companyName || '',
            location: baseRegistry?.location?.location || '',
            province: baseRegistry?.location?.province || '',
            postalCode: baseRegistry?.location?.postalCode || ''
          })
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
  }

  handleValidation = () => {
    let isValid = true
    return isValid
  }

  addArcoSubject = (subject, showGestioneTabelle) => {
    actions.saveArcoSubject(subject).then(
      (response) => {
        console.log('subject updated:' + JSON.stringify(response))
        this.setState(
          {
            subject: response
          },
          () => {
            this.props.handleCloseModal()
            this.props.getSubjects()
            if (showGestioneTabelle) {
              this.props.showPopupGestioneTabelle(this.state.subject)
            }
          }
        )
      },
      (errors) => {
        this.props.handleCloseModal()
        PopupError({
          ...this.props,
          text:
            errors.status === 511
              ? 'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
              : "Errore nell'aggiornamento del soggetto " + errors
        })
      }
    )
  }

  updateAll = (values, showGestioneTabelle) => {
    this.addArcoSubject({ ...values, id: this.state.subject.id }, showGestioneTabelle)
  }

  handleInputAutocompile = (newLocation, setValues, values) => {
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then((loc) => {
      if (loc) {
        setValues({
          ...values,
          location: loc.location,
          province: loc.province,
          postalCode: loc.postalCode
        })
      } else {
        setValues({
          ...values,
          location: newLocation
        })
      }
    })
  }

  gestioneTabelle = async (validateForm, values) => {
    const formErrors = await validateForm(values)

    // Check if there are any errors
    if (!(Object.keys(formErrors).length === 0)) return
    actions.saveArcoSubject(this.state.subject).then((response) => {
      console.log('subject updated:' + JSON.stringify(response))
      this.props.showPopupGestioneTabelle(response)
    })
  }

  uploadZipFile = (dataFile) => {
    let formData = new FormData()
    if (dataFile?.length > 0) {
      this.setState({ isLoading: true }, () => {
        for (let file of dataFile) {
          formData.append('file', file)
        }
        actions
          .importArcoDocumentsZip(formData, this.state.subject.id)
          .then(
            (res) => {
              PopupSuccess({ title: `Sono stati importati ${res} documenti` })
            },
            (err) => {
              PopupError({ text: "Errore durante l'import dei documenti" })
            }
          )
          .then(() => this.setState({ isLoading: false, showImportDocumentsModal: false }))
      })
    }
  }

  initialValues = {
    fiscalCode: this.props.subject?.fiscalCode || '',
    companyName: this.props.subject?.companyName || '',
    address: this.props.subject?.address || '',
    location: this.props.subject?.location || '',
    province: this.props.subject?.province || '',
    postalCode: this.props.subject?.postalCode || '',
    vatNumber: this.props.subject?.vatNumber || '',
    operationDescription: this.props.subject?.operationDescription || '',
    operationNature: this.props.subject?.operationNature || '',
    operationPurpose: this.props.subject?.operationPurpose || '',
    arcoSubjectType:
      this.props.subject?.arcoSubjectType ||
      (this.props?.subjectTypes ? this.props?.subjectTypes[0] : {})
  }

  validationSchema = Yup.object().shape({
    fiscalCode: Yup.string()
      .required('Campo obbligatorio')
      .max(16, 'Codice Fiscale deve essere lungo  al massimo 16 caratteri'),
    arcoSubjectType: Yup.object().shape({
      id: Yup.string().required(),
      cod: Yup.string()
    }),
    companyName: Yup.string()
      .required('Campo obbligatorio')
      .max(60, 'Ragione Sociale deve essere al massimo di 60 caratteri'),
    vatNumber: Yup.string()
      .required('Campo obbligatorio')
      .max(16, 'Partita IVA deve essere al massimo di 16 caratteri'),
    address: Yup.string()
      .required('Campo obbligatorio')
      .max(100, 'Indirizzo deve essere al massimo di 100 caratteri'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    postalCode: Yup.string()
      .required('Campo obbligatorio')
      .max(5, 'CAP deve essere lungo 5 caratteri'),
    operationDescription: Yup.string(),
    operationNature: Yup.string(),
    operationPurpose: Yup.string()
  })

  render() {
    console.log(this.props.subject)
    return (
      <>
        {this.state.isLoading && <PageSpinner></PageSpinner>}
        <div className="row-detail bg-grey mt-2 d-flex row-form big">
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={(values) => this.updateAll(values, false)}>
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setValues,
              submitForm,
              validateForm
            }) => (
              <Form className="w-100 h-100">
                {/* Mostra l'errore in un overlay che rimane fisso in cima */}
                {errors && typeof errors === 'object' && Object.keys(errors).length > 0 && (
                  <div
                    style={{
                      // position: 'fixed',
                      // top: 250,
                      // width: '77%',
                      color: 'white',
                      // zIndex: 1000,
                      padding: '1px',
                      textAlign: 'center'
                    }}>
                    {this.state.showError ? (
                      <ErrorListAlert
                        errors={this.extractErrors(errors, this.formErrorsTabMapping)}
                      />
                    ) : null}
                  </div>
                )}
                <div class="h-100 d-flex flex-column">
                  <div id={`anagrafica-${this.props.subject?.id || 0}`} className="">
                    <div class="">
                      <div className="row mb-0 mb-lg-0 col-12">
                        <div className="col-12 col-lg-2 mb-1 me-1 mt-2">
                          <Field name="fiscalCode">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="Codice Fiscale"
                                className="form-control"
                                value={values.fiscalCode}
                                onChange={(e) => {
                                  const { name, value } = e.target
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase()
                                    }
                                  })
                                }}
                                onBlur={(e) => {
                                  this.handleFiscalCode(e.target.value, setValues, values)
                                  handleBlur(e)
                                }}
                                maxLength="16"
                                error={errors.fiscalCode}
                                touched={touched.fiscalCode}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-lg-1 mb-1 me-1 mt-2">
                          <Field name="vatNumber">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="Partita IVA"
                                className="form-control"
                                value={values.vatNumber}
                                onChange={(e) => {
                                  const { name, value } = e.target
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase()
                                    }
                                  })
                                }}
                                maxLength="16"
                                error={errors.vatNumber}
                                touched={touched.vatNumber}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-lg-4 mb-1 me-1 mt-2">
                          <Field name="arcoSubjectType">
                            {({ field }) => (
                              <BwmSelect
                                {...field}
                                options={this.props.subjectTypes}
                                label="Tipo Soggetto"
                                className="form-control"
                                value={values.arcoSubjectType.id}
                                onChange={(e) =>
                                  this.handlerChangeSubjectType(e, setValues, values)
                                }
                                showCode={true}
                                error={errors.arcoSubjectType}
                                touched={touched.arcoSubjectType}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-lg-2 me-1 mt-2 ">
                          <Field name="address">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="Indirizzo"
                                className="form-control"
                                value={values.address}
                                onChange={(e) => {
                                  const { name, value } = e.target
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase()
                                    }
                                  })
                                }}
                                maxLength="100"
                                error={errors.address}
                                touched={touched.address}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-lg-1 me-1 mt-2">
                          <Field name="location">
                            {({ field }) => (
                              <AutoCompileCustom
                                {...field}
                                label="Località"
                                handleInputAutocompile={(e) =>
                                  this.handleInputAutocompile(e, setValues, values)
                                }
                                filter={this.props.locations}
                                value={values.location || ''}
                                error={errors.location}
                                touched={touched.location}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-lg-1 me-1 mt-2">
                          <Field name="postalCode">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="CAP"
                                className="form-control"
                                value={values.postalCode}
                                onChange={(e) => {
                                  const { name, value } = e.target
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase()
                                    }
                                  })
                                }}
                                maxLength="5"
                                error={errors.postalCode}
                                touched={touched.postalCode}
                              />
                            )}
                          </Field>
                        </div>

                        <div className="col-12 col-lg-8 mb-1 me-1">
                          <Field name="companyName">
                            {({ field }) => (
                              <BwmInput
                                {...field}
                                label="Ragione Sociale"
                                className="form-control fs-4 fw-bold"
                                value={values.companyName}
                                onChange={(e) => {
                                  const { name, value } = e.target
                                  handleChange({
                                    target: {
                                      name,
                                      value: value.toUpperCase()
                                    }
                                  })
                                }}
                                error={errors.companyName}
                                touched={touched.companyName}
                                maxLength="60"
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '15px',
                        right: '100px',
                        fontSize: '15px'
                      }}
                      className="text-danger">
                      {this.state.errorInConfigSid}
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <div class="">
                        {/* <h6><strong>Valori preimpostati</strong></h6> */}
                        <div className="row">
                          <div className="col-12 col-lg-3 mb-lg-1 mb-sm-4 me-1">
                            <Field name="operationDescription">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label="Descrizione Operazione"
                                  className="form-control"
                                  value={values.operationDescription}
                                  onChange={(e) => {
                                    const { name, value } = e.target
                                    handleChange({
                                      target: {
                                        name,
                                        value: value.toUpperCase()
                                      }
                                    })
                                  }}
                                  error={errors.operationDescription}
                                  touched={touched.operationDescription}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-12 col-lg-3 mb-lg-0 mb-sm-4 me-1">
                            <Field name="operationNature">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label="Natura Operazione"
                                  className="form-control"
                                  value={values.operationNature}
                                  onChange={(e) => {
                                    const { name, value } = e.target
                                    handleChange({
                                      target: {
                                        name,
                                        value: value.toUpperCase()
                                      }
                                    })
                                  }}
                                  error={errors.operationNature}
                                  touched={touched.operationNature}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="col-12 col-lg-3 mb-lg-0 mb-sm-4 me-1">
                            <Field name="operationPurpose">
                              {({ field }) => (
                                <BwmInput
                                  {...field}
                                  label="Scopo"
                                  className="form-control"
                                  value={values.operationPurpose}
                                  onChange={(e) => {
                                    const { name, value } = e.target
                                    handleChange({
                                      target: {
                                        name,
                                        value: value.toUpperCase()
                                      }
                                    })
                                  }}
                                  error={errors.operationPurpose}
                                  touched={touched.operationPurpose}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '15px',
                        right: '100px',
                        fontSize: '15px'
                      }}
                      className="text-danger">
                      {this.state.errorInConfigSid}
                    </div>
                  </div>
                  <div className="form-row mt-auto pt-3 justify-content-between align-item-center border-bottom-0">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-empty px-4 btn-sm "
                      onClick={this.props.handleCloseModal}>
                      <IUndo className={'padding-right-4px svg'} width="16" fill={'#128186'} />
                      &nbsp; ANNULLA
                    </button>

                    <button
                      onClick={(e) =>
                        this.props?.showModal
                          ? submitForm()
                          : this.gestioneTabelle(validateForm, values)
                      }
                      type="button"
                      className="btn btn-primary btn-new-rel px-5">
                      <ITextFile className={'padding-right-4px svg'} width="16" />
                      &nbsp; GESTIONE TABELLE
                    </button>
                    {this.state.subject.id && (
                      <button
                        onClick={() => {
                          this.setState({ showImportDocumentsModal: true })
                        }}
                        className="btn btn-primary btn-new-rel px-5"
                        type="button"
                        disabled={!this.props.isLicenseValid}>
                        <IUpload className={'padding-right-4px svg'} width="16" />
                        &nbsp; IMPORTA DOCUMENTI
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary btn-new-rel px-5"
                      onClick={() => this.setState({ errors, showError: true })}>
                      <ISave className="padding-right-4px svg" width="16" fill="#FFFFFF" />
                      &nbsp; CONFERMA
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {this.state.showImportDocumentsModal && (
          <ImportModal
            key={'import-zip-' + this.props.subject?.id}
            show={this.state.showImportDocumentsModal}
            allowedFileExtensions={['zip', '7z', 'rar']}
            onHide={() => {
              this.setState({ showImportDocumentsModal: !this.state.showImportDocumentsModal })
            }}
            onSend={(dataFile) => this.uploadZipFile(dataFile)}
            secondaryTitle={() => (
              <div>Importa un file zip/7z/rar con i documenti di ARCO desktop</div>
            )}
          />
        )}
      </>
    )
  }
}
