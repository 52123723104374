import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import '../../../../styles/comurei-form.css'
import { ReactComponent as IAnagrafica } from '../../../../styles/images/svg/id-card.svg'
import * as actions from '../../../../actions'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'
import { PopupError } from '../../../shared/PopupError'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import '../../../../styles/subject-crs.css'
import { CreateCommunicationModal } from '../communications/NewCreateCommunicationModal'
import { ReactComponent as ISave } from '../../../../styles/images/svgs/regular/save.svg'
import { ReactComponent as IUndo } from '../../../../styles/images/svgs/regular/times-circle.svg'
import { ReactComponent as ITextFile } from '../../../../styles/images/svgs/regular/file-alt.svg'
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer'
import { saveSid, validateSid } from '../../../shared/SidCertsHandler'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear'
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert'
import { cloneDeep } from 'lodash'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

export function CrsForm(props) {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)

  const [subject, setSubject] = useState({})
  const [defaultEmail, setDefaultEmail] = useState('')

  const [coraSubjectSid, setCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [errorsSid, setErrorsSid] = useState({})

  const retrieveSubject = (id) => {
    setLoading(true)
    if (id) {
      actions.getCrsSubject(id).then((response) => {
        setSubject(response)
        setNewInitialValues(response)
        setLoading(false)
      })
    } else {
      setSubject({})
      setNewInitialValues(undefined)
      setLoading(false)
    }
  }

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  const setNewInitialValues = (subjectItem = {}) => {
    if (isNotEmpty(subjectItem?.coraSubjectSid)) {
      setCoraSubjectSid(subjectItem?.coraSubjectSid)
    }
    if (isNotEmpty(auth)) {
      if (isEmpty(coraSubjectSid?.email)) {
        setDefaultEmail(auth?.user?.login)
      }
    }

    setInitialValues({
      id: subjectItem?.id,
      subjectCode: subjectItem?.subjectCode || '0' + props.newSubjectCode,
      fiscalCode: subjectItem?.fiscalCode || '',
      companyName: subjectItem?.companyName || '',
      address: subjectItem?.address || '',
      location: subjectItem?.location || '',
      province: subjectItem?.province || '',
      flagOicrTrust: subjectItem?.flagOicrTrust || false,
      idFiscalOicrTrust: subjectItem?.idFiscalOicrTrust || '',
      countryCode: subjectItem?.countryCode || 'IT',
      country: subjectItem?.country || 'ITALIA (REPUBBLICA ITALIANA)',
      communicationTypeCode: subjectItem?.communicationTypeCode || '1',
      referenceYear: subjectItem?.referenceYear || new Date().getFullYear() - 1,
      lastCommunication: subjectItem?.lastCommunication || '',
      errorFiscalCode: false
    })
  }

  const validationSchema = Yup.object().shape({
    subjectCode: Yup.string().required('Campo obbligatorio'),
    companyName: Yup.string().required('Campo obbligatorio'),
    fiscalCode: Yup.string().required('Campo obbligatorio'),
    address: Yup.string().required('Campo obbligatorio'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    flagOicrTrust: Yup.boolean(),
    idFiscalOicrTrust: Yup.string().when('flagOicrTrust', {
      is: true, // When flagOicrTrust is true (checked)
      then: (schema) => schema.required('Campo obbligatorio'), // Make idFiscalOicrTrust required
      otherwise: (schema) => schema.notRequired() // Otherwise, it's optional
    }),
    countryCode: Yup.string().required('Campo obbligatorio'),
    country: Yup.string().required('Campo obbligatorio'),
    communicationTypeCode: Yup.string().required('Campo obbligatorio'),
    referenceYear: Yup.string().required('Campo obbligatorio'),
    lastCommunication: Yup.string(),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'subjectCode',
      errorLabel: 'Codice'
    },
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Ragione Sociale'
    },
    {
      errorKey: 'address',
      errorLabel: 'Indirizzo'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede Legale (Località)'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'idFiscalOicrTrust',
      errorLabel: 'Id Fiscal OICR/TRUST'
    },
    {
      errorKey: 'country',
      errorLabel: 'Paese'
    },
    {
      errorKey: 'countryCode',
      errorLabel: 'Codice Paese'
    },
    {
      errorKey: 'referenceYear',
      errorLabel: 'Anno di riferimento'
    },
    {
      errorKey: 'lastCommunication',
      errorLabel: 'Ultima comunicazione'
    },
    {
      errorKey: 'errorSidNumber',
      errorLabel: 'Numero accreditamento SID'
    },
    {
      errorKey: 'errorPassword',
      errorLabel: 'Password di protezione'
    },
    {
      errorKey: 'errorInSubject',
      errorLabel: 'Soggetto'
    },
    {
      errorKey: 'errorInConfigSid',
      errorLabel: 'Configurazione SID'
    },
    {
      errorKey: 'errorEmail',
      errorLabel: 'Indirizzo email'
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: 'Codice fiscale'
    }
  ]

  const getError = (errors, key, removeOldErrors) => {
    if (errorsSid[key]?.isValid === false) {
      errors[key] = errorsSid[key].msg
    } else if (removeOldErrors) {
      delete errors[key]
    }
    return errors
  }

  const getTotalErrors = (errors = {}, removeOldErrors = false) => {
    errors = getError(errors, 'errorCertificates', removeOldErrors)
    errors = getError(errors, 'errorPassword', removeOldErrors)
    errors = getError(errors, 'errorSidNumber', removeOldErrors)
    errors = getError(errors, 'errorEmail', removeOldErrors)
    errors = getError(errors, 'errorInConfigSid', removeOldErrors)
    return errors
  }

  const getErrors = (errors) => {
    const totalErrors = getTotalErrors(errors, true)
    return Utility.extractErrors(totalErrors, formErrorsTabMapping)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}>
      {({ errors }) => (
        <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : 'form-on-bottom'}>
          {showErrorAlert && touched && (
            <div className={props.formOnBottom ? 'crs-form-on-bottom-error' : ''}>
              <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
            </div>
          )}
          <FormBody
            {...props}
            loading={loading}
            touched={touched}
            subject={subject}
            initialValues={initialValues}
            defaultEmail={defaultEmail}
            coraSubjectSid={coraSubjectSid}
            errorsSid={errorsSid}
            setSubject={setSubject}
            setErrorsSid={setErrorsSid}
            setCoraSubjectSid={setCoraSubjectSid}
            setShowErrorAlert={setShowErrorAlert}
            setLoading={setLoading}
            setTouched={setTouched}
            retrieveSubject={retrieveSubject}
            getTotalErrors={getTotalErrors}
          />
        </Form>
      )}
    </Formik>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const createCommunicationRefData = useRef()

  const [certificateFiles, setCertificateFiles] = useState()
  const [isCertSidOpened, setIsCertSidOpened] = useState(false)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const nameApplication = Constants.APPLICATION_CRS
  const id = props.subjectId

  const configurationSidValidation = () => {
    return validateSid(
      props.coraSubjectSid?.sidNumber,
      props.coraSubjectSid?.email,
      props.errorsSid.errorSidNumber,
      props.errorsSid.errorEmail
    )
  }

  const close = (retrieve = false, response) => {
    props.setTouched(false)
    props.setErrorsSid({})
    if (retrieve) props.retrieveSubject(props.subjectId)
    if (props.close) props.close(props.subjectId ? null : response)
  }

  const handleChangeSidValues = (newValues) => {
    props.setCoraSubjectSid({ ...props.coraSubjectSid, ...newValues })
  }

  const handleCommunicationTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    form.communicationTypeCode = val
    setValues(form)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleInputAutocompile = (newLocation) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        form.location = newLocation
        if (loc) form.province = loc.province
        setValues(form)
      },
      () => {
        const form = Object.assign({}, values)
        form.location = ''
        form.province = ''
        setValues(form)
      }
    )
  }

  const handleInputAutocompileCountry = (newCountry) => {
    const form = Object.assign({}, values)
    if (!newCountry || newCountry === '') {
      form.countryCode = newCountry
      form.country = ''
      setValues(form)
      return
    }
    newCountry = newCountry ? newCountry.toUpperCase() : ''
    let countriesFilter = props.countries.filter((country) => {
      return country.name === newCountry
    })
    if (countriesFilter.length === 1) {
      form.countryCode = countriesFilter[0].name
      form.country = countriesFilter[0].key
    }
    if (countriesFilter.length === 0) {
      form.countryCode = newCountry
      form.country = ''
    }
    setValues(form)
  }

  // TODO ASPETTARE API DI EDOARDO
  const checkSid = (sidNumber) => {
    const allSubjects = props.subjects || []
    for (const subject of allSubjects) {
      const sid = subject.coraSubjectSid?.sidNumber
      if (subject.id != props.subjectId && sid === sidNumber) {
        PopupError({
          text: 'Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto.'
        })
        return false
      }
    }
    return true
  }

  /* Validation onChange */
  const handleChangeSid = (val, errorKey, errorMessage) => {
    let value = val.target.value
    let name = val.target.name
    let newCoraSubjectSid = cloneDeep(props.coraSubjectSid)
    if (!newCoraSubjectSid) newCoraSubjectSid = {}
    const error = {}
    error[errorKey] = {}
    if (isEmpty(value)) {
      error[errorKey].isValid = false
      error[errorKey].msg = errorMessage
    } else {
      error[errorKey].isValid = true
      error[errorKey].msg = null
    }
    props.setErrorsSid({
      ...props.errorsSid,
      errorInConfigSid: { isValid: true, msg: '' },
      ...error
    })
    if (name === 'password') {
      newCoraSubjectSid.password = value.toUpperCase().trim()
    }
    if (name === 'sidNumber') {
      if (checkSid(value.toUpperCase().trim())) {
        newCoraSubjectSid.sidNumber = value.toUpperCase().trim()
      } else {
        PopupError({
          text: `Numero SID: ${value}<br> Rilevato per più di un soggetto: registrazione impossibile.`
        })
      }
    }
    if (name === 'email') {
      newCoraSubjectSid.email = value ? value.toUpperCase().trim() : value
    }
    props.setCoraSubjectSid(newCoraSubjectSid)
  }

  const handleCertificateFilesCustom = (val) => {
    /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
    setIsCertSidOpened(true)
    if (isNotEmpty(val)) setCertificateFiles(val)
  }

  const handleFiscalCode = (fiscalCode) => {
    const form = Object.assign({}, values)
    if (fiscalCode) {
      actions.getBaseRegistryByFiscalCode(fiscalCode).then(
        (baseRegistry) => {
          if (baseRegistry) {
            form.companyName = baseRegistry?.companyName || ''
            form.location = baseRegistry?.location?.location || ''
            form.province = baseRegistry?.location?.province || ''
            form.lastName = baseRegistry?.lastName || ''
            form.firstName = baseRegistry?.firstName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender
          }
          form.errorFiscalCode = false
          setValues(form)
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
      actions.getCoraSubjectSidByFiscalCode(fiscalCode, 'crs').then(
        (_coraSubjectSid) => {
          if (_coraSubjectSid?.id) {
            props.setCoraSubjectSid(_coraSubjectSid)
          }
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
    }
  }

  const formValidation = () => {
    if (!isCertSidOpened) {
      return {
        errorSidNumber: { isValid: true, msg: '' },
        errorEmail: { isValid: true, msg: '' }
      }
    }
    return configurationSidValidation()
  }

  const addCrsSubject = (newSubject, sendComm, sid) => {
    const shouldVerifyOnComurei =
      isEmpty(newSubject.id) || props.subject.fiscalCode !== newSubject.fiscalCode
    newSubject.coraSubjectSid = sid
    props.setLoading(true)
    actions.addCrsSubject(newSubject).then(
      (response) => {
        props.setLoading(false)
        props.setSubject(response)
        setValues(response)
        if (shouldVerifyOnComurei) {
          // TODO Check right behave
          actions.verifyCrsSubjectOnComurei(newSubject.fiscalCode).then(
            (res) => {
              if (res === false) {
                ComureiSubjectNotPresentAlert({
                  fiscalCode: newSubject.fiscalCode,
                  denomination: newSubject.companyName
                })
              } else {
                PopupSuccess({ text: 'Soggetto salvato correttamente' })
              }
            },
            (errors) => {
              props.setLoading(false)
            }
          )
        } else if (sendComm) {
          if (document.getElementById('subject-modal')) {
            document.getElementById('subject-modal').style.display = 'none'
          }
          createCommunicationRefData.current.clickButtonSend()
        } else {
          PopupSuccess({ text: 'Soggetto salvato correttamente' })
        }
        props.setLoading(false)
        close(false, response)
        props.getSubjects()
      },
      (errors) => {
        props.setLoading(false)
        if (errors.status === 511) {
          PopupError({
            text:
              errors.status === 511
                ? 'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
                : Constants.APPLICATION_GENERIC_ERROR
          })
        } else {
          PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
        }
      }
    )
  }

  const saveCrsSubject = (form, sendComm) => {
    props.setLoading(true)
    saveSid(
      certificateFiles?.files,
      props.coraSubjectSid,
      form?.fiscalCode,
      'crs',
      (res) => {
        addCrsSubject(form, sendComm, res)
        props.setLoading(false)
      },
      (errors) => {
        props.setLoading(false)
        PopupError({
          text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR
        })
      }
    )
  }

  const updateAll = (values, sendComm) => {
    const form = Object.assign({}, values)
    if (sendComm === true) {
      const lastYear = new Date().getFullYear() - 1
      if (form.referenceYear < lastYear) {
        PopupAlertCommunicationYear(
          values.referenceYear,
          lastYear,
          () => saveCrsSubject(form, sendComm),
          () => {
            form.referenceYear = lastYear
            props.setSubject(form)
            setValues(form)
            saveCrsSubject(form, sendComm)
          }
        )
        return
      }
    }
    saveCrsSubject(form, sendComm)
  }

  const handleFormSubmit = async (sendComm) => {
    const errors = await validateForm(values)
    props.setTouched(true)
    const { errorSidNumber, errorEmail } = formValidation()
    const totalErrors = props.getTotalErrors({
      ...errors,
      errorSidNumber: errorSidNumber?.msg,
      errorEmail: errorEmail?.msg
    })

    const thereAreErrors =
      Object.keys(totalErrors).length > 0 &&
      Object.values(totalErrors).filter((e) => !!e)?.length > 0

    props.setErrorsSid({ ...props.errorsSid, errorSidNumber, errorEmail })
    if (thereAreErrors) {
      props.setShowErrorAlert(true)
    } else {
      updateAll(sendComm)
      props.setShowErrorAlert(false)
    }
  }

  return (
    <div className="row row-detail bg-gray d-flex">
      <div className="col-6 pe-3" id={`anagrafica-${props.subject?.id || 0}`}>
        <div className="configurazioneSID p-1 pt-3">
          <div id={`anagrafica-${id || 0}`}>
            {/* <div className="row mb-3">
              <h5>
                <IAnagrafica fill="#000" width="25" />
                Anagrafica
              </h5>
            </div> */}
            <div className="row">
              <div className="w-10 pe-2">
                <BwmInput
                  name="subjectCode"
                  className="form-control"
                  label="Codice"
                  maxLength="16"
                  disabled={true}
                  value={values.subjectCode}
                  error={errors.subjectCode}
                  touched={props.touched}
                />
              </div>
              <div className="col-3 mb-2 pe-2">
                <BwmInput
                  disabled={props.disabled}
                  name="fiscalCode"
                  label={!values.flagOicrTrust ? 'Codice Fiscale' : 'C. Fiscale del Comunicante'}
                  className="form-control"
                  maxLength="11"
                  value={values.fiscalCode}
                  error={errors.fiscalCode || errors.errorFiscalCode}
                  touched={props.touched}
                  onBlur={(e) => handleFiscalCode(e.target.value)}
                  onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true, true)}
                />
              </div>

              <div className="col-4 pe-2">
                <BwmInput
                  disabled={props.disabled}
                  name="address"
                  label="Indirizzo"
                  className="form-control"
                  maxLength="100"
                  value={values.address}
                  error={errors.address}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'address', true)}
                />
              </div>
              <div className="w-23 pe-2">
                <AutoCompileCustom
                  disabled={props.disabled}
                  label="Sede Legale (Località)"
                  id="location"
                  filter={props.locations}
                  value={values.location}
                  error={errors.location}
                  touched={props.touched}
                  handleInputAutocompile={(e) => handleInputAutocompile(e)}
                />
              </div>
              <div className="w-8">
                <ProvinceField
                  disabled={props.disabled}
                  label="Prov."
                  placeholder="EE per Estero"
                  province={values.province || ''}
                  error={errors.province}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'province', true, true)}
                />
              </div>
              <div className="w-18 pe-2">
                <BwmSelect
                  disabled={props.disabled}
                  options={props.countries}
                  name="country"
                  id="country"
                  label="Paese"
                  className="form-control"
                  showCode={true}
                  nameKey={true}
                  value={values.countryCode || ''}
                  error={errors.countryCode}
                  touched={props.touched}
                  onChange={(e) => handleInputAutocompileCountry(e.target.value)}
                />
              </div>
              <div className="w-68 pe-2">
                <BwmInput
                  disabled={props.disabled}
                  name="companyName"
                  label={!values.flagOicrTrust ? 'Ragione Sociale' : 'Denominazione OICR o TRUST'}
                  className="form-control font-weight-bold font-size-big"
                  maxLength="70"
                  value={values.companyName}
                  error={errors.companyName}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.value, 'companyName', true)}
                />
              </div>
              <div className="col mt-3">
                <BwmCheckbox
                  disabled={props.disabled}
                  name={`flagOicrTrust`}
                  label="OICR o TRUST"
                  className="form-control"
                  checked={values.flagOicrTrust}
                  error={errors.flagOicrTrust}
                  touched={props.touched}
                  onChange={(e) => handleChangeValue(e.target.checked, 'flagOicrTrust')}
                />
              </div>
              <div className="col-12">
                <div className="input-group flex-column h-100 justify-content-around">
                  {/* Radio Buttons Section */}
                  <div className="d-flex ps-1 mb-3 mt-3">
                    <span className="m-0 pe-3 font-weight-bold">
                      <IAnagrafica fill="#000" width="16" height="14" />
                      &nbsp; Tipo Comunicazione
                    </span>
                    <div className="col-auto">
                      <div className="inputRadio me-3 pe-2">
                        <input
                          hidden={true}
                          className="form-check-input"
                          type="radio"
                          name={`communicationTypeCode`}
                          checked={values.communicationTypeCode === '1'}
                          id={`ct1`}
                          value={'1'}
                          onChange={(e) => handleCommunicationTypeClick(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={`ct1`}>
                          <img
                            width="20"
                            className="pe-2"
                            src={values.communicationTypeCode === '1' ? radioSel : radioUnsel}
                            alt=""
                          />
                          NUOVI DATI
                        </label>
                      </div>
                    </div>
                    <div className="col-auto me-3">
                      <div className="inputRadio">
                        <input
                          hidden={true}
                          className="form-check-input"
                          type="radio"
                          name={`communicationTypeCode`}
                          checked={values.communicationTypeCode === '2'}
                          id={`ct2`}
                          value={'2'}
                          disabled={true}
                          onChange={(e) => handleCommunicationTypeClick(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={`ct2`}>
                          <img
                            width="20"
                            className="pe-2"
                            src={values.communicationTypeCode === '2' ? radioSel : radioUnsel}
                            alt=""
                          />
                          SOSTITUTIVA
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="inputRadio">
                        <input
                          hidden={true}
                          className="form-check-input"
                          type="radio"
                          name={`communicationTypeCode`}
                          checked={values.communicationTypeCode === '3'}
                          id={`ct3`}
                          value={'3'}
                          disabled={true}
                          onChange={(e) => handleCommunicationTypeClick(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor={`ct3`}>
                          <img
                            width="20"
                            className="pe-2"
                            src={values.communicationTypeCode === '3' ? radioSel : radioUnsel}
                            alt=""
                          />
                          ANNULLAMENTO
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Fields Section */}
                  <div className="row">
                    <div className="col mb-3 pe-2">
                      <div className="form-group">
                        <BwmInput
                          disabled={props.disabled}
                          name="referenceYear"
                          label="Anno di riferimento"
                          className="form-control"
                          maxLength="4"
                          value={values.referenceYear}
                          error={errors.referenceYear}
                          touched={props.touched}
                          onChange={(e) => handleChangeValue(e.target.value, 'referenceYear')}
                        />
                      </div>
                    </div>

                    <div className="col pe-2">
                      <div className="form-group">
                        <BwmInput
                          name="lastCommunication"
                          label="Ultima comunicazione"
                          className="form-control"
                          value={values.lastCommunication}
                          error={errors.lastCommunication}
                          touched={props.touched}
                          maxLength="100"
                          disabled={true}
                          onChange={(e) => handleChangeValue(e.target.value, 'lastCommunication')}
                        />
                      </div>
                    </div>

                    <div className="col pe-2">
                      <div
                        className={`form-group ${values.flagOicrTrust && 'show'}`}
                        data-tip="ID OICR senza p. giuridica rilasciato da Banca d'Italia (5 caratteri) oppure ID Fiscale TRUST (16 caratteri)">
                        <div className="input-group">
                          <BwmInput
                            disabled={props.disabled}
                            name="idFiscalOicrTrust"
                            label={'Id Fiscal OICR/TRUST'}
                            type="text"
                            value={values.idFiscalOicrTrust}
                            error={errors.idFiscalOicrTrust}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeValue(e.target.value, 'idFiscalOicrTrust', true, true)
                            }
                          />
                        </div>
                        {!errors.idFiscalOicrTrust && props.touched && (
                          <div className="text-danger">{errors.idFiscalOicrTrust}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'col-6'}>
        <div className="configurazioneSID p-3">
          <SidContactFormContainer
            disabled={props.disabled}
            id={props.subjectId || 0}
            errors={props.errorsSid}
            nameApplication={nameApplication}
            subject={props.subject}
            defaultEmail={props.defaultEmail}
            coraSubjectSid={props.coraSubjectSid}
            certificateFiles={certificateFiles}
            fiscalCode={props.subject?.fiscalCode}
            setCoraSubjectSid={props.setCoraSubjectSid}
            setErrorCertificates={(errorCertificates) =>
              props.setErrorsSid({ ...props.errorsSid, errorCertificates })
            }
            handleChangeSid={handleChangeSid}
            handleChangeSidValues={handleChangeSidValues}
            handleCertificateFilesCustom={handleCertificateFilesCustom}
          />
          {values.id && (
            <div className="panel-info align-items-end">
              <h5>Codice soggetto: {props.subject?.id}</h5>
              {props.coraSubjectSid && <h5>Codice SID: {props.coraSubjectSid?.id}</h5>}
            </div>
          )}
        </div>
      </div>
      <div className="form-row row align-item-center mt-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              disabled={props.disabled}
              type="button"
              className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
              onClick={() => close(true)}>
              <IUndo className={'svg'} width="18" fill={'#128186'} />
              &nbsp; ANNULLA
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <CreateCommunicationModal
              {...props}
              ref={createCommunicationRefData}
              subjectId={values?.id}
              emailAddress={props.coraSubjectSid?.email}
              generateEmpty={true}
              classCustom={'display-none'}
              closeSubjectModal={close}
              subject={props.subject}
            />
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary btn-empty px-5 me-2"
              onClick={() => handleFormSubmit(values, true)}>
              <ITextFile className={'svg'} width="16" fill={`#128186`} />
              &nbsp; CREA COMUNICAZIONE VUOTA
            </button>
            <button
              type="submit"
              disabled={props.disabled}
              className="btn btn-primary btn-new-rel px-5"
              onClick={() => handleFormSubmit(values)}>
              <ISave className={'svg'} width="18" fill={'#FFFFFF'} />
              &nbsp; CONFERMA
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
