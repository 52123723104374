import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'

export const BwmSelect = ({
  label,
  options,
  onChange,
  name,
  error,
  touched,
  value,
  showCode,
  disabled,
  nameKey
}) => {
  function renderOptions(nameKey) {
    return options?.map((option, index) => {
      if (nameKey) {
        if (nameKey === 'currencies') {
          let code =
            option.code !== undefined
              ? option.code
              : option.isoCode !== undefined
                ? option.isoCode
                : ''
          return (
            <option
              key={index}
              value={option.id}
              data-cod={code}>{`${showCode ? code : option.description}`}</option>
          )
        }
        return (
          <option key={index} value={option.name} data-cod={option.name}>
            {option.name + ' - ' + option.key}
          </option>
        )
      } else {
        let code =
          option.code !== undefined
            ? option.code
            : option.isoCode !== undefined
              ? option.isoCode
              : ''
        return (
          <option
            key={index}
            value={option?.id}
            data-cod={
              code
            }>{`${showCode ? code + ' - ' + option.description : option.description}`}</option>
        )
      }
    })
  }

  const inputWrapperClass = `input-wrapper w-100 ${touched && error !== undefined ? 'has-error' : ''}`

  return (
    <div className={inputWrapperClass}>
      <FloatingLabel id="floatingLabel" label={label}>
        <Form.Select
          onChange={onChange}
          name={name}
          value={value || ''}
          disabled={disabled}
          className="form-select-b5"
          isInvalid={touched ? error : undefined}>
          <option></option>
          {renderOptions(nameKey)}
        </Form.Select>
      </FloatingLabel>
    </div>
  )
}
