import { isValid } from 'date-fns'
import { formatDateForInput, isValidDate } from '../Utility'

export const DateField = (props) => {
  const renderErrorMessage = (date) => {
    if (!isValidDate(new Date(date))) {
      return <div className="text-danger">Inserire data valida</div>
    } else if (props.error && !props.error.isValid) {
      return <div className="text-danger">{props.error?.msg}</div>
    }
    return <></>
  }

  return (
    <>
      <div className="form-group">
        {props.label && <label>{props.label}</label>}
        <div className="input-group">
          <input
            type="date"
            value={
              props.date !== null && isValid(new Date(props.date))
                ? formatDateForInput(props.date)
                : undefined
            }
            disabled={props.disabled}
            onChange={(e) =>
              props.onChange(
                isValidDate(new Date(e.target.value)) ? new Date(e.target.value) : undefined
              )
            }
            className={`form-control ${props.className}`}
            max={'2500-01-01'}></input>
        </div>
        {renderErrorMessage(props.date)}
      </div>
    </>
  )
}
