import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StarTable } from '../../shared/tables/StarTable'
import { getAllBlueSubjects } from '../../../actions'
import BlueSubjectForm from './NewBlueSubjectForm'
import { ReactComponent as ISearch } from '../../../styles/images/svg/search-solid.svg'
import getText from './labels'
import { baseSearchObject, TableUtils } from '../../shared/tables/TableUtils'
import list from '../../../styles/images/elenco.png'
import '../../../styles/dac7NewStyles.css'

const BlueSubjectList = (props) => {
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const history = useHistory()

  const [subject, setSubject] = useState()
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)

  const [searchObject, setSearchObject] = useState(baseSearchObject)

  const tableUtils = TableUtils
  const tableName = 'blue-subject'

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      searchable: false,
      displayedName: labels.FISCALCODE
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      searchable: false,
      displayedName: labels.DENOMINATION
    }),
    tableUtils.composeHeader({
      fieldName: 'emailAddress',
      searchable: false,
      displayedName: labels.EMAILADDRESS
    }),
    tableUtils.composeHeader({
      fieldName: 'address',
      searchable: false,
      displayedName: labels.ADDRESS
    }),
    tableUtils.composeHeader({
      fieldName: 'historyAction',
      sortable: false,
      searchable: false,
      maxWidth: '100px',
      additionalClass: 'justify-content-center',
      displayedName: labels.HISTORY
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((row) => {
      return tableUtils.composeRow({
        id: row.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: row.fiscalCode
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: row.denomination
          }),
          emailAddress: tableUtils.composeCell({
            fieldName: 'emailAddress',
            fieldValue: row.email
          }),
          address: tableUtils.composeCell({
            fieldName: 'address',
            fieldValue: row.address
          }),
          historyAction: tableUtils.composeCell({
            fieldName: 'historyAction',
            additionalClass: 'd-flex justify-content-center p-0',
            component: () => {
              return (
                <div className="d-flex justify-content-center p-0">
                  <button
                    className="btn btn-outline-primary btn-table"
                    onClick={() => props.showHistory(row.id)}>
                    <img src={list} alt={'Mostra storico'} />
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  const setNewSubjects = (content, res) => {
    props.setSubjects(res)
  }

  const openDetail = (subject) => {
    const path = `/app/blue/search/${subject.id}`
    history.push(path)
  }

  const onClickRow = (row) => {
    let _subject
    if (row.id && props.subjects?.length > 0) {
      _subject = props.subjects.find((el) => el.id === row.id)
    }
    setSubject(_subject)
  }

  useEffect(() => {
    setRows(prepareRows(props.subjects || []))
    setSubject(subject ? subject : props.subjects?.[0])
  }, [props.subjects])

  useEffect(() => {
    setSubject()
  }, [props.resetForm])

  return (
    <StarTable
      id={subject?.id}
      headerColums={header}
      rows={rows}
      offset="450"
      isLoading={show}
      tableConfigurationKey={tableName}
      searchObjectPrototype={searchObject}
      setSearchObject={setSearchObject}
      language={lang}
      searchCallBack={(searchObject) => getAllBlueSubjects(searchObject)}
      onExecutedSearch={(content, res) => setNewSubjects(content, res)}
      usePagination={false}
      withCheckBoxes={true}
      onClickRow={onClickRow}
      onDoubleClickRow={openDetail}
      onClickCheck={props.setSelectedSubjects}
      actionsComponent={props.actionsComponent}
      formComponent={BlueSubjectForm}
      formComponentProps={{
        id: subject?.id,
        disabled: props.disabled,
        formOnBottom: true,
        subject: subject,
        getSubjects: props.getSubjects,
        close: () => setSubject(subject ? subject : undefined)
      }}
    />
  )
}

export default BlueSubjectList
