import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import NumberFormat from 'react-number-format'
import { APPLICATIONS, LICENSE_TYPES } from '../../config/Constants'
import { BtnEmpty } from '../shared/BtnEmpty'
import { BtnFill } from '../shared/BtnFill'
import { BwmSelect } from '../shared/form/BwmSelectB5'
import { BwmInput } from '../shared/form/BwmInputB5'
import { DateField } from '../shared/form/DateFieldB5'

export function AdminNewApplicationModal(props) {
  const defaultYear = new Date().getFullYear() + 1
  const customer = props.customer
  const [modalShow, setModalShow] = useState(false)
  const [application, setApplication] = useState(2)
  const [licenseType, setLicenseType] = useState('BOUGHT')
  const [maxSubjects, setMaxSubjects] = useState(1)
  const [expiration, setExpiration] = useState(new Date().setFullYear(defaultYear))

  const onSave = () => {
    if (expiration && application && maxSubjects && licenseType) {
      const customerApplication = {
        expiration: new Date(expiration),
        application: { id: application },
        maxSubjects: maxSubjects,
        licenseType: licenseType,
        customer: customer
      }
      props.onSave(customerApplication)
    }
  }

  const openModal = () => {
    setModalShow(true)
  }

  const closeModal = () => {
    setModalShow(false)
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-new-rel btn-cell btn-sm px-4 me-2"
        onClick={openModal}>
        <strong>+</strong>
      </button>
      <Modal
        show={modalShow}
        onHide={closeModal}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-content-sm"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Aggiungi Applicazione per l'utente {customer?.user?.email}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-auto">
            <div className="col-6 mb-3 pe-2">
              <BwmSelect
                label={'Applicazione'}
                className="form-control"
                value={application}
                options={APPLICATIONS}
                onChange={(e) => setApplication(e.target.value)}
              />
            </div>
            <div className="col-6 mb-3 form-group">
              <NumberFormat
                id="filled-multiline-flexible"
                label="Numero Soggetti"
                multiline={true}
                fixedDecimalScale={true}
                className="form-control"
                variant="filled"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                inputMode="numeric"
                customInput={BwmInput}
                value={maxSubjects || 0}
                onValueChange={(value) => setMaxSubjects(value.value)}
              />
            </div>
            <div className="col-6 pe-2">
              <BwmSelect
                label={'Tipo licenza'}
                className="form-control"
                value={licenseType}
                options={LICENSE_TYPES}
                onChange={(e) => setLicenseType(e.target.value)}
              />
            </div>
            <div className="col-6 form-group">
              <DateField
                label="Scadenza"
                date={expiration}
                onChange={(date) => setExpiration(date)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
          <BtnFill
            text="CREA E CONTINUA"
            classCustom="float-end text-end"
            handlerOnClick={() => onSave()}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
