import { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import crsPositive from '../../styles/images/app/positive/Crs.png'
import coraPositive from '../../styles/images/app/positive/Cora.png'
import comureiPositive from '../../styles/images/app/positive/Comurei.png'
import corproPositive from '../../styles/images/app/positive/Corpro.png'
import arcoPositive from '../../styles/images/app/positive/Arco.png'
import bluePositive from '../../styles/images/app/positive/Blue.png'
import Modal from 'react-bootstrap/Modal'
import * as actions from '../../actions'
import { PopupError } from '../shared/PopupError'
import { PopupSuccess } from '../shared/PopupSuccess'
import { formatDateForDisplay } from '../shared/Utility'

export const ApplicationList = ({ applications, getApplication }) => {
  const [showDemoModal, setShowDemoModal] = useState(false)
  const [selectedApplicationForModal, setSelectedApplicationForModal] = useState('')

  const checkExpire = (expire) => {
    const format = formatDateForDisplay(expire)
    if (moment(expire).add(1, 'days').isSameOrAfter(moment())) {
      return 'fino al ' + format
    } else {
      return 'scaduto il ' + format
    }
  }

  const openModal = (applicationName) => {
    setShowDemoModal(true)
    setSelectedApplicationForModal(applicationName)
  }

  const closeModal = () => {
    setShowDemoModal(false)
  }

  const activateDemo = (applicationName) => {
    actions.activateDemoForApplication(applicationName).then(
      (response) => {
        if (response) {
          closeModal()
          getApplication()
          PopupSuccess({
            text: 'Licenza DEMO attivata',
            handleClickConfirm: () => window.location.reload()
          })
        }
      },
      (errors) => {
        PopupError({ text: 'Errore generico nel salvataggio dei dati' })
      }
    )
  }

  const renderApplications = () => {
    return applications.map((application) => {
      let icon = ''

      let modalFunc

      const applicationName = application.application.name

      switch (applicationName.toLowerCase()) {
        case 'cora':
          icon = coraPositive
          modalFunc = renderCoraModal
          break
        case 'comurei':
          icon = comureiPositive
          modalFunc = renderComureiModal
          break
        case 'crs':
          icon = crsPositive
          modalFunc = renderCRSModal
          break
        case 'corpro':
          icon = corproPositive
          modalFunc = renderCorproModal
          break
        case 'arco':
          icon = arcoPositive
          modalFunc = renderArcoModal
          break
        case 'blue':
          icon = bluePositive
          modalFunc = renderBlueModal
          break
        case 'dac7':
          // eslint-disable-next-line no-restricted-globals
          modalFunc = renderDac7Modal
          icon = '/app/dac7/assets/dac7-logo.png'
          break
        default:
      }

      let licenseString = ''
      switch (application.licenseType) {
        case 'BOUGHT':
          licenseString = 'Abbonamento ' + checkExpire(application.expiration)
          break
        case 'DEMO':
          licenseString = 'In DEMO ' + checkExpire(application.expiration)
          break
        case 'DEMOAVAILABLE':
          licenseString = 'Demo disponibile per 30 giorni'
          break
      }

      return (
        <div key={applicationName}>
          {modalFunc()}
          {application.licenseType === 'DEMOAVAILABLE' ? (
            <button className="card" onClick={() => openModal(applicationName)}>
              <div className="col">
                <div className="row coraStar-img">
                  <img src={icon} alt={`${applicationName}`} className="image-app" />
                </div>
                <div className="row coraStar-subtitle">
                  <span>{application.application.description}</span>
                </div>
                <div className="row coraStar-scadenza">
                  {applicationName === 'COMUREI' ? (
                    <span>"Attiva il primo Soggetto gratuitamente"</span>
                  ) : (
                    <span>{licenseString}</span>
                  )}
                </div>
              </div>
            </button>
          ) : (
            <Link className="card" to={`/app/${applicationName.toLowerCase()}/dashboard`}>
              <div className="col">
                <div className="row coraStar-img">
                  <img src={icon} alt={`${applicationName}`} className="image-app" />
                </div>
                <div className="row coraStar-subtitle">
                  <span>{application.application.description}</span>
                </div>
                {renderLabel(application, licenseString)}
              </div>
            </Link>
          )}
        </div>
      )
    })
  }

  const renderBlueModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'BLUE'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare la versione demo di {selectedApplicationForModal} per 30 giorni?
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderArcoModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'ARCO'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare la versione demo di {selectedApplicationForModal} per 30 giorni?
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderCorproModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'CRS'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare la versione demo di {selectedApplicationForModal} per 30 giorni?
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderDac7Modal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'DAC7'}
        onHide={closeModal}
        applicationName={selectedApplicationForModal}
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare la versione demo di {selectedApplicationForModal} per 30 giorni?
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderCoraModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'CORA'}
        onHide={closeModal}
        applicationName="CORA"
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare la versione demo di CORA per 30 giorni?
            </div>
            <div className="d-flex justify-content-center">
              <strong>La demo di CORA Web non ha limiti e può essere usata per 30 giorni.</strong>
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderCRSModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'CRS'}
        onHide={closeModal}
        applicationName="CRS"
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare la versione demo di CRS per 30 giorni?
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderComureiModal = () => {
    return (
      <DemoActivationModal
        show={showDemoModal && selectedApplicationForModal === 'COMUREI'}
        onHide={closeModal}
        applicationName="COMUREI"
        BodyComponent={() => (
          <>
            <div className="mt-4 d-flex justify-content-center">
              Si desidera attivare COMUREI per il PRIMO SOGGETTO (gratuito)?
            </div>
          </>
        )}
        onActivateDemo={() => activateDemo(selectedApplicationForModal)}
      />
    )
  }

  const renderLabel = (application, licenseString) => {
    if (application.application.name.toLowerCase() === 'comurei') {
      return (
        <div className="row coraStar-scadenza">
          <span>
            Abbonamento SENZA SCADENZA per <b>{application.maxSubjects}</b> soggetti
          </span>
        </div>
      )
    }
    if (application.licenseType === 'BOUGHT' || application.licenseType === 'DEMO') {
      return (
        <div className="row coraStar-scadenza">
          <span>
            {licenseString} per <b>{application.maxSubjects}</b> soggetti
          </span>
        </div>
      )
    }
  }

  return (
    <div className="container-dash">
      <div className={applications?.length > 0 ? 'panel-dash' : ''}>{renderApplications()}</div>
    </div>
  )
}

function DemoActivationModal({
  show = false,
  onHide = () => {},
  applicationName = '',
  BodyComponent = () => <></>,
  buttonText = 'Attiva demo',
  titleText = 'Attivazione demo ' + applicationName,
  onActivateDemo = () => {}
}) {
  return (
    <Modal
      key={'modal' + applicationName}
      backdrop="static"
      dialogClassName="modal-40w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}>
      <div className="bar" />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="font-size-big">
          <BodyComponent />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between">
          <button className="btn btn-outline-primary btn-empty px-5 btn-sm" onClick={onHide}>
            Chiudi
          </button>
          <button className="btn btn-primary btn-new-rel px-5" onClick={onActivateDemo}>
            {buttonText}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
