import * as actions from '../../../../actions'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as IUpload } from '../../../../styles/images/svg/file-upload.svg'
import { ReactComponent as ICheck } from '../../../../styles/images/svg/check.svg'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { PopupError } from '../../../shared/PopupError'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as IImport } from '../../../../styles/images/svgs/regular/file-import.svg'
import { isEmpty } from '../../../shared/Utility'

export const ImportCrsSubjectDataModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })

  const closeModal = () => {
    setShowModal(false)
    setDataFile('')
  }

  const openModal = () => {
    setShowModal(true)
  }

  const checkFileName = (name) => {
    return name.split('.').pop().toLowerCase() === 'txt'
  }

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: 'Il file è obbligatorio' })
    }
    let files = []
    for (let file of val.target.files) {
      if (!checkFileName(file.name)) {
        setErrorDataFile({ isValid: false, msg: 'Estensione o nome file errati' })
      } else {
        files.push(file)
      }
    }
    if (files.length > 0) setDataFile(files)
  }

  const handleSendDataFile = () => {
    setShowModal(false)
    setLoading(true)
    let formData = new FormData()
    if (dataFile?.length > 0) {
      for (let datafile of dataFile) {
        formData.append('dataFile', datafile)
      }
    } else {
      return
    }
    actions
      .importCrsDataFile(formData)
      .then(
        (res) => {
          props.getSubjects()
          let results = []
          if (res) {
            for (let result of res) {
              results.push('Importato soggetto: ' + result.denomination)
            }
            PopupSuccess({
              dataList: results
            })
          } else {
            PopupError({
              text: "Errore durante l'importazione del soggetto. <br>Controllare il file e riprovare."
            })
          }
        },
        (errors) => {
          if (errors?.errorKey === 'licenseCheck') {
            PopupError({
              text: '<p>Hai raggiunto il numero massimo di soggetti acquistati: per importare ulteriori soggetti puoi acquistare soggetti aggiuntivi</p> <button onClick={window.open(\'https://www.starsoluzioni.it/product/crs-comunicazione-common-reporting-standard-ocse/\')}  className="btn btn-primary btn-new-rel px-5">Compra Online</button>'
            })
          } else {
            PopupError({
              text: "Errore durante l'importazione del soggetto. <br>Controllare il file e riprovare."
            })
          }
        }
      )
      .then(() => {
        setDataFile('')
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <PageSpinner />}
      <button
        type="button"
        data-tip={'Importa soggetti alla tua lista'}
        className={`btn btn-outline-primary btn-empty btn-sm `}
        disabled={props.disabled}
        onClick={openModal}>
        <IImport
          className={'padding-right-4px'}
          width="20"
          height="18"
          fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`}
        />
        &nbsp; Importa
      </button>

      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>IMPORTA SOGGETTI DA CRS DESKTOP</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="image-upload text-start">
              <label htmlFor={`dataFile`}>
                <div className="certificateFiles">
                  CARICA FILE DATI <IUpload width="18" fill="#000" />
                </div>
              </label>
              <input
                id={`dataFile`}
                type="file"
                name={`dataFile`}
                multiple
                onChange={(e) => handleUploadFile(e)}
              />
              <div className="certificatiLoaded text-start">
                {!errorDataFile.isValid ? (
                  <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
                ) : (
                  <div>
                    {dataFile &&
                      dataFile.map((file, index) => {
                        return (
                          <div key={index}>
                            <ICheck width="12" fill="#8e8e8e" />
                            {file.name}
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorDataFile.isValid || !dataFile}
              onClick={handleSendDataFile}
              type="button"
              className="btn btn-primary btn-new-rel px-5">
              CONFERMA
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
