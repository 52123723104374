import { useState, useEffect } from 'react'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import { ISave, IUndo } from '../../../../styles/icons'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { PopupError } from '../../../shared/PopupError'
import * as Utility from '../../../shared/Utility'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { saveSid, validateSid } from '../../../shared/SidCertsHandler'
import { cloneDeep } from 'lodash'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

export const CoraSubjectForm = (props) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)

  const [subject, setSubject] = useState({})

  const [coraSubjectSid, setCoraSubjectSid] = useState({
    id: null,
    certificate1: '',
    certificate2: '',
    certificate3: '',
    password: '',
    email: '',
    sidNumber: '',
    flagPec: false,
    expirationDate: null
  })

  const [errorsSid, setErrorsSid] = useState({})

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required('Campo obbligatorio'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().required('Campo obbligatorio'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date('Inserire una data valida').when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required('Campo obbligatorio')
          .test(
            'is-valid-date',
            'Inserire una data valida',
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const retrieveSubject = (id) => {
    setLoading(true)
    if (id) {
      actions
        .getCoraSubjectDetail(id)
        .then((response) => {
          setSubject(response.data)
          if (props.subject) props.setSubject(response.data)
          setNewInitialValues(response.data)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    } else {
      setSubject({})
      setNewInitialValues(undefined)
      setLoading(false)
    }
  }

  const setNewInitialValues = (subjectItem) => {
    if (subjectItem) setCoraSubjectSid(subjectItem.coraSubjectSid)
    setInitialValues({
      id: subjectItem?.id || null,
      personType: subjectItem?.personType || Constants.PNF,
      fiscalCode: subjectItem?.fiscalCode,
      companyName: subjectItem?.companyName,
      location: subjectItem?.location,
      province: subjectItem?.province,
      firstName: subjectItem?.firstName,
      lastName: subjectItem?.lastName,
      gender: subjectItem?.gender,
      birthDate: subjectItem?.birthDate,
      errorFiscalCode: false
    })
  }

  const close = (retrieve = false) => {
    setTouched(false)
    if (retrieve) retrieveSubject(props.subjectId)
    setErrorsSid({})
    if (props.close) props.close()
  }

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Ragione sociale'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede legale'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'gender',
      errorLabel: 'Sesso'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'errorSidNumber',
      errorLabel: 'Numero accreditamento SID'
    },
    {
      errorKey: 'errorPassword',
      errorLabel: 'Password di protezione'
    },
    {
      errorKey: 'errorInSubject',
      errorLabel: 'Soggetto'
    },
    {
      errorKey: 'errorInConfigSid',
      errorLabel: 'Configurazione SID'
    },
    {
      errorKey: 'errorEmail',
      errorLabel: 'Indirizzo email'
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: 'Codice fiscale'
    }
  ]

  const getError = (errors, key, removeOldErrors) => {
    if (errorsSid[key]?.isValid === false) {
      errors[key] = errorsSid[key].msg
    } else if (removeOldErrors) {
      delete errors[key]
    }
    return errors
  }

  const getTotalErrors = (errors = {}, removeOldErrors = false) => {
    errors = getError(errors, 'errorCertificates', removeOldErrors)
    errors = getError(errors, 'errorPassword', removeOldErrors)
    errors = getError(errors, 'errorSidNumber', removeOldErrors)
    errors = getError(errors, 'errorEmail', removeOldErrors)
    errors = getError(errors, 'errorInConfigSid', removeOldErrors)
    return errors
  }

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = 'Luogo di nascita'
    else formErrorsTabMapping[4].errorLabel = 'Sede legale (Comune)'
    const totalErrors = getTotalErrors(errors, true)
    return Utility.extractErrors(totalErrors, formErrorsTabMapping)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ values, errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : 'form-on-bottom'}>
            {showErrorAlert && touched && (
              <div className={props.formOnBottom ? 'cora-form-on-bottom-error' : ''}>
                <ErrorListAlert
                  errors={getErrors(errors, values.personType)}
                  hide={() => setShowErrorAlert(false)}
                />
              </div>
            )}
            <FormBody
              {...props}
              initialValues={initialValues}
              loading={loading}
              touched={touched}
              subject={subject}
              errorsSid={errorsSid}
              coraSubjectSid={coraSubjectSid}
              setTouched={setTouched}
              setLoading={setLoading}
              setCoraSubjectSid={setCoraSubjectSid}
              setErrorsSid={setErrorsSid}
              setShowErrorAlert={setShowErrorAlert}
              getTotalErrors={getTotalErrors}
              close={close}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const nameApplication = Constants.APPLICATION_CORA
  const id = props.subjectId

  const [certificateFiles, setCertificateFiles] = useState()
  const [isCertSidOpened, setIsCertSidOpened] = useState(false)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const handlePersonTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    form.personType = val
    setValues(form)
  }

  // TODO ASPETTARE API DI EDOARDO
  const checkSid = (sidNumber) => {
    const allSubjects = props.subjects || []
    for (const subject of allSubjects) {
      const sid = subject.coraSubjectSid?.sidNumber
      if (subject.id != props.subjectId && sid === sidNumber) {
        PopupError({
          text: 'Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto.'
        })
        return false
      }
    }
    return true
  }

  const handleChangeSid = (val, errorKey, errorMessage) => {
    let value = val.target.value
    let name = val.target.name
    let newCoraSubjectSid = cloneDeep(props.coraSubjectSid)
    if (!newCoraSubjectSid) newCoraSubjectSid = {}
    const error = {}
    error[errorKey] = {}
    if (isEmpty(value)) {
      error[errorKey].isValid = false
      error[errorKey].msg = errorMessage
    } else {
      error[errorKey].isValid = true
      error[errorKey].msg = null
    }
    props.setErrorsSid({ ...props.errorsSid, errorInConfigSid: '', ...error })
    if (name === 'password') {
      newCoraSubjectSid.password = value.toUpperCase().trim()
    }
    if (name === 'sidNumber') {
      if (checkSid(value.toUpperCase().trim())) {
        newCoraSubjectSid.sidNumber = value.toUpperCase().trim()
      } else {
        PopupError({
          text: `Numero SID: ${value}<br> Rilevato per più di un soggetto: registrazione impossibile.`
        })
      }
    }
    if (name === 'email') {
      props.coraSubjectSid.email = value ? value.toUpperCase().trim() : value
    }
    props.setCoraSubjectSid(newCoraSubjectSid)
  }

  const handleChangeSidValues = (newValues) => {
    props.setCoraSubjectSid({ ...props.coraSubjectSid, ...newValues })
  }

  const handleCertificateFilesCustom = (val) => {
    /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
    setIsCertSidOpened(true)
    if (isNotEmpty(val)) setCertificateFiles(val)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleFiscalCode = (fiscalCode) => {
    const form = Object.assign({}, values)
    if (fiscalCode) {
      actions.getBaseRegistryByFiscalCode(fiscalCode).then(
        (baseRegistry) => {
          if (baseRegistry) {
            form.companyName = baseRegistry?.companyName || ''
            form.location = baseRegistry?.location?.location || ''
            form.province = baseRegistry?.location?.province || ''
            form.lastName = baseRegistry?.lastName || ''
            form.firstName = baseRegistry?.firstName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender
          }
          form.errorFiscalCode = false
          setValues(form)
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
      actions.getCoraSubjectSidByFiscalCode(fiscalCode, 'cora').then(
        (_coraSubjectSid) => {
          if (_coraSubjectSid?.id) {
            props.setCoraSubjectSid(_coraSubjectSid)
          }
          form.errorFiscalCode = false
        },
        () => {
          form.errorFiscalCode = true
          setValues(form)
        }
      )
    }
  }

  const handleInputAutocompile = (newLocation) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        form.location = newLocation
        if (loc) form.province = loc.province
        setValues(form)
      },
      () => {
        const form = Object.assign({}, values)
        form.location = ''
        form.province = ''
        setValues(form)
      }
    )
  }

  const formValidation = () => {
    if (!isCertSidOpened) {
      return {
        errorSidNumber: { isValid: true, msg: '' },
        errorEmail: { isValid: true, msg: '' }
      }
    }
    return configurationSidValidation()
  }

  const updateAll = (values) => {
    saveSid(
      certificateFiles?.files,
      props.coraSubjectSid,
      values.fiscalCode,
      'cora',
      (res) => addOrUpdateCoraSubject(res, { ...values, errorFiscalCode: undefined }),
      () => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        resetFields()
      }
    )
  }

  const addOrUpdateCoraSubject = (sid, values) => {
    let newSubject = { ...values }
    if (newSubject.personType === Constants.PF) {
      newSubject.companyName = null
    } else {
      newSubject.gender = null
      newSubject.firstName = null
      newSubject.lastName = null
      newSubject.birthDate = null
    }
    newSubject.createDate = new Date()
    newSubject.modifyDate = new Date()
    newSubject.coraSubjectSid = sid
    props.addOrUpdateCoraSubject(newSubject, values?.fiscalCode)
    props.close()
  }

  const resetFields = () => {
    props.setCoraSubjectSid({})
  }

  const configurationSidValidation = () => {
    return validateSid(
      props.coraSubjectSid?.sidNumber,
      props.coraSubjectSid?.email,
      props.errorsSid.errorSidNumber,
      props.errorsSid.errorEmail
    )
  }

  const handleFormSubmit = async () => {
    const errors = await validateForm(values)
    props.setTouched(true)
    const { errorSidNumber, errorEmail } = formValidation()
    const totalErrors = props.getTotalErrors({
      ...errors,
      errorSidNumber: errorSidNumber?.msg,
      errorEmail: errorEmail?.msg
    })

    const thereAreErrors =
      Object.keys(totalErrors).length > 0 &&
      Object.values(totalErrors).filter((e) => !!e)?.length > 0
    props.setErrorsSid({ ...props.errorsSid, errorSidNumber, errorEmail })
    if (thereAreErrors) {
      props.setShowErrorAlert(true)
    } else {
      updateAll(values)
      props.setShowErrorAlert(false)
    }
  }

  return (
    <div className="row row-detail d-flex">
      <div className={'w-55 pe-3'}>
        <div className="configurazioneSID pt-3">
          <div id={`anagrafica-${id || 0}`}>
            {/* <div className="row mb-3">
              <h5>
                <IAnagrafica fill="#000" width="25" />
                Anagrafica
              </h5>
            </div> */}
            <div className="row">
              <div className="col-md-12 col-lg-12 mb-3 ps-0">
                <div className="input-group">
                  <div className="inputRadio me-5" style={{ fontSize: '15px' }}>
                    <label
                      disabled={props.disabled}
                      className="form-check-label d-flex align-items-center"
                      htmlFor={`pType1${values?.id}`}
                      onClick={() => handlePersonTypeClick(Constants.PNF)}>
                      <input
                        disabled={props.disabled}
                        className="form-check-input pe-1 me-2 mt-0"
                        style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                        type="radio"
                        checked={values.personType === Constants.PNF}
                        onChange={() => {}}
                      />
                      Persona giuridica
                    </label>
                  </div>
                  <div className="inputRadio" style={{ fontSize: '15px' }}>
                    <label
                      disabled={props.disabled}
                      className="form-check-label d-flex align-items-center"
                      htmlFor={`pType2${values?.id}`}
                      onClick={() => handlePersonTypeClick(Constants.PF)}>
                      <input
                        disabled={props.disabled}
                        className="form-check-input pe-1 me-2 mt-0"
                        style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                        type="radio"
                        checked={values.personType === Constants.PF}
                        onChange={() => {}}
                      />
                      Persona fisica
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {values.personType === Constants.PNF ? (
                <>
                  <div className="w-18 pe-2 mb-2">
                    <BwmInput
                      disabled={props.disabled}
                      name="fiscalCode"
                      label="Codice Fiscale"
                      className="form-control"
                      maxLength="16"
                      value={values.fiscalCode}
                      error={errors.fiscalCode || errors.errorFiscalCode}
                      touched={props.touched}
                      onBlur={(e) => handleFiscalCode(e.target.value)}
                      onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true, true)}
                    />
                  </div>
                  <div className="w-82 mb-2">
                    <BwmInput
                      disabled={props.disabled}
                      label="Ragione Sociale"
                      className="form-control font-weight-bold font-size-big"
                      maxLength="60"
                      value={values.companyName}
                      error={errors.companyName}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'companyName', true)}
                    />
                  </div>
                  <div className="col-6 pe-2 mb-2">
                    {/* LOCALITÀ */}
                    <AutoCompileCustom
                      disabled={props.disabled}
                      label={
                        values.personType === Constants.PNF
                          ? 'Sede Legale (Località)'
                          : 'Comune di nascita'
                      }
                      id="location"
                      filter={props.locations}
                      value={values.location}
                      error={errors.location}
                      touched={props.touched}
                      handleInputAutocompile={(e) => handleInputAutocompile(e)}
                    />
                  </div>

                  <div className="w-10 pe-2 mb-2">
                    <ProvinceField
                      disabled={props.disabled}
                      placeholder="EE per Estero"
                      label="Prov."
                      province={values.province}
                      error={errors.province}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'province', true, true)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-18 pe-2 mb-2">
                    <BwmInput
                      disabled={props.disabled}
                      name="fiscalCode"
                      label="Codice Fiscale"
                      className="form-control"
                      value={values.fiscalCode}
                      error={errors.fiscalCode || errors.errorFiscalCode}
                      touched={props.touched}
                      maxLength="16"
                      onBlur={(e) => handleFiscalCode(e.target.value)}
                      onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true, true)}
                    />
                  </div>
                  <div className="w-35 pe-2">
                    <BwmInput
                      disabled={props.disabled}
                      name="firstName"
                      label="Nome"
                      type="text"
                      className="form-control font-weight-bold font-size-big"
                      value={values.firstName}
                      error={errors.firstName}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'firstName', true)}
                    />
                  </div>
                  <div className="w-35 mb-2 pe-2">
                    <BwmInput
                      disabled={props.disabled}
                      label="Cognome"
                      type="text"
                      className="form-control font-weight-bold font-size-big"
                      value={values.lastName}
                      error={errors.lastName}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'lastName', true)}
                    />
                  </div>
                  <div className="w-12 pe-2 mb-2">
                    <BwmSelect
                      disabled={props.disabled}
                      options={Constants.genderList || []}
                      name="gender"
                      label="Sesso"
                      className="form-control"
                      value={values.gender}
                      error={errors.gender}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'gender')}
                    />
                  </div>
                  <div className="col-3 col pe-2 mb-2">
                    <DateField
                      disabled={props.disabled}
                      label="Data di nascita"
                      date={values.birthDate}
                      error={errors.birthDate}
                      touched={props.touched}
                      onChange={(value) => handleChangeValue(value, 'birthDate')}
                    />
                  </div>
                  <div className="w-46 pe-2 mb-2">
                    {/* LOCALITÀ */}
                    <AutoCompileCustom
                      disabled={props.disabled}
                      label={
                        values.personType === Constants.PNF
                          ? 'Sede Legale (Località)'
                          : 'Comune di nascita'
                      }
                      id="location"
                      filter={props.locations}
                      value={values.location}
                      error={errors.location}
                      touched={props.touched}
                      handleInputAutocompile={(e) => handleInputAutocompile(e)}
                    />
                  </div>

                  <div className="w-10 mb-2">
                    <ProvinceField
                      disabled={props.disabled}
                      placeholder="EE per Estero"
                      label="Prov."
                      province={values.province}
                      error={errors.province}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'province', true, true)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={'w-45'}>
        <div className="configurazioneSID pt-3">
          <SidContactFormContainer
            id={props.subjectId || 0}
            disabled={props.disabled}
            errors={props.errorsSid}
            nameApplication={nameApplication}
            subject={props.subject}
            defaultEmail={props.defaultEmail}
            coraSubjectSid={props.coraSubjectSid}
            certificateFiles={certificateFiles}
            fiscalCode={values.fiscalCode}
            setCoraSubjectSid={props.setCoraSubjectSid}
            setErrorCertificates={(errorCertificates) =>
              props.setErrorsSid({ ...props.errorsSid, errorCertificates })
            }
            handleChangeSid={handleChangeSid}
            handleChangeSidValues={handleChangeSidValues}
            handleCertificateFilesCustom={handleCertificateFilesCustom}
          />
        </div>
      </div>
      <div className="form-row mt-2 row align-item-center">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
              onClick={() => props.close(true)}>
              <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
              &nbsp; ANNULLA
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <button
              type="submit"
              disabled={props.disabled}
              className="btn btn-primary btn-new-rel px-5 ml-2"
              onClick={() => handleFormSubmit()}>
              <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
              &nbsp; CONFERMA
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
