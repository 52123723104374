import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BtnEmpty } from './BtnEmpty'
import { BtnFill } from './BtnFill'

export function PopupDelete(props) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="close btn btn-outline-primary btn-sm"
                onClick={onClose}>
                <span aria-hidden="true" style={{ fontSize: '14px' }}>
                  X
                </span>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <Row className={'show-grid popup-title-delete text-center font-weight-bold'}>
              <Col md={12} lg={12} className="py-3">
                {props.title ? <h5>{props.title}</h5> : <h4>Elimina {props.operazione}</h4>}
              </Col>
            </Row>
            <Row className={'show-grid text-center'}>
              {props.text ? (
                <Col md={12} lg={12}>
                  <div className="text-center">{props.text}</div>
                </Col>
              ) : (
                ''
              )}

              <Col md={12} lg={12}>
                {props.list?.map((item, index) => (
                  <div key={index} className="text-center">
                    {item}
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="d-flex pt-5 justify-content-between">
              <BtnEmpty
                classCustom={'col me-3'}
                text={props.cancelText || 'ANNULLA'}
                handlerOnClick={onClose}
              />
              <BtnFill
                classCustom={'col d-flex justify-content-end'}
                text={props.confirmText || 'CONFERMA'}
                handlerOnClick={() => {
                  props.handleClickConfirm()
                  onClose()
                }}
              />
            </Row>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
