import { useEffect, useState } from 'react'
import { CommunicationStatus } from '../../../../config/CommunicationStatus'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { ReactComponent as IListFile } from '../../../../styles/images/svg/list.svg'
import { ReactComponent as ITriangle } from '../../../../styles/images/svgs/solid/exclamation-triangle.svg'
import { ReactComponent as IRotateRight } from '../../../../styles/images/svgs/solid/redo.svg'

import { downloadErrorReport } from '../CommunicationDownloadUtils'
import { formatDateForDisplay } from '../../../shared/Utility'

export function CommunicationErrorList(props) {
  const {
    communicationsInError,
    renderDeleteButton,
    downloadTxt,
    downloadDgn,
    reelaborateCommunicationTxt
  } = props
  const [allChecked, setAllChecked] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(true)
  const [checkedCommunications, setCheckedCommunications] = useState(new Map())

  //rivaluto la flag del bottone di eliminazione, e la flag di check delle righe
  useEffect(() => {
    if (checkedCommunications.size > 0) {
      setDisableDeleteButton(false)
    } else {
      setDisableDeleteButton(true)
    }
    setAllChecked(
      communicationsInError?.length !== 0 &&
        checkedCommunications.size === communicationsInError.length
    )
  }, [checkedCommunications])

  //resetto la lista di comunicazioni selezionate al ricaricamento della lista
  useEffect(() => {
    setCheckedCommunications(new Map())
  }, [communicationsInError])

  const handleAllChecked = (e) => {
    let checkedComms = new Map()
    if (e.target.checked) {
      setAllChecked(true)
      communicationsInError.forEach((element) => {
        checkedComms.set(element.id, element)
      })
    } else {
      setAllChecked(false)
    }
    setCheckedCommunications(checkedComms)
  }

  const handleCheckChange = (e, communication) => {
    let checkedComms = new Map(checkedCommunications)
    if (e.target.checked) {
      checkedComms.set(communication.id, communication)
    } else {
      checkedComms.delete(communication.id)
    }
    setAllChecked(checkedComms.size === communicationsInError.length)
    setCheckedCommunications(checkedComms)
  }

  const renderErrorRowHeader = () => {
    return (
      <>
        <div className="col w-5 text-center div-td">
          <input
            type="checkbox"
            checked={allChecked}
            onChange={(e) => {
              handleAllChecked(e)
            }}
          />
        </div>
        <div className="col w-10 div-td">Data creazione</div>
        <div className="col w-8 div-td">Periodo</div>
        <div className="col w-15 div-td">Comunicazione</div>
        <div className="col w-10 div-td">Tipologia di invio</div>
        <div className="col w-50 div-td">Stato</div>
        <div className="col w-10 div-td">Scarica</div>
      </>
    )
  }

  const renderErrorRow = (communication) => {
    let status = 'Errori presenti nella comunicazione'
    const createdWithError =
      communication?.communicationStatus === CommunicationStatus.CREATED_WITH_ERROR
    if (createdWithError) {
      status = status + ': cliccare sul pulsante giallo per scaricare gli errori in chiaro'
    }
    const isChecked = checkedCommunications.has(communication.id)
    return (
      <div key={`key-comm-history-error${communication.id}`}>
        <div
          className={`text-start row-table ${isChecked && 'bg-cobalt bold'}`}
          id={`row-error-check-${communication.id}`}>
          <div className="col w-5 text-center div-td">
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              id={`row-error-check-${communication.id}`}
              name="communicationErrorCheck"
              value={communication.id}
              checked={isChecked}
              onChange={(e) => handleCheckChange(e, communication)}
            />
          </div>
          <div className="col w-10 div-td text-truncate">
            {formatDateForDisplay(communication?.creationDate)}
          </div>
          <div className="col w-8 div-td text-truncate">{communication?.referencePeriod}</div>
          <div className="col w-15 div-td text-truncate">
            {communication?.communicationType?.code} -{' '}
            {communication?.communicationType?.description}
          </div>
          <div className="col w-10 div-td text-truncate">
            {communication?.dispatchType === 'ORDINARY' ? 'Ordinario' : 'Straordinario'}
          </div>
          <div className="col w-50 div-td text-truncate">{status}</div>
          <div className="col w-10 div-td d-flex justify-content-around pe-4 ps-4 containerPopOver">
            {createdWithError && (
              <span
                data-tip={'Scarica dettaglio errori'}
                onClick={() => downloadErrorReport(communication)}>
                <ITriangle width="25" fill="#e3c400" />
              </span>
            )}
            <span data-tip={'Scarica COMUNICAZIONE TXT'} onClick={() => downloadTxt(communication)}>
              <ITextFile width="20" fill="#128186" />
            </span>
            <div data-tip={'Scarica DIAGNOSTICO'}>
              <IListFile width="20" fill="#128186" onClick={() => downloadDgn(communication)} />
            </div>
            <span
              data-tip={'Rielabora FILE TXT'}
              onClick={() => reelaborateCommunicationTxt(communication)}>
              <IRotateRight width="20" fill="#128186" />
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {communicationsInError?.length > 0 && (
        <>
          <div className="row header-btn-history mb-2">
            <div className={'col-3'}>
              COMUNICAZIONI IN ERRORE &nbsp; &nbsp; &nbsp;
              {renderDeleteButton(disableDeleteButton, checkedCommunications)}
            </div>
          </div>
          <div className="text-start header-table error">{renderErrorRowHeader()}</div>

          {communicationsInError.map((e) => {
            return renderErrorRow(e)
          })}
        </>
      )}
    </>
  )
}
