import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tab from './Tab'

export const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.children[0]?.props.label)

  useEffect(() => {
    if (props.activeTab) setActiveTab(props.activeTab)
  }, [props.activeTab])

  const isPromise = (obj) => {
    return obj && typeof obj === 'object' && typeof obj.then === 'function'
  }

  const onClickTabItem = useCallback(
    (tab, idTab) => {
      const childProps = props.children[1]?.props

      if (childProps?.handleFormSubmitHolder) {
        childProps.handleFormSubmitHolder(tab)
      } else if (childProps?.handleCreateRelationship) {
        const result = childProps.handleCreateRelationship()
        if (isPromise(result)) {
          result.then((res) => {
            if (res) setActiveTab(tab)
          })
        } else {
          setActiveTab(tab)
        }
      } else {
        setActiveTab(tab)
      }

      if (props.setTab) {
        props.setTab(tab)
      }
    },
    [props.children, props.setTab]
  )

  return (
    <div className={`tabs  ${props.className}`}>
      <ul className="tab-list nav nav-tabs mt-2">
        {props.title}
        {props.children.map((child, index) => {
          const { label, icon, id, disabled } = child.props
          return (
            <Tab
              {...child.props}
              activeTab={activeTab}
              key={label}
              last={index === props.children.length - 1}
              icon={icon}
              idTab={id}
              disabled={disabled}
              onClick={onClickTabItem}
            />
          )
        })}
      </ul>
      <div className={`tab-content ${props.className || 'pt-3'}`}>
        {props.children.map((child, index) => {
          if (props.forceRender) {
            return (
              <div key={index} className={child.props.label !== activeTab ? 'tab-hide' : ''}>
                {child.props.children}
              </div>
            )
          } else {
            if (child.props.label !== activeTab) return null
            return child.props.children
          }
        })}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  setTab: PropTypes.func,
  className: PropTypes.string
}
