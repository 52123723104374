import { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { BtnEmpty } from '../../../shared/BtnEmpty'
import Row from 'react-bootstrap/Row'
import axiosService from '../../../../services/axios-service'
import { ReactComponent as IExport } from '../../../../styles/images/svgs/regular/file-export.svg'
import { BwmInput } from '../../../shared/form/BwmInput'
import { PopupError } from '../../../shared/PopupError'
import { isEmpty } from '../../../shared/Utility'

export function CoraSubjectsExportModal(props) {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseModal, setResponseModal] = useState('')
  const [responseKo, setResponseKo] = useState('')
  const [responseOk, setResponseOk] = useState('')
  const [emailAddress, setEmailAddress] = useState(props.checkedSubjects[0]?.customer?.user?.login)
  const [emailModified, setEmailModified] = useState(false)
  const [errorEmail, setErrorEmail] = useState({ isValid: true, msg: '' })

  const prevEmailAddressRef = useRef(emailAddress)

  useEffect(() => {
    const newEmailAddress = props.checkedSubjects[0]?.customer?.user?.login

    if (newEmailAddress && newEmailAddress !== prevEmailAddressRef.current) {
      setEmailAddress(newEmailAddress)
      prevEmailAddressRef.current = newEmailAddress // Update the ref to reflect the current state
    }
  }, [props.checkedSubjects])

  const closeModal = () => {
    setShowModal(false)
    setEmailAddress(props.checkedSubjects[0]?.customer?.user?.login)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const handlerEmail = (e) => {
    setErrorEmail({ isValid: true, msg: '' })
    setEmailAddress(e.target.value)
    setEmailModified(true)
  }

  const handlerValidation = () => {
    if (isEmpty(emailAddress)) {
      setErrorEmail({ isValid: false, msg: 'Campo obbligatorio' })
      return false
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddress)
    ) {
      setErrorEmail({ isValid: false, msg: 'Email non formalmente corretta' })
      return false
    }
    return true
  }

  const exportCoraSubjects = () => {
    if (handlerValidation()) {
      setLoading(true)
      const axiosInstance = axiosService.getInstance()
      let subjectsIds = props.checkedSubjects
        ? props.checkedSubjects?.map((r) => r.id)
        : props.checkedSubjects?.map((r) => r.id)
      axiosInstance({
        data: subjectsIds,
        url: `/api/crs/custom/cora-subjects/export/${emailAddress}`,
        method: 'POST'
      })
        .then(
          (response) => {
            if (response) {
              if (response?.status === 200) {
                closeModal()
                setResponseOk('OK')
                setResponseModal(true)
              } else {
                closeModal()
                setResponseKo("Si è verificato un errore durante l'esportazione dei dati.")
              }
            }
          },
          (err) => {
            closeModal()
            PopupError({
              text: "Si è verificato un errore durante l'esportazione dei dati."
            })
          }
        )
        .then(() => setLoading(false))
    }
  }

  let email =
    isEmpty(emailAddress) && !emailModified
      ? props.checkedSubjects[0]?.customer?.user?.login
      : emailAddress

  return (
    <>
      {loading ? <PageSpinner /> : ''}
      <button
        type="button"
        disabled={props.disabled}
        className={`btn btn-empty btn-sm ${props.disabled ? 'disabled' : ''}`}
        data-tip={'Esporta i soggetti selezionati'}
        onClick={openModal}>
        <IExport width="16" fill={` ${props.disabled ? '#FFFFFF' : '#128186'}`} />
        &nbsp; Esporta
      </button>
      <Modal
        backdrop="static"
        show={showModal}
        onHide={closeModal}
        dialogClassName="modal-40w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>ESPORTA SOGGETTI</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: '16px' }}>
              Attraverso questa funzione è possibile scaricare tutti i dati inerenti ai soggetti
              selezionati.
            </p>
          </div>
          <div className="row-detail comurei mt-2 d-flex radius">
            <div className="tab-content col-md-12 col-lg-12">
              <div className="form-row">
                <div className="col-md-8 col-lg-8 subDetailComurei">
                  <div className="row">
                    <div className="col-md-11 col-lg-11">
                      <BwmInput
                        id="emailAddress"
                        type="email"
                        className="form-control form-control-normal"
                        label="Indirizzo email a cui inviare il file di export"
                        value={email}
                        maxLength="100"
                        onChange={(e) => handlerEmail(e)}
                        error={errorEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-content-end mt-5">
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
            <button onClick={exportCoraSubjects} type="button" className="btn btn-new-rel px-5">
              ESPORTA
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {responseModal && (
        <Modal
          backdrop="static"
          show={responseModal}
          onHide={closeModal}
          dialogClassName="modal-70w"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="bar"></div>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2>INIZIO PROCEDURA ESPORTAZIONE SOGGETTI</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseOk ? (
              <>Esportazione dei soggetti avviata: riceverai i file al tuo indirizzo email.</>
            ) : (
              <div className="popup communication">
                <Row>
                  <>
                    <p className={'color-red'}>
                      Errore nell'esportazione dei soggetti {responseKo}
                    </p>
                  </>
                </Row>
              </div>
            )}
            <div className="d-flex justify-content-between align-content-end mt-5">
              <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
              <button onClick={() => closeModal()} type="button" className="btn btn-new-rel px-5">
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
