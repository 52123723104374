import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BtnEmpty } from './BtnEmpty'

export function PopupSuccess(props) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <>
          <div className="bar"></div>
          <div className="popup modal-content">
            <div className="w-100 d-flex justify-content-end">
              <button
                type="button"
                className="close btn btn-outline-primary btn-sm"
                onClick={onClose}>
                <span aria-hidden="true" style={{ fontSize: '14px' }}>
                  X
                </span>
                <span className="visually-hidden">Close</span>
              </button>
            </div>
            <Row className={'show-grid popup-title-success text-center'}>
              <Col md={12} lg={12}>
                <img alt={''} src="" />
              </Col>
              <Col md={12} lg={12} className="py-3">
                <h5>{props?.title || 'Operazione completata con successo'}</h5>
              </Col>
            </Row>
            <Row className={'show-grid text-center'}>
              <Col md={12} lg={12} dangerouslySetInnerHTML={{ __html: props?.text }}></Col>
              {props?.dataList && props?.dataList?.length > 0 ? (
                <Col md={12} lg={12}>
                  <div className={'h300Scroll'}>
                    {props.dataList.map((data, index) => {
                      return <p key={index}>{data}</p>
                    })}
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <div className="pt-5 text-center d-flex justify-content-center">
              <BtnEmpty
                text={props?.ok || 'OK, CHIUDI'}
                handlerOnClick={() => {
                  if (props?.handleClickConfirm) {
                    props.handleClickConfirm()
                  }
                  onClose()
                }}
              />
            </div>
          </div>
        </>
      )
    },
    closeOnClickOutside: false
  })
}
