import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import * as Constants from '../../../../config/Constants'
import 'react-datepicker/dist/react-datepicker.css'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { Tabs } from './Tabs'
import BalanceForm from './BalanceForm'
import * as actions from '../../../../actions'
import { DelegateForm } from './DelegateForm'
import { DateField } from '../../../shared/form/DateFieldB5'
import { PersonTypeField } from './PersonTypeField'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import '../../../../styles/autocomplete.css'
import * as Utility from '../../../shared/Utility'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import CodiceFiscale from 'codice-fiscale-js'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { PopupError } from '../../../shared/PopupError'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { RankedDropdownList } from '../../../shared/form/RankedDropdownList'
import { isNotEmpty } from '../../../shared/Utility'
import { endOfDay } from 'date-fns'
import { Col, Row } from 'react-bootstrap'
import { ICopy, ISave, IUndo, IUnlock } from '../../../../styles/icons'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'
import { Formik, Form, useFormikContext } from 'formik'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import * as Yup from 'yup'

registerLocale('it', it)

const FIRST_TAB = 'Rapporto'
const SECOND_TAB = 'Titolare Eff. e Delegato'
const THIRD_TAB = 'Saldi Annuali'

export const RelationshipForm = (props) => {
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  const [activeTab, setActiveTab] = useState(FIRST_TAB)

  const relationshipTypes = useSelector((state) => state.relationshipTypes.data)
  const rankedrelationshipTypes = useSelector((state) => state.rankedRelationshipTypes.data)

  const [showCab, setShowCab] = useState(false)
  const [touched, setTouched] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [endDateDisabled, setEndDateDisabled] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  const setNewInitialValues = (data = {}) => {
    setShowCab(Constants.codRelTypeCab.indexOf(data.relationshipType?.code) !== -1)
    setShowDescription(data.relationshipType?.code === '99')
    if (data?.relationshipType?.code === '98') {
      setEndDateDisabled(data?.relationshipType?.code === '98')
      data.endDate = null
    }
    data.relationshipType =
      data.relationshipType || rankedrelationshipTypes[0] || relationshipTypes[0]

    setInitialValues({
      id: data.id,
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.endDate ? new Date(data.endDate) : null,
      relationshipType: {
        id: data.relationshipType?.id,
        code: data.relationshipType?.code,
        description: data.relationshipType?.description
      },
      coraSubject: { id: props.subjectId },
      coraRole: { id: '1' },
      cab: Constants.codRelTypeCab.indexOf(data?.relationshipType?.code) !== -1 ? data.cab : '',
      description: data?.relationshipType?.code === '99' ? data.description : '',
      personType: data?.personType || Constants.PNF,
      fiscalCode: data.fiscalCode || '',
      companyName: data.companyName || '',
      location: data.location || '',
      province: data.province || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      gender: data.gender || 'FEMALE',
      birthDate: data.birthDate ? new Date(data.birthDate) : null,
      comment: data.comment || '',
      identifier: data.identifier || '',
      includedInNextCommunication: data.includedInNextCommunication !== false,
      coraCommunicationType: {
        id: data.coraCommunicationType?.id || '1'
      },
      codError: data.codError,
      idResult: data.idResult || null,
      lastCommunicationError: data.lastCommunicationError,
      coraRelationDelegates: data.coraRelationDelegates || [],
      canceledAndCommunicated: data.canceledAndCommunicated,
      showDescription,
      showCab,
      endDateDisabled,
      errorFiscalCode: false
    })
  }

  const retrieveSubject = () => {
    setActiveTab(FIRST_TAB)
    setLoading(true)
    if (props.subjectId && props.id) {
      actions.getCoraRelationshipBySubjectIdAndId(props.subjectId, props.id).then((data) => {
        setNewInitialValues(data)
        setLoading(false)
      })
    } else {
      setNewInitialValues()
      setLoading(false)
    }
  }

  useEffect(() => {
    retrieveSubject()
  }, [props.id])

  const sameMonthYear = (startDate, endDate) => {
    return (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    )
  }

  const canCloseReport = (endDateDisabled, commType) => {
    return !(endDateDisabled && commType === 2)
  }

  const validationSchema = Yup.object().shape({
    startDate: Yup.date('Inserire una data valida')
      .required('Campo obbligatorio')
      .test(
        'is-valid-date',
        'Inserire una data valida',
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    endDate: Yup.date('Inserire una data valida')
      .nullable()
      .min(Yup.ref('startDate'), 'La data fine non può essere minore della data inizio')
      .test(
        'same-month-year',
        'Data di fine non valida per comunicazione nuovo rapporto',
        function (value) {
          const { startDate, coraCommunicationType } = this.parent
          if (!startDate) return false
          if (coraCommunicationType?.id === '1' && value) {
            return sameMonthYear(new Date(startDate), new Date(value))
          }
          return true
        }
      )
      .test('can-close', 'Chiusura non ammessa per rapporti di tipo 98', function (value) {
        const { endDateDisabled, coraCommunicationType } = this.parent
        return canCloseReport(endDateDisabled, coraCommunicationType?.id)
      })
      .test(
        'is-valid-date',
        'Inserire una data valida',
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    cab: Yup.string().when('showCab', (data, schema) => {
      if (data[0]) {
        return schema.required('Campo obbligatorio')
      }
      return
    }),
    description: Yup.string().when('showDescription', (data, schema) => {
      if (data[0]) {
        return schema.required('Campo obbligatorio')
      }
      return
    }),
    personType: Yup.string().required('Selezionare il tipo di persona'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date('Inserire una data valida').when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required('Campo obbligatorio')
          .test(
            'is-valid-date',
            'Inserire una data valida',
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().nullable(),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    relationshipType: Yup.object().shape({
      id: Yup.string().required('Campo obbligatorio'),
      code: Yup.string(),
      description: Yup.string()
    }),
    coraCommunicationType: Yup.object().shape({
      id: Yup.string().required('Campo obbligatorio')
    }),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: 'Codice Fiscale'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Denominazione'
    },
    {
      errorKey: 'location',
      errorLabel: 'Sede legale'
    },
    {
      errorKey: 'province',
      errorLabel: 'Provincia'
    },
    {
      errorKey: 'gender',
      errorLabel: 'Sesso'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'coraCommunicationType.id',
      errorLabel: 'Tipo Comunicazione'
    },
    {
      errorKey: 'cab',
      errorLabel: 'CAB'
    },
    {
      errorKey: 'startDate',
      errorLabel: 'Data inizio'
    },
    {
      errorKey: 'endDate',
      errorLabel: 'Data fine'
    }
  ]

  const dispatch = useDispatch()

  useEffect(() => {
    setIsMounted(true)
    if (!relationshipTypes?.length) {
      dispatch(actions.getRelationshipTypes())
    }
    dispatch(actions.getRankedRelationshipTypes(props.subject.id))

    return () => {
      setIsMounted(false)
    }
  }, [])

  // i rapporti cancellati e comunicati in precedenza devono tornare visibili sulla lista principale in caso di modifica
  useEffect(() => {
    if (initialValues.id) {
      setInitialValues({
        ...initialValues,
        personType: initialValues.personType || Constants.PNF,
        canceledAndCommunicated: false
      })
    }
  }, [
    initialValues.startDate,
    initialValues.endDate,
    initialValues.cab,
    initialValues.description,
    initialValues.personType,
    initialValues.fiscalCode,
    initialValues.companyName,
    initialValues.location,
    initialValues.lastName,
    initialValues.firstName,
    initialValues.gender,
    initialValues.birthDate,
    initialValues.comment,
    initialValues.includedInNextCommunication,
    initialValues.identifier,
    initialValues.relationshipType,
    initialValues.coraCommunicationType
  ])

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = 'Luogo di nascita'
    else formErrorsTabMapping[4].errorLabel = 'Sede legale (Comune)'
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    isMounted && (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        context={{ showCab, showDescription, endDateDisabled }}
        validationSchema={validationSchema}>
        {({ values, errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : 'form-on-bottom'}>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert &&
              errors &&
              touched &&
              typeof errors === 'object' &&
              Object.keys(errors).length > 0 && (
                <div className={props.formOnBottom ? 'cora-relationship-form-on-bottom-error' : ''}>
                  <ErrorListAlert
                    errors={getErrors(errors, values.personType)}
                    hide={() => setShowErrorAlert(false)}
                  />
                </div>
              )}
            <FormBody
              {...props}
              loading={loading}
              initialValues={initialValues}
              touched={touched}
              activeTab={activeTab}
              relationshipTypes={relationshipTypes}
              rankedrelationshipTypes={rankedrelationshipTypes}
              endDateDisabled={endDateDisabled}
              showCab={showCab}
              showDescription={showDescription}
              setTouched={setTouched}
              setLoading={setLoading}
              setNewInitialValues={setNewInitialValues}
              setShowCab={setShowCab}
              setActiveTab={setActiveTab}
              setShowDescription={setShowDescription}
              setEndDateDisabled={setEndDateDisabled}
              setShowErrorAlert={setShowErrorAlert}
              retrieveSubject={retrieveSubject}
            />
          </Form>
        )}
      </Formik>
    )
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [disableIdentifierInput, setDisableIdentifierInput] = useState(true)
  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const handlePersonTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    form.personType = val
    setValues(form)
  }

  const handleInputAutocompile = (location) => {
    const form = Object.assign({}, values)
    if (!location || location === '') {
      form.location = location
      setValues(form)
    }
    if (form.province?.toUpperCase() === 'EE') {
      form.location = location?.toUpperCase() || ''
      form.province = form.province?.toUpperCase() || ''
      return
    }
    actions.getLocation(location?.toUpperCase()).then(
      (loc) => {
        if (loc) {
          form.province = loc.province
          form.location = location?.toUpperCase() || ''
          setValues(form)
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
    form.location = location?.toUpperCase() || ''
    setValues(form)
  }

  const handlerChangeRelationshipType = (val) => {
    const form = Object.assign({}, values)
    const relationshipType = props.relationshipTypes.find(
      (el) => el.id?.toString() === val?.toString()
    )
    const cod = relationshipType?.code
    form.relationshipType = relationshipType
    resetTypeDependentFields(setValues, values)

    if (Constants.codRelTypeCab.indexOf(cod) !== -1) {
      props.setShowCab(true)
    } else if (cod === '98') {
      form.endDate = null
      props.setEndDateDisabled(true)
    } else if (cod === '99') {
      props.setShowDescription(true)
    }
    setValues(form)
  }

  const saveAndSetRelationship = (newValues, onSuccess = () => {}) => {
    const formData = Object.assign(newValues, {})
    delete formData.setShowCab
    delete formData.setShowDescription
    delete formData.endDateDisabled
    props.setLoading(true)
    actions.createCoraRelationship(formData).then(
      (res) => {
        if (res?.constructor === Object) props.setNewInitialValues(res)
        props.getRelationships(props.subjectId)
        PopupSuccess({ text: 'Salvataggio effettuato' })
        onSuccess()
        close(formData.id)
        props.setLoading(false)
      },
      (errors) => {
        if (errors.errorKey && errors.title) {
          PopupError({ text: errors.title })
        } else {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
        props.setLoading(false)
        return false
      }
    )
  }

  const updateRelationship = (formData, onSuccess) => {
    formData.lastCommunicationError = null
    delete formData.endDateDisabled
    delete formData.showDescription
    delete formData.showCab
    delete formData.coraRelationDelegates
    props.setLoading(true)
    actions.updateCoraRelationship(formData).then(
      () => {
        PopupSuccess({ text: 'Salvataggio effettuato' })
        onSuccess()
        close(formData.id)
        props.setLoading(false)
      },
      (errors) => {
        if (errors.errorKey && errors.title) {
          PopupError({ text: errors.title })
        } else {
          PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
        }
        props.setLoading(false)
      }
    )
  }

  const close = (retrieve = false, id) => {
    props.setTouched(false)
    if (retrieve) props.retrieveSubject()
    else props.getRelationships(props.subjectId)
    if (props.close) props.close(props.subjectId ? null : id)
  }

  const prepareClonedRelationship = (values) => {
    let cloned = JSON.parse(JSON.stringify(values))
    let cleanDelegates = []
    for (let delegate of values.coraRelationDelegates || []) {
      cleanDelegates.push({
        ...delegate,
        id: null
      })
    }

    cloned.id = null
    actions.createCoraRelationship(cloned).then(
      (res) => {
        if (cleanDelegates.length > 0) {
          let promises = []
          for (let index = 0; index < cleanDelegates.length; index++) {
            const delegate = cleanDelegates[index]
            delegate.coraRelationship = { id: res.id }
            promises.push(actions.createCoraDelegate(delegate))
          }
          Promise.all(promises).then(() => {
            props.getRelationships(props.subjectId)
            PopupSuccess({
              title: 'Rapporto clonato con successo , il rapporto clonato è aperto nel dettaglio'
            })
            close()
            props.onClone(res.id)
          })
        } else {
          props.getRelationships(props.subjectId)
          PopupSuccess({
            title: 'Rapporto clonato con successo, il rapporto clonato è aperto nel dettaglio'
          })
          close()
          props.onClone(res.id)
        }
      },
      (err) => {
        PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
      }
    )
  }

  const handlerChange = (val, name) => {
    const form = Object.assign({}, values)
    if (name === 'includedInNextCommunication') {
      form.includedInNextCommunication = val
    } else if (name === 'coraCommunicationType') {
      val = val && val !== '' ? parseInt(val.trim()) : null
      form.coraCommunicationType = { id: val }
      form.includedInNextCommunication = true
    }
    setValues(form)
  }

  const handlerChangeEndDate = (val) => {
    const form = Object.assign({}, values)
    form.endDate = val
    setValues(form)
    handleEndDateLogic(val, setValues, values)
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const resetTypeDependentFields = () => {
    const form = Object.assign({}, values)
    props.setShowCab(false)
    form.cab = null
    props.setShowDescription(false)
    props.setEndDateDisabled(false)
    setValues(form)
  }

  const handleEndDateLogic = (endDate) => {
    const form = Object.assign({}, values)
    let today = isNotEmpty(delayedCommunicationDate)
      ? new Date(delayedCommunicationDate)
      : new Date()
    //datemin = inizio mese precedente; datemax:fine mese precedente
    let endDateEndOfDay = endOfDay(endDate) //utilizzo endOfDay di date-fns per prevenire casistiche in cui l'ora di endDate sia successiva all'ra di dateMax
    let dateMin = endOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1))
    let dateMax = endOfDay(new Date(today.getFullYear(), today.getMonth(), 0))
    // Se la data selezionata è tra datemin e datemax-> tipo comunicazione 5-Chiusura nei termini
    if (endDateEndOfDay >= dateMin && endDateEndOfDay <= dateMax) {
      form.coraCommunicationType = { id: '2' }
      form.includedInNextCommunication = true
      setValues(form)
    } else if (endDateEndOfDay < dateMin) {
      //se la data selezionata è precedente al mese precedente -> tipo comunicazione 2=chiusura tardiva
      form.coraCommunicationType = { id: '3' }
      form.includedInNextCommunication = true
    }
    form.endDate = endDate
    setValues(form)
  }

  const testForSimilarCoraRelationship = (values, onConfirm = () => {}, onCancel = () => {}) => {
    return actions.testForSimilarCoraRelationship(values, props.subjectId).then((res) => {
      if (res) {
        PopupConfirm({
          titleColor: titleColors.ALERT,
          title: 'RILEVATO RAPPORTO POTENZIALMENTE DUPLICATO',
          component: () => {
            return (
              <>
                <Row>
                  <Col className="text-center">
                    <strong> Stessa data inizio, stesso soggetto, stesso tipo rapporto</strong>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    Ricordiamo che per chiudere (o aggiornare) rapporti già comunicati basta
                    selezionare il rapporto dall'elenco e indicare SOLO la data di chiusura (non
                    deve essere creato un nuovo rapporto) e impostare poi il tipo comunicazione
                    5=chiusura nei termini o 2=chiusura tardiva o aggiornamento.
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <strong>
                      {' '}
                      Se il rapporto NON è duplicato, premere CONFERMA, altrimenti CHIUDI SENZA
                      SALVARE e il rapporto NON verrà registrato.
                    </strong>
                  </Col>
                </Row>
              </>
            )
          },
          confirm: 'CHIUDI SENZA SALVARE',
          cancel: 'CONFERMA',
          handleClickConfirm: onConfirm,
          onClickCancel: onCancel
        })
      }
    })
  }

  const handleFiscalCode = (val) => {
    const form = Object.assign({}, values)
    actions
      .getBaseRegistryPersonByFiscalCode(val)
      .then(
        (baseRegistry) => {
          if (isNotEmpty(baseRegistry)) {
            if (baseRegistry.personType === Constants.PNF) {
              form.companyName = baseRegistry?.companyName || form.companyName
              form.location = baseRegistry?.location || form.location
              form.province = baseRegistry?.province || form.province
            } else if (baseRegistry?.personType === Constants.PF) {
              form.lastName = baseRegistry?.lastName || form.lastName
              form.firstName = baseRegistry?.firstName || form.firstName
              form.birthDate = baseRegistry?.birthDate
                ? new Date(baseRegistry?.birthDate)
                : form.birthDate
              form.gender = baseRegistry?.gender || form.gender
              form.location = baseRegistry?.location?.location || form.location
              form.province = baseRegistry?.location?.province || form.province
            }
            form.errorFiscalCode = false
            setValues(form)
          }
        },
        () => {
          if (form.personType === Constants.PF) {
            try {
              const cf = new CodiceFiscale(val).toJSON()
              if (cf) {
                form.birthDate = cf?.birthday ? new Date(cf?.birthday) : null
                form.gender = cf?.gender === 'M' ? 'MALE' : form.gender
                form.location = cf?.birthplace.toUpperCase() || form.location
                form.province = cf?.birthplaceProvincia || form.province
              }
              form.errorFiscalCode = false
            } catch (e) {
              form.errorFiscalCode = true
            }
            setValues(form)
          }
        }
      )
      .then(() => {
        testForSimilarCoraRelationship(values, close)
      })
  }

  const onTabChange = async (tab) => {
    const errors = await validateForm(values)
    if (Object.keys(errors).length > 0) {
      const allTouched = Object.keys(values).reduce((acc, key) => {
        acc[key] = true // Imposta ogni campo come "touched"
        return acc
      }, {})

      props.setTouched(allTouched)
      props.setShowErrorAlert(true)
      return false
    } else {
      props.setShowErrorAlert(false)
      props.setActiveTab(tab)
      if (!values?.id) {
        saveAndSetRelationship(values)
        return true
      }
      return true
    }
  }

  const handleFormSubmit = async () => {
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false)
      const newValues = Object.assign({}, values)
      newValues.idResult = props.idResult
      if (newValues.personType === Constants.PF) {
        newValues.gender = 'FEMALE'
        newValues.companyName = null
        newValues.location = null
      } else if (newValues.personType === Constants.PNF) {
        newValues.gender = null
        newValues.firstName = null
        newValues.lastName = null
        newValues.birthDate = null
        newValues.location = null
      }
      if (!newValues?.id) {
        saveAndSetRelationship(newValues, close)
      } else {
        updateRelationship(newValues, close)
      }
    }
  }

  return (
    <div>
      <div style={{ height: '320px' }}>
        <input
          defaultValue={values?.identifier || ''}
          className="form-control"
          name="identifier"
          hidden={true}
        />

        <div className="form-row">
          <div className="col-6 pe-3">
            <div className="panel py-1 px-2">
              <div className="mb-2">
                <RankedDropdownList
                  name="relationshipType"
                  label={'Tipo di Rapporto'}
                  list={props.relationshipTypes}
                  rankedElements={props.rankedrelationshipTypes}
                  selectedValue={values.relationshipType?.id}
                  error={errors.relationshipType}
                  touched={props.touched}
                  onChange={(e) => handlerChangeRelationshipType(e.target.value)}
                />
              </div>
              <div className="d-flex w-100">
                <div className="col-4 pe-2">
                  <DateField
                    disabled={props.disabled}
                    label={'Data inizio rapporto'}
                    date={values.startDate}
                    error={errors.startDate}
                    touched={props.touched}
                    onChange={(date) => handleChangeValue(date, 'startDate')}
                  />
                </div>
                {!props.endDateDisabled && (
                  <div className="col-4 pe-2">
                    <DateField
                      disabled={props.disabled || props.endDateDisabled}
                      label={'Data fine rapporto'}
                      date={values.endDate}
                      error={errors.endDate}
                      touched={props.touched}
                      onChange={(date) => handlerChangeEndDate(date)}
                    />
                  </div>
                )}
                {props.showCab && (
                  <div className="small col">
                    <BwmInput
                      name="cab"
                      label="CAB"
                      className="form-control"
                      maxLength="5"
                      value={values.cab}
                      error={errors.cab}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'cab')}
                    />
                  </div>
                )}
                {props.showDescription && (
                  <div className="small col">
                    <BwmInput
                      name="description"
                      label="Descrizione"
                      className="form-control"
                      maxLength="24"
                      value={values.description}
                      error={errors.description}
                      touched={props.touched}
                      onChange={(e) => handleChangeValue(e.target.value, 'description', true)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="panel col-6 d-flex flex-column py-1 px-2">
            <div className="row d-flex">
              <div className="col-8 pe-2">
                <BwmSelect
                  options={props?.communicationTypes || []}
                  name="coraCommunicationType"
                  label="Tipo Comunicazione"
                  className="form-control"
                  showCode={true}
                  value={values.coraCommunicationType?.id}
                  error={errors.coraCommunicationType}
                  touched={props.touched}
                  onChange={(e) => handlerChange(e.target.value, 'coraCommunicationType')}
                />
              </div>
              <div className="col-4 pt-3">
                <div>
                  <BwmCheckbox
                    name={`includedInNextCommunication_${values?.id}`}
                    label="Incluso nella prossima comunicazione"
                    className="form-control"
                    checked={values.includedInNextCommunication}
                    error={errors.includedInNextCommunication}
                    touched={props.touched}
                    onChange={(e) => handlerChange(e.target.checked, 'includedInNextCommunication')}
                  />
                </div>
              </div>
            </div>
            <div>
              {values?.identifier && (
                <div className="w-100 mt-2">
                  <BwmInput
                    name="identifier"
                    label="Codice Univoco"
                    className="form-control"
                    Icon={() => {
                      if (disableIdentifierInput) {
                        return (
                          <IUnlock
                            fill={'#128186'}
                            className={'padding-bottom-4px svg'}
                            width="25"
                          />
                        )
                      } else {
                        return null
                      }
                    }}
                    iconTip={disableIdentifierInput ? 'Clicca per abilitare le modifiche' : ''}
                    maxLength="50"
                    value={values.identifier}
                    error={errors.identifier}
                    touched={props.touched}
                    disabled={disableIdentifierInput}
                    onIconClick={() => disableIdentifierInput && setDisableIdentifierInput(false)}
                    onChange={(e) => handleChangeValue(e.target.value, 'identifier', true, true)}
                  />
                </div>
              )}
            </div>
            {values.canceledAndCommunicated && (
              <div className="justify-content-center pt-2">
                <Col className="text-danger" style={{ fontSize: '1.1rem' }}>
                  <strong> ULTIMA COMUNICAZIONE CANCELLAZIONE</strong>
                </Col>
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="tab-content relationship col-md-12 col-lg-12">
            <Tabs activeTab={props.activeTab} className="bg-white tabs-sm">
              <div
                id="tab-rel"
                label={FIRST_TAB}
                handleCreateRelationship={() => onTabChange(FIRST_TAB)}>
                <div className="form-row p-1 pt-3 ps-2">
                  <div className="w-10 w-lg-15">
                    <PersonTypeField
                      disabled={props.disabled}
                      key={props.index}
                      id={props.index}
                      personType={values.personType}
                      error={errors.personType}
                      touched={props.touched}
                      onClick={(e) => handlePersonTypeClick(e.target.value)}
                    />
                  </div>
                  <div
                    id="infoPnf"
                    className={'col-7' + (values.personType === Constants.PNF ? '' : ' d-none')}>
                    <div className="row">
                      <div className="col-4 pe-2 mb-2">
                        <BwmInput
                          disabled={props.disabled}
                          name="fiscalCode"
                          label="Codice Fiscale"
                          className="form-control"
                          maxLength="16"
                          value={values.fiscalCode}
                          error={errors.fiscalCode || errors.errorFiscalCode}
                          touched={props.touched}
                          onBlur={(e) => handleFiscalCode(e.target.value)}
                          onChange={(e) =>
                            handleChangeValue(e.target.value, 'fiscalCode', true, true)
                          }
                        />
                      </div>
                      <div className="col-8 mb-2 pe-4">
                        <BwmInput
                          disabled={props.disabled}
                          name="companyName"
                          label="Denominazione"
                          className="form-control font-weight-bold font-size-big"
                          maxLength="60"
                          value={values.companyName}
                          error={errors.companyName}
                          touched={props.touched}
                          onChange={(e) =>
                            handleChangeValue(e.target.value, 'companyName', true, true)
                          }
                        />
                      </div>
                      <div className="col-md-5 col-lg-5 pe-2">
                        <AutoCompileCustom
                          disabled={props.disabled}
                          label="Sede Legale (Comune) "
                          id="location"
                          filter={props.relationshipLocations}
                          value={values.location}
                          error={errors.location}
                          touched={props.touched}
                          handleInputAutocompile={(e) => handleInputAutocompile(e)}
                        />
                      </div>
                      <div className="col-md-3 col-lg-3">
                        <ProvinceField
                          disabled={props.disabled}
                          label="Prov."
                          placeholder="EE per Estero"
                          province={values.province}
                          error={errors.province}
                          touched={props.touched}
                          onChange={(e) => handleChangeValue(e.target.value, 'province', true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="infoPf"
                    className={
                      'col-md-7 col-lg-7' + (values.personType === Constants.PF ? '' : ' d-none')
                    }>
                    <div className="form-row col-md-12 col-lg-12 mb-2">
                      <div className="col-4 pe-2">
                        <BwmInput
                          disabled={props.disabled}
                          name="fiscalCode"
                          label="Codice Fiscale"
                          className="form-control"
                          maxLength="16"
                          value={values.fiscalCode}
                          error={errors.fiscalCode || errors.errorFiscalCode}
                          touched={props.touched}
                          onBlur={(e) => handleFiscalCode(e.target.value)}
                          onChange={(e) =>
                            handleChangeValue(e.target.value, 'fiscalCode', true, true)
                          }
                        />
                      </div>

                      <div className="col-4 pe-2">
                        <BwmInput
                          disabled={props.disabled}
                          name="lastName"
                          label="Cognome"
                          className="form-control font-weight-bold font-size-big"
                          maxLength="26"
                          value={values.lastName}
                          error={errors.lastName}
                          touched={props.touched}
                          onChange={(e) => handleChangeValue(e.target.value, 'lastName', true)}
                        />
                      </div>
                      <div className="col-4 pe-2">
                        <BwmInput
                          disabled={props.disabled}
                          name="firstName"
                          label="Nome"
                          className="form-control font-weight-bold font-size-big"
                          maxLength="25"
                          value={values.firstName}
                          error={errors.firstName}
                          touched={props.touched}
                          onChange={(e) => handleChangeValue(e.target.value, 'firstName', true)}
                        />
                      </div>
                    </div>
                    <div className="form-row col-md-12 col-lg-12">
                      <div className="col-2 pe-2">
                        <BwmSelect
                          disabled={props.disabled}
                          options={Constants.genderList}
                          name="gender"
                          label="Sesso"
                          className="form-control"
                          value={values.gender}
                          error={errors.gender}
                          touched={props.touched}
                          onChange={(e) => handleChangeValue(e.target.value, 'gender', true)}
                        />
                      </div>
                      <div className="col-2 pe-2">
                        <DateField
                          disabled={props.disabled}
                          name={'birthDate'}
                          label={'Data di Nascita'}
                          date={values.birthDate}
                          error={errors.birthDate}
                          touched={props.touched}
                          onChange={(date) => handleChangeValue(date, 'birthDate')}
                        />
                      </div>
                      <div className="col-4 pe-2">
                        <AutoCompileCustom
                          disabled={props.disabled}
                          label="Comune di Nascita"
                          id="location"
                          filter={props.relationshipLocations}
                          value={values.location}
                          error={errors.location}
                          touched={props.touched}
                          handleInputAutocompile={(e) => handleInputAutocompile(e)}
                        />
                      </div>
                      <div className="col-3">
                        <ProvinceField
                          disabled={props.disabled}
                          label="Prov."
                          placeholder="EE per Estero"
                          province={values.province}
                          error={errors.province}
                          touched={props.touched}
                          onChange={(e) => handleChangeValue(e.target.value, 'province', true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <FloatingLabel className="w-100" label={'Note'}>
                      <textarea
                        disabled={props.disabled}
                        name="comment"
                        className={'form-control custom-text-area'}
                        rows={3}
                        cols={12}
                        value={values.comment}
                        maxLength={255}
                        onChange={(e) => handleChangeValue(e.target.value, 'comment')}
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              <div
                handleCreateRelationship={() => onTabChange(SECOND_TAB)}
                id="tab-tit"
                className="d-none"
                label={SECOND_TAB}>
                <DelegateForm
                  disabled={props.disabled}
                  key={props.index}
                  relationship={values}
                  startDate={values.startDate}
                  endDate={values.endDate}
                  relationshipProvinces={props.relationshipProvinces}
                  relationshipLocations={props.relationshipLocations}
                  roles={props.roles}
                  subjectId={props.subjectId}
                  getRelationships={props.getRelationships}
                  updateDelegates={(delegatesArr) => {
                    values.coraRelationDelegates = delegatesArr
                    values.canceledAndCommunicated = false
                    setValues(values)
                  }}
                />
              </div>
              <div
                handleCreateRelationship={(e) => onTabChange(THIRD_TAB)}
                id="tab-saldi"
                className="d-none"
                label={THIRD_TAB}>
                <BalanceForm
                  disabled={props.disabled}
                  key={props.index}
                  relationship={values}
                  currencies={props.currencies}
                  subjectId={props.subjectId}
                  onSave={() => {
                    values.canceledAndCommunicated = false
                    setValues(values)
                  }}
                />
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="form-row mt-2 row align-item-center">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              disabled={props.disabled}
              type="button"
              className="btn btn-empty px-4 btn-sm me-4"
              onClick={() => close(true)}>
              <IUndo className={'padding-bottom-4px svg'} width="16" fill={'#128186'} />
              &nbsp; ANNULLA
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <button
              disabled={props.disabled}
              type="button"
              className={'btn btn-empty me-2'}
              hidden={!props.subjectId}
              onClick={() => prepareClonedRelationship()}>
              <ICopy className={'padding-bottom-4px svg'} width="16" fill={'#128186'} />
              &nbsp; CLONA RAPPORTO
            </button>
            <button
              disabled={props.disabled}
              type="submit"
              onClick={() => handleFormSubmit()}
              className="btn btn-new-rel px-5">
              <ISave className={'padding-bottom-4px svg'} width="16" fill={'#FFFFFF'} />
              &nbsp; CONFERMA
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
