import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import * as Constants from '../../../../config/Constants'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import * as Utility from '../../../shared/Utility'
import CodiceFiscale from 'codice-fiscale-js'
import { isEmpty, isNotEmpty } from '../../../shared/Utility'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

export const HolderForm = forwardRef((props, ref) => {
  const [initialValues, setInitialValues] = useState({})
  const holderFormBodyRefData = useRef()

  const [relationshipHolderTypes, setRelationshipHolderTypes] = useState([])

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      return await holderFormBodyRefData.current.handleValidation()
    },
    getHolderType() {
      return holderFormBodyRefData.current.getHolderType()
    },
    getPersonType() {
      return holderFormBodyRefData.current.getPersonType()
    },
    getData() {
      return holderFormBodyRefData.current.getData()
    }
  }))

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.relationship?.id || null,
      personType: props.relationship?.personType || Constants.PNF,
      fiscalIdentifier: props.relationship?.fiscalIdentifier || '',
      companyName: props.relationship?.companyName || '',
      completeAddress: props.relationship?.completeAddress || '',
      countryCode: props.relationship?.countryCode || 'IT',
      country: props.relationship?.country || 'ITALIA (REPUBBLICA ITALIANA)',
      holderType: props.relationship?.holderType?.id || '',
      holderTypeCode: props.relationship?.holderType?.code || '',
      disabledHolderType: props.relationship?.personType === Constants.PF ? true : false,
      identifier: props.relationship?.identifier || '',
      lastName: props.relationship?.lastName || '',
      firstName: props.relationship?.firstName || '',
      birthDate: props.relationship?.birthDate ? new Date(props.relationship?.birthDate) : null,
      birthLocation: props.relationship?.birthLocation || '',
      birthProvince: props.relationship?.birthProvince || '',
      birthCountry: props.relationship?.birthCountry || 'ITALIA (REPUBBLICA ITALIANA)',
      errorFiscalCode: false
    })
  }

  useEffect(() => {
    getRelationshipHolderTypes()
    setNewInitialValues()
  }, [])

  useEffect(() => {
    setNewInitialValues()
  }, [props.relationship.id, props.forceReload])

  const getRelationshipHolderTypes = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get('/api/crs/crs-holder-types').then((res) => {
      setRelationshipHolderTypes(res.data)
    })
  }

  const formErrorsMapping = [
    {
      errorKey: 'personType',
      errorLabel: 'Tipo'
    },
    {
      errorKey: 'identifier',
      errorLabel: 'Identificativo rapporto'
    },
    {
      errorKey: 'fiscalIdentifier',
      errorLabel: 'TIN (ID Fiscale)'
    },
    {
      errorKey: 'holderType',
      errorLabel: 'Tipologia titolare'
    },
    {
      errorKey: 'companyName',
      errorLabel: 'Denominazione'
    },
    {
      errorKey: 'firstName',
      errorLabel: 'Nome'
    },
    {
      errorKey: 'lastName',
      errorLabel: 'Cognome'
    },
    {
      errorKey: 'birthDate',
      errorLabel: 'Data di nascita'
    },
    {
      errorKey: 'birthLocation',
      errorLabel: 'Località di nascita'
    },
    {
      errorKey: 'birthProvince',
      errorLabel: 'Prov. EE per Estero'
    },
    {
      errorKey: 'completeAddress',
      errorLabel: 'Indirizzo (via, numero, cap e città)'
    },
    {
      errorKey: 'countryCode',
      errorLabel: 'Paese'
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: 'Codice fiscale'
    }
  ]

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required('Campo obbligatorio'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    holderType: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date('Inserire una data valida').when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required('Campo obbligatorio')
          .test(
            'is-valid-date',
            'Inserire una data valida',
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    birthLocation: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthProvince: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthCountry: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalIdentifier: Yup.string().required('Campo obbligatorio'),
    completeAddress: Yup.string().required('Campo obbligatorio'),
    countryCode: Yup.string().required('Campo obbligatorio'),
    country: Yup.string().required('Campo obbligatorio'),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  return (
    <div className="mt-3">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        <div>
          <FormBody
            {...props}
            initialValues={initialValues}
            ref={holderFormBodyRefData}
            formErrorsMapping={formErrorsMapping}
            relationshipHolderTypes={relationshipHolderTypes}
          />
        </div>
      </Formik>
    </div>
  )
})

const FormBody = forwardRef((props, ref) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [crsHolderType, setCrsHolderType] = useState({})

  useEffect(() => {
    props.setErrors({ errors, formErrorsMapping: props.formErrorsMapping })
  }, [errors])

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      const errors = await validateForm(values)
      if (Object.keys(errors).length > 0) {
        return false
      } else {
        return true
      }
    },
    getHolderType() {
      if (isNotEmpty(crsHolderType?.id)) {
        return crsHolderType
      } else {
        return props.relationship?.holderType
      }
    },
    getPersonType() {
      if (isNotEmpty(values.personType)) {
        return values.personType
      } else {
        return props.relationship?.personType
      }
    },
    getData() {
      let ht = null
      if (isNotEmpty(crsHolderType?.id)) {
        ht = crsHolderType
      } else {
        ht = props.relationship?.holderType?.id
          ? props.relationship?.holderType
          : { id: props.relationship?.holderType }
      }

      if (values.personType === Constants.PNF) {
        values.firstName = null
        values.lastName = null
        values.firstName = null
        values.lastName = null
        values.birthDate = null
        values.birthLocation = null
      } else if (values.personType === Constants.PF) {
        values.companyName = null
      }

      return {
        id: values.id,
        fiscalIdentifier: values.fiscalIdentifier,
        companyName: values.companyName,
        completeAddress: values.completeAddress,
        countryCode: values.countryCode,
        country: values.country,
        personType: values.personType === Constants.PNF ? 'LEGAL' : 'FISICAL',
        identifier: values.identifier,
        lastName: values.lastName,
        firstName: values.firstName,
        birthDate: values.birthDate ? new Date(values.birthDate) : null,
        birthLocation: values.birthLocation,
        birthProvince: values.birthProvince,
        birthCountry: values.birthCountry,
        holderType: ht
      }
    }
  }))

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handlePersonTypeClick = (value) => {
    const form = Object.assign({}, values)
    if (value === Constants.PF) {
      form.personType = value
      form.holderType = ''
      setCrsHolderType(null)
      form.disabledHolderType = true
      props.handlerTabHolder(true)
    } else {
      form.personType = value
      form.disabledHolderType = false
    }
    props.setRelationship(form)
    setValues(form)
  }

  const handlerChangeFiscalIdentifier = (e) => {
    const form = Object.assign({}, values)
    form.fiscalIdentifier = e.target.value?.toUpperCase() ? e.target.value.toUpperCase() : ''
    if (isEmpty(e.target.value)) {
      form.companyName = ''
      form.lastName = ''
      form.firstName = ''
      form.birthDate = null
      form.completeAddress = ''
      form.fiscalIdentifier = ''
      form.errorFiscalCode = false
      props.setRelationship(form)
      setValues(form)
      return
    }
    actions.getBaseRegistryByFiscalCode(form.fiscalIdentifier).then(
      (baseRegistry) => {
        if (baseRegistry) {
          form.companyName = baseRegistry?.companyName || ''
          form.lastName = baseRegistry?.lastName || ''
          form.firstName = baseRegistry?.firstName || ''
          form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
          form.completeAddress =
            baseRegistry?.location?.location + '  (' + baseRegistry?.location?.province + ')' || ''
        }
        form.errorFiscalCode = false
        props.setRelationship(form)
        setValues(form)
      },
      (errors) => {
        if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(form.fiscalIdentifier).toJSON()
            if (cf) {
              form.birthDate = cf?.birthday ? new Date(cf?.birthday) : null
              form.birthLocation =
                cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace.toUpperCase() : ''
              form.birthProvince = cf?.birthplaceProvincia || ''
            }
            form.errorFiscalCode = false
            props.setRelationship(form)
            setValues(form)
          } catch (e) {
            form.errorFiscalCode = true
            props.setRelationship(form)
            setValues(form)
          }
        }
      }
    )
  }

  const handleInputAutocompileCountry = (e) => {
    const form = Object.assign({}, values)
    let newCountry = e.target.value
    form.countryCode = newCountry?.toUpperCase()
    let countriesFilter = props.countries.filter((country) => country.name === newCountry)
    if (countriesFilter.length === 1) {
      form.countryCode = countriesFilter[0].name
      form.country = countriesFilter[0].key
    }
    if (countriesFilter.length === 0) {
      form.countryCode = newCountry.toUpperCase()
      form.country = ''
    }
    props.setRelationship(form)
    setValues(form)
  }

  const handlerChangeHolderType = (e) => {
    const form = Object.assign({}, values)
    let code = e.target[e.target.selectedIndex].dataset.cod
    form.holderType = e.target.value
    form.holderTypeCode = code
    form.crsHolderType = { id: parseInt(e.target.value) }
    props.handlerTabHolder(e.target.value !== '1')
    props.setRelationship(form)
    setValues(form)
  }

  const handleInputAutocompileBirthLocation = (newLocation) => {
    const form = Object.assign({}, values)
    form.birthLocation = newLocation?.toUpperCase() || ''
    actions.getLocation(form.birthLocation).then((loc) => {
      if (loc) {
        form.birthLocation = loc.location
        form.birthProvince = loc.province
        form.birthCountry = 'ITALIA (REPUBBLICA ITALIANA)'
      } else {
        form.birthLocation = newLocation?.toUpperCase() || ''
      }
      props.setRelationship(form)
      setValues(form)
    })
  }

  return (
    <div className="row align-items-center form-row ms-2 pb-2">
      {/* Switch per Tipo Persona */}
      <div className="w-8 w-lg-13 ps-1 pe-2 pb-2">
        <div className="input-group">
          <div className="inputRadio" style={{ fontSize: '15px' }}>
            <label
              disabled={props.disabled}
              className="form-check-label d-flex align-items-center"
              htmlFor={`pType1${values?.id}`}
              onClick={() => handlePersonTypeClick(Constants.PNF)}>
              <input
                disabled={props.disabled}
                className="form-check-input pe-1 me-2 mt-0"
                style={{
                  width: '1.2em',
                  height: '1.2em',
                  boxShadow: 'none'
                }}
                type="radio"
                checked={values.personType === Constants.PNF}
                onChange={() => {}}
              />
              Organization
            </label>
          </div>
          <div className="inputRadio" style={{ fontSize: '15px' }}>
            <label
              disabled={props.disabled}
              className="form-check-label d-flex align-items-center"
              htmlFor={`pType2${values?.id}`}
              onClick={() => handlePersonTypeClick(Constants.PF)}>
              <input
                disabled={props.disabled}
                className="form-check-input pe-1 me-2 mt-0"
                style={{
                  width: '1.2em',
                  height: '1.2em',
                  boxShadow: 'none'
                }}
                type="radio"
                checked={values.personType === Constants.PF}
                onChange={() => {}}
              />
              Individual
            </label>
          </div>
        </div>
      </div>

      {/* Campo Identificativo */}
      <div className="col-3 pe-2 mb-2">
        <BwmInput
          name="identifier"
          label="Identificativo rapporto"
          className="form-control"
          disabled={true}
          maxLength="16"
          value={values.identifier}
          error={errors.identifier}
          touched={props.touched}
        />
      </div>

      {/* Campo TIN */}
      <div className="col-2 pe-2 mb-2">
        <BwmInput
          name="fiscalIdentifier"
          label="TIN (ID Fiscale)"
          className="form-control"
          maxLength="16"
          value={values.fiscalIdentifier}
          error={errors.fiscalIdentifier}
          touched={props.touched}
          onChange={(e) => handleChangeValue(e.target.value, 'fiscalIdentifier', true)}
          onBlur={(e) => handlerChangeFiscalIdentifier(e)}
        />
      </div>

      {/* Campo Tipologia Titolare */}
      <div className="col-3 mb-2">
        <BwmSelect
          options={props.relationshipHolderTypes}
          name="holderType"
          label="Tipologia titolare"
          className="form-control"
          showCode={true}
          disabled={values.disabledHolderType}
          value={values.holderType}
          error={errors.holderType}
          touched={props.touched}
          onChange={(e) => handlerChangeHolderType(e)}
        />
      </div>

      {/* Campo Denominazione (se personType è PNF) */}
      {values.personType === Constants.PNF && (
        <div className="col-5 pe-2 mb-2">
          <BwmInput
            name="companyName"
            label="Denominazione"
            className="form-control font-weight-bold font-size-big"
            maxLength="60"
            value={values.companyName}
            error={errors.companyName}
            touched={props.touched}
            onChange={(e) => handleChangeValue(e.target.value, 'companyName', true)}
          />
        </div>
      )}

      {/* Campi Cognome e Nome (se personType è PF) */}
      {values.personType === Constants.PF && (
        <div className="row">
          <div className="col-3 pe-2 mb-2">
            <BwmInput
              name="lastName"
              label="Cognome"
              className="form-control font-weight-bold font-size-big"
              maxLength="26"
              value={values.lastName}
              error={errors.lastName}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'lastName', true)}
            />
          </div>
          <div className="col-3 pe-2 mb-2">
            <BwmInput
              name="firstName"
              label="Nome"
              className="form-control font-weight-bold font-size-big"
              maxLength="25"
              value={values.firstName}
              error={errors.firstName}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'firstName', true)}
            />
          </div>
          <div className="col-2 pe-2 mb-2">
            <DateField
              label="Data di Nascita"
              placeholder="Data di Nascita"
              name={'birthDate'}
              date={values.birthDate}
              error={errors.birthDate}
              touched={props.touched}
              onChange={(date) => handleChangeValue(date, 'birthDate')}
            />
          </div>
          <div className="col-2 pe-2 mb-2">
            <AutoCompileCustom
              label="Comune di Nascita"
              id="birthLocation"
              filter={props.locations}
              value={values.birthLocation}
              error={errors.birthLocation}
              touched={props.touched}
              handleInputAutocompile={(e) => handleInputAutocompileBirthLocation(e)}
            />
          </div>
          <div className="col-1 pe-2 mb-2">
            <ProvinceField
              label="Prov."
              placeholder="EE per Estero"
              province={values.birthProvince}
              error={errors.birthProvince}
              touched={props.touched}
              onChange={(e) => handleChangeValue(e.target.value, 'birthProvince', true, true)}
            />
          </div>
        </div>
      )}

      {/* Campo Indirizzo Completo */}
      <div className="col-4 pe-2 mb-2">
        <BwmInput
          name="completeAddress"
          label="Indirizzo (via, numero, cap e città)"
          className="form-control"
          value={values.completeAddress}
          error={errors.completeAddress}
          touched={props.touched}
          onChange={(e) => handleChangeValue(e.target.value, 'completeAddress', true)}
        />
      </div>

      {/* Campo Paese */}
      <div className="col-3 mb-2">
        <BwmSelect
          options={props.countries}
          name="country"
          id="country"
          label="Paese"
          className="form-control"
          showCode={true}
          nameKey={true}
          value={values.countryCode || ''}
          error={errors.countryCode}
          touched={props.touched}
          onChange={(e) => handleInputAutocompileCountry(e)}
        />
      </div>
    </div>
  )
})
