import { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import * as actions from '../../../../actions'
import '../../../../styles/comurei-form.css'
import { BwmInput } from '../../../shared/form/BwmInput'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'

const UploadReceiptEmailForm = (props) => {
  const [formData, updateFormData] = useState()
  const [emailAddress, setEmailAddress] = useState(props.emailAddress)
  const [errorEmailAddress, setErrorEmailAddress] = useState({ isValid: true, msg: '' })
  const [flagPec, setFlagPec] = useState(props.flagPec || false)

  useEffect(() => {
    updateFormData({
      ...formData,
      id: props.subjectId,
      emailAddress: emailAddress,
      flagPec: flagPec
    })
  }, [])

  const confirmCreateReceiptEmail = (subjectId, communication, emailAddress, flagPec) => {
    actions.sendCrsReceiptMail(subjectId, communication.id, emailAddress, flagPec)
    props.closeModal()
  }

  const handlerChangeEmailAddress = (e) => {
    setEmailAddress(e.target.value.toUpperCase())
    updateFormData({
      ...formData,
      emailAddress: e.target.value.toUpperCase()
    })
    setFlagPec(false)
  }

  const handlerChangeFlagPec = (e) => {
    let checked = e.target.checked
    setFlagPec(checked)
    updateFormData({
      ...formData,
      flagPec: checked
    })
  }

  const resetError = () => {
    setErrorEmailAddress({ isValid: true, msg: '' })
  }

  const handleValidation = () => {
    resetError()
    let isValid = true
    if (!emailAddress) {
      setEmailAddress({ isValid: false, msg: 'Campo obbligatorio' })
      isValid = false
    } else if (emailAddress.toUpperCase().indexOf('AGENZIAENTRATE') != -1) {
      setErrorEmailAddress({
        isValid: false,
        msg: "Inserire un destinatario diverso dall'agenzia delle entrate"
      })
      isValid = false
    }

    return isValid
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    if (handleValidation()) {
      confirmCreateReceiptEmail(props.subjectId, props.communication, emailAddress, flagPec)
    }
  }

  return (
    <form onSubmit={(e) => handleFormSubmit(e, false)}>
      <div className="row-detail comurei bg-blue pt-4 pe-3 pb-4 ps-3 mt-5 d-flex radius">
        <div className="tab-content col-md-12 col-lg-12">
          <div className="form-row">
            <div className="col-md-12 col-lg-12 subDetailComurei">
              <div className="row">
                <div className="col-md-11 col-lg-11">
                  <BwmInput
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    label="Indirizzo email a cui spedire la ricevuta in chiaro"
                    value={emailAddress}
                    error={errorEmailAddress.msg}
                    touched={errorEmailAddress?.isValid === false}
                    maxLength="100"
                    onChange={handlerChangeEmailAddress}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 pt-1 pb-1">
                  <BwmCheckbox
                    name="flagPec"
                    label="Selezionare in caso di email PEC"
                    className="form-control"
                    value={flagPec}
                    onClick={handlerChangeFlagPec}></BwmCheckbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
        <button
          className="btn btn-outline-primary btn-empty px-4 btn-sm"
          onClick={props.closeModal}>
          ANNULLA
        </button>
        <button type="submit" className="btn btn-primary btn-new-rel px-5">
          CONFERMA
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'UploadReceiptEmailForm'
})(UploadReceiptEmailForm)
