import { DateField } from '../../shared/form/DateField'
import { BwmInput } from '../../shared/form/BwmInput'
import { formatTimeFromDate, joinDateAndTime } from '../../shared/Utility'
import { CallIcon } from './NewContactInfoModal'

export function ContactHistoryForm({ historyObject, onChange }) {
  return (
    <>
      <div className="row mb-2">
        <div className="col-1 pe-2">
          <DateField
            className="form-control form-control-cell"
            date={historyObject.date}
            onChange={(date) => {
              onChange({ ...historyObject, date: date })
            }}
          />
        </div>

        <div className="col-1 pe-2">
          <BwmInput
            className="form-control form-control-cell"
            type={'time'}
            value={formatTimeFromDate(historyObject.date)}
            placeholder={'Inserisci contatto'}
            onChange={(e) =>
              onChange({
                ...historyObject,
                date: joinDateAndTime(historyObject.date, e.target.value)
              })
            }
          />
        </div>

        <div className="col-2 pe-2">
          <BwmInput
            className="form-control form-control-cell"
            value={historyObject.contact}
            placeholder={'Inserisci contatto'}
            onChange={(e) => onChange({ ...historyObject, contact: e.target.value })}
          />
        </div>
        <div className="col-2 pe-2">
          <BwmInput
            className="form-control form-control-cell"
            value={historyObject.phone}
            placeholder={'Inserisci Numero'}
            Icon={() => <CallIcon phone={historyObject.phone} />}
            onChange={(e) => onChange({ ...historyObject, phone: e.target.value })}
          />
        </div>
        <div className="col-1 pe-2">
          <BwmInput
            className="form-control form-control-cell"
            value={historyObject.caller}
            placeholder={'Inserisci chiamante'}
            onChange={(e) => {
              onChange({ ...historyObject, caller: e.target.value })
            }}
          />
        </div>
        <div className="col">
          <BwmInput
            autofocus={true}
            className="form-control form-control-cell"
            placeholder={'Inserisci note'}
            value={historyObject.notes}
            onChange={(e) => onChange({ ...historyObject, notes: e.target.value })}
          />
        </div>
      </div>
    </>
  )
}
