import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { baseSearchObject, TableUtils } from '../../../shared/tables/TableUtils'
import { PopupDelete } from '../../../shared/PopupDelete'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import '../../../../styles/relationship-form.css'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { APPLICATION_CRS } from '../../../../config/Constants'
import { IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
// import { CrsModalRelationship } from './NewModalRelationship'
import { RelationshipForm } from './NewRelationshipForm'
import { CreateCommunicationModal } from '../../application-crs/communications/NewCreateCommunicationModal'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import moment from 'moment'
import { downloadFile } from '../../../shared/Utility'
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear'
import { StarTable } from '../../../shared/tables/StarTable'
import { cloneDeep } from 'lodash'

export const CrsRelationshipList = (props) => {
  const { subjectId } = props
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [relationship, setRelationship] = useState(null)
  const auth = useSelector((state) => state.auth)
  const history = useHistory()
  const [expired, setExpired] = useState(false)
  const createCommunicationRefData = useRef()

  const [relationships, setRelationships] = useState([])

  const [checkedItems, setCheckedItems] = useState([])
  const [checkedItemsRows, setCheckedItemsRows] = useState([])

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject
  })

  const tableName = 'crs-relationships-subject'

  const getRelationships = () => {
    searchRelationships(searchObject).then((res) => {
      setRelationships(res.content, res)
    })
  }

  const tableUtils = TableUtils

  useEffect(() => {
    setExpired(isExpired())
  }, [])

  useEffect(() => {
    setExpired(isExpired())
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, APPLICATION_CRS)
    }
  }

  useEffect(() => {
    if (expired) history.push(`/app/crs/dashboard/`)
  }, [expired])

  const searchRelationships = (newSearchObject) => {
    return actions.getCrsRelationshipsBySubjectId(subjectId, newSearchObject).then((res) => {
      setRelationships(res?.content || res?.data, res)
      setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
      return res
    })
  }

  const setNewRelationships = (content, res) => {
    setRelationships(content)
    setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Rapporto',
      handleClickConfirm: handlerDeleteRelationship
    })
  }

  const handlerDeleteRelationship = () => {
    setLoading(true)
    const promises = []
    checkedItemsRows.forEach((el) => {
      promises.push(actions.deleteCrsRelationship(el, subjectId))
    })
    Promise.all(promises).then(
      () => {
        searchRelationships(searchObject)
        resetCheckBox()
        setLoading(false)
        PopupSuccess()
        setRelationship()
      },
      () => PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
    )
  }

  const resetCheckBox = () => {
    setCheckedItems([])
    setCheckedItemsRows([])
    setDisabled(true)
  }

  const downloadRelationshipPdfList = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: `/api/crs/custom/crs-communications/downloadRelationshipPdfList/${subjectId}`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        downloadFile(response.data, props.subject?.companyName + '_RELATIONSHIPS.pdf')
        PopupSuccess({ text: 'File scaricato' })
      }
    })
  }

  // const closeModal = () => {
  //   setRelationship(null)
  // }

  const sendCommunication = () => {
    if (props.subject) {
      const lastYear = new Date().getFullYear() - 1
      const referenceYear = props.subject.referenceYear
      if (referenceYear < lastYear) {
        PopupAlertCommunicationYear(
          referenceYear,
          lastYear,
          () => createCommunicationRefData.current.clickButtonSend(),
          () => {
            let subject = props.subject
            subject.referenceYear = lastYear
            actions.addCrsSubject(subject).then(
              (res) => {
                createCommunicationRefData.current.clickButtonSend()
              },
              (err) => {
                PopupError({
                  text: Constants.APPLICATION_GENERIC_ERROR
                })
              }
            )
          }
        )
      } else {
        createCommunicationRefData.current.clickButtonSend()
      }
    }
  }

  const renderHeaderButtons = () => {
    let containsRelationships =
      relationships?.length > 0 &&
      moment(
        auth?.user?.customer?.customerApplications.filter((ca) => ca?.application?.id === 3)[0]
          .expiration
      ).diff(moment()) > 0
    return (
      <>
        <div className="d-flex align-items-center justify-content-between header-btn-relationship">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className={`btn btn-outline-primary btn-empty px-4 me-2 btn-cell btn-sm ${disabled ? 'disabled' : ''}`}
              onClick={openPopupDelete}
              disabled={disabled}>
              <IDelete
                className={'padding-bottom-4px'}
                width="12"
                fill={` ${disabled ? '#FFFFFF' : '#128186'}`}
              />
              &nbsp; Elimina
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary btn-empty px-4 me-2 btn-cell btn-sm`}
              onClick={() => downloadRelationshipPdfList()}>
              <IPdfFile className={'padding-bottom-4px'} width="14" height="18" fill={'#128186'} />
              &nbsp; Stampa Rapporti
            </button>

            <CreateCommunicationModal
              ref={createCommunicationRefData}
              label={!containsRelationships ? 'CREA COMUNICAZIONE VUOTA' : 'CREA COMUNICAZIONE'}
              generateEmpty={!containsRelationships}
              subjectId={props.subject?.id}
              subject={props.subject}
              emailAddress={props.subject?.coraSubjectSid?.email}
              reloadRelationships={getRelationships}
              classCustom={'display-none'}
              selectedRelationship={checkedItems}
            />
            <button
              onClick={() => sendCommunication()}
              className="btn btn-primary btn-new-rel  btn-cell px-4 me-2">
              <ITextFile className={'padding-bottom-4px svg'} width="13" fill={`#FFFFFF`} />
              &nbsp; CREA COMUNICAZIONE{!containsRelationships && ' VUOTA'}
            </button>
          </div>
          <div>
            {/* <CrsModalRelationship
              disabled={!containsRelationships}
              subject={props.subject}
              getRelationships={getRelationships}
              closeModal={closeModal}
            /> */}
            <button
              type="button"
              className="btn btn-primary btn-new-rel px-5 btn-cell btn-sm float-end"
              disabled={expired}
              onClick={() => setRelationship()}>
              <IPlus className={'padding-bottom-4px'} width="14" />
              &nbsp; NUOVO RAPPORTO
            </button>
          </div>
        </div>
      </>
    )
  }

  const updateCheckedItems = (_checkedItems = []) => {
    const checkedItemsRows = cloneDeep(_checkedItems).map(
      (el) => rows.find((row) => row.id === el)?.rowData
    )
    setDisabled(!_checkedItems.length)
    setCheckedItemsRows(checkedItemsRows)
    setCheckedItems(_checkedItems)
  }

  const onClickRow = (row) => {
    setRelationship(row.rowData)
  }

  const formatCurrency = (amount, currencyCode) => {
    let body = {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
    return amount.toLocaleString('it-IT', body)
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'sequentialCode',
      orderingName: 'progressive',
      displayedName: 'Progressivo'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalIdentifier',
      orderingName: 'fiscalIdentifier',
      displayedName: 'ID Fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'completeName',
      orderingName: 'completeName',
      displayedName: 'Titolare'
    }),
    tableUtils.composeHeader({
      fieldName: 'relationshipNumber',
      orderingName: 'relationshipNumber',
      displayedName: 'Numero'
    }),
    tableUtils.composeHeader({
      fieldName: 'numberType',
      orderingName: 'numberType',
      displayedName: 'Tipo Numero'
    }),
    tableUtils.composeHeader({
      fieldName: 'balance',
      sortable: false,
      searchable: false,
      displayedName: 'Saldo'
    })
  ]

  const prepareRows = (fromRelationships = []) => {
    return fromRelationships.map((relationship) => {
      return tableUtils.composeRow({
        id: relationship.id,
        rowData: relationship,
        cellData: {
          sequentialCode: tableUtils.composeCell({
            fieldName: 'sequentialCode',
            fieldValue: relationship.progressive
          }),
          fiscalIdentifier: tableUtils.composeCell({
            fieldName: 'fiscalIdentifier',
            fieldValue: relationship?.fiscalIdentifier
          }),
          completeName: tableUtils.composeCell({
            fieldName: 'completeName',
            fieldValue: relationship?.completeName
          }),
          relationshipNumber: tableUtils.composeCell({
            fieldName: 'relationshipNumber',
            fieldValue: relationship?.relationshipNumber
          }),
          numberType: tableUtils.composeCell({
            fieldName: 'numberType',
            fieldValue: relationship?.numberType
          }),
          balance: tableUtils.composeCell({
            fieldName: 'balance',
            fieldValue: formatCurrency(
              parseInt(relationship.balance == null ? '0.00' : relationship.balance),
              relationship?.currency?.isoCode || 'EUR'
            )
          })
        }
      })
    })
  }

  useEffect(() => {
    setRows(prepareRows(relationships))
    setRelationship(relationship ? relationship : relationships?.[0])
  }, [relationships])

  return (
    <>
      {renderHeaderButtons()}
      <StarTable
        id={relationship?.id}
        headerColums={header}
        checkedItems={checkedItems}
        rows={rows}
        loading={loading}
        offset={window.innerWidth < 1500 ? 400 : 598}
        formOnBottom={true}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setRelationship()
          return searchRelationships(searchObject)
        }}
        onExecutedSearch={(content, res) => setNewRelationships(content, res)}
        usePagination={true}
        withCheckBoxes={true}
        onClickCheck={updateCheckedItems}
        onClickRow={onClickRow}
        formComponent={RelationshipForm}
        formComponentProps={{
          index: relationship?.id || '0',
          disabled: expired || loading,
          formOnBottom: true,
          id: relationship?.id,
          subject: props.subject,
          setLoading: setLoading,
          getRelationships: () => searchRelationships(searchObject),
          close: (newRelationship) =>
            setRelationship(
              newRelationship ? newRelationship : relationship ? relationship : undefined
            )
        }}
        // dialogComponent={
        //   relationship?.id !== null &&
        //   relationship?.id !== undefined && (
        //     <CrsModalRelationship
        //       id={relationship.id}
        //       hideButton={true}
        //       showModal={!!relationship?.id}
        //       relationship={relationship}
        //       subject={props.subject}
        //       getRelationships={getRelationships}
        //       closeModal={closeModal}
        //     />
        //   )
        // }
      />
    </>
  )
}
