import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import { PopupError, closePopupError } from '../../../shared/PopupError'
import { PopupDelete } from '../../../shared/PopupDelete'
// import { CrsSubjectModal } from './NewCrsSubjectModal'
import { CrsSubjectList } from './NewCrsSubjectList'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { UploadReceiptModal } from '../../application-crs/receipts/UploadReceiptModal'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { ImportCrsSubjectDataModal } from '../import/NewImportCrsSubjectDataModal'
import { CrsSubjectsExportModal } from './NewCrsSubjectsExportModal'
import { APPLICATION_CRS, APPLICATIONS } from '../../../../config/Constants'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import * as Constants from '../../../../config/Constants'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import { ReactComponent as ITextFile } from '../../../../styles/images/svg/file-alt.svg'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { CrsCommunicationModal } from '../communications/CrsCommunicationModal'
import { isNotEmpty } from '../../../shared/Utility'
import { BtnFill } from '../../../shared/BtnFill'
import { handleCreateCommunicationErrorsText } from '../communications/NewCreateCommunicationModal'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'

export const CrsSubject = () => {
  const [searchObject, setSearchObject] = useState({})
  const [checkedSubjects, setCheckedSubjects] = useState([])
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [expired, setExpired] = useState(false)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const previousYear = new Date().getFullYear() - 1
  const [isLoading, setIsLoading] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [elaboratedCommunicationModals, setElaboratedCommunicationModals] = useState([])
  const [currentModalIndex, setCurrentModalIndex] = useState(null)
  const [customerApplication, setCustomerApplication] = useState(null)

  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)

  const _subjects = useSelector((state) => state.crsSubjects.data)
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    getLocations()
    getCountries()
    getSubjectSummary()
  }, [])

  useEffect(() => {
    setIsLoading(false)
    setSubjects(_subjects)
  }, [_subjects])

  useEffect(() => {
    if (expired) LicenseExpiredPopup({})
  }, [expired])

  useEffect(() => {
    setExpired(isExpired())
    const application = APPLICATIONS.find((a) => a.description === APPLICATION_CRS)
    const customerApplication = auth?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application?.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_CRS)
    }
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const getSubjectSummary = (showPopup) => {
    CheckRemainingSubjects(Constants.APPLICATION_CRS, showPopup, (subjectSummary) => {
      setSubjectSummary(subjectSummary)
      setDisableNewSubjectButtons(subjectSummary?.available < 1)
    })
  }

  const getSubjects = () => {
    actions.searchCrsSubjects(searchObject).then((res) => {
      setSubjects(res.content)
      getSubjectSummary(true)
    })
  }

  const onClickCheck = (newList = []) => {
    setCheckedSubjects(newList)
  }

  const setData = (subjects, searchObject) => {
    setSubjects(subjects)
    setSearchObject(searchObject)
  }

  const deleteCrsSubject = () => {
    checkedSubjects.map((subject) => {
      actions.deleteCrsSubject(subject).then(
        () => {
          setCheckedSubjects([])
          getSubjects()
          getSubjectSummary(false)
          PopupSuccess()
        },
        (errors) => {
          PopupError({ text: errors })
        }
      )
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Soggetti Selezionati',
      handleClickConfirm: deleteCrsSubject,
      text: "ATTENZIONE: se in futuro dovesse servire ripristinare questo/i soggetto/i, dovrà essere chiesta l'autorizzazione all'ufficio Assistenza"
    })
  }

  const onClickCreateEmptyCommunications = () => {
    setIsLoading(true)
    setElaboratedCommunicationModals([])
    let elaboratedCommunications = []
    actions
      .createCrsCommunicationEmptyMultiple(checkedSubjects)
      .then(
        (res) => {
          res.forEach((element, index) => {
            if (element.communication) {
              elaboratedCommunications.push(() => (
                <CrsCommunicationModal
                  key={'communication-modal-' + element.communication.id}
                  sentCommunications={[element.communication]}
                  showModal={true}
                  subject={element.communication.subject}
                  emailAddress={element.communication.subject?.coraSubjectSid?.email}
                  closeModal={() => {
                    setCurrentModalIndex(
                      elaboratedCommunications[currentModalIndex + 1] ? currentModalIndex + 1 : null
                    )
                  }}
                />
              ))
            } else if (element.error) {
              elaboratedCommunications.push(() => (
                <div key={'communication-error-modal-' + index}>
                  {PopupError({
                    text: element.error,
                    buttonClose: () => (
                      <BtnFill
                        text={'CHIUDI'}
                        handlerOnClick={() => {
                          closePopupError()
                          setCurrentModalIndex(
                            elaboratedCommunications[currentModalIndex + 1]
                              ? currentModalIndex + 1
                              : null
                          )
                        }}
                      />
                    )
                  })}
                </div>
              ))
            }
          })
        },
        (err) => {
          PopupError({
            text: handleCreateCommunicationErrorsText(err)
          })
        }
      )
      .then(() => {
        setIsLoading(false)
        setElaboratedCommunicationModals(elaboratedCommunications)
        setCurrentModalIndex(elaboratedCommunications.length !== 0 ? 0 : null)
        getSubjects()
      })
  }

  const actionsComponent = () => {
    return (
      <div className="col-12 ps-0">
        <div className="btn-group" role="group" aria-label="action buttons">
          <button
            type="button"
            className={`btn btn-outline-primary btn-empty  btn-sm  ${checkedSubjects?.length === 0 && 'disabled'}`}
            onClick={openPopupDelete}>
            <IDelete
              className={'padding-right-4px'}
              width="20"
              height="15"
              fill={` ${checkedSubjects.length === 0 ? '#FFFFFF' : '#128186'}`}
            />
            &nbsp; Elimina
          </button>
          <CrsSubjectsExportModal
            disabled={checkedSubjects?.length === 0}
            checkedSubjects={checkedSubjects}
          />
          <ImportCrsSubjectDataModal
            disabled={disableNewSubjectButtons || expired}
            getSubjects={getSubjects}
          />

          <StarTooltip
            text={
              checkedSubjects?.length === 0
                ? "Selezionare dall'elenco i soggetti per cui creare la comunicazione"
                : 'Crea comunicazioni vuote per i soggetti selezionati'
            }>
            <button
              type="button"
              className={`btn   btn-new-rel  btn-sm  ${checkedSubjects?.length === 0 && 'disabled'}`}
              onClick={() => {
                /**
                 * -salva soggetti con anno
                 * -apri modale per ogni soggetto
                 */
                onClickCreateEmptyCommunications()
              }}
              disabled={checkedSubjects?.length === 0 || expired}>
              <ITextFile className={'padding-right-4px svg'} width="16" />
              &nbsp; CREA COMUNICAZIONI VUOTE {previousYear}
            </button>
          </StarTooltip>
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && <PageSpinner />}
      <div className="table-custom">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <SubjectTotalAndCallToAction
            total={subjectSummary?.total}
            remaining={subjectSummary?.available}
            used={subjectSummary?.used}
            baseEcommerceProductId={Constants.getEcommerceProductId(Constants.APPLICATION_CRS)}
            subjectsProductId={Constants.getEcommerceSubjectProductId(Constants.APPLICATION_CRS)}
            licenseType={customerApplication?.licenseType}
            customerApplication={customerApplication}
            buyLink={Constants.CRS_BUY_LINK}
          />
          <div className="d-flex">
            <div className="me-2">
              <UploadReceiptModal disabled={expired} />
            </div>
            <div>
              {/* <CrsSubjectModal
                subjectSummary={subjectSummary}
                licenseExpired={!isLicenseValid}
                locations={locations || []}
                countries={countries || []}
                getSubjects={getSubjects}
                disabled={!isLicenseValid}
                closeModal={() => setSubject(null)}
              /> */}
              <button
                type="button"
                className="btn btn-primary  btn-new-rel  btn-sm"
                disabled={expired || disableNewSubjectButtons}
                onClick={() => setResetForm(!resetForm)}>
                <IPlus className={'padding-right-4px'} width="14" fill={'#FFFFFF'} />
                &nbsp; Nuovo soggetto
              </button>
            </div>
          </div>
        </div>
        <CrsSubjectList
          disabled={expired}
          resetForm={resetForm}
          actionsComponent={actionsComponent()}
          subjects={subjects}
          subjectSummary={subjectSummary}
          getSubjects={getSubjects}
          checkedItems={checkedSubjects}
          setData={setData}
          onClickCheck={onClickCheck}
        />
      </div>
      {isNotEmpty(currentModalIndex) &&
        isNotEmpty(elaboratedCommunicationModals[currentModalIndex]) &&
        elaboratedCommunicationModals[currentModalIndex]()}
    </>
  )
}
