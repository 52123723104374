import { useEffect, useState } from 'react'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'
import { searchComureiSubject } from '../../../../actions'
import { CommunicationsHistoryModal } from '../communications/NewCommunicationsHistoryModal'
import { useSelector } from 'react-redux'
import { ComureiForm } from './NewComureiForm'
// import { ComureiSubjectModal } from './NewComureiSubjectModal'

export function ComureiSubjectList(props) {
  const [subjects, setSubjects] = useState([])
  const [loading, setLoading] = useState(false)
  const communicationTypes = useSelector((state) => state.comureiCommunicationTypes.data)
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const subjectTypes = useSelector((state) => state.comureiSubjectTypes.data)
  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject,
    subjectType: null,
    communicationType: null,
    denomination: null,
    fiscalCode: null,
    responsibleName: null,
    section: null
  })

  const [id, setId] = useState(null)
  const tableUtils = TableUtils
  const tableName = 'comurei-subject'

  const closeModal = () => {
    setId(undefined)
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'subjectType',
      type: fieldTypes.SELECTION,
      options: subjectTypes,
      displayedName: 'Tipo Soggetto'
    }),
    tableUtils.composeHeader({
      fieldName: 'communicationType',
      type: fieldTypes.SELECTION,
      options: communicationTypes,
      displayedName: 'Tipo Comunicazione'
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      displayedName: 'Soggetto Obbligato'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: 'Codice Fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'responsibleName',
      displayedName: 'Responsabile'
    }),
    tableUtils.composeHeader({
      fieldName: 'section',
      sortable: false,
      searchable: false,
      displayedName: 'Sezione'
    }),
    tableUtils.composeHeader({
      fieldName: 'actions',
      sortable: false,
      searchable: false,
      maxWidth: '100px',
      additionalClass: 'justify-content-center',
      displayedName: 'Storico'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        cellData: {
          subjectType: tableUtils.composeCell({
            fieldName: 'subjectType',
            fieldValue: c.subjectType
          }),
          communicationType: tableUtils.composeCell({
            fieldName: 'communicationType',
            fieldValue: c.communicationType
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: c.denomination
          }),
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          responsibleName: tableUtils.composeCell({
            fieldName: 'responsibleName',
            fieldValue: c.responsibleName
          }),
          section: tableUtils.composeCell({
            fieldName: 'section',
            fieldValue: c.section
          }),
          actions: tableUtils.composeCell({
            fieldName: 'actions',
            additionalClass: 'd-flex justify-content-center',
            component: () => {
              return (
                <div className="d-flex justify-content-center p-0">
                  <CommunicationsHistoryModal
                    subjectId={c.id}
                    subjectName={c.denomination}
                    closeModal={closeModal}
                  />
                </div>
              )
            }
          })
        }
      })
    })
  }

  const reloadRows = (searchObject) => {
    searchComureiSubject(searchObject).then((res) => {
      setSubjects(res.content || res.data)
    })
  }

  const setNewSubjects = (newSubjects) => {
    setSubjects(newSubjects)
    props.setSubjects(newSubjects)
  }

  useEffect(() => {
    setRows(prepareRows(subjects))
    setId(id ? id : subjects?.[0]?.id)
  }, [subjects])

  useEffect(() => {
    setId()
  }, [props.resetForm])

  const renderTable = () => {
    return (
      <StarTable
        id={id}
        headerComponent={props.headerComponent}
        headerColums={header}
        rows={rows}
        formOnBottom={true}
        offset="480"
        isLoading={show}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setId()
          return searchComureiSubject(searchObject)
        }}
        onExecutedSearch={(content) => setNewSubjects(content)}
        usePagination={true}
        setId={setId}
        formComponent={ComureiForm}
        formComponentProps={{
          disabled: props.disabled || loading,
          formOnBottom: true,
          subjectId: id,
          locations: props.locations,
          subjectTypes: props.subjectTypes,
          openCreateCommunicationModal: props.openCreateCommunicationModal,
          setLoading: setLoading,
          close: (newId) => setId(newId || id ? id : undefined),
          getSubjects: () => {
            props.reloadList()
            reloadRows(searchObject)
          }
        }}
        // dialogComponent={
        //   id !== null &&
        //   id !== undefined &&
        //   !historyModal && (
        //     <ComureiSubjectModal
        //       id={id}
        //       showModal={!!id}
        //       hideButton={true}
        //       locations={props.locations}
        //       subjectTypes={subjectTypes}
        //       openCreateCommunicationModal={props.openCreateCommunicationModal}
        //       getSubjects={() => {
        //         props.reloadList()
        //         reloadRows(searchObject)
        //       }}
        //       closeModal={setId}
        //     />
        //   )
        // }
      />
    )
  }

  return <>{subjectTypes?.length > 0 && renderTable()}</>
}
