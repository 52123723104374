import { isValid } from 'date-fns'
import { formatDateForInput, isValidDate } from '../Utility'

export const DateField = (props) => {
  const { label, date, onChange, disabled } = props

  const inputWrapperClass = `input-wrapper w-100 ${props.touched && props.error !== undefined ? 'has-error' : ''}`

  return (
    <>
      <div className={inputWrapperClass}>
        <div className="form-group">
          {label && <span className="floatingLabel-datepickr">{label}</span>}
          <div className="input-group">
            <input
              type="date"
              value={date !== null && isValid(new Date(date)) ? formatDateForInput(date) : ''}
              disabled={disabled}
              onChange={(e) => onChange(new Date(e.target.value))}
              className={'form-control form-date px-2'}
              max={'2500-01-01'}></input>
          </div>
        </div>
      </div>
    </>
  )
}
