import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DatePicker, { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
// import ButtonRelationshipModal from './relationship-modal/ButtonRelationshipModal'
import { APPLICATION_CORA } from '../../../../config/Constants'
import { IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { ReactComponent as IPlus } from '../../../../styles/images/svgs/regular/plus.svg'
import { PopupDelete } from '../../../shared/PopupDelete'
import { PopupSuccess } from '../../../shared/PopupSuccess'
import * as actions from '../../../../actions'
import { PopupError } from '../../../shared/PopupError'
import Dropdown from 'react-bootstrap/Dropdown'
import CoraImportRelationsModal from '../subject-cora/NewCoraImportRelationsModal'
import { ReactComponent as ICalendar } from '../../../../styles/images/svg/calendar-alt-regular.svg'
import { ReactComponent as IList } from '../../../../styles/images/svg/list-ul-solid.svg'
import '../../../../styles/relationship-form.css'
import { ReactComponent as IPdfFile } from '../../../../styles/images/svg/file-pdf.svg'
import { ReactComponent as IImport } from '../../../../styles/images/svgs/regular/file-import.svg'
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { ReactComponent as IExclude } from '../../../../styles/images/svg/do-not-enter.svg'
import { ReactComponent as IDelete } from '../../../../styles/images/svg/trash-solid.svg'
import { StarTable } from '../../../shared/tables/StarTable'
import * as Constants from '../../../../config/Constants'
import { RelationshipForm } from './NewRelationshipForm'

import { DropdownButton } from 'react-bootstrap'
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay } from '../../../shared/Utility'
import ImportConsitencyRelationshipsForm from '../import/ImportConsistencyRelationshipsForm'
import { MultiImportModal } from '../import/NewMultiImportModal'
import { ImportDataModal } from '../import/NewImportDataModal'

registerLocale('it', it)

const DatePickerCustomInput = React.forwardRef(({ value, onClick, disabled }, ref) => (
  <button
    ref={ref} // Pass the ref to the button
    type="button"
    className={`btn btn-outline-primary btn-empty btn-sm btn-cell ms-1 me-1 ${disabled ? 'disabled' : ''}`}
    disabled={disabled}
    onClick={onClick}>
    <ICalendar
      className="padding-right-4px"
      fill={disabled ? '#FFFFFF' : '#128186'}
      width="22"
      height="16"
    />
    &nbsp; Imposta Data Chiusura
  </button>
))

const CommunicationToggle = React.forwardRef(({ children: disabled, onClick }, ref) => (
  <button
    type={'button'}
    className={`btn btn-outline-primary btn-empty btn-sm btn-cell me-0 ${disabled && 'disabled'}`}
    disabled={disabled}
    ref={ref}
    onClick={(e) => {
      if (!disabled) {
        onClick(e)
      }
    }}>
    <IList
      className={'padding-right-4px'}
      fill={` ${disabled ? '#FFFFFF' : '#128186'}`}
      width="15"
    />
    &nbsp; Da comunicare come &nbsp; &#9660;
  </button>
))

const filterTypes = {
  DEFAULT: {
    searchField: 'DEFAULT',
    label: 'Elenco iniziale'
  },
  OPEN_ONLY: {
    searchField: 'OPEN_ONLY',
    label: 'Rapporti solo aperti'
  },
  CLOSED_ONLY: {
    searchField: 'CLOSED_ONLY',
    label: 'Rapporti chiusi'
  },
  CANCELED_AND_COMMUNICATED: {
    searchField: 'CANCELED_AND_COMMUNICATED',
    label: 'Rapporti cancellati'
  },
  INCLUDED_IN_NEXT_COMMUNICATION: {
    searchField: 'INCLUDED_IN_NEXT_COMMUNICATION',
    label: 'Rapporti da comunicare'
  },
  BY_MISSING_IN_CONSISTENCY: {
    searchField: 'BY_MISSING_IN_CONSISTENCY',
    label: 'Assenti in fotografia'
  }
}

export function RelationshipsList(props) {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [endDateUpload, setEndDateUpload] = useState(new Date())
  const [id, setId] = useState(null)
  const auth = useSelector((state) => state.auth)
  const history = useHistory()
  const [expired, setExpired] = useState(false)

  const [showImportModal, setShowImportModal] = useState(false)
  const [showImportConsistencyModal, setShowImportConsistencyModal] = useState(false)
  const [showSubjectImportModal, setShowSubjectImportModal] = useState(false)
  const [showRelationshipImportModal, setShowRelationshipImportModal] = useState(false)

  const [relationships, setRelationships] = useState([])

  const [filterLabel, setFilterLabel] = useState('Elenco iniziale')

  const [checkedItems, setCheckedItems] = useState([])

  const [clonedRelationshipId, setClonedRelationshipId] = useState()

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject
  })

  const tableUtils = TableUtils
  const tableName = 'cora-relationships-subject'

  useEffect(() => {
    reloadRelationships()
    setExpired(isExpired())
  }, [])

  useEffect(() => {
    setExpired(isExpired())
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, APPLICATION_CORA)
    }
  }

  useEffect(() => {
    if (expired) history.push(`/app/cora/dashboard/`)
  }, [expired])

  const searchRelationships = (newSearchObject) => {
    const criteria = Object.values(filterTypes).find((e) => e.label === filterLabel)
    newSearchObject = {
      ...newSearchObject,
      criteria: newSearchObject.criteria ? newSearchObject.criteria : criteria?.searchField
    }
    setLoading(true)
    return actions.getRelationshipsBySubjectId(props.subjectId, newSearchObject).finally(() => {
      setLoading(false)
    })
  }

  const reloadRelationships = () => {
    searchRelationships(searchObject).then((data) => {
      setNewRelationships(data.content, data)
    })
  }

  const setNewRelationships = (content, res) => {
    setRelationships(content)
    setSearchObject({ ...baseSearchObject, totalElements: res?.totalElements })
  }

  const handleFilterChange = (filter) => {
    setFilterLabel(filter.label)
    setLoading(true)
    const newSearchObject = {
      ...baseSearchObject,
      pageSize: searchObject.pageSize,
      criteria: filter.searchField
    }
    setSearchObject(newSearchObject)
    searchRelationships(newSearchObject).then((data) => {
      setNewRelationships(data.content, data)
      setLoading(false)
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: 'Rapporto',
      handleClickConfirm: handlerDeleteRelationship
    })
  }

  const handlerDeleteRelationship = () => {
    let ids = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        ids.push(parseInt(checkedItem))
      }
    }
    setLoading(true)
    actions
      .deleteCoraRelationship(ids, props.subjectId)
      .then(
        () => {
          PopupSuccess()
        },
        () => PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
      )
      .then(() => reloadRelationships())
      .then(() => {
        resetCheckBox()
        setLoading(false)
      })
  }

  const handlerUpdateEndDate = () => {
    let ids = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        let relationshipChecked = relationships.find((rel) => rel.id === parseInt(checkedItem))
        ids.push(relationshipChecked.id)
      }
    }

    if (ids.length > 0) {
      setLoading(true)
      actions
        .updateCoraRelationshipEndDate({ ids: ids, endDate: endDateUpload }, props.subject?.id)
        .then(
          () => {},
          (errors) => {
            setLoading(false)
            PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
          }
        )
        .then(() => {
          reloadRelationships()
        })
        .then(() => {
          resetCheckBox()
          setLoading(false)
        })
    }
  }

  const handlerDontSendCommunication = () => {
    let checkedIds = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        checkedIds.push(parseInt(checkedItem))
      }
    }
    setLoading(true)
    actions
      .removeNextCommunication(checkedIds)
      .then(
        () => {
          setLoading(false)
          PopupSuccess()
        },
        (errors) => {
          setLoading(false)
          PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
        }
      )
      .then(() => {
        reloadRelationships()
      })
      .then(() => {
        resetCheckBox()
        setLoading(false)
      })
  }

  const handlerUpdateCommType = (communicationTypeId) => {
    if (props.subject?.coraSubjectSid) {
      let ids = []
      for (const checkedItem of checkedItems) {
        if (checkedItem) {
          let relationshipChecked = relationships.find((rel) => rel.id === parseInt(checkedItem))
          ids.push(relationshipChecked.id)
        }
      }
      setLoading(true)
      actions
        .updateCoraRelationshipCommunicationType(
          { ids: ids, communicationTypeId: communicationTypeId },
          props.subject.id
        )
        .then(
          () => {
            setLoading(false)
            PopupSuccess()
          },
          () => {
            setLoading(false)
            PopupError({ ...props, text: Constants.APPLICATION_GENERIC_ERROR })
          }
        )
        .then(() => {
          reloadRelationships()
        })
        .then(() => {
          resetCheckBox()
          setLoading(false)
        })
    } else {
      PopupError({
        text: "E' necessario inserire le credenziali SID del soggetto per poter continuare"
      })
    }
  }

  const resetCheckBox = () => {
    setCheckedItems([])
    setDisabled(true)
  }

  const setDropdownCommType = () => {
    return props.communicationTypes.map((option, index) => (
      <Dropdown.Item key={index} eventKey={option.id}>
        {option.code + '-' + option.description}
      </Dropdown.Item>
    ))
  }

  const callDownloadPdfListService = () => {
    setLoading(true)
    let companyName =
      props.subject?.personType === Constants.PNF
        ? props.subject?.companyName
        : props.subject?.lastName + ' ' + props.subject?.firstName

    let relationshipIds = []
    for (const [key, value] of checkedItems) {
      if (value) {
        relationshipIds.push(key)
      }
    }
    actions
      .downloadRelationshipPdf(relationshipIds, props.subjectId, searchObject)
      .then(
        (response) => {
          if (response.length > 0) {
            downloadFile(base64ToArrayBuffer(response), companyName + '_RELATIONSHIPS.pdf')
          }
        },
        (error) => {
          PopupError({ text: 'Si è verificato un errore nello scaricamento del file' })
        }
      )
      .then(() => setLoading(false))
  }

  const renderButtonsHeader = () => {
    const relationshipListSize = relationships?.length || 0
    return (
      <>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Dropdown
              onSelect={(eventKey) => {
                handleFilterChange(filterTypes[eventKey])
              }}>
              <DropdownButton
                title={filterLabel}
                bsPrefix={'dropdown-menu-green dropdown-menu-green-sm btn-fill m-0 me-1 pe-2'}>
                <Dropdown.Item
                  eventKey={'DEFAULT'}
                  active={filterLabel === filterTypes.DEFAULT.label}
                  onSelect={() => handleFilterChange(filterTypes.DEFAULT)}>
                  {'Elenco iniziale'}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={'OPEN_ONLY'}
                  active={filterLabel === filterTypes.OPEN_ONLY.label}
                  onSelect={() => handleFilterChange(filterTypes.OPEN_ONLY)}>
                  {'Rapporti solo aperti'}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={'CLOSED_ONLY'}
                  active={filterLabel === filterTypes.CLOSED_ONLY.label}
                  onSelect={() => handleFilterChange(filterTypes.CLOSED_ONLY)}>
                  {'Rapporti chiusi'}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={'CANCELED_AND_COMMUNICATED'}
                  active={filterLabel === filterTypes.CANCELED_AND_COMMUNICATED.label}
                  onSelect={() => handleFilterChange(filterTypes.CANCELED_AND_COMMUNICATED)}>
                  {'Rapporti cancellati'}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={'INCLUDED_IN_NEXT_COMMUNICATION'}
                  active={filterLabel === filterTypes.INCLUDED_IN_NEXT_COMMUNICATION.label}
                  onSelect={() => handleFilterChange(filterTypes.INCLUDED_IN_NEXT_COMMUNICATION)}>
                  {'Rapporti da comunicare'}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey={'BY_MISSING_IN_CONSISTENCY'}
                  active={filterLabel === filterTypes.BY_MISSING_IN_CONSISTENCY.label}
                  onSelect={() => handleFilterChange(filterTypes.BY_MISSING_IN_CONSISTENCY)}>
                  {'Assenti in fotografia'}
                </Dropdown.Item>
              </DropdownButton>
            </Dropdown>
            <div className="btn-group" role="group" aria-label="action buttons">
              <div className="me-1">
                <button
                  type="button"
                  className={`btn btn-outline-primary btn-empty btn-cell btn-sm w-100 m-0 me-1`}
                  onClick={(e) => setShowImportModal(true)}>
                  <IImport fill={`#128186`} width="16" />
                  &nbsp; Importa
                </button>
              </div>
              <div className="me-1">
                <button
                  type="button"
                  className={`btn btn-empty btn-sm btn-cell w-100 ${relationshipListSize === 0 && 'disabled'}`}
                  disabled={relationshipListSize === 0}
                  onClick={() => callDownloadPdfListService()}>
                  <IPdfFile
                    className={'padding-bottom-4px'}
                    width="12"
                    fill={` ${relationshipListSize === 0 ? '#FFFFFF' : '#128186'}`}
                  />
                  &nbsp; Stampa Rapporti
                </button>
              </div>
            </div>
            {showImportModal && (
              <MultiImportModal
                show={showImportModal}
                onHide={() => setShowImportModal(false)}
                components={[
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowSubjectImportModal(true)
                      }}>
                      Soggetto da CORAtu (Export per CoraWeb)
                    </button>
                  ),
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowSubjectImportModal(true)
                      }}>
                      Rapporti da comunicazione TXT
                    </button>
                  ),
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowRelationshipImportModal(true)
                      }}>
                      Rapporti da file CSV
                    </button>
                  ),
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowImportConsistencyModal(true)
                      }}>
                      Allineamento rapporti all'ultima fotografia elaborata
                    </button>
                  )
                ]}
              />
            )}
            {showSubjectImportModal && (
              <ImportDataModal
                show={showSubjectImportModal}
                onSuccess={() => {
                  reloadRelationships()
                  setShowSubjectImportModal(false)
                }}
                onClose={() => {
                  setShowSubjectImportModal(false)
                }}
                subjectId={props.subjectId}
              />
            )}
            {showImportConsistencyModal && (
              <ImportConsitencyRelationshipsForm
                show={showImportConsistencyModal}
                onHide={() => setShowImportConsistencyModal(false)}
                onSuccess={() => {
                  reloadRelationships()
                  setShowImportConsistencyModal(false)
                }}
              />
            )}
            {showRelationshipImportModal && (
              <CoraImportRelationsModal
                show={showRelationshipImportModal}
                onClose={() => setShowRelationshipImportModal(false)}
                onSuccess={() => {
                  reloadRelationships()
                  setShowRelationshipImportModal(false)
                }}
                subject={props.subject}
              />
            )}
            <div className="btn-group" role="group" aria-label="action buttons">
              <div className="me-1">
                <button
                  type="button"
                  className={`btn btn-empty btn-sm btn-cell m-0 ${disabled && 'disabled'}`}
                  disabled={disabled}
                  onClick={(e) => handlerDontSendCommunication()}>
                  {window.innerWidth > 1500 ? (
                    <IExclude
                      className={'padding-bottom-4px me-2'}
                      fill={` ${disabled ? '#FFFFFF' : '#128186'}`}
                      width="15"
                    />
                  ) : (
                    ''
                  )}
                  {window.innerWidth < 1500
                    ? 'Escludi dalla comunicazione'
                    : ' Escludi dalla comunicazione'}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className={`btn btn-empty btn-sm btn-cell m-0 ${disabled && 'disabled'}`}
                  onClick={() => openPopupDelete()}
                  disabled={disabled}>
                  <IDelete
                    className={'padding-bottom-4px'}
                    width="12"
                    fill={` ${checkedItems.length === 0 ? '#FFFFFF' : '#128186'}`}
                  />
                  &nbsp; Elimina
                </button>
              </div>
              <div>
                <DatePicker
                  onChange={(date) => setEndDateUpload(date)}
                  selected={endDateUpload}
                  customInput={<DatePickerCustomInput disabled={disabled} />}
                  locale="it"
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  showMonthDropdown
                  disabled={disabled}
                  shouldCloseOnSelect={false}>
                  <div className="text-center">
                    <button
                      type="button"
                      className={`btn btn-outline-primary  btn-empty m-2 btn-sm `}
                      onClick={() => handlerUpdateEndDate()}>
                      {'Conferma: ' + formatDateForDisplay(endDateUpload)}
                    </button>
                  </div>
                </DatePicker>
              </div>
              <Dropdown onSelect={(option) => handlerUpdateCommType(option)}>
                <Dropdown.Toggle as={CommunicationToggle} variant="success" id="dropdown-comm-type">
                  {disabled}
                </Dropdown.Toggle>
                <Dropdown.Menu>{setDropdownCommType()}</Dropdown.Menu>
              </Dropdown>
            </div>
            {showImportModal && (
              <MultiImportModal
                show={showImportModal}
                onHide={() => setShowImportModal(false)}
                components={[
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowSubjectImportModal(true)
                      }}>
                      Soggetto da CORAtu (Export per CoraWeb)
                    </button>
                  ),
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowSubjectImportModal(true)
                      }}>
                      Rapporti da comunicazione TXT
                    </button>
                  ),
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowRelationshipImportModal(true)
                      }}>
                      Rapporti da file CSV
                    </button>
                  ),
                  () => (
                    <button
                      className="btn btn-secondary btn-new-rel btn-lg me-2"
                      onClick={() => {
                        setShowImportModal(false)
                        setShowImportConsistencyModal(true)
                      }}>
                      Allineamento rapporti all'ultima fotografia elaborata
                    </button>
                  )
                ]}
              />
            )}
            {showSubjectImportModal && (
              <ImportDataModal
                show={showSubjectImportModal}
                onSuccess={() => {
                  reloadRelationships()
                  setShowSubjectImportModal(false)
                }}
                onClose={() => {
                  setShowSubjectImportModal(false)
                }}
                subjectId={props.subject?.id}
              />
            )}
            {showImportConsistencyModal && (
              <ImportConsitencyRelationshipsForm
                show={showImportConsistencyModal}
                onHide={() => setShowImportConsistencyModal(false)}
                onSuccess={() => {
                  reloadRelationships()
                  setShowImportConsistencyModal(false)
                }}
              />
            )}
            {showRelationshipImportModal && (
              <CoraImportRelationsModal
                show={showRelationshipImportModal}
                onClose={() => setShowRelationshipImportModal(false)}
                onSuccess={() => {
                  reloadRelationships()
                  setShowRelationshipImportModal(false)
                }}
                subject={props.subject}
              />
            )}
          </div>
          {/* <ButtonRelationshipModal
            subject={props.subject}
            subjectId={props.subjectId}
            relationshipTypes={props.relationshipTypes}
            relationshipLocations={props.relationshipLocations}
            relationshipProvinces={props.relationshipProvinces}
            communicationTypes={props.communicationTypes}
            currencies={props.currencies}
            roles={props.roles}
            onClone={setClonedRelationshipId}
            getRelationships={reloadRelationships}
          /> */}
          <button type="button" className="btn btn-new-rel btn-sm btn-cell" onClick={() => setId()}>
            <IPlus className={'padding-right-4px'} width="14" fill={`#128186`} />
            {window.innerWidth < 1500 ? 'NUOVO' : ' NUOVO RAPPORTO'}
          </button>
        </div>
      </>
    )
  }

  const headerComponent = () => {
    return (
      <div>
        <div className="row header-btn-relationship align-items-center">
          {renderButtonsHeader()}
        </div>
      </div>
    )
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'completeName',
      orderingName: 'completeName',
      displayedName: 'Anagrafica'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: 'Codice fiscale'
    }),
    tableUtils.composeHeader({
      fieldName: 'startDate',
      type: fieldTypes.DATE,
      displayedName: 'Data Inizio'
    }),
    tableUtils.composeHeader({
      fieldName: 'endDate',
      type: fieldTypes.DATE,
      displayedName: 'Data Fine'
    }),
    tableUtils.composeHeader({
      fieldName: 'relationshipTypes',
      type: fieldTypes.SELECTION,
      options: Array.isArray(props.relationshipTypes) ? props.relationshipTypes : [],
      displayedName: 'Rapporto'
    }),
    tableUtils.composeHeader({
      fieldName: 'comment',
      displayedName: 'Note',
      searchable: true
    }),
    tableUtils.composeHeader({
      fieldName: 'identifier',
      displayedName: 'Codice univoco',
      searchable: true
    }),
    tableUtils.composeHeader({
      fieldName: 'communicationTypes',
      type: fieldTypes.SELECTION,
      options: Array.isArray(props.communicationTypes) ? props.communicationTypes : [],
      displayedName: 'Da spedire'
    }),
    tableUtils.composeHeader({
      fieldName: 'lastCommunicationError',
      displayedName: 'errori'
    }),
    tableUtils.composeHeader({
      fieldName: 'codError',
      displayedName: 'Codice esito'
    })
  ]

  const prepareRows = (newRelations = []) => {
    const list = Array.isArray(newRelations) ? newRelations : []
    return list.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        rowData: c,
        cellData: {
          completeName: tableUtils.composeCell({
            fieldName: 'completeName',
            fieldValue: c.completeName
          }),
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          location: tableUtils.composeCell({
            fieldName: 'startDate',
            fieldValue: c.startDate
          }),
          province: tableUtils.composeCell({
            fieldName: 'endDate',
            fieldValue: c.endDate
          }),
          relationshipType: tableUtils.composeCell({
            fieldName: 'relationshipTypes',
            fieldValue: c.relationshipType
          }),
          comment: tableUtils.composeCell({
            fieldName: 'comment',
            fieldValue: c.comment
          }),
          identifier: tableUtils.composeCell({
            fieldName: 'identifier',
            fieldValue: c.identifier
          }),
          communicationType: tableUtils.composeCell({
            fieldName: 'communicationTypes',
            fieldValue: c.coraCommunicationType
          }),
          lastCommunicationError: tableUtils.composeCell({
            fieldName: 'lastCommunicationError',
            fieldValue: c.lastCommunicationError
          }),
          codError: tableUtils.composeCell({
            fieldName: 'codError',
            fieldValue: c.codError
          })
        }
      })
    })
  }

  const setNewCheckedItems = (list = []) => {
    setDisabled(list.length === 0)
    setCheckedItems(list)
  }

  const getRowClass = (rowData) => {
    if (rowData.id !== id) {
      if (rowData.id === clonedRelationshipId) {
        return 'bg-green-row'
      } else if (rowData.lastCommunicationError) {
        return 'bg-yellow-row'
      } else if (rowData.includedInNextCommunication) {
        return 'bg-green-row'
      }
    }
  }

  // const onHide = () => {
  //   setId(undefined)
  //   reloadRelationships()
  // }

  const onClickRow = (row) => {
    setId(row.id)
  }

  useEffect(() => {
    setRows(prepareRows(relationships))
    setId(id ? id : relationships?.[0]?.id)
  }, [relationships])

  const renderTable = () => {
    return (
      <div>
        {headerComponent()}
        <StarTable
          id={id}
          headerColums={header}
          checkedItems={checkedItems}
          rows={rows}
          formOnBottom={true}
          rowClass={getRowClass}
          loading={loading}
          offset={window.innerWidth < 1500 ? 400 : 598}
          perPageOptions={[10, 20, 50, 100, 250, 500]}
          tableConfigurationKey={tableName}
          searchObjectPrototype={searchObject}
          setSearchObject={setSearchObject}
          searchCallBack={(searchObject) => {
            setId()
            return searchRelationships(searchObject)
          }}
          onExecutedSearch={(content, res) => setNewRelationships(content, res)}
          usePagination={true}
          withCheckBoxes={true}
          onClickCheck={setNewCheckedItems}
          onClickRow={onClickRow}
          formComponent={RelationshipForm}
          formComponentProps={{
            disabled: expired || loading,
            formOnBottom: true,
            id: id,
            subjectId: props.subjectId,
            subject: props.subject,
            relationshipLocations: props.relationshipLocations,
            relationshipProvinces: props.relationshipProvinces,
            communicationTypes: props.communicationTypes,
            currencies: props.currencies,
            roles: props.roles,
            setLoading: setLoading,
            onClone: setClonedRelationshipId,
            getRelationships: reloadRelationships,
            close: (newId) => setId(newId || id ? id : undefined)
          }}
          // dialogComponent={
          //   id !== null &&
          //   id !== undefined && (
          //     <ModalRelationship
          //       show={id !== null && id !== undefined}
          //       id={id}
          //       subjectId={props.subjectId}
          //       subject={props.subject}
          //       relationshipLocations={props.relationshipLocations}
          //       relationshipProvinces={props.relationshipProvinces}
          //       communicationTypes={props.communicationTypes}
          //       currencies={props.currencies}
          //       roles={props.roles}
          //       onClone={setClonedRelationshipId}
          //       getRelationships={reloadRelationships}
          //       onHide={onHide}
          //     />
          //   )
          // }
        />
      </div>
    )
  }

  return <>{renderTable()}</>
}
