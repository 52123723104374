import { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Header from './components/shared/Header'
import * as actions from './actions'
import './App.css'
import { Provider } from 'react-redux'
import { ProtectedRoute } from './components/shared/auth/ProtectedRoute'
import Footer from './components/shared/Footer'
import ReactGA from 'react-ga'
import { ApplicationPaths } from './services/ApplicationPaths'

const store = require('./reducers').init()

const App = () => {
  const getCurrentUser = () => {
    store.dispatch(actions.getAccount())
  }

  useEffect(() => {
    getCurrentUser()

    const unsubscribe = store.subscribe(() => {
      if (store.getState().auth?.user?.email) {
        ReactGA.initialize('UA-148800686-1')
        ReactGA.pageview(window.location.pathname)
        ReactGA.set({
          userId: store.getState().auth.user.email
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const logout = () => {
    store.dispatch(actions.logout())
  }

  const renderUnprotectedPaths = () => {
    return ApplicationPaths.unprotected.map((p) => {
      return <Route key={p} exact path={p.path} component={p.component} />
    })
  }

  const renderProtectedPaths = () => {
    return ApplicationPaths.protected.map((p) => {
      return <ProtectedRoute key={p} exact path={p.path} component={p.component} />
    })
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Header logout={logout} />
          <div className="mx-2 page-body">
            <Switch>
              {renderUnprotectedPaths()}
              {renderProtectedPaths()}
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </Provider>
  )
}

export default App
