import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ISave, IUndo } from '../../../../styles/icons'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import NumberFormat from 'react-number-format'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../actions'
import * as Utility from '../../../shared/Utility'
import { isNotEmpty } from '../../../shared/Utility'
import {
  BALANCE_DISABLED,
  getBalanceRulesByRelationshipType,
  getDisabledFields
} from '../BalanceRules'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'
import { Formik, Form, useFormikContext } from 'formik'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import * as Yup from 'yup'

export const ModalCreateBalance = (props) => {
  const dispatch = useDispatch()
  const { relationship, balance } = props
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const [relationshipRules] = useState(
    getBalanceRulesByRelationshipType(props.relationship?.relationshipType?.code || '01')
  )

  const currencyTypes = useSelector((state) => state.currencyTypes.data)

  const setNewInitialValues = () => {
    setInitialValues({
      coraRelationship: {
        id: relationship?.id ? relationship?.id : null
      },
      id: balance?.id || null,
      currency: {
        id: balance?.currency?.id ? balance?.currency?.id : 58,
        isoCode: 'EUR',
        description: 'EURO UNIONE ECONOMICA MONETARIA'
      },
      amount1: balance?.amount1 ? balance.amount1 : 0,
      amount2: balance?.amount2 ? balance.amount2 : 0,
      amount3: balance?.amount3 ? balance.amount3 : 0,
      amount4: balance?.amount4 ? balance.amount4 : 0,
      amount5: balance?.amount5 ? balance.amount5 : 0,
      stock: balance?.stock ? balance.stock : 0,
      year: balance?.year ? balance.year : new Date().getFullYear() - 1,
      currencyType: balance?.currencyType || null,
      idResult: balance?.idResult || ''
    })
  }

  useEffect(() => {
    if (currencyTypes.length === 0) {
      dispatch(actions.getAllCurrencyTypes())
    }
  }, [])

  useEffect(() => {
    setNewInitialValues()
  }, [props])

  const handlerClose = () => {
    props.onHide()
  }

  const getValidationSchema = () => {
    const disableAmount1 = relationshipRules?.amount1 !== BALANCE_DISABLED
    const disableAmount2 = relationshipRules?.amount2 !== BALANCE_DISABLED
    const disableAmount3 = relationshipRules?.amount3 !== BALANCE_DISABLED
    const disableAmount4 = relationshipRules?.amount4 !== BALANCE_DISABLED
    const disableAmount5 = relationshipRules?.amount5 !== BALANCE_DISABLED
    const disableStock = relationshipRules?.stock !== BALANCE_DISABLED

    return Yup.object().shape({
      year: Yup.string().required('Campo obbligatorio'),
      currency: Yup.object().shape({
        id: Yup.string().required('Campo obbligatorio')
      }),
      amount1: disableAmount1
        ? Yup.number().required('Campo obbligatorio')
        : Yup.number().nullable(),
      amount2: disableAmount2
        ? Yup.number().required('Campo obbligatorio')
        : Yup.number().nullable(),
      amount3: disableAmount3
        ? Yup.number().required('Campo obbligatorio')
        : Yup.number().nullable(),
      amount4: disableAmount4
        ? Yup.number().required('Campo obbligatorio')
        : Yup.number().nullable(),
      amount5: disableAmount5
        ? Yup.number().required('Campo obbligatorio')
        : Yup.number().nullable(),
      stock: disableStock ? Yup.number().required('Campo obbligatorio') : Yup.number().nullable()
    })
  }

  const validationSchema = getValidationSchema()

  const formErrorsTabMapping = [
    {
      errorKey: 'year',
      errorLabel: 'Anno'
    },
    {
      errorKey: 'idResult',
      errorLabel: 'Esito'
    },
    {
      errorKey: 'amount1',
      errorLabel: 'Importo 1'
    },
    {
      errorKey: 'amount2',
      errorLabel: 'Importo 2'
    },
    {
      errorKey: 'amount3',
      errorLabel: 'Importo 3'
    },
    {
      errorKey: 'amount4',
      errorLabel: 'Importo 4'
    },
    {
      errorKey: 'amount5',
      errorLabel: 'Altre Informazioni / Importo 5'
    },
    {
      errorKey: 'stock',
      errorLabel: 'Giacenza media'
    },
    {
      errorKey: 'currency',
      errorLabel: 'Valuta'
    },
    {
      errorKey: 'currencyType',
      errorLabel: 'Natura'
    }
  ]

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      onHide={handlerClose}
      bsPrefix="modal-create modal"
      backdrop="static">
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Aggiungi Saldo
          <h4>
            {relationship?.companyName != null && relationship?.companyName !== ''
              ? relationship.companyName
              : relationship?.lastName + ' ' + relationship?.firstName}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ errors }) => (
            <Form>
              {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
              {showErrorAlert &&
                errors &&
                typeof errors === 'object' &&
                Object.keys(errors).length > 0 && (
                  <div>
                    <ErrorListAlert
                      errors={getErrors(errors)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
              <FormBody
                {...props}
                relationshipRules={relationshipRules}
                currencyTypes={currencyTypes}
                setShowErrorAlert={setShowErrorAlert}
                handlerClose={handlerClose}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [touched, setTouched] = useState(false)
  const [disabledFields] = useState(getDisabledFields(props.relationshipRules))

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  if (values.stock === null) values.stock = 0

  const handlerCurrency = (e) => {
    const form = Object.assign({}, values)
    form.currency = e.target.value
      ? {
          id: e.target.value.trim(),
          isoCode: e.target.getAttribute('data-cod'),
          description: e.target.options[e.target.value].text
        }
      : {}
    setValues(form)
  }

  const handleFormSubmit = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false)
      updateAll(values)
    }
  }

  const updateAll = (form) => {
    if (!props.balance?.id) {
      const balancesList = props.balances || []
      let id = null
      for (let value of balancesList) {
        if (value.year == values.year) {
          id = value.id
        }
      }
      form.id = id
    }
    props.handlerSubmitBalance(form)
    props.handlerClose()
  }

  const renderAmountTextBox = (key, disableKey, label) => {
    const inputWrapperClass = `input-wrapper d-flex w-100 align-items-center text-end ${touched && errors[key] !== undefined ? 'has-error' : ''}`
    return (
      <StarTooltip text={props.relationshipRules[key]}>
        <div className="form-group">
          <div className="input-group">
            <NumberFormat
              id="filled-multiline-flexible"
              label={label}
              multiline={true}
              variant="filled"
              name="amountRel_"
              thousandSeparator={'.'}
              decimalSeparator={','}
              decimalScale={0}
              fixedDecimalScale
              className={inputWrapperClass}
              inputMode="numeric"
              customInput={BwmInput}
              value={!disabledFields[disableKey] && values[key]}
              disabled={disabledFields[disableKey]}
              onValueChange={(val) => handleChangeValue(val?.floatValue || 0, key)}
            />
          </div>
        </div>
      </StarTooltip>
    )
  }

  return (
    <div>
      <Row className="mb-2">
        <Col md={2} lg={2} className="pe-2">
          <BwmInput
            disabled={props.disabled}
            name="year"
            label="Anno"
            className="form-control"
            maxLength="4"
            value={values.year}
            error={errors.year}
            touched={touched}
            onChange={(e) => handleChangeValue(e.target.value, 'year', false, true)}
          />
        </Col>
        <Col md={2} lg={2} className="pe-2">
          <BwmInput
            disabled={props.disabled}
            name="idResult"
            label="Esito"
            className="form-control"
            value={values.idResult}
            error={errors.idResult}
            touched={touched}
            onChange={(e) => handleChangeValue(e.target.value, 'idResult', false, true)}
          />
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col md={2} lg={2} className="pe-2">
          {renderAmountTextBox('amount1', 'amount1Disabled', 'Importo 1')}
        </Col>
        <Col md={2} lg={2} className="pe-2">
          {renderAmountTextBox('amount2', 'amount2Disabled', 'Importo 2')}
        </Col>
        <Col md={2} lg={2} className="pe-2">
          {renderAmountTextBox('amount3', 'amount3Disabled', 'Importo 3')}
        </Col>
        <Col md={2} lg={2} className="pe-2">
          {renderAmountTextBox('amount4', 'amount4Disabled', 'Importo 4')}
        </Col>
        <Col md={2} lg={2} className="pe-2">
          {renderAmountTextBox('amount5', 'amount5Disabled', 'Altre Informazioni / Importo 5')}
        </Col>
      </Row>
      <Row>
        <Col md={2} lg={2} className="pe-2">
          {renderAmountTextBox('stock', 'stockDisabled', 'Giacenza media')}
        </Col>
        <Col lg={4} className="pe-2">
          <BwmSelect
            disabled={props.disabled}
            options={props.currencies}
            name="currency"
            label="Valuta"
            className="form-control"
            showCode={true}
            value={values.currency?.id}
            error={errors.currency}
            touched={touched}
            onChange={(e) => handlerCurrency(e)}
          />
        </Col>
        <Col lg={4} className="pe-2">
          <BwmSelect
            disabled={props.disabled}
            options={props.currencyTypes}
            name="currencyType"
            label="Natura"
            showCode={true}
            className="form-control"
            value={values.currencyType?.id}
            error={errors.currencyType}
            touched={touched}
            onChange={(e) =>
              handleChangeValue(
                isNotEmpty(e.target.value) ? { id: e.target.value } : null,
                'currencyType'
              )
            }
          />
        </Col>
      </Row>
      <div className="form-row mt-4 d-flex justify-content-between">
        <button className="btn btn-empty px-4 btn-sm" onClick={props.handlerClose}>
          <IUndo className={'padding-bottom-4px svg'} width="16" fill={'#128186'} />
          &nbsp; ANNULLA
        </button>
        <button
          disabled={props.disabled}
          onClick={() => handleFormSubmit()}
          className="btn btn-new-rel px-5">
          <ISave className={'padding-bottom-4px svg'} width="16" fill={'#FFFFFF'} />
          &nbsp; CONFERMA
        </button>
      </div>
    </div>
  )
}
