import { useEffect, useState } from 'react'
import { Tabs } from '../../application-cora/relationships/Tabs'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import * as actions from '../../../../actions'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import AutoCompileCustom from '../../../shared/form/AutoCompileCustomB5'
import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'
import { ISave, ITextFile, IUndo } from '../../../../styles/icons'
import { DateField } from '../../../shared/form/DateFieldB5'
import * as Constants from '../../../../config/Constants'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { ReactComponent as IInfo } from '../../../../styles/images/svg/info-circle.svg'
import '../../../../styles/comurei-form.css'
import CodiceFiscale from 'codice-fiscale-js'
import { PopupError } from '../../../shared/PopupError'
import * as Utility from '../../../shared/Utility'
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm'
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense'
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

const keys = {
  Soggetto: 'tab-sub',
  Responsabile: 'tab-resp',
  'Posta Elettronica': 'tab-pec',
  Cancellazione: 'tab-delete',
  'Impegno alla trasmissione': 'tab-transmission'
}

export const ComureiForm = (props) => {
  const [subjectSummary, setSubjectSummary] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tabError, setTabError] = useState({
    subject: true,
    responsible: true,
    pec: true,
    canc: true
  })
  const [activeTab, setActiveTab] = useState('Soggetto')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  const retrieveSubject = (id) => {
    setActiveTab('Soggetto')
    setLoading(true)
    if (id) {
      actions.getComureiSubjectDetail(id).then((response) => {
        setNewInitialValues(response.data)
        setLoading(false)
      })
    } else {
      setNewInitialValues()
      setLoading(false)
    }
  }

  const setNewInitialValues = (data) => {
    setInitialValues({
      id: data?.id || null,
      fiscalCode: data?.fiscalCode || '',
      companyName: data?.companyName || '',
      location: data?.location || '',
      province: data?.province || '',
      registryReason: data && data.registryReason !== null ? data.registryReason : true,
      monitoringReason: data?.monitoringReason || false,
      factaReason: data?.factaReason || false,
      birthDate: data?.birthDate ? new Date(data?.birthDate) : null,
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      centralizedStructure: data?.centralizedStructure || false,
      gender:
        data?.gender ||
        ((data?.personType || Constants.PNF) === Constants.PF ? Constants.genderList[0].id : null),
      personType: data?.personType || Constants.PNF,
      comureiCommunicationType: {
        id: data?.comureiCommunicationType.id.toString() || '1'
      },
      comureiSubjectType: {
        id: data?.comureiSubjectType?.id || '',
        code: data?.comureiSubjectType?.code || ''
      },
      comureiSubjectResponsible: {
        id: data?.comureiSubjectResponsible?.id || null,
        fiscalCode: data?.comureiSubjectResponsible?.fiscalCode || '',
        firstName: data?.comureiSubjectResponsible?.firstName || '',
        lastName: data?.comureiSubjectResponsible?.lastName || '',
        gender: data?.comureiSubjectResponsible?.gender || 'FEMALE',
        birthDate: data?.comureiSubjectResponsible?.birthDate
          ? new Date(data?.comureiSubjectResponsible?.birthDate)
          : null,
        location: data?.comureiSubjectResponsible?.location || '',
        province: data?.comureiSubjectResponsible?.province || '',
        email: data?.comureiSubjectResponsible?.email || props.user?.login || '',
        phone: data?.comureiSubjectResponsible?.phone || ''
      },
      comureiSubjectPec: {
        id: data?.comureiSubjectPec?.id || null,
        pecEmail: data?.comureiSubjectPec?.pecEmail || '',
        pecManager: data?.comureiSubjectPec?.pecManager || '',
        effectiveDate: data?.comureiSubjectPec?.effectiveDate
          ? new Date(data.comureiSubjectPec.effectiveDate)
          : '',
        endDate: data?.comureiSubjectPec?.endDate ? new Date(data.comureiSubjectPec.endDate) : '',
        reiEmail: data?.comureiSubjectPec?.reiEmail || ''
      },
      comureiSubjectCancellation: {
        id: data?.comureiSubjectCancellation?.id || null,
        cancellationDate: data?.comureiSubjectCancellation?.cancellationDate
          ? new Date(data.comureiSubjectCancellation.cancellationDate)
          : null,
        address: data?.comureiSubjectCancellation?.address || '',
        incorporatingFiscalCodeOne:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeOne || '',
        incorporatingFiscalCodeTwo:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeTwo || '',
        incorporatingFiscalCodeThree:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeThree || '',
        location: data?.comureiSubjectCancellation?.location || '',
        province: data?.comureiSubjectCancellation?.province || '',
        comureiSubjectCancellationType: {
          id:
            data?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1',
          code:
            data?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1'
        }
      },
      comureiTransmission: {
        id: data?.comureiTransmission?.id || null,
        fiscalCode: data?.comureiTransmission?.fiscalCode || '',
        comureiTransmissionType: {
          id: data?.comureiTransmission?.comureiTransmissionType.id.toString() || '1'
        }
      },
      errorFiscalCode: false,
      errorComureiSubjectResponsibleFiscalCode: false
    })
  }

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth) // Aggiorna la larghezza ogni volta che la finestra viene ridimensionata
    }

    // Aggiungi l'evento resize per aggiornare la larghezza
    window.addEventListener('resize', handleResize)

    // Pulisci l'evento quando il componente viene smontato
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const setTabWithError = (tabDict) => {
    const tabList = Object.entries(tabDict)
      .filter(([key, value]) => value === true) // Filtra solo dove il valore è true
      .map(([key]) => key) // Mappa solo le chiavi

    document.querySelectorAll('.tab-list-item').forEach((item) => {
      item.firstChild.classList.remove('text-danger')
    })
    tabList.forEach((tabId) => {
      // Add 'border-danger' to the element with id 'test'
      document.getElementById(keys[tabId]).firstChild.classList.add('text-danger')
    })
  }

  const validationSchema = Yup.object().shape({
    // VALIDATION SUBJECT
    personType: Yup.string().required('Selezionare il tipo di persona'),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required('Campo obbligatorio'),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date('Inserire una data valida').when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required('Campo obbligatorio')
          .test(
            'is-valid-date',
            'Inserire una data valida',
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().required('Campo obbligatorio'),
    location: Yup.string().required('Campo obbligatorio'),
    province: Yup.string().required('Campo obbligatorio'),
    atLeastOne: Yup.boolean().test('at-least-one', 'Selezionare almeno una tipologia', function () {
      const { monitoringReason, registryReason, factaReason } = this.parent
      return monitoringReason || registryReason || factaReason
    }),
    notMonitoringReasonSelectable: Yup.boolean().test(
      'notMonitoringReasonSelectable',
      'non può essere selezionato se il Tipo Soggetto è con codice 05',
      function () {
        const { comureiSubjectType, monitoringReason } = this.parent // accede ai valori dei campi
        return !(comureiSubjectType?.id?.toString() === '5' && monitoringReason)
      }
    ),
    notRegistryReasonSelectable: Yup.boolean().test(
      'notRegistryReasonSelectable',
      'Il Tipo Soggetto 29 non può essere iscritto alla sezione INDAGINI BANCARIE. In caso di Holding da iscrivere per la comunicazione Anagrafe Rapporti scegliere il tipo 05',
      function () {
        const { comureiSubjectType, registryReason } = this.parent // accede ai valori dei campi
        return !(comureiSubjectType?.id?.toString() === '29' && registryReason)
      }
    ),
    comureiSubjectType: Yup.object().shape({
      id: Yup.string().required('Campo obbligatorio'),
      code: Yup.string()
    }),
    // VALIDATION RESPONSABLE
    comureiSubjectResponsible: Yup.object().shape({
      id: Yup.string().nullable(),
      fiscalCode: Yup.string().required('Campo obbligatorio'),
      firstName: Yup.string().required('Campo obbligatorio'),
      lastName: Yup.string().required('Campo obbligatorio'),
      gender: Yup.string().required('Campo obbligatorio'),
      birthDate: Yup.date('Inserire una data valida')
        .required('Campo obbligatorio')
        .test(
          'is-valid-date',
          'Inserire una data valida',
          (value) => !value || Utility.isValidDate(new Date(value))
        ),
      location: Yup.string().required('Campo obbligatorio'),
      province: Yup.string().required('Campo obbligatorio'),
      email: Yup.string().email('Formato email non valido').required('Campo obbligatorio'),
      phone: Yup.string().required('Campo obbligatorio')
    }),
    // VALIDATION EMAIL
    comureiSubjectPec: Yup.object().shape({
      id: Yup.string().nullable(),
      pecEmail: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) =>
          comureiCommunicationType?.id !== '2' && comureiCommunicationType?.id !== '3',
        then: (schema) => schema.email('Formato email non valido').required('Campo obbligatorio'),
        otherwise: (schema) => schema.email('Formato email non valido')
      }),
      reiEmail: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) =>
          comureiCommunicationType?.id === '2' || comureiCommunicationType?.id === '3',
        then: (schema) => schema.email('Formato email non valido').required('Campo obbligatorio'),
        otherwise: (schema) => schema.email('Formato email non valido')
      }),
      pecManager: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) => comureiCommunicationType?.id !== '2',
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      effectiveDate: Yup.date('Inserire una data valida')
        .required('Campo obbligatorio')
        .test(
          'is-valid-date',
          'Inserire una data valida',
          (value) => !value || Utility.isValidDate(new Date(value))
        )
        .when('$comureiCommunicationType', {
          is: (comureiCommunicationType) => comureiCommunicationType?.id !== '2',
          then: (schema) => schema.required('Campo obbligatorio'),
          otherwise: (schema) => schema.nullable()
        }),
      endDate: Yup.date('Inserire una data valida')
        .nullable()
        .test(
          'is-valid-date',
          'Inserire una data valida',
          (value) => !value || Utility.isValidDate(new Date(value))
        )
        .when(['effectiveDate', '$comureiCommunicationType'], (data, schema) => {
          return schema.test({
            test: (endDate) => {
              return !(
                data[1]?.id !== '2' &&
                endDate &&
                data?.[0] &&
                new Date(endDate) < new Date(data[0])
              )
            },
            message: 'La data non può essere minore della Data DECORRENZA'
          })
        })
    }),

    // VALIDATION CANCELLATION
    comureiSubjectCancellation: Yup.object().shape({
      id: Yup.string().nullable(),
      cancellationDate: Yup.date('Inserire una data valida')
        .nullable()
        .test(
          'is-valid-date',
          'Inserire una data valida',
          (value) => !value || Utility.isValidDate(new Date(value))
        )
        .when('$comureiCommunicationType', {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required('Campo obbligatorio')
        }),
      address: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      location: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      province: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required('Campo obbligatorio')
      }),
      comureiSubjectCancellationType: Yup.object().shape({
        id: Yup.string().when(['$comureiCommunicationType'], {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required('Campo obbligatorio')
        }),
        code: Yup.string().when(['$comureiCommunicationType'], {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required('Campo obbligatorio')
        })
      }),
      incorporatingFiscalCodeOne: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            if (
              comureiCommunicationType?.id === '2' &&
              comureiSubjectCancellation?.comureiSubjectCancellationType?.id === '1'
            )
              return true
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required('Campo obbligatorio')
        }
      ),
      incorporatingFiscalCodeTwo: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required('Campo obbligatorio')
        }
      ),
      incorporatingFiscalCodeThree: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required('Campo obbligatorio')
        }
      )
    }),

    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => 'non valido',
      (value) => !value
    ),
    errorComureiSubjectResponsibleFiscalCode: Yup.boolean().test(
      'errorComureiSubjectResponsibleFiscalCode',
      () => 'non valido',
      (value) => !value
    )
  })

  const formErrorsTabMapping = {
    Soggetto: [
      {
        errorKey: 'comureiSubjectType.id',
        errorLabel: 'Tipo soggetto'
      },
      {
        errorKey: 'atLeastOne',
        errorLabel: 'Motivi della comunicazione'
      },
      {
        errorKey: 'notMonitoringReasonSelectable',
        errorLabel: 'Monitoraggio fiscale'
      },
      {
        errorKey: 'notRegistryReasonSelectable',
        errorLabel: 'Indagini Finanziarie'
      },
      {
        errorKey: 'errorFiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'fiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'companyName',
        errorLabel: 'Ragione Sociale'
      },
      {
        errorKey: 'location',
        errorLabel: 'Sede legale'
      },
      {
        errorKey: 'province',
        errorLabel: 'Provincia'
      },
      {
        errorKey: 'firstName',
        errorLabel: 'Nome'
      },
      {
        errorKey: 'lastName',
        errorLabel: 'Cognome'
      },
      {
        errorKey: 'birthDate',
        errorLabel: 'Data di nascita'
      },
      {
        errorKey: 'gender',
        errorLabel: 'Sesso'
      }
    ],
    Responsabile: [
      {
        errorKey: 'errorComureiSubjectResponsibleFiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'comureiSubjectResponsible.fiscalCode',
        errorLabel: 'Codice Fiscale'
      },
      {
        errorKey: 'comureiSubjectResponsible.firstName',
        errorLabel: 'Nome'
      },
      {
        errorKey: 'comureiSubjectResponsible.lastName',
        errorLabel: 'Cognome'
      },
      {
        errorKey: 'comureiSubjectResponsible.gender',
        errorLabel: 'Sesso'
      },
      {
        errorKey: 'comureiSubjectResponsible.birthDate',
        errorLabel: 'Data di nascita'
      },
      {
        errorKey: 'comureiSubjectResponsible.location',
        errorLabel: 'Comune di nascita'
      },
      {
        errorKey: 'comureiSubjectResponsible.province',
        errorLabel: 'Provincia'
      },
      {
        errorKey: 'comureiSubjectResponsible.email',
        errorLabel: 'Email'
      },
      {
        errorKey: 'comureiSubjectResponsible.phone',
        errorLabel: 'Telofono'
      }
    ],
    'Posta Elettronica': [
      {
        errorKey: 'comureiSubjectPec.pecEmail',
        errorLabel: 'Posta Elettronica'
      },
      {
        errorKey: 'comureiSubjectPec.pecManager',
        errorLabel: 'Gestore PEC'
      },
      {
        errorKey: 'comureiSubjectPec.effectiveDate',
        errorLabel: 'Data DECORRENZA'
      },
      {
        errorKey: 'comureiSubjectPec.endDate',
        errorLabel: 'Data CESSAZIONE'
      },
      {
        errorKey: 'comureiSubjectPec.pecEmailAddress',
        errorLabel: 'Indirizzo PEC ultima pecEmailAddress'
      }
    ],
    Cancellazione: [
      {
        errorKey: 'comureiSubjectCancellation.cancellationDate',
        errorLabel: 'Data CANCELLAZIONE'
      },
      {
        errorKey: 'comureiSubjectCancellation.address',
        errorLabel: 'Indirizzo'
      },
      {
        errorKey: 'comureiSubjectCancellation.province',
        errorLabel: 'Provincia'
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeOne',
        errorLabel: 'Codice Fiscale operatore incorporante 1'
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeTwo',
        errorLabel: 'Codice Fiscale operatore incorporante 2'
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeThree',
        errorLabel: 'Codice Fiscale operatore incorporante 3'
      }
    ],
    'Impegno alla trasmissione': []
  }

  const formTabsWithErrors = {
    Soggetto: false,
    Responsabile: false,
    'Posta Elettronica': false,
    Cancellazione: false,
    'Impegno alla trasmissione': false
  }

  const getActivePropsAndTab = (errors) => {
    var tabsWithErrors = findTabsWithErrors(errors, formTabsWithErrors, formErrorsTabMapping)
    setTabWithError(tabsWithErrors)

    var activeErrorsLabels = undefined
    if (activeTab) {
      activeErrorsLabels = formErrorsTabMapping[activeTab]
    }

    if (activeErrorsLabels) {
      errors = Utility.extractErrors(errors, activeErrorsLabels)
    }

    return errors
  }

  // Funzione per cercare i tab con errori
  const findTabsWithErrors = (errors, formTabsWithErrors, formErrorsTabMapping) => {
    const updatedTabsWithErrors = { ...formTabsWithErrors } // Clone the initial state

    // Helper function to check recursively if a field exists in errors (including nested objects)
    const checkFieldInErrors = (errors, fieldKey) => {
      const keys = fieldKey.split('.') // Handle nested keys, e.g., comureiSubjectResponsible.fiscalCode
      let current = errors

      // Traverse through nested objects
      for (let key of keys) {
        if (current[key]) {
          current = current[key] // Dive deeper if the key exists
        } else {
          return false // If any key is not found, return false
        }
      }
      return true // If all keys are found, return true
    }

    // Iterate over each tab in the mapping
    for (let tab in formErrorsTabMapping) {
      const fields = formErrorsTabMapping[tab]

      // Check if at least one field in this tab has an error
      const hasError = fields.some(({ errorKey }) => checkFieldInErrors(errors, errorKey))

      // Update the tab status based on whether it has an error
      updatedTabsWithErrors[tab] = hasError
    }

    return updatedTabsWithErrors
  }

  useEffect(() => {
    if (!tabError.subject) {
      if (!document.getElementById('tab-sub').classList.contains('active')) {
        document.getElementById('tab-sub').click()
      }
    } else if (!tabError.responsible) {
      if (!document.getElementById('tab-resp').classList.contains('active')) {
        document.getElementById('tab-resp').click()
      }
    } else if (!tabError.pec) {
      if (!document.getElementById('tab-pec').classList.contains('active')) {
        document.getElementById('tab-pec').click()
      }
    } else if (!tabError.canc) {
      if (!document.getElementById('tab-delete').classList.contains('active')) {
        document.getElementById('tab-delete').click()
      }
    }
  }, [tabError])

  useEffect(() => {
    checkRemainingSubjects()
  }, [])

  const checkIncorporatingFiscalCodeOne = (
    comureiCommunicationType,
    comureiSubjectCancellation = {}
  ) => {
    const cancellationReason = comureiSubjectCancellation?.comureiSubjectCancellationType?.id
    if (comureiCommunicationType?.id?.toString() === '2') {
      if (cancellationReason === '5') {
        return (
          (!comureiSubjectCancellation.incorporatingFiscalCodeOne &&
            !comureiSubjectCancellation.incorporatingFiscalCodeTwo) ||
          (!comureiSubjectCancellation.incorporatingFiscalCodeOne &&
            !comureiSubjectCancellation.incorporatingFiscalCodeThree) ||
          (!comureiSubjectCancellation.incorporatingFiscalCodeTwo &&
            !comureiSubjectCancellation.incorporatingFiscalCodeThree)
        )
      }
    }
    return false
  }

  const checkRemainingSubjects = () => {
    CheckRemainingSubjects(Constants.APPLICATION_COMUREI, false, (subjectSummary) => {
      setSubjectSummary(subjectSummary)
    })
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : 'form-on-bottom'}>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert &&
              touched &&
              errors &&
              typeof errors === 'object' &&
              Object.keys(errors).length > 0 && (
                <div className={props.formOnBottom ? 'comurei-form-on-bottom-error' : ''}>
                  <ErrorListAlert
                    errors={getActivePropsAndTab(errors, activeTab)}
                    hide={() => setShowErrorAlert(false)}
                  />
                </div>
              )}
            <FormBody
              {...props}
              loading={loading}
              activeTab={activeTab}
              touched={touched}
              initialValues={initialValues}
              subjectSummary={subjectSummary}
              errors={errors}
              windowWidth={windowWidth}
              formErrorsTabMapping={formErrorsTabMapping}
              formTabsWithErrors={formTabsWithErrors}
              findTabsWithErrors={findTabsWithErrors}
              retrieveSubject={retrieveSubject}
              setLoading={setLoading}
              setTouched={setTouched}
              setActiveTab={setActiveTab}
              setTabWithError={setTabWithError}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const handlerChangeSubjectType = (e) => {
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setValues({ ...values, comureiSubjectType: { id: parseInt(e.target.value), code: cod } })
  }

  const handleChangeTextField = (value, field, trim) => {
    if (props.disabled) return
    if (typeof value === 'string') {
      value = value ? value?.toUpperCase() : ''
      if (trim) value = value?.trim()
    }
    const form = Object.assign({}, values)
    Utility.updateObjProp(form, value, field)
    setValues(form)
  }

  const handleChangeComureiTrasmissionTypeCheckbox = (value) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    if (form.comureiTransmission && !form.comureiTransmission.comureiTransmissionType) {
      form.comureiTransmission.comureiTransmissionType = {}
    }
    form.comureiTransmission.comureiTransmissionType.id = value
    setValues(form)
  }

  const handleChangeComureiSubjectCancellationTypeCheckbox = (value) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    if (
      form.comureiSubjectCancellation &&
      !form.comureiSubjectCancellation.comureiSubjectCancellationType
    ) {
      form.comureiSubjectCancellation.comureiSubjectCancellationType = {}
    }
    form.comureiSubjectCancellation.comureiSubjectCancellationType.id = value
    setValues(form)
  }

  const handleFiscalCode = (val) => {
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    const form = Object.assign({}, values)
    actions
      .getBaseRegistryByFiscalCode(val)
      .then((baseRegistry) => {
        if (baseRegistry) {
          if (values.personType === Constants.PNF) {
            form.companyName = baseRegistry?.companyName || values.companyName || ''
            form.location = baseRegistry?.location?.location || values.location || ''
            form.province = baseRegistry?.location?.province || values.province || ''
            form.firstName = null
            form.lastName = null
            form.birthDate = null
            form.gender = null
          } else if (values.personType === Constants.PF) {
            form.companyName = null
            form.location = baseRegistry?.location?.location || values.location || ''
            form.province = baseRegistry?.location?.province || values.province || ''
            form.firstName = baseRegistry?.firstName || ''
            form.lastName = baseRegistry?.lastName || ''
            form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
            form.gender = baseRegistry?.gender || ''
          }
          form.errorFiscalCode = false
          setValues(form)
        } else if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(val).toJSON()
            if (cf) {
              const form = Object.assign({}, values)
              form.birthDate = form.birthDate || cf?.birthday ? new Date(cf?.birthday) : null
              form.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
              if (cf.birthplaceProvincia !== 'EE') {
                form.location = form.location || cf?.birthplace.toUpperCase()
              }
              form.province = form.province || cf?.birthplaceProvincia || ''
              setValues(form)
            }
            form.errorFiscalCode = false
            setValues(form)
          } catch (e) {
            form.errorFiscalCode = true
            setValues(form)
          }
        }
      })
      .catch(() => {
        form.errorFiscalCode = true
        setValues(form)
      })
  }

  const handleChangeValue = (val, field) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    Utility.updateObjProp(form, val, field)
    setValues(form)
  }

  const handlerResponsibleFiscalCode = (val) => {
    const form = Object.assign({}, values)
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    actions
      .getBaseRegistryPersonByFiscalCode(val)
      .then((baseRegistry) => {
        if (baseRegistry !== undefined && baseRegistry?.personType === Constants.PF) {
          const form = Object.assign({}, values)
          form.comureiSubjectResponsible.fiscalCode = val
          form.comureiSubjectResponsible.lastName = baseRegistry?.lastName || ''
          form.comureiSubjectResponsible.firstName = baseRegistry?.firstName || ''
          form.comureiSubjectResponsible.location = baseRegistry?.location?.location || ''
          form.comureiSubjectResponsible.province = baseRegistry?.location?.province || ''
          form.comureiSubjectResponsible.birthDate = baseRegistry?.birthDate
            ? new Date(baseRegistry?.birthDate)
            : null
          form.comureiSubjectResponsible.gender = baseRegistry?.gender
          form.errorComureiSubjectResponsibleFiscalCode = false
          setValues(form)
        } else if (form.personType === Constants.PF) {
          try {
            const cf = new CodiceFiscale(val).toJSON()
            if (cf) {
              const form = Object.assign({}, values)
              form.comureiSubjectResponsible.location =
                cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace : ''
              form.comureiSubjectResponsible.province = cf?.birthplaceProvincia || ''
              form.comureiSubjectResponsible.birthDate = cf?.birthday
                ? new Date(cf?.birthday)
                : null
              form.comureiSubjectResponsible.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
            }
            form.errorComureiSubjectResponsibleFiscalCode = false
            setValues(form)
          } catch (e) {
            form.errorComureiSubjectResponsibleFiscalCode = true
            setValues(form)
          }
        }
      })
      .catch(() => {
        form.errorComureiSubjectResponsibleFiscalCode = true
        setValues(form)
      })
  }

  const handleInputAutocompile = (newLocation, objectKey) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        if (objectKey) {
          form[objectKey].location = newLocation
          if (loc) form[objectKey].province = loc.province
        } else {
          form.location = newLocation
          if (loc) form.province = loc.province
        }
        setValues(form)
      },
      (errors) => {
        const form = Object.assign({}, values)
        if (objectKey) {
          form[objectKey].location = ''
          form[objectKey].province = ''
        } else {
          form.location = ''
          form.province = ''
        }
        setValues(form)
      }
    )
  }

  const setTab = (tab) => {
    props.setActiveTab(tab)
  }

  const handleFormSubmit = async (sendComm) => {
    const errors = await validateForm(values) // Validazione dei valori
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      updateAll(values, sendComm) // Esegui l'aggiornamento se non ci sono errori
      var tabsWithErrors = props.findTabsWithErrors(
        props.errors,
        props.formTabsWithErrors,
        props.formErrorsTabMapping
      )
      props.setTabWithError(tabsWithErrors)
      props.setShowErrorAlert(false) // Nascondi l'alert
    }
  }

  const close = (retrieve = false, id = null) => {
    if (retrieve) props.retrieveSubject(props.subjectId)
    props.setTouched(false)
    props.setTabWithError({})
    if (props.close) props.close(props.subjectId ? null : id)
  }

  const updateAll = (values, sendComm) => {
    let newSubject = { ...values }
    if (newSubject.personType === Constants.PF) {
      newSubject.companyName = null
    } else {
      newSubject.gender = null
      newSubject.firstName = null
      newSubject.lastName = null
      newSubject.birthDate = null
    }
    addComureiSubject(
      {
        ...newSubject,
        id: values?.id,
        errorFiscalCode: undefined,
        errorComureiSubjectResponsibleFiscalCode: undefined
      },
      sendComm
    )
  }

  const addComureiSubject = (newSubject, sendComm) => {
    props.setLoading(true)
    actions.addComureiSubject(newSubject).then(
      (response) => {
        if (sendComm) {
          createCommunication(response)
          props.getSubjects()
        } else {
          PopupConfirm({
            text: 'DATI SALVATI, SI DESIDERA CREARE LA COMUNICAZIONE?',
            confirm: 'CREA COMUNICAZIONE',
            cancel: 'CHIUDI',
            titleColor: titleColors.SUCCESS,
            title: 'Soggetto salvato correttamente',
            handleClickConfirm: () => createCommunication(response)
          })
          close(false, response.id)
        }
        props.setLoading(false)
        props.getSubjects()
      },
      (errors) => {
        props.setLoading(false)
        if (errors?.status === 511) {
          PopupSubjectLicense({
            total: props.subjectSummary.total,
            used: props.subjectSummary.used,
            link: Constants.COMUREI_BUY_LINK
          })
        } else {
          PopupError({
            text: Constants.APPLICATION_GENERIC_ERROR
          })
        }
      }
    )

    if (values?.id === null) close()
  }

  const createCommunication = (response) => {
    props.setLoading(true)
    actions.createComureiCommunication(response).then(
      (communications) => {
        props.setLoading(false)
        props.openCreateCommunicationModal(response, communications)
      },
      (errors) => {
        props.setLoading(false)
        if (errors?.status === 511) {
          PopupSubjectLicense({
            total: props.subjectSummary.total,
            used: props.subjectSummary.used,
            link: Constants.COMUREI_BUY_LINK
          })
        } else if (errors?.data?.title) {
          PopupError({
            text: errors?.data?.title
          })
        } else {
          PopupError({
            text: 'Errore nella generazione della comunicazione'
          })
        }
      }
    )
  }

  return (
    <>
      <div className="row-detail comurei d-flex">
        <div className="tab-content tab-content-comurei col-12 pe-3">
          <Tabs activeTab={props.activeTab} setTab={setTab}>
            <div id="tab-sub" label="Soggetto">
              <div className="row">
                <div className={`w-60 w-lg-55 subDetailComurei pe-2`}>
                  {/* <h6>Dettaglio Soggetto che Comunica</h6> */}
                  <div className="row mb-2">
                    {/* TIPO PERSONA */}
                    <div className="w-12 w-lg-18 pt-1 ps-2">
                      <div className="input-group">
                        <div className="inputRadio" style={{ fontSize: '15px' }}>
                          <label
                            disabled={props.disabled}
                            className="form-check-label d-flex align-items-center"
                            htmlFor={`pType1${values?.id}`}
                            onClick={() => handleChangeValue(Constants.PNF, 'personType')}>
                            <input
                              disabled={props.disabled}
                              className="form-check-input pe-1 me-2 mt-0"
                              style={{
                                width: '1.2em',
                                height: '1.2em',
                                boxShadow: 'none'
                              }}
                              type="radio"
                              checked={values.personType === Constants.PNF}
                              onChange={() => {}}
                            />
                            P. giuridica
                          </label>
                        </div>
                        <div className="inputRadio" style={{ fontSize: '15px' }}>
                          <label
                            disabled={props.disabled}
                            className="form-check-label d-flex align-items-center"
                            htmlFor={`pType2${values?.id}`}
                            onClick={() => handleChangeValue(Constants.PF, 'personType')}>
                            <input
                              disabled={props.disabled}
                              className="form-check-input pe-1 me-2 mt-0"
                              style={{
                                width: '1.2em',
                                height: '1.2em',
                                boxShadow: 'none'
                              }}
                              type="radio"
                              checked={values.personType === Constants.PF}
                              onChange={() => {}}
                            />
                            P. fisica
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* TIPO SOGGETTO */}
                    <div className="w-65 w-lg-56 mb-2 pe-2">
                      <BwmSelect
                        disabled={props.disabled}
                        options={props.subjectTypes || []}
                        name="comureiSubjectType"
                        label="Tipo Soggetto"
                        className="form-control"
                        showCode={true}
                        value={values.comureiSubjectType?.id}
                        error={errors.comureiSubjectType}
                        touched={props.touched}
                        onChange={(e) => handlerChangeSubjectType(e)}
                      />
                    </div>

                    {/* CODICE FISCALE */}
                    <div className={`w-15 w-lg-18 mb-2 pe-2`}>
                      <BwmInput
                        disabled={props.disabled}
                        name="fiscalCode"
                        label="Codice Fiscale"
                        className="form-control"
                        value={values.fiscalCode}
                        error={errors.fiscalCode || errors.errorFiscalCode}
                        touched={props.touched}
                        maxLength="16"
                        onBlur={(e) => handleFiscalCode(e.target.value)}
                        onChange={(e) => handleChangeTextField(e.target.value, 'fiscalCode', true)}
                      />
                    </div>

                    <div className="w-6 w-lg-8 pe-2">
                      {/* PROVINCIA */}
                      <ProvinceField
                        disabled={props.disabled}
                        label="Prov."
                        placeholder="EE per Estero"
                        maxLength="2"
                        province={values.province}
                        error={errors.province}
                        touched={props.touched}
                        onChange={(e) => handleChangeTextField(e.target.value, 'province', true)}
                      />
                    </div>

                    {values.personType === Constants.PNF && (
                      <div className="w-78 w-lg-80 mb-2 pe-2">
                        {/* RAGIONE SOCIALE */}
                        <BwmInput
                          disabled={props.disabled}
                          name="companyName"
                          label="Ragione Sociale"
                          className="form-control font-weight-bold font-size-big"
                          maxLength="60"
                          value={values.companyName}
                          error={errors.companyName}
                          touched={props.touched}
                          onChange={(e) => handleChangeTextField(e.target.value, 'companyName')}
                        />
                      </div>
                    )}
                    {values.personType === Constants.PF && (
                      <>
                        {/* NOME */}
                        <div className="w-22 w-lg-34 pe-2 mb-2">
                          <BwmInput
                            disabled={props.disabled}
                            name="firstName"
                            label="Nome"
                            className="form-control font-weight-bold font-size-big"
                            maxLength="60"
                            value={values.firstName}
                            error={errors.firstName}
                            touched={props.touched}
                            onChange={(e) => handleChangeTextField(e.target.value, 'firstName')}
                          />
                        </div>
                        {/* COGNOME */}
                        <div className="w-23 w-lg-34 pe-2">
                          <BwmInput
                            disabled={props.disabled}
                            name="lastName"
                            label="Cognome"
                            className="form-control font-weight-bold font-size-big"
                            maxLength="60"
                            value={values.lastName}
                            error={errors.lastName}
                            touched={props.touched}
                            onChange={(e) => handleChangeTextField(e.target.value, 'lastName')}
                          />
                        </div>

                        {/* SESSO */}
                        <div className="w-8 w-lg-12 hide-error-icon pe-2">
                          <BwmSelect
                            disabled={props.disabled}
                            options={Constants.genderList || []}
                            name="gender"
                            label="Sesso"
                            className="form-control"
                            value={values.gender}
                            error={errors.gender}
                            touched={props.touched}
                            onChange={(e) => handleChangeTextField(e.target.value, 'gender')}
                          />
                        </div>
                        {/* DATA DI NASCITA */}
                        <div className="w-15 w-lg-19 pe-2">
                          <DateField
                            disabled={props.disabled}
                            label="Data di nascita"
                            date={values.birthDate}
                            error={errors.birthDate}
                            touched={props.touched}
                            onChange={(value) => handleChangeValue(value, 'birthDate')}
                          />
                        </div>
                      </>
                    )}

                    <div className={`${values.personType === Constants.PF ? 'w-30' : 'w-20'} pe-2`}>
                      {/* LOCALITÀ */}
                      <AutoCompileCustom
                        disabled={props.disabled}
                        label={
                          values.personType === Constants.PNF ? 'Sede Legale' : 'Comune di nascita'
                        }
                        placeholder={values.personType === Constants.PNF ? 'Località' : undefined}
                        id="location"
                        filter={props.locations}
                        value={values.location}
                        error={errors.location}
                        touched={props.touched}
                        handleInputAutocompile={(e) => handleInputAutocompile(e, undefined)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row w-40 w-lg-45" style={{ height: '180px' }}>
                  {/* Sezione Tipo Comunicazione (a sinistra) */}
                  <div className="col-6 pe-5">
                    {/* <h6>Dettaglio Comunicazione</h6> */}
                    <div className="form-check">
                      <label>Tipo Comunicazione</label>
                      <div className="input-group mt-2 d-flex flex-column">
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'2'}
                              id={`ct2${values?.id}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`ct2${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('2', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '2'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Cancellazione dal/dai Registro/i"
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '2' ? 'fw-bold' : ''
                                }>
                                Cancellazione dal/dai Registro/i
                              </span>
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'1'}
                              id={`ct1${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`ct1${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('1', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '1'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Prima iscrizione"
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '1' ? 'fw-bold' : ''
                                }>
                                Prima iscrizione
                              </span>
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'3'}
                              id={`ct3${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`ct3${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('3', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '3'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Variazione di dati già comunicati"
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '3' ? 'fw-bold' : ''
                                }>
                                Variazione di dati già comunicati
                              </span>
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'4'}
                              id={`ct4${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`ct4${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('4', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '4'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Reiscrizione al/ai Registri"
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '4' ? 'fw-bold' : ''
                                }>
                                Reiscrizione al/ai Registri
                              </span>
                            </label>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sezione Motivi della Comunicazione (a destra) */}
                  <div className="col-6">
                    <label
                      className="mb-3"
                      style={{ color: '#128186', fontWeight: 'bold', marginRight: '10px' }}>
                      Motivi della Comunicazione (sezioni REI)
                    </label>
                    <div className="form-check col-12 p-0">
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`registryReason_${values?.id}`}
                        label={
                          <span
                            className={values.registryReason ? 'fw-bold' : ''}
                            style={{ fontSize: '13px' }}>
                            Indagini Finanziarie (Anagrafe Rapporti)
                          </span>
                        }
                        className="form-control"
                        checked={values.registryReason}
                        error={errors.registryReason}
                        touched={props.touched}
                        onChange={(e) => handleChangeValue(e.target.checked, 'registryReason')}
                      />
                    </div>

                    <div className="form-check col-12 p-0">
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`monitoringReason_${values?.id}`}
                        label={
                          <span
                            className={values.monitoringReason ? 'fw-bold' : ''}
                            style={{ fontSize: '13px' }}>
                            Monitoraggio fiscale
                          </span>
                        }
                        className="form-control"
                        checked={values.monitoringReason}
                        error={errors.monitoringReason}
                        touched={props.touched}
                        onChange={(e) => handleChangeValue(e.target.checked, 'monitoringReason')}
                      />
                    </div>

                    <div className="form-check col-12 p-0">
                      {/* <label>Sezione FACTA/CRS</label> */}
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`factaReason_${values?.id}`}
                        label={
                          <span
                            className={values.factaReason ? 'fw-bold' : ''}
                            style={{ fontSize: '13px' }}>
                            (FACTA/CRS)
                          </span>
                        }
                        className="form-control"
                        checked={values.factaReason}
                        error={errors.factaReason}
                        touched={props.touched}
                        onChange={(e) => {
                          handleChangeValue(e.target.checked, 'factaReason')
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-check col-12 p-0 d-flex align-items-start">
                    <label
                      style={{
                        color: '#128186',
                        fontWeight: 'bold',
                        marginRight: '10px',
                        minWidth: '153px',
                        paddingBottom: '20px',
                        fontSize: '15px'
                      }}>
                      Struttura accentrata
                    </label>
                    <div className="d-flex align-items-center">
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`centralizedStructure_${values?.id}`}
                        label="Sì"
                        className="form-control"
                        labelStyle={{ minWidth: '50px' }}
                        checked={values.centralizedStructure}
                        error={errors.centralizedStructure}
                        touched={props.touched}
                        onChange={(e) =>
                          handleChangeValue(e.target.checked, 'centralizedStructure')
                        }
                      />
                    </div>

                    {values.centralizedStructure && (
                      <span className="text-danger ms-1 me-1 fw-bold">
                        per le strutture accentrate non sarà possibile caricare l'impegno alla
                        trasmissione di un intermediario
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-resp" className="d-none" label="Responsabile">
              <div className="form-row">
                <div className="col-2 pe-2 mb-3 mt-2">
                  <BwmInput
                    disabled={props.disabled}
                    label="Codice Fiscale"
                    className="form-control"
                    maxLength="16"
                    value={values.comureiSubjectResponsible?.fiscalCode}
                    touched={props.touched}
                    error={
                      errors.comureiSubjectResponsible?.fiscalCode ||
                      errors.errorComureiSubjectResponsibleFiscalCode
                    }
                    onBlur={(e) => handlerResponsibleFiscalCode(e.target.value)}
                    onChange={(e) =>
                      handleChangeTextField(
                        e.target.value,
                        'comureiSubjectResponsible.fiscalCode',
                        true
                      )
                    }
                  />
                </div>
                <div className="col-2 pe-2 mb-3 mt-2">
                  <BwmInput
                    disabled={props.disabled}
                    label="Cognome"
                    className="form-control font-weight-bold font-size-big"
                    maxLength="60"
                    value={values.comureiSubjectResponsible?.lastName}
                    error={errors.comureiSubjectResponsible?.lastName}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.lastName')
                    }
                  />
                </div>
                <div className="col-2 mt-2 mb-3 pe-2">
                  <BwmInput
                    disabled={props.disabled}
                    label="Nome"
                    className="form-control font-weight-bold font-size-big"
                    maxLength="60"
                    value={values.comureiSubjectResponsible?.firstName}
                    error={errors.comureiSubjectResponsible?.firstName}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.firstName')
                    }
                  />
                </div>
                <div className="col-1 pe-2 mb-3 mt-2">
                  <BwmSelect
                    disabled={props.disabled}
                    options={Constants.genderList || []}
                    name="responsibleGender"
                    label="Sesso"
                    className="form-control"
                    value={values.comureiSubjectResponsible?.gender}
                    error={errors.comureiSubjectResponsible?.gender}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.gender')
                    }
                  />
                </div>
                <div className="w-50 mb-3 mt-2 pe-2">
                  <DateField
                    disabled={props.disabled}
                    label="Data di nascita"
                    date={values.comureiSubjectResponsible?.birthDate}
                    error={errors.comureiSubjectResponsible?.birthDate}
                    touched={props.touched}
                    onChange={(value) =>
                      handleChangeTextField(value, 'comureiSubjectResponsible.birthDate')
                    }
                  />
                </div>
                <div className="col-2 pe-2 mb-3 mt-2">
                  <AutoCompileCustom
                    disabled={props.disabled}
                    label="Comune di nascita"
                    filter={props.locations}
                    value={values.comureiSubjectResponsible?.location}
                    error={errors.comureiSubjectResponsible?.location}
                    touched={props.touched}
                    handleInputAutocompile={(location) =>
                      handleInputAutocompile(location, 'comureiSubjectResponsible')
                    }
                  />
                </div>
                <div className="col-1 pe-2 mb-3 mt-2">
                  <ProvinceField
                    disabled={props.disabled}
                    label="Prov."
                    placeholder="EE per Estero"
                    province={values.comureiSubjectResponsible?.province}
                    error={errors.comureiSubjectResponsible?.province}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(
                        e.target.value,
                        'comureiSubjectResponsible.province',
                        true
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-row"></div>
              <div className="form-row mb-4">
                <div className="col-4 me-2 mb-3">
                  <BwmInput
                    disabled={props.disabled}
                    label="Indirizzo Email (indirizzo personale del responsabile)"
                    className="form-control"
                    maxLength="60"
                    value={values.comureiSubjectResponsible?.email}
                    error={errors.comureiSubjectResponsible?.email}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.email', true)
                    }
                  />
                </div>
                <div className="col-2 mb-3">
                  <BwmInput
                    disabled={props.disabled}
                    label="Telefono"
                    className="form-control"
                    maxLength="10"
                    value={values.comureiSubjectResponsible?.phone}
                    error={errors.comureiSubjectResponsible?.phone}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.phone', true)
                    }
                  />
                </div>
              </div>
            </div>
            <div id="tab-pec" className="d-none" label="Posta Elettronica">
              <div className="form-row">
                <div className="w-50 w-lg-40">
                  <div className="form-row mb-3 pe-3">
                    <div
                      className={`col-12 ${!(values.comureiCommunicationType?.id?.toString() === '1' || values.comureiCommunicationType?.id?.toString() === '4') ? 'hidden' : ''}`}>
                      <BwmInput
                        disabled={props.disabled}
                        label={`${values.comureiCommunicationType?.id === '4' ? 'INDIRIZZO PEC DA REISCRIVERE AL REI' : 'INDIRIZZO PEC DA SCRIVERE AL REI'} `}
                        className="form-control"
                        maxLength="100"
                        value={values.comureiSubjectPec?.pecEmail}
                        error={errors.comureiSubjectPec?.pecEmail}
                        touched={props.touched}
                        onChange={(e) =>
                          handleChangeTextField(e.target.value, 'comureiSubjectPec.pecEmail', true)
                        }
                      />
                    </div>
                  </div>
                  {values.comureiCommunicationType?.id?.toString() !== '2' && (
                    <div className="form-row mb-3 pe-3">
                      <div
                        className={`w-46 pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                        <BwmInput
                          disabled={props.disabled}
                          label="Gestore PEC"
                          className="form-control"
                          maxLength="100"
                          value={values.comureiSubjectPec?.pecManager}
                          error={errors.comureiSubjectPec?.pecManager}
                          touched={props.touched}
                          onChange={(e) =>
                            handleChangeTextField(e.target.value, 'comureiSubjectPec.pecManager')
                          }
                        />
                      </div>
                      {values.comureiCommunicationType?.id?.toString() !== '2' && (
                        <>
                          <div
                            className={`w-32 pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                            <DateField
                              disabled={props.disabled}
                              label={'Data DECORRENZA'}
                              date={values.comureiSubjectPec?.effectiveDate}
                              error={errors.comureiSubjectPec?.effectiveDate}
                              touched={props.touched}
                              onChange={(value) =>
                                handleChangeTextField(value, 'comureiSubjectPec.effectiveDate')
                              }
                            />
                          </div>
                          <div
                            className={`w-32 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                            <DateField
                              disabled={props.disabled}
                              label={'Data CESSAZIONE'}
                              date={values.comureiSubjectPec?.endDate}
                              error={errors.comureiSubjectPec?.endDate}
                              touched={props.touched}
                              onChange={(value) =>
                                handleChangeTextField(value, 'comureiSubjectPec.endDate')
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className={`form-row ${values.comureiCommunicationType?.id?.toString() === '2' || values.comureiCommunicationType?.id?.toString() === '3' ? '' : 'hidden'}`}>
                    <div
                      className={
                        values.comureiCommunicationType?.id?.toString() === '2'
                          ? 'col-10 pe-2'
                          : 'col-5 pe-2'
                      }>
                      <BwmInput
                        disabled={props.disabled}
                        label={` ${values.comureiCommunicationType?.id?.toString() === '2' ? 'Indirizzo di posta elettronica già comunicata al REI DA CANCELLARE' : 'Indirizzo PEC già comunicato DA VARIARE'}`}
                        className="form-control"
                        maxLength="255"
                        value={values.comureiSubjectPec?.reiEmail}
                        error={errors.comureiSubjectPec?.reiEmail}
                        touched={props.touched}
                        onChange={(e) =>
                          handleChangeTextField(e.target.value, 'comureiSubjectPec.reiEmail', true)
                        }
                      />
                    </div>
                    {values.comureiCommunicationType?.id?.toString() === '3' && (
                      <div className={'col-5'}>
                        <BwmInput
                          disabled={props.disabled}
                          label="Indirizzo PEC da variare"
                          className="form-control"
                          maxLength="255"
                          value={values.comureiSubjectPec?.pecEmailAddress}
                          error={errors.comureiSubjectPec?.pecEmailAddress}
                          touched={props.touched}
                          onChange={(e) =>
                            handleChangeTextField(
                              e.target.value,
                              'comureiSubjectPec.pecEmailAddress',
                              true
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-50 w-lg-60 infoPostaEle mb-3">
                  <div className="infoPoint">
                    <strong className="pe-1">Indirizzo EMAIL PEC:</strong> lo stesso da indicare
                    nella RICHIESTA DI ACCREDITAMENTO al SID
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">Gestore PEC:</strong> Il gestore del servizio PEC
                    (Infocamere, Aruba, PEC.it etc.)
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">Data DECORRENZA:</strong> è l'inizio del contratto con
                    il gestore del servizio PEC
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">Data CESSAZIONE:</strong> non obbligatoria. Se indicata
                    in caso di rinnovo <strong>NON</strong> deve essere fatta una nuova
                    comunicazione
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">La Data VARIAZIONE</strong> non è più prevista quindi{' '}
                    <strong>NON</strong> va indicata
                  </div>
                </div>
              </div>
            </div>
            {values.comureiCommunicationType?.id?.toString() === '2' ? (
              <div id="tab-delete" className="d-none" label="Cancellazione">
                <div className="row">
                  <div className="col-2">
                    <DateField
                      disabled={props.disabled}
                      label={'Data CANCELLAZIONE'}
                      date={values.comureiSubjectCancellation?.cancellationDate}
                      error={errors.comureiSubjectCancellation?.cancellationDate}
                      touched={props.touched}
                      onChange={(value) =>
                        handleChangeTextField(value, 'comureiSubjectCancellation.cancellationDate')
                      }
                    />
                  </div>
                  <div className="col ps-5">
                    <div className="form-check">
                      <label>Motivo della CANCELLAZIONE</label>
                      <div className="input-group mt-2">
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'1'}
                              id={`cr1${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr1${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('1')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '1'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Cessazione  con confluenza dell'attività finanziaria in un solo operatore finanziario"
                              />{' '}
                              Cessazione con confluenza dell'attività finanziaria in un solo
                              operatore finanziario
                            </label>
                          </>
                        </div>
                        <div className="col-6 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'2'}
                              id={`cr2${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr2${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('2')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '2'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt=""
                              />{' '}
                              Perdita dei requisiti soggettivi
                            </label>
                          </>
                        </div>
                        <div className="col-6 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'3'}
                              id={`cr3${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr3${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('3')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '3'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Cessazione senza confluenza in altro soggetto"
                              />{' '}
                              Cessazione senza confluenza in altro soggetto
                            </label>
                          </>
                        </div>
                        <div className="col-6 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'4'}
                              id={`cr4${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr4${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('4')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '4'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Cessazione con confluenza in altro soggetto non operatore finanziario"
                              />{' '}
                              Cessazione con confluenza in altro soggetto non operatore finanziario
                            </label>
                          </>
                        </div>
                        <div className="col-6 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'5'}
                              id={`cr5${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr5${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('5')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '5'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Cessazione  con confluenza dell'attività finanziaria in più operatori finanziari"
                              />{' '}
                              Cessazione con confluenza dell'attività finanziaria in più operatori
                              finanziari
                            </label>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="w-100 p-0">
                    {(values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                      '2' ||
                      values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                        '3' ||
                      values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                        '4') && (
                      <div className="row">
                        <h6 className="w-15 w-lg-19 mt-3">
                          <strong>Disponibilità Contabilità Ordinaria:</strong>
                        </h6>
                        <div className="w-27 pe-2">
                          <BwmInput
                            disabled={props.disabled}
                            label="Indirizzo"
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.address}
                            error={errors.comureiSubjectCancellation?.address}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.address'
                              )
                            }
                          />
                        </div>
                        <div className="w-22 pe-2">
                          <AutoCompileCustom
                            disabled={props.disabled}
                            label="Comune Sede Legale"
                            filter={props.locations}
                            value={values.comureiSubjectCancellation?.location}
                            error={errors.comureiSubjectCancellation?.location}
                            touched={props.touched}
                            handleInputAutocompile={(e) =>
                              handleInputAutocompile(e, 'comureiSubjectCancellation')
                            }
                          />
                        </div>
                        <div className="w-12 pe-2">
                          <ProvinceField
                            disabled={props.disabled}
                            label="Prov."
                            placeholder="EE per Estero"
                            province={values.comureiSubjectCancellation?.province}
                            error={errors.comureiSubjectCancellation?.province}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.province',
                                true
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                      '1' && (
                      <div className="row">
                        <h6 className="w-15 w-lg-19 mt-3">
                          <strong>Disponibilità Contabilità Ordinaria:</strong>
                        </h6>
                        <div className="w-27">
                          <BwmInput
                            disabled={props.disabled}
                            label="Codice Fiscale operatore incorporante 1"
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeOne',
                                true
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                      '5' && (
                      <div className="row">
                        <h6 className="w-15 w-lg-19 mt-3">
                          <strong>Disponibilità Contabilità Ordinaria:</strong>
                        </h6>
                        <div className="w-18 w-lg-22 pe-2">
                          <BwmInput
                            disabled={props.disabled}
                            label="Codice Fiscale operatore incorporante 1"
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeOne',
                                true
                              )
                            }
                          />
                        </div>
                        <div className="w-18 w-lg-22 pe-2">
                          <BwmInput
                            disabled={props.disabled}
                            name="incorporatingFiscalCodeTwo"
                            label="Codice Fiscale operatore incorporante 2"
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeTwo',
                                true
                              )
                            }
                          />
                        </div>
                        <div className="w-18 w-lg-22">
                          <BwmInput
                            disabled={props.disabled}
                            name="incorporatingFiscalCodeThree"
                            label="Codice Fiscale operatore incorporante 3"
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeThree}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeThree}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeThree',
                                true
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div id="tab-delete" className="d-none" label="Cancellazione" disabled></div>
            )}
            {values.centralizedStructure ? (
              <div id="tab-transmission" className="d-none" label="Impegno alla trasmissione"></div>
            ) : (
              <div id="tab-transmission" className="d-none" label="Impegno alla trasmissione">
                <div className="form-row mb-2">
                  <div className="col-4">
                    <BwmInput
                      disabled={props.disabled}
                      label="Codice Fiscale"
                      className="form-control"
                      maxLength="100"
                      value={values.comureiTransmission?.fiscalCode}
                      error={errors.comureiTransmission?.fiscalCode}
                      touched={props.touched}
                      onChange={(e) =>
                        handleChangeTextField(
                          e.target.value,
                          'comureiTransmission.fiscalCode',
                          true
                        )
                      }
                    />
                  </div>
                  <div className="col-8 commDetailComurei" style={{ paddingLeft: '130px' }}>
                    <div className="form-check">
                      <label>Impegno alla trasmissione telematica</label>
                      <div className="input-group mt-2">
                        <div className="inputRadio me-5">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'1'}
                              id={`tt1${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`tt1${values?.id}`}
                              onClick={() => handleChangeComureiTrasmissionTypeCheckbox('1')}>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiTransmission?.comureiTransmissionType?.id === '1'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Da CONTRIBUENTE"
                              />
                              Da CONTRIBUENTE
                            </label>
                          </>
                        </div>
                        <div className="inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'2'}
                              id={`tt2${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`tt2${values?.id}`}
                              onClick={() => handleChangeComureiTrasmissionTypeCheckbox('2')}>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiTransmission?.comureiTransmissionType?.id === '2'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt="Da chi effettua l'INVIO"
                              />
                              Da chi effettua l'INVIO
                            </label>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-9 me-4">
                    <div className="infoPostaEle panelTrasm">
                      <div className="infoPoint-title ms-0 mb-3">
                        <IInfo fill="#b8b8b8" width="20" /> Informazioni
                      </div>
                      <div className="infoPoint m-0">
                        Compilare solo nel caso di comunicazione spedita tramite Entratel di un
                        intermediario (commercialista) diverso dal soggetto obbligato.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Tabs>
        </div>
      </div>
      <div className="form-row row align-item-center">
        <div className="d-flex justify-content-between">
          {/* Bottone Annulla */}
          <div className="d-flex align-items-center">
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
              onClick={() => close(true)}>
              <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
              &nbsp; ANNULLA
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
          </div>
          {/* Bottone Crea Comunicazione */}
          <div className="d-flex align-items-center">
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary btn-empty px-5 ml-2 me-4"
              onClick={() => handleFormSubmit(true)}>
              <ITextFile
                className={'padding-right-4px svg'}
                width="20"
                height="18"
                fill={'#128186'}
              />
              &nbsp; CREA COMUNICAZIONE
            </button>

            {/* Bottone Conferma */}
            <button
              disabled={props.disabled}
              type="submit"
              className="btn btn-primary btn-new-rel px-5 ml-2"
              onClick={() => handleFormSubmit(false)}>
              <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
              &nbsp; CONFERMA
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
