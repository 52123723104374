import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import starMulticolor from '../../styles/images/star_multicolor.png'
import menuOpen from '../../styles/images/menu_open.png'
import arrowL from '../../styles/images/arrow_l.png'
import AppBox from './AppBox'
import { CustomerBox } from './CustomerBox'
import crsNegative from '../../styles/images/app/negative/Crs.png'
import coraNegative from '../../styles/images/app/negative/Cora.png'
import comureiNegative from '../../styles/images/app/negative/Comurei.png'
import corproNegative from '../../styles/images/app/negative/Corpro.png'
import arcoNegative from '../../styles/images/app/negative/Arco.png'
import blueNegative from '../../styles/images/app/negative/Blue.png'
import facciamoNoiLogo from '../../styles/images/app/negative/FacciamoNoi.png'
import { Overlay } from 'react-bootstrap'
import { getCurrentApplicationName } from './Utility'
import authService from '../../services/auth-service'
import { FAKE_USER } from '../../config/Constants'

const Header = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  const [showHeader, setShowHeader] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [hasAppBox, setHasAppBox] = useState(false)
  const [fakeUser, setFakeUser] = useState(undefined)
  const [showAppBox, setShowAppBox] = useState(false)
  const [showCustomerBox, setShowCustomerBox] = useState(false)

  const customerBoxButtonRef = useRef(null)
  const appBoxButtonRef = useRef(null)

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const selectedLogin = queryParameters.get('asUser')
    if (selectedLogin) {
      authService.setHeader(FAKE_USER, selectedLogin)
    }
  }, [])

  useEffect(() => {
    const updateState = () => {
      setShowHeader(location.pathname !== '/login' && location.pathname !== '/')
      setIsAdmin(auth?.isAdmin)
      setIsLoggedIn(auth?.isAuth)
      setHasAppBox(
        ['cora', 'comurei', 'crs', 'corpro', 'arco', 'blue'].some((e) =>
          location.pathname.includes(e)
        )
      )
      setFakeUser(authService.getHeader(FAKE_USER))
    }

    updateState()
  }, [location, auth])

  const handleClickMenuApp = () => {
    if (location.pathname !== '/dashboard') {
      setShowAppBox((prev) => !prev)
    }
  }

  const handleClickMenuCustomer = () => {
    setShowCustomerBox((prev) => !prev)
  }

  const logout = () => {
    setShowCustomerBox(false)
    dispatch({ type: 'LOGOUT' }) // Adjust based on your redux setup
  }

  const renderBrand = (title) => {
    switch (getCurrentApplicationName()) {
      case 'cora':
        return renderNavbar(coraNegative, 'CORA', title)
      case 'comurei':
        return renderNavbar(comureiNegative, 'COMUNEI', title)
      case 'crs':
        return renderNavbar(crsNegative, 'CRS', title)
      case 'corpro':
        return renderNavbar(corproNegative, 'CORPRO', title)
      case 'arco':
        return renderNavbar(arcoNegative, 'ARCO', title)
      case 'blue':
        return renderNavbar(blueNegative, 'BLUE', title)
      case 'facciamo-noi':
        return renderNavbar(facciamoNoiLogo, 'FACCIAMONOI', title)
      default:
        return (
          <ul className="navbar-nav">
            <li className="nav-item">
              {hasAppBox && (
                <div className="m-auto">
                  <img src={menuOpen} alt={`${title}`} width="28" height="18" />
                </div>
              )}
              <Link className="navbar-brand logo-dashboard" to="/">
                <img src={starMulticolor} alt={`${title}`} width="181" />
              </Link>
            </li>
          </ul>
        )
    }
  }

  const renderNavbar = (logo, altText, title) => (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link className="navbar-brand logo-app" to="/">
          <img src={starMulticolor} alt={`${title}`} width="181" />
        </Link>
        <div className="m-auto">
          <div className="vertical-line me-3 "></div>
        </div>
        <div className="m-auto">
          <img src={logo} alt={altText} width="160" />
        </div>
        {hasAppBox && (
          <div className="m-auto margin-left-2-percent" role="button" onClick={handleClickMenuApp}>
            <img src={menuOpen} alt={`${title}`} width="28" height="18" ref={appBoxButtonRef} />
          </div>
        )}
        <Overlay target={appBoxButtonRef.current} show={showAppBox} placement={'bottom'}>
          {({ ...props }) => (
            <div className="app-box-overlay" {...props}>
              <AppBox mouseLeave={handleClickMenuApp} />
            </div>
          )}
        </Overlay>
      </li>
    </ul>
  )

  const renderOwnerSection = (user) => {
    if (isLoggedIn && user) {
      const displayName = user.firstName
        ? `${user.firstName} ${user.lastName || ''}`
        : user.customer?.companyName

      return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link nav-item clickable"
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.starsoluzioni.it/assistenza-remota/">
              <button type="button" className="btn btn-light px-4 btn-xs">
                ASSISTENZA
              </button>
            </a>
          </li>
          <li className="nav-item">
            <div className="dropdown-customer">
              <a
                className="nav-link nav-item clickable"
                onClick={handleClickMenuCustomer}
                ref={customerBoxButtonRef}>
                <span className="notranslate">{displayName}</span>
                <img src={arrowL} width={10} height={18} className="rotate-down" />
              </a>
            </div>

            <Overlay
              target={customerBoxButtonRef.current}
              show={showCustomerBox}
              placement={'bottom'}>
              {({ ...props }) => (
                <div className="customer-box-overlay" {...props}>
                  <CustomerBox logout={logout} mouseLeave={handleClickMenuCustomer} />
                </div>
              )}
            </Overlay>
          </li>
        </ul>
      )
    }
  }

  if (showHeader) {
    return (
      <nav id="header" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid p-0">
          <div id="navbarContent">
            {renderBrand('StarSoluzioni')}
            {isAdmin && !fakeUser && !location.pathname.endsWith('admin') && (
              <Link id="admin" className="nav-link active text-white" to="/admin">
                <button type="button" className="btn btn-light px-4">
                  DASHBOARD ADMIN
                </button>
              </Link>
            )}
            {fakeUser && (
              <>
                <span>
                  <strong className="pulse text-light px-4" style={{ fontSize: '2rem' }}>
                    LOGGATO COME {fakeUser}
                  </strong>
                </span>
                <Link onClick={() => authService.setHeader(FAKE_USER, '')} to={'/admin'}>
                  <button type="button" className="btn btn-light px-4">
                    ESCI
                  </button>
                </Link>
              </>
            )}
            {!fakeUser && <ul className="navbar-nav">{renderOwnerSection(auth.user)}</ul>}
          </div>
        </div>
      </nav>
    )
  }

  return null
}

export default Header
