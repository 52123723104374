import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Constants from '../../../config/Constants'
import { ReactComponent as IDelete } from '../../../styles/images/svg/trash-solid.svg'
import BlueSubjectList from './NewBlueSubjectList'
import Modal from 'react-bootstrap/Modal'
import { BtnEmpty } from '../../shared/BtnEmpty'
// import BlueSubjectModal from './NewBlueSubjectModal'
import { ReactComponent as IPlus } from '../../../styles/images/svgs/regular/plus.svg'
import BlueHistoryModal from './BlueHistoryModal'
import * as actions from '../../../actions'
import { PopupDelete } from '../../shared/PopupDelete'
import { PopupError } from '../../shared/PopupError'
import getText from './labels'
import BlueLanguageSelector from './BlueLanguageSelector'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../shared/PopupSuccess'
import { CheckRemainingSubjects, IsLicenseValid } from '../../shared/auth/license/LicenseChecker'
import { LicenseExpiredPopup } from '../../shared/auth/license/LicenseExpiredPopup'
import { SubjectTotalAndCallToAction } from '../../shared/SubjectTotalAndCallToAction'
import { Col, Row } from 'react-bootstrap'
import { baseSearchObject } from '../../shared/tables/TableUtils'

export default function BlueSubject() {
  const [selectedSubjects, setSelectedSubjects] = useState([])
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)
  const [subjects, setSubjects] = useState([])
  const [isLoading, setLoading] = useState(false)
  // FOR MODAL
  // const [showAddModal, setShowAddModal] = useState(false)
  // const [currSubject, setCurrSubject] = useState({})
  // const [subjectId, setSubjectId] = useState(undefined)
  const [expired, setExpired] = useState(false)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const [subjectHistoryId, setSubjectHistoryId] = useState(undefined)
  const [subjectSummary, setSubjectSummary] = useState(undefined)
  const [customerApplication, setCustomerApplication] = useState()
  const [resetForm, setResetForm] = useState(false)

  useEffect(() => {
    getSubjects()
    getLocations()
    CheckRemainingSubjects(Constants.APPLICATION_BLUE, false, (subjectSummary) => {
      setDisableNewSubjectButtons(subjectSummary?.available < 1)
      setSubjectSummary(subjectSummary)
    })
  }, [])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_BLUE)
    }
  }
  useEffect(() => {
    setExpired(isExpired())
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_BLUE
    )
    const customerApplication = auth.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  useEffect(() => {
    if (expired) {
      LicenseExpiredPopup({ language: lang })
    }
  }, [expired])

  const getSubjects = () => {
    actions.getAllBlueSubjects(baseSearchObject).then((res) => {
      setSubjects(res)
      CheckRemainingSubjects(Constants.APPLICATION_BLUE, false, (subjectSummary) => {
        setDisableNewSubjectButtons(subjectSummary?.available < 1)
        setSubjectSummary(subjectSummary)
      })
    })
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const openPopupDelete = () => {
    PopupDelete({
      title: labels.DELETESUBJECTSPOPUPTITLE,
      cancelText: labels.CANCEL,
      confirmText: labels.CONFIRM,
      handleClickConfirm: deleteBlueSubject
    })
  }

  const deleteBlueSubject = () => {
    setLoading(true)
    let ids = []
    selectedSubjects.forEach((subject) => ids.push(subject))

    actions
      .deleteBlueSubjects(ids)
      .then(
        (res) => {
          PopupSuccess({
            title: labels.SUCCESSTITLE,
            text: labels.SUCCESSTITLE,
            ok: labels.SUCCESSOK
          })
          setSelectedSubjects([])
          getSubjects()
        },
        (err) => {
          PopupError({
            text: labels.GENERICERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => {
        setLoading(false)
      })
  }

  const dowloadLocalDocument = (fileName) => {
    const link = document.createElement('a')
    link.href = process.env.PUBLIC_URL + '/docs/' + fileName
    link.setAttribute('download', fileName)
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // FOR MODAL
  // const handleAddClick = () => {
  //   setShowAddModal(true)
  // }

  // const handleAddClose = () => {
  //   setShowAddModal(false)
  // }

  const handleHistoryClose = () => {
    setSubjectHistoryId()
  }

  const handleHistoryClick = (subjectId) => {
    setSubjectHistoryId(subjectId)
  }

  // FORM MODAL
  // const handleDetailClick = (subjectId, subjectsList) => {
  //   setSubjectId(subjectId)
  //   setCurrSubject(subjectsList.find((obj) => obj.id === subjectId))
  // }

  // const handleDetailClose = () => {
  //   setSubjectId(undefined)
  //   setCurrSubject(undefined)
  // }

  const renderActionsComponent = () => (
    <div className="col-12 ps-0">
      <div className="btn-group" role="group" aria-label="action buttons">
        <button
          type="button"
          disabled={selectedSubjects.length === 0}
          className={`btn btn-outline-primary btn-empty btn-sm me-0`}
          onClick={() => openPopupDelete()}>
          <IDelete className={'padding-right-4px'} width="18" height="15" fill={` ${'#128186'}`} />
          &nbsp; {labels.SUBJECTDELETE}
        </button>
        <button
          type="button"
          className={`btn ms-2 btn-new-rel btn-sm`}
          onClick={() => dowloadLocalDocument('BLUE-Ricerca-Massiva.pdf')}>
          {labels.MASSIVEINSTRUCTION}
        </button>
      </div>
    </div>
  )

  return (
    <>
      {isLoading && <PageSpinner text={labels.SPINNERTITLE} />}
      <div className="table-custom">
        <div className="tabs single">
          <div className="d-flex justify-content-between">
            <div>
              <SubjectTotalAndCallToAction
                total={subjectSummary?.total}
                remaining={subjectSummary?.available}
                buyLink={Constants.BLUE_BUY_LINK}
                used={subjectSummary?.used}
                licenseType={customerApplication?.licenseType}
                customerApplication={customerApplication}
              />
            </div>
            <div className="d-flex align-items-center">
              <BlueLanguageSelector />
              <div className="ps-2">
                {/* <BlueSubjectModal
                  subject={{}}
                  licenseExpired={expired || disableNewSubjectButtons}
                  disabled={props.licenseExpired}
                  subjectSummary={subjectSummary}
                  addBlueSubject={actions.addBlueSubject}
                  customLocations={props.locations}
                  showModal={showAddModal}
                  openModal={handleAddClick}
                  closeModal={handleAddClose}
                  getSubjects={getSubjects}
                /> */}
                {!(expired || disableNewSubjectButtons) && (
                  <button
                    type="button"
                    className="btn btn-primary btn-new-rel"
                    disabled={expired || disableNewSubjectButtons}
                    onClick={() => setResetForm(!resetForm)}>
                    <IPlus className={'padding-right-4px'} width="14" fill={` ${'#128186'}`} />
                    &nbsp; {labels.SUBJECTNEW}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* {subjectId && (
            <BlueSubjectModal
              id={subjectId}
              hideButton={true}
              showModal={!!subjectId}
              licenseExpired={expired}
              subject={currSubject}
              disabled={expired}
              subjectSummary={subjectSummary}
              addBlueSubject={actions.addBlueSubject}
              customLocations={props.locations}
              getSubjects={getSubjects}
              openModal={handleDetailClick}
              closeModal={handleDetailClose}
            />
          )} */}
          <Modal
            backdrop="static"
            show={!!subjectHistoryId}
            onHide={() => handleHistoryClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable>
            <div className="bar"></div>
            <Modal.Header closeButton className="ps-3">
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>STORICO RICERCHE</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BlueHistoryModal subjectId={subjectHistoryId} />
            </Modal.Body>
            <Modal.Footer>
              <BtnEmpty text="ANNULLA" handlerOnClick={() => handleHistoryClose()} />
            </Modal.Footer>
          </Modal>
          <div className="table-custom mt-1">
            <div className="tab-content tab-rel">
              <BlueSubjectList
                disabled={expired}
                resetForm={resetForm}
                subjects={subjects}
                getSubjects={getSubjects}
                setSubjects={setSubjects}
                selectedSubjects={selectedSubjects}
                setSelectedSubjects={setSelectedSubjects}
                actionsComponent={renderActionsComponent()}
                showHistory={handleHistoryClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
