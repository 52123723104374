import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Constants from '../../config/Constants'
import * as actions from '../../actions'

const AppBox = ({ mouseLeave }) => {
  const [availableSubjects, setAvailableSubjects] = useState(undefined)
  const location = useLocation()
  const auth = useSelector((state) => state.auth)

  const checkExpire = (application) => {
    if (!actions.isApplicationExpired(application)) {
      return 'fino al ' + new Date(application.expiration).toLocaleDateString('en-GB')
    } else {
      return 'scaduto il ' + new Date(application.expiration).toLocaleDateString('en-GB')
    }
  }

  useEffect(() => {
    const actualAppName = location.pathname.split('/')[2]?.toUpperCase()
    const application = auth?.customer?.customerApplications?.find(
      (ap) => ap.application.name === actualAppName
    )

    if (application) {
      actions.getSubjectSummary(application.application.name).then((res) => {
        if (res) {
          setAvailableSubjects(res.available)
        }
      })
    }
  }, [location.pathname, auth])

  const actualAppName = location.pathname.split('/')[2]?.toUpperCase()
  const application = auth?.customer?.customerApplications?.find(
    (ap) => ap.application.name === actualAppName
  )

  if (!application) {
    return null
  }

  const applicationName = application.application.name
  const buyURL = Constants.APPLICATIONS.find((a) => a.description === applicationName)?.buyLink
  const licenseString =
    application.licenseType === 'BOUGHT'
      ? 'Abbonamento ' + checkExpire(application)
      : 'In DEMO ' + checkExpire(application)

  return (
    <div id="appBox" aria-labelledby="navbarDropdown" onMouseLeave={() => mouseLeave()}>
      <div className="p-4">
        <h4>{application.application.name}</h4>
        {application.application.description}
        <br />
        {application.licenseType === 'BOUGHT' ? (
          <>
            <div>
              {application.type}
              {licenseString}
            </div>
            <div>
              <b>{availableSubjects}</b> soggetti rimanenti su&nbsp;
              <b>{application.maxSubjects}</b> acquistati
            </div>
          </>
        ) : application.application.name.toLowerCase() === 'comurei' ? (
          <div>ATTIVO per 1 soggetto</div>
        ) : (
          <div className={'row coraStar-scadenza'}>
            {licenseString} per&nbsp;<b>{application.maxSubjects}</b>&nbsp;soggetti
          </div>
        )}
      </div>
      <div className="bg-dark-blue">
        <ul className="nav flex-column p-4">
          <li>
            <Link
              onClick={() => window.open(application.application.videoUrl)}
              id="video"
              className="nav-link">
              VIDEO TUTORIAL
            </Link>
          </li>
          <li>
            <Link
              onClick={() => window.open(application.application.manualsUrl)}
              id="manuals"
              className="nav-link">
              MANUALI E NORMATIVA
            </Link>
          </li>
          <li>
            <Link onClick={() => window.open(buyURL)} id="buy" className="nav-link">
              Compra OnLine
            </Link>
          </li>
          <li>
            <Link
              onClick={() =>
                window.open('https://lnx.starinfostudio.it/wp-content/files_mf/Assistenza-TVQS.exe')
              }
              id="manuals"
              className="nav-link">
              Team Viewer
            </Link>
          </li>
          <li>
            <Link
              onClick={() =>
                window.open('https://lnx.starinfostudio.it/downloads/exe/SupremoP.exe')
              }
              id="manuals"
              className="nav-link">
              Supremo
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AppBox
