import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as actions from '../../actions'
import LoginForm from './LoginForm'
import Signin from './Signin'
import logo from '../../styles/images/logo.png'
import '../../styles/login.css'

const Login = () => {
  const [showLogin, setShowLogin] = useState(true)
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const errors = useSelector((state) => state.auth.errors)

  const loginUser = (userdata) => {
    if (document.getElementById('header'))
      document.getElementById('header').style.visibility = 'visible'

    dispatch(actions.login(userdata))
  }

  const registerUser = (newUser) => {
    dispatch(actions.registerUser(newUser))
  }

  if (auth.isAuth) {
    return <Redirect to={{ pathname: '/dashboard' }} />
  }

  return (
    <div className="login">
      <div>
        <img src={logo} alt="logo" className="logo-login" />
      </div>
      <div className="content-login">
        {showLogin ? (
          <div className="body-login small">
            <div className="form-login">
              <p className="benvenuto-inserisci">
                <strong>
                  Inserisci le tue <span>Credenziali</span>
                </strong>
              </p>
              <LoginForm submitCb={loginUser} errors={errors} />
            </div>
          </div>
        ) : (
          <div className="body-login big">
            <div className="form-login">
              <p className="benvenuto-inserisci">
                <strong>
                  Crea il tuo <span>Account</span>
                </strong>
              </p>
              <Signin
                submitCb={registerUser}
                errors={errors}
                showLogin={() => setShowLogin(true)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login
