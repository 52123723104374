import { useState, useEffect } from 'react'
import { BwmInput } from '../../shared/form/BwmInputB5'
import * as Constants from '../../../config/Constants'
import AutoCompileCustom from '../../shared/form/AutoCompileCustomB5'
import { ProvinceField } from '../../shared/form/ProvinceFieldB5'
import ErrorListAlert from '../../shared/form/ErrorListAlert'
import { ISave, IUndo } from '../../../styles/icons'
import * as actions from '../../../actions'
import { PageSpinner } from '../../shared/spinner/PageSpinner'
import { PopupSuccess } from '../../shared/PopupSuccess'
import { PopupError } from '../../shared/PopupError'
import * as Utility from '../../shared/Utility'
import { useSelector } from 'react-redux'
import getText from './labels'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

export default function BlueSubjectForm(props) {
  const customer = useSelector((state) => state.auth.customer)
  const lang = useSelector((state) => state.blueLanguage.language)
  const labels = getText(lang)
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({})

  const [showErrorAlert, setShowErrorAlert] = useState(false)

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCALCODE
    },
    {
      errorKey: 'denomination',
      errorLabel:
        initialValues.personType === Constants.PNF ? labels.DENOMINATION : labels.FIRSTLASTNAME
    },
    {
      errorKey: 'address',
      errorLabel: labels.ADDRESS
    },
    {
      errorKey: 'location',
      errorLabel: labels.LOCATION
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'postalCode',
      errorLabel: labels.POSTALCODE
    },
    {
      errorKey: 'email',
      errorLabel: labels.EMAILADDRESS
    },
    {
      errorKey: 'errorFiscalCode',
      errorLabel: labels.FISCALCODE
    }
  ]

  const validationSchema = Yup.object().shape({
    personType: Yup.string().required(labels.SELECTTYPEPERSON),
    denomination: Yup.string().required(labels.REQUIREDFIELD),
    fiscalCode: Yup.string().required(labels.REQUIREDFIELD),
    address: Yup.string().required(labels.REQUIREDFIELD),
    province: Yup.string().required(labels.REQUIREDFIELD),
    location: Yup.string().required(labels.REQUIREDFIELD),
    postalCode: Yup.string().required(labels.REQUIREDFIELD),
    email: Yup.string().email(labels.INVALIDEMAILFORMAT).required(labels.REQUIREDFIELD),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      () => labels.NOTVALID,
      (value) => !value
    )
  })

  const init = () => {
    if (!props.id) {
      setInitialValues({
        personType: Constants.PNF,
        customer: customer,
        errorFiscalCode: false
      })
    } else {
      setInitialValues({ ...props.subject, errorFiscalCode: false })
    }
  }

  useEffect(() => init(), [])
  useEffect(() => init(), [props.id])

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[1].errorLabel = labels.FIRSTLASTNAME
    else formErrorsTabMapping[1].errorLabel = labels.DENOMINATION
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <>
      <div className="row-detail form-on-bottom">
        {loading && <PageSpinner text={labels.SPINNERTITLE} />}
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}>
          {({ errors, values }) => (
            <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : 'form-on-bottom'}>
              {showErrorAlert &&
                touched &&
                errors &&
                typeof errors === 'object' &&
                Object.keys(errors).length > 0 && (
                  <div className={props.formOnBottom ? 'blue-form-on-bottom-error' : ''}>
                    <ErrorListAlert
                      label={labels.INCOMPLETEDATA}
                      errors={getErrors(errors, values.personType)}
                      hide={() => setShowErrorAlert(false)}
                    />
                  </div>
                )}
              <FormBody
                {...props}
                initialValues={initialValues}
                touched={touched}
                init={init}
                setTouched={setTouched}
                setLoading={setLoading}
                setShowErrorAlert={setShowErrorAlert}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  const lang = useSelector((state) => state.blueLanguage.language)
  const locations = useSelector((state) => state.locations.customLocations)
  const labels = getText(lang)

  const handleFiscalCode = (val) => {
    if (val) {
      actions
        .getBaseRegistryByFiscalCode(val)
        .then((baseRegistry) => {
          if (baseRegistry) {
            let newLocation = values.location || baseRegistry.location?.location || ''
            let newDenomination = values.denomination || baseRegistry.companyName || ''
            let newProvince = values.province || baseRegistry.location?.province || ''
            let newPostalCode = values.postalCode || baseRegistry.location?.postalCode || ''
            setValues({
              ...values,
              denomination: newDenomination,
              location: newLocation,
              province: newProvince,
              postalCode: newPostalCode,
              errorFiscalCode: false
            })
          }
        })
        .catch(() => {
          setValues({
            ...values,
            errorFiscalCode: true
          })
        })
    }
  }

  const handleInputAutocompile = (newLocation) => {
    newLocation = newLocation.toUpperCase()
    const locationObject = locations?.find((e) => {
      return e.name === newLocation
    })
    if (locationObject) {
      setValues({
        ...values,
        location: locationObject.name?.toUpperCase() || '',
        province: locationObject.province?.toUpperCase() || values.province || '',
        postalCode: locationObject.postalCode || values.postalCode || ''
      })
    } else {
      setValues({
        ...values,
        location: newLocation
      })
    }
  }

  const handlePersonTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    if (val === Constants.PF) {
      form.personType = val
      form.gender = 'FEMALE'
      form.companyName = null
    } else {
      form.personType = val
      form.gender = null
      form.firstName = null
      form.lastName = null
      form.birthDate = null
    }
    setValues(form)
  }

  const close = () => {
    props.init()
    props.setTouched(false)
    if (props.close) props.close()
  }

  const saveSubject = (subject) => {
    props.setLoading(true)
    actions
      .saveBlueSubject(subject)
      .then(
        () => {
          PopupSuccess({ text: 'Salvataggio effettuato' })
          close()
          props.getSubjects()
        },
        (err) => {
          PopupError({
            text: err.status === 511 ? labels.MAXSUBJECTSREACHED : labels.GENERICERROR,
            title: labels.WARNING,
            request: labels.CONTACTSUPPORT,
            close: labels.CLOSE
          })
        }
      )
      .then(() => {
        props.setLoading(false)
      })
  }

  const handleChangeValue = (val, name, upperCase = false, trim = false) => {
    const form = Object.assign({}, values)
    if (typeof val === 'string') {
      if (val && val !== '' && upperCase) val = val.toUpperCase()
      if (val && val !== '' && trim) val = val.trim()
    }
    form[name] = val && val !== '' ? val : null
    setValues(form)
  }

  const handleFormSubmit = async () => {
    const errors = await validateForm(values)
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true)
    } else {
      saveSubject(values)
      props.setShowErrorAlert(false)
    }
  }

  return (
    <div className={'col-12'}>
      <div className="big pe-4">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12 mb-3 ps-1 pt-2">
              <div className="input-group">
                <div className="inputRadio me-5" style={{ fontSize: '15px' }}>
                  <label
                    disabled={props.disabled}
                    className="form-check-label d-flex align-items-center"
                    htmlFor={`pType1${values?.id}`}
                    onClick={() => handlePersonTypeClick(Constants.PNF)}>
                    <input
                      disabled={props.disabled}
                      className="form-check-input pe-1 me-2 mt-0"
                      style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                      type="radio"
                      checked={values.personType === Constants.PNF}
                      onChange={() => {}}
                    />
                    {labels.LEGALPERSON}
                  </label>
                </div>
                <div className="inputRadio" style={{ fontSize: '15px' }}>
                  <label
                    disabled={props.disabled}
                    className="form-check-label d-flex align-items-center"
                    htmlFor={`pType2${values?.id}`}
                    onClick={() => handlePersonTypeClick(Constants.PF)}>
                    <input
                      disabled={props.disabled}
                      className="form-check-input pe-1 me-2 mt-0"
                      style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                      type="radio"
                      checked={values.personType === Constants.PF}
                      onChange={() => {}}
                    />
                    {labels.PHYSICALPERSON}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-2 pe-2 mb-2">
              <BwmInput
                disabled={props.disabled}
                name="fiscalCode"
                label={labels.FISCALCODE}
                placeholder={labels.FISCALCODE}
                className="form-control"
                value={values.fiscalCode}
                error={errors.fiscalCode || errors.errorFiscalCode}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'fiscalCode', true)}
                onBlur={(e) => handleFiscalCode(e.target.value)}
              />
            </div>
            <div className="col-6 pe-2 mb-2">
              <BwmInput
                disabled={props.disabled}
                name="denomination"
                maxLength="60"
                label={
                  values.personType === Constants.PF ? labels.FIRSTLASTNAME : labels.DENOMINATION
                }
                placeholder={
                  values.personType === Constants.PF ? labels.FIRSTLASTNAME : labels.DENOMINATION
                }
                className="form-control font-weight-bold font-size-big"
                value={values.denomination}
                error={errors.denomination}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'denomination', true)}
              />
            </div>
            <div className="col-4 pe-2 mb-2">
              <BwmInput
                disabled={props.disabled}
                name="address"
                label={labels.ADDRESS}
                placeholder={labels.ADDRESS}
                className="form-control"
                value={values.address}
                error={errors.address}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'address', true)}
              />
            </div>
            <div className="col-2 pe-2 mb-2">
              <AutoCompileCustom
                disabled={props.disabled}
                label={labels.LOCATION}
                id="location"
                filter={locations}
                value={values.location}
                error={errors.location}
                touched={props.touched}
                handleInputAutocompile={(e) => handleInputAutocompile(e)}
              />
            </div>
            <div className="col-1 pe-2 mb-2">
              <ProvinceField
                disabled={props.disabled}
                placeholder={labels.OUTSIDEITALY}
                label={labels.PROVINCE}
                province={values.province}
                error={errors.province}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'province', true)}
              />
            </div>
            <div className="col-1 pe-2 mb-2">
              <BwmInput
                disabled={props.disabled}
                name="postalCode"
                placeholder={labels.POSTALCODE}
                label={labels.POSTALCODE}
                className="form-control"
                value={values.postalCode}
                error={errors.postalCode}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'postalCode', true)}
              />
            </div>
            <div className="col-3 pe-2 mb-2">
              <BwmInput
                disabled={props.disabled}
                name="email"
                placeholder={labels.EMAILADDRESS}
                label={labels.EMAILADDRESS}
                className="form-control"
                value={values.email}
                error={errors.email}
                touched={props.touched}
                onChange={(e) => handleChangeValue(e.target.value, 'email', true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row mt-1 row align-item-center">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn btn-outline-primary btn-empty px-4 btn-sm me-4"
              onClick={close}>
              <IUndo className={'padding-right-4px svg'} width="20" fill={'#128186'} />
              &nbsp; {labels.CANCEL}
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-new-rel px-5 ml-2"
            onClick={() => handleFormSubmit()}>
            <ISave className={'padding-right-4px svg'} width="20" fill={'#FFFFFF'} />
            &nbsp; {labels.CONFIRM}
          </button>
        </div>
      </div>
    </div>
  )
}
